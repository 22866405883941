import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";
import { useLocation  } from 'react-router-dom';

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const InvestmentEdit = () => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const location = useLocation();
    const [newFileList, setNewFileList] = useState([]);

    const navigate = useNavigate();

    const record = location.state && location.state.record;

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => {
      setFileList(newFileList)
      setNewFileList(newFileList)
    };

    useEffect(() => {
      const fetchData = async () => {
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: record.imageUrl,
          }
        ]);
      };
      fetchData();
    }, []);

    const uploadButton = (
        <div style={{width:"1000px" }}>
          <img alt="plus" src="/images/uploadplus.png" />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Logo Yükle
          </div>
        </div>
      );

      const onFinish = async (values) => {
        const currentDate =new Date()
        const investmentData = {
          firstTransactionDate: record.firstTransactionDateEdit??currentDate,
          firstUserId: record.firstUserId??localStorage.getItem("uid"),
          lastTransactionDate: currentDate,
          lastUserId: localStorage.getItem("uid"),
          isDeleted: record.isDeleted,
          url: values.url,
        };

        values.image= newFileList.length > 0 ? values.image : fileList.length>0 ? record.imageUrl : null;
          
          if(values.image===null){
            NotificationService.openErrorNotification({
              title: 'Geçersiz Resim',
              description: "Lütfen resim seçiniz!",
              placement: 'topRight'
            });
            return null;
          }
          try {
            if (newFileList.length>0) {
              investmentData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(odsCollections.ourInvestment, values.image.file);}
            const result = await FirebaseService.updateInvestment(record.key,investmentData);
            if (result.success) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: result.message,
                placement: 'topRight'
              });
              setTimeout(() => {
                navigate("/adminpanel");
              }, 2000);
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });}
            
            }
          catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
          }     
    };    

    const validateURL = (rule, value, callback) => {
      if (value === undefined || value === null || value === '') {
        callback();
        return;
      }
    
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    
      if (!urlPattern.test(value)) {
        callback('Lütfen geçerli bir URL giriniz.');
        return;
      }
    
      callback();
    };

   return ( <div>
    
         <Form
            name="basic"
            initialValues={{
                url: record.url ,
              }}
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

          <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
            >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="partner-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
                    </Modal>
          </Space>

          <Form.Item
            name="url"
            rules={[
              { validator: validateURL }
            ]}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Url Ekle"/>
            </Form.Item>
           
            <Form.Item
            wrapperCol={{
                offset: 3,
                span: 13,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default InvestmentEdit;