import React,{useState,useEffect} from "react";
import { Card, Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

const data = [
  {
    id: 1,
    image: '/images/service1.png',
    title:'Hibe & Teşvik Almak İstiyorum',
  },
  {
    id: 2,
    image: '/images/service2.png',
    title:'İhracat Yapmak İstiyorum',
  },
  {
    id: 3,
    image: '/images/service3.png',
    title:'SAP ile Dijitalleşme İstiyorum',
  },
  {
    id: 4,
    image: '/images/service4.png',
    title:'Kurumsal Gelişim İstiyorum',
  }
];

const OurServices = () => {
  const [lastServices, setLastServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //const allLastData = await FirebaseService.getLastFourServices();
      const allLastData = await FirebaseService.getServiceDataFilterHomePageLast4();
      setLastServices(allLastData);
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const goEditPage = (record) => {
    navigate('/serviceedit', { state: { record } })
  };
  const goAddPage = (record) => {
    navigate('/serviceadd')
  };

  
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <h3 style={{ marginBottom: '16px',textAlign:'justify',color:'#1A446C' }}>Hizmetlerimizi Düzenle</h3>
      <p style={{ marginBottom: '16px',textAlign:'justify',color:'#1A446C' }}>(Anasayfada gösterilen 4 hizmet)</p>
        <Card style={{width:'400px',borderRadius:'0px', border:"1px solid"}}>
         <Row gutter={16}>
          {lastServices.map((item) => (
              <Col span={12} key={item.key}>
                <Card bordered={false} className="custom-card-services" onClick={()=>goEditPage(item)} style={{cursor:"pointer"}}>
                  <div className="ant-card-body-style-2">
                    <img alt="df" src={item.imageUrl} className="ant-card-img" />
                    <span style={{ color: "#1A446C", fontWeight: 'bold', fontSize: '13px', textTransform: "capitalize", display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 2 }}>
    {item.title}
</span>
                  </div>
                </Card>
              </Col>
            ))}
          <Col span={12} style={{marginBottom:'50px',marginLeft:'20px'}} key="add">
              <Card bordered={false} className="custom-card-dash-plus-card" onClick={()=>goAddPage()} style={{cursor:"pointer", marginTop:"20px"}}>
                  <Button
                      shape="circle"
                      icon={<img alt="plus" src="/images/plusbutton.png" />}
                      style={{ alignSelf: 'center',background:"transparent",border:"none",color:"#1A446C"}}
                      href="/serviceadd"
                      >
                    Hizmet Ekle
                  </Button>
              </Card>
          </Col>
        </Row>
        </Card>
    </div>

};
  export default OurServices;