import React, { useState, useEffect } from 'react';
import FirebaseService from '../../services/firebaseService';
import { Button, Form, Select } from 'antd';

const AskAdvisor = () => {
  const [advisor, setAdvisor] = useState([]);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const advisors = await FirebaseService.getAdvisorData();
      const advisorsOption = advisors
        .filter((f) => !f.isDeleted)
        .map((advisor) => ({
          value: advisor.key,
          label: advisor.nameSurname,
          email: advisor.email
        }));
      setAdvisor(advisorsOption);
    };

    fetchData();
  }, []);

  const handleAdvisorChange = (value) => {
    //console.log("Seçilen Danışman:", value);
    const selected = advisor.find((adv) => adv.value === value);
    setSelectedAdvisor(selected);
   // console.log("Seçilen Danışman:", selected);
  };
  


  const handleSubmit = () => {
    //console.log("handleSubmit fonksiyonu çalıştı");

    if (selectedAdvisor) {
     // console.log("kbkjbkb")
  
      const mailtoLink = `mailto:${selectedAdvisor.email}?subject=Soru%20Sormak%20Istiyorum`;
      window.location.href = mailtoLink;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{marginRight:"350px", marginBottom: '20px', textAlign: 'left', }}>
      <div style={{ color: '#1A446C', fontWeight: '800' , padding:"15px 30px"}} >
          Mail Gönder
        </div>
      </div>
      <Form.Item
        name="advisorId"
        label={<label style={{ color: '#1A446C', fontWeight: '500' }}>Danışman Tercihi</label>}
        wrapperCol={{
          span: 10,
        }}
        style={{
          maxWidth: '100%',
          textAlign: 'center',
          marginBottom: '8px',
        }}
        rules={[
          {
            message: 'Lütfen danışman seçiniz!',
          },
        ]}
      >
        <Select
          placeholder="Danışman"
          style={{ borderRadius: 0, borderColor: '#1A446C80', textAlign: 'left', width: '300px' }}
          suffixIcon={<img style={{ width: '20px' }} alt="edit" src="/images/downarrow.png" />}
          options={advisor}
          size="large"
          onChange={handleAdvisorChange}

        />
      </Form.Item>
      <Button  onClick={handleSubmit} style={{ backgroundColor: '#1A446C', color: 'white', fontWeight: '500', marginTop:"20px" , marginLeft:"400px"}}>
        Gönder
      </Button>
    </div>
  );
};

export default AskAdvisor;
