import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import FirebaseService from '../../services/firebaseService';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import NotificationService from "../../services/antNotificationService";

const SurveyDetail = () => {
  const location = useLocation();
  const { record } = location.state;
  const [selectedChoices, setSelectedChoices] = useState({});
  const navigate = useNavigate();

  const handleRadioChange = (questionIndex, choiceIndex) => {
    setSelectedChoices((prevChoices) => ({
      ...prevChoices,
      [questionIndex]: choiceIndex,
    }));
  };

  const saveChoices = async () => {
    try {
      const userId = localStorage.getItem("uid"); 
      const updatedRecord = await FirebaseService.updateSurveyAnswers(record.key, selectedChoices, userId);
      //console.log('Güncellenmiş Kayıt:', updatedRecord);
  
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Anket cevaplarınız başarıyla kaydedildi.',
        placement: 'topRight'
      });
  
      navigate('/survey'); 
    } catch (error) {
      //console.error('Kayıt güncellenirken bir hata oluştu:', error.message);
  
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.',
        placement: 'topRight'
      });
    }
  };
  return (
    <div style={{ textAlign: 'left', padding: '10px 50px' }}>
      <p style={{ textAlign: 'right', color: "#1A446C80" }}>Bitiş Tarihi: {record.endDate}</p>
  
      <h2 style={{ textAlign: 'left' }}>{record.title}</h2>
  
      {record.questions.map((question, questionIndex) => (
        <div key={questionIndex} style={{ marginTop: "40px" }}>
          <h4 style={{ marginTop: "40px" }}>Soru {questionIndex + 1}</h4>
          <hr />
          <p>{question.question}</p>
          {/* Wrap each choice in its own div for vertical stacking */}
          {question.answer.map((choice, choiceIndex) => (
            <div key={choiceIndex} style={{ marginBottom: "10px" /* Add spacing between choices */ }}>
              <label className="radio-label">
                <input
                  type="radio"
                  name={`question_${questionIndex}`}
                  checked={selectedChoices[questionIndex] === choiceIndex}
                  onChange={() => handleRadioChange(questionIndex, choiceIndex)}
                />
                <span style={{ position: "relative", bottom: "3px" }}>{choice.choice}</span>
              </label>
            </div>
          ))}
        </div>
      ))}
  
      <Button onClick={saveChoices} style={{ backgroundColor: '#1A446C', color: 'white', fontWeight: '500', marginTop: "20px", float: "right" }}>Seçimleri Kaydet</Button>
    </div>
  );
  
};

export default SurveyDetail;
