import React,{ useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Button, Progress } from 'antd';
import moment from 'moment';

const SapDetail = () => {
  const location = useLocation();
  const { record } = location.state;
 

  const calculateProgress = (startDate, endDate, projectProcess) => {
    if (!startDate || !endDate) {
        return { progress: 'NaN', start: null, end: null, processes: [] };
    }

    const start = moment(new Date(startDate.seconds * 1000 + startDate.nanoseconds / 1000000));
    const end = moment(new Date(endDate.seconds * 1000 + endDate.nanoseconds / 1000000));

    if (!start.isValid() || !end.isValid()) {
      //  console.log('Invalid start or end date:', start, end);
        return { progress: 'NaN', start, end, processes: [] };
    }

    const currentDate = moment();
    const totalDays = end.diff(start, 'days');
    const daysFromStart = currentDate.diff(start, 'days');
    const daysFromEnd = end.diff(currentDate, 'days');

    const processes = projectProcess || [];

    const formattedProcesses = processes.map(process => ({
        name: process.name,
        date: process.date ? formatDate(process.date) : null,
    }));

   // console.log('Formatted Processes:', formattedProcesses);

    const sortedProcesses = formattedProcesses
        .filter(process => process.date !== null)
        .sort((a, b) => moment(a.date, 'DD/MM/YYYY').isBefore(moment(b.date, 'DD/MM/YYYY')) ? -1 : 1);

   // console.log('Sorted Processes:', sortedProcesses);

    // Ensure progress is between 0 and 100
    const progressPercent = Math.max(0, Math.min(100, (daysFromStart / totalDays) * 100));

    return { progress: progressPercent.toFixed(2), start, end, processes: sortedProcesses };
};

const formatDate = (timestamp) => {
    if (!timestamp) {
        return null;
    }

    const seconds = timestamp.seconds || 0;
    const nanoseconds = timestamp.nanoseconds || 0;

    const dateObject = new Date(seconds * 1000 + nanoseconds / 1000000);

    if (isNaN(dateObject.getTime())) {
        return null;
    }

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear().toString();

    return `${day}/${month}/${year}`;
};


  const progressData = calculateProgress(record.startDate, record.endDate, record.projectProcess);

  
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{padding:"20px"}}>
    <Card
        title={<h2 style={{ fontWeight: 'bold' , color:"#A47B5A"}}>SAP</h2>}
        style={{textAlign: 'left' }}
        extra={
          <p  style={{ color:"#1A446C80"}}>{formatDate(record.firstTransactionDate)}</p>
        }
      >
        <div style={{justifyContent:"space-between", display:"flex", padding:"20px"}}>
          <div  style={{display:"flex"}}>

          <div>
          {Array.isArray(record.managerName) && (
            <>
                <div>Yönetici:
                {record.managerName.map((item) => (
                <span key={item.nameSurname}> {item.nameSurname}</span>
                ))}
                </div>
            </>
           )}

            {Array.isArray(record.businessDevelopmentName) && (
            <>
                <div>İş Geliştirme Danışmanı:
                {record.businessDevelopmentName.map((item) => (
                <span key={item.nameSurname}> {item.nameSurname}</span>
                ))}
                </div>
            </>
           )}     
            </div>
          </div>
         
        </div>
        <Progress showInfo={false} style={{padding:"0px 20px"}} strokeColor="#A47B5A" percent={calculateProgress(record.startDate, record.endDate, record.projectProcess).progress} status="active" />

        <div style={{padding:"0px 20px", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

            <div style={{ flex: '1', textAlign:"left" }}>
                <div style={{ color: "#1A446C" }}>Sözleşme Başlangıcı </div>
                <div style={{ color: "#1A446C" }}>{formatDate(record.startDate)} </div>
            </div>

            <div style={{ flex: '1' }}>
            <div style={{ color: "#1A446C" }}>
                {progressData.processes[0] ? progressData.processes[0].name : 'No Name'}
            </div>
            <div style={{ color: "#1A446C" }}>
                {progressData.processes[0] ? (progressData.processes[0].date) : 'No Date'}
            </div>
            </div>


            <div style={{ flex: '1' }}>
                <div style={{ color: "#1A446C" }}>
                {progressData.processes[1] ? progressData.processes[1].name : 'No Name'}
                </div>
                <div style={{ color: "#1A446C" }}>
                {progressData.processes[1] ? (progressData.processes[1].date) : 'No Date'}
            </div>
            </div>

            <div style={{ flex: '1',textAlign:"right"  }}>
                <div style={{ color: "#1A446C" }}>Sözleşme Bitişi</div>
                <div style={{ color: "#1A446C" }}>{formatDate(record.endDate)} </div>

            </div>
        </div>

      </Card>
      <div style={{ background: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginBottom: '10px', marginTop:"20px" }}>
            <p onClick={handleToggle} style={{ cursor: 'pointer', textAlign:"left",color: "#1A446C", fontWeight:"bold" }}>
            Ürünler <img src={isExpanded ?  'images/uparrow.png' : 'images/downarrow.png' } width={20} height={10} />
            </p>
            
            {isExpanded && (
                <div style={{textAlign:"left", color:"#1A446C"}}>
                {record.productLists.map((item) => (
                <div key={item.name}> {item.name} : {item.piece} Adet </div>

                ))}
                </div>
            )}
      </div>
     
     
      <div>
      {record.serviceSublists.map((sublist, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <p style={{ color: "#1A446C", textAlign:"left" }}>{sublist.name}</p>
          <Progress percent={sublist.percent}  style={{padding:"0px 20px"}} strokeColor="#A47B5A"/>
        </div>
      ))}
    </div>
     </div>
  );
};

export default SapDetail;
