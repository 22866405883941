import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
import FirebaseService from '../../services/firebaseService';
import MapComponent from './mapsComponent';

const Map = () => {
  const [address, setAddress] = useState([]);
  const [isGlobal, setIsGlobal] = useState(false);

  useEffect(() => {
    fetchData();
  }, [isGlobal]);

  const fetchData = async () => {
    const data = await FirebaseService.getAddressDataFilter();
    const filteredAddress = isGlobal
      ? data.filter((item) => item.global)
      : data.filter((item) => !item.global);

    setAddress(filteredAddress);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
       alignItems: 'center',
       marginTop:"20px"
      //  minHeight: '94vh',
     //   padding: '3%',
      }}
    >
      <div style={{ marginBottom: '16px' }}>
       
        <Button
          onClick={() => setIsGlobal(false)}
          style={{
            background: 'transparent',
            backgroundColor : !isGlobal ? '#1A446C' : 'white',
            borderColor: !isGlobal ?  '#1A446C' : '#1A446C',
            color: !isGlobal ?  'white' : '#1A446C',
            fontWeight: 'bold',
            borderRadius: '0px',
            width:"150px",
            height:"50px"
          }}
        >
          Türkiye
        </Button>
        <Button
          onClick={() => setIsGlobal(true)}
          style={{
            background: 'transparent',
            backgroundColor :  isGlobal ? '#1A446C' : 'white',
            borderColor: isGlobal ? '#1A446C' : '#1A446C',
            color: isGlobal ? 'white' : '#1A446C',
            fontWeight: 'bold',
            borderRadius: '0px',
           
            width:"150px",
            height:"50px"
          }}
        >
          Global
        </Button>
      </div>

      {address.map((record) => (
        <Card
          key={record.key}
          title={<span style={{ fontWeight: 'bold', color: "#A47B5A" }}>{record.city}</span>}
          style={{ width: "80%", margin: '16px', textAlign: 'left' }}
        >
          <div>
            <MapComponent coordinates={[record.latitude, record.longitude]} />
          </div>
          <p style={{ color: "#1A446C" }}>Kurumsal Hat: {record.address}</p>
          <p style={{ color: "#1A446C" }}>Kurumsal Hat: {record.corporateLine}</p>
          <p style={{ color: "#1A446C" }}> {record.corporateEmail}</p>
        </Card>
      ))}

      {address.length === 0 && <p>Henüz adres bulunmamaktadır...</p>}
    </div>
  );
};

export default Map;
