import React, { useState, useEffect } from "react";
import { Card, Row, Empty } from 'antd';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom'; 
import '../layouts.css';

const WebOurServices = () => {
  const [servicesData, setServicesData] = useState([]);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const goDetailPage = (record) => {
    navigate('/service-detail', { state: { record } });
  };

  const getServiceData = async () => {
    try {
      const data = await FirebaseService.getServiceDataFilterHomePageLast4();
      setServicesData(data);
    } catch (error) {
     // console.error('Hizmet verisi getirme hatası:', error);
    }
  };

  useEffect(() => {
    getServiceData();
  }, []); 

  const displayedServices = servicesData.slice(0, 4);

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <h3 style={{ marginBottom: '16px', textAlign: 'justify', color: '#1A446C', marginTop: '60px' }}>Hizmetlerimiz</h3>

      {servicesData.length === 0 ? (
         <p style={{ textAlign: 'justify', color: '#1A446C', marginLeft: '5px' }}>Hizmetimiz bulunmamaktadır.</p>
      ) : (
        <Card style={{ width: '1050px', border: "none" }}>
          <Row gutter={28}>
            {displayedServices.map((item) => (
              <Card bordered={false} className="custom-card-our-services" key={item.id} onClick={() => goDetailPage(item)}
              style={{
                padding: 0, marginRight: '10px' ,
                color: isHovered ? '#001F3F' : '#1A446C',
                cursor: 'pointer',
                position: 'relative', // Add this line
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
                <div className="ant-card-body-style">
                  <img alt={item.title} src={item.imageUrl} className="ant-card-img-service" style={{ width: "250px", height: "200px", borderRadius: '15px', objectFit:"cover" }} />
                  <span style={{ color: "#1A446C", fontWeight: 'bold', fontSize: '13px' }}>{item.title}</span>
                  <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'pointer',
                  }}
                ></div>
                </div>
              </Card>
            ))}
          </Row>
        </Card>
      )}
    </div>
  );
};

export default WebOurServices;
