import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";



const BlogDetail = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { state } = location;
  const blogItem = state && state.record;

  const currentUser = { id: localStorage.getItem("uid") }; 
  const [liked, setLiked] = useState(false);
  const [favoriteUserIdCount, setFavoriteUserIdCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);

    if (blogItem && blogItem.favoriteUserId) {
      if (blogItem.favoriteUserId.includes(currentUser.id)) {
        setLiked(true);
      } else {
        setLiked(false);
      }
  
      setFavoriteUserIdCount(blogItem.favoriteUserId.length);
    } else {
     
      setLiked(false);
      setFavoriteUserIdCount(0);
    }
  }, [blogItem, currentUser.id]);
  
  const handleLikeToggle = async () => {
   
    setLiked((prevLiked) => !prevLiked);
  
    await updateBlogFavoriteStatus(blogItem.key, currentUser.id, !liked);
  
  
    setFavoriteUserIdCount(
      !liked ? favoriteUserIdCount + 1 : favoriteUserIdCount - 1
    );
  };
  const updateBlogFavoriteStatus = async (blogItem, userId, liked) => {
    try {
      if (!blogItem) {
       // console.error('Invalid blogsItemId');
        return;
      }
   
     
      await FirebaseService.updateBlogFavoriteStatus(blogItem, userId, liked);
    //  console.log('Favorite status updated successfully.başarılı');
    } catch (error) {
     // console.error('Error updating favorite status:', error);
    }
  };
  const goAuthorDetailPage = (record) => {
    navigate('/author-detail', { state: { record } });
  };
  if (!blogItem) {
   
    return <div>Veri bulunamadı</div>;
  }
  return (
  <div style={{margin:"3% 0 0 5%"}}>
    <Card
    style={{
      width: 1000, 
      border:"none"
    }}
      >
          {blogItem.imageUrl && (
  <div
    style={{
      width: '100%',
      height: '300px',
      borderRadius: '15px',
      overflow: 'hidden', 
    }}
  >
    <img
      src={blogItem.imageUrl}
      alt="Blog Resmi"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',  
      }}
    />
  </div>
)}
      
        <div style={{textAlign: "start", marginTop:"30px"}}>
        {currentUser.id ? (
         <div style={{ display: "flex", alignItems: "center", float: "right", marginTop: 0 }}>
         <img
           alt="like"
           src={liked ? "../images/likedIcon.png" : "/images/likeicon.png"}
           style={{ width: "38px", cursor: 'pointer' }}
           onClick={handleLikeToggle}
         />
         <h4 style={{ marginLeft: "10px", marginTop: "10px", color:'#1A446C' }}>{favoriteUserIdCount}</h4>
       </div>
        ) : (
         <></>
        )}      
           <h3 style={{ color: '#1A446C' }}>{blogItem.title} </h3>
           <p  style={{
        color: isHovered ? '#001F3F' : '#1A446C', 
        cursor: 'pointer',
      }} 
      onClick={() => blogItem.authorNameSurname && goAuthorDetailPage(blogItem)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
 {blogItem.authorNameSurname ? (
  <span>{blogItem.authorNameSurname}</span>
) : (
  <span>Yazar bulunmamaktadır</span>
)}</p>


          <div style={{ color: '#1A446C', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', height: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: blogItem.description }} />
          </div>

          {blogItem.url && (
            <div>
              <p style={{ color: '#1A446C' }}>Blogun Devamına Buradan Ulaşabilirsiniz;</p>
              <p><a href={blogItem.url} target="_blank" rel="noopener noreferrer">{blogItem.url}</a></p>
            </div>
          )}
        </div>
      </Card>
  </div>
);
};
export default BlogDetail;