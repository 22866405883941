import React,{useState,useEffect,useRef} from "react";
import { Button, Form, Input, Select, DatePicker, Upload, Space, Checkbox, Divider,InputNumber } from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  {odsCollections, consultingOptions, Process, Currencies}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined,MinusOutlined } from '@ant-design/icons';
import IpmProjectBasedList from "./ProjectBasedSupports/ipmProjectBasedList";
import { useLocation  } from 'react-router-dom';
const { RangePicker } = DatePicker;

const onFinishFailed = (errorInfo) => {
  NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
};

const IpmProjectAdd = () => {

    const [users, setUsers] = useState([]);
    const [randomId, setRandomId] = useState("");
    const [advisor, setAdvisor] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [process, setProcess] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [projectbasedList, setProjectBasedList] = useState([]);
    const [recordIpm, setRecordIpm] = useState({});
    const navigate = useNavigate();
    const [isFinish, setIsFinish] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [projectTotalAmount, setProjectTotalAmount] = useState(0);

     const [fetchProjectBased, setFetchProjectBased] = useState(() => async () => {});

    const location = useLocation();
    const ipmprojectbasedAddEdit = location.state && location.state.result

    function generateRandomId(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randomId = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters.charAt(randomIndex);
      }
    
      return randomId;
    }

    useEffect(() => {
      window.scrollTo(0, 0);

      const random = generateRandomId(10);
    setRandomId(random);
    const control = JSON.parse(localStorage.getItem('ipmNewProjectId')) || [];
    
    const fetchProjectBased = async () => {
      const records = JSON.parse(localStorage.getItem('ipmProjectBased')) || [];
      if (records.length > 0) {
        const projectbased = await FirebaseService.getIpmProjectBasedById(records);
        setProjectBasedList(projectbased);
        const projectBasedListTotal = projectbased.filter(f=>!f.isDeleted).reduce((acc, project) => {
          project.applicationAmount.forEach((amountObj) => {
            acc += amountObj.amount || 0;
          });
          project.supportAmount.forEach((amount)=>{
            acc += amount.amount || 0;
          })
          return acc;
        }, 0);
        setProjectTotalAmount(projectBasedListTotal)
        setTotalAmount(projectBasedListTotal);
      }
    };
    if (ipmprojectbasedAddEdit !== undefined && ipmprojectbasedAddEdit) {
      setRandomId(control);
      fetchProjectBased(); 
    } else {
      localStorage.setItem('ipmNewProjectId', JSON.stringify(random));
      localStorage.setItem('ipmProjectBased', JSON.stringify([]));
      setProjectBasedList([]);
    }

      setRecordIpm({addIpm:true})
      
      const fetchData = async () => {
        const users = await FirebaseService.getUserData();
        const filteredUser = users.filter(f=>f.userType.includes('user')&&f.userType.length===1)
        const usersOption = filteredUser.map(user => ({
          value: user.userId,
         // label: user.nameSurname + "-" + user.email +"-" +user.companyName,
          label: user.nameSurname

        }));
        setUsers(usersOption)

          setProjectNames(consultingOptions);
          setProcess(Process);
          setCurrencies(Currencies);

          const advisors = await FirebaseService.getAdvisorData();
          const advisorsOption = advisors.filter(f=>!f.isDeleted).map(advisor => ({
            value: advisor.key,
            label: advisor.nameSurname,
          }));
          setAdvisor(advisorsOption)
      };

      fetchData();
    }, []);

    const handleGenelSupportChange = (changedFields) => {
      const isGenelSupportChanged = changedFields.some(
        (field) => field.name[0] === 'genelSupportReferenced'
      );
  
      if (isGenelSupportChanged) {
        const newTotalAmount = changedFields.reduce((acc, field) => {
          if (field.name[2] === 'price' || field.name[2] === 'approvedPrice') {
            acc += field.value || 0;
          }
          return acc;
        }, 0);
  
        setTotalAmount(newTotalAmount+projectTotalAmount);
      }
    };

      const onFinish = async (values) => {
        const currentDate =new Date()

        const selectedAdvisorIds = values.businessDevelopmentName;
        const selectedManagerIds = values.operationName;

        const advisorData = selectedAdvisorIds.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });

        const managerData = selectedManagerIds.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });
        const records = JSON.parse(localStorage.getItem('ipmProjectBased')) || [];

        const projectData = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          projectName: values.projectName,
          projectProcess : [{name:values.process1,date:new Date(values.process1Date)},{name:values.process2,date:new Date(values.process2Date)}],
          userId:values.userId,
          userNameSurname:users.find(f=>f.value===values.userId).label,
          businessDevelopmentName:advisorData,
          operationName:managerData,
          isFinish:isFinish,
          startDate:new Date(values.startEndDate[0]),
          endDate:new Date(values.startEndDate[1]),
          genelSupportReferenced:values.genelSupportReferenced.map(step => ({
            text:step.text,
            price:step.price,
            currency:step.currency,
          })),
          genelSupportApproved:values.genelSupportReferenced.map(step => ({
            text:step.text,
            price:step.approvedPrice,
            currency:step.currency,
          })),
          ipmProjectsId:records,
          totalAmountEarned:totalAmount
        }; 
      //  console.log(projectData);
        try {
      
          const result = await FirebaseService.addRecording(odsCollections.ipm, projectData);
           if (result.success) {
             NotificationService.openSuccessNotification({
               title: 'İşlem Başarılı',
               description: result.message,
               placement: 'topRight'
             });
             setTimeout(() => {
               navigate("/ipmprojectlist",{ state: { result }});
             }, 2000);
           } else {
             NotificationService.openErrorNotification({
               title: 'İşlem Başarısız',
               description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
               placement: 'topRight'
             });}    
         }
          catch (error) {
   
           NotificationService.openErrorNotification({
             title: 'İşlem Başarısız',
             description: "Bilinmeyen bir hata ile karşılaşıldı.",
             placement: 'topRight'
           });
         }
    }; 

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onFieldsChange={(_, allFields) => handleGenelSupportChange(allFields)}
        >

            <div className="news-Edit-container">
              <Form.Item 
              name="projectName"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen şirket seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 15,
                }}
                style={{
                maxWidth: "100%",
                }}
              > 
                  <Select
                        placeholder="Proje Adı"
                        style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        options={projectNames}
                        size="large"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                          </>
                        )}
                      />                
              </Form.Item>
            </div>

            <div className="news-Edit-container">
              <Form.Item 
              name="userId"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen şirket seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 15,
                }}
                style={{
                maxWidth: "100%",
                }}
              > 
                  <Select
                        placeholder="Kullanıcı Adı"
                        style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        options={users}
                        size="large"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                          </>
                        )}
                      />                
              </Form.Item>
            </div>

            <div className="news-Edit-container">
            <Form.Item 
            name="operationName"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>Operasyon Danışmanı</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"start"
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen danışman seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="Danışman"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div>

              <div className="news-Edit-container">
            <Form.Item 
            name="businessDevelopmentName"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>İş Geliştirme Danışmanı</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen danışman seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="İş Geliştirme Danışmanı"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div>
              <Form.Item
                name="startEndDate"
                rules={[{ required: true, message: 'Lütfen başlangıç ve bitiş tarihlerini giriniz!' }]}
                wrapperCol={{
                  span: 14,
                  }}
                  style={{
                  display:"left",
                  maxWidth: "100%",
                  }}
              >
                  <RangePicker placeholder={["Sözleşme Başlangıç","Sözleşme Bitiş"]}   
                  separator={<><span style={{ transform: 'rotate(0deg)' }}><MinusOutlined /></span></>}
                allowClear style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} size='middle' />
            </Form.Item>
            <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
              <div className="news-Edit-container">

              <Form.Item 
                name="process1"
                rules={[{ required: true, message: 'Lütfen süreç ismini giriniz!' }]}
                wrapperCol={{
                  span: 30,
                  }}
                  style={{
                  width: "335px",
                  }}
                > 
                  <Select
                        placeholder="Süreç Ekle"
                        style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        options={process}
                        size="large"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                          </>
                        )}
                      />                
              </Form.Item>
              </div>
            <Form.Item  
            name="process1Date"
            rules={[
                  {
                  required: true,
                  message: 'Lütfen tarih seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 25,
                }}
                style={{
                display:"left",
                width: "335px",
                }}>
              <DatePicker 
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} placeholder="Tarih Ekle" size="large"/>
            </Form.Item>  
            </Space>  

            <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
            <div className="news-Edit-container">

            <Form.Item 
              name="process2"
              rules={[{ required: true, message: 'Lütfen süreç ismini giriniz!' }]}
              wrapperCol={{
                span: 25,
                }}
                style={{
                width: "335px",
                }}
              > 
                <Select
                      placeholder="Süreç Ekle"
                      style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      options={process}
                      size="large"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0',
                            }}
                          />
                        </>
                      )}
                    />                
              </Form.Item>
              </div>

              <Form.Item  
              name="process2Date"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen tarih seçiniz !',
                    },
                ]}
                wrapperCol={{
                  span: 25,
                  }}
                  style={{
                  display:"left",
                  width: "335px",
                  }}>
                <DatePicker 
                  suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                  style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} placeholder="Tarih Ekle" size="large"/>
              </Form.Item>  
              </Space> 
              <label style={{ color: "#1A446C",fontWeight:"700",fontSize:"15px",float:"left" }}>Kazandırılan Toplam Tutar <span style={{ color: "#1A446C",fontWeight:"700",fontSize:"15px",marginLeft:"50px"}}>{totalAmount}</span></label>

            <h2 style={{textAlign:"start", marginLeft:"12px", marginTop:"50px",color:"#1A446C"}}>Genel Destekler</h2>

            <h3 style={{textAlign:"start", marginLeft:"30px", marginTop:"30px",color:"#1A446C"}}>Başvurulan Genel Destekler</h3>

                  <Form.List name="genelSupportReferenced">
                  {(fields, { add, remove }) => (
                  <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">           
                        <Form.Item
                          {...restField}
                          name={[name, 'text']}
                          rules={[{ required: true, message: 'Lütfen destek adını ismini giriniz!' }]}
                        >
                          <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Destek Adı"/>
                        </Form.Item>
                       
                        <Form.Item
                          {...restField}
                          name={[name, 'price']}
                          rules={[{ required: true, message: 'Lütfen tutar giriniz!' }]}
                        >
                          <InputNumber 
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                            }}
                          size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Tutar"/>
                        </Form.Item>

                        <div className="news-Edit-container">
                        <Form.Item 
                        {...restField}
                        name={[name, 'currency']}
                        rules={[{ required: true, message: 'Lütfen para birimini seçiniz!' }]}
                        wrapperCol={{
                          span: 15,
                          }}
                          style={{
                          width:"250px"
                          }}
                        > 
                          <Select
                            placeholder="Para Birimi"
                            style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                            suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                            options={currencies}
                            size="large"
                          />
                        </Form.Item>
                          </div>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button style={{width:"50%",float:"left",border:"0"}} size="large" onClick={() => add()} block icon={<img style={{width:"15px",float:"right"}} alt="edit" src="/images/plusbutton.png" />}>
                        Destek Ekle
                      </Button>
                    </Form.Item>
                  </>
                  )}
                  </Form.List>


            <h3 style={{textAlign:"start", marginLeft:"30px", marginTop:"30px",color:"#1A446C"}}>Onaylanan Genel Destekler</h3>

              <Form.List name="genelSupportReferenced">
              {(fields, { add, remove }) => (
              <>
              {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                          {...restField}
                          name={[name, 'text']}
                          rules={[{ required: true, message: 'Lütfen destek adını ismini giriniz!' }]}
                        >
                          <Input size="large" 
                          style={{border:0, borderColor:"#1A446C80",width:"150px",backgroundColor:"white",color:"#1A446C",fontWeight:"500" }} 
                          placeholder="Destek Adı"
                          disabled={true}
                          />
                        </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'approvedPrice']}
                        rules={[{ required: true, message: 'Lütfen süreç tutarını giriniz!' }]}
                      >
                        <InputNumber 
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                          }
                          }}
                        size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} min={0} defaultValue={0} placeholder="Tutar Ekle"/>
                </Form.Item>
              </Space>
              ))}
              <Form.Item>
              </Form.Item>
              </>
              )}
              </Form.List>
              <p style={{fontWeight:"bold", color:"red"}}>**Proje Bazlı Destek eklemek için önce IPM hizmetinizi kaydetmeniz gerekmektedir.</p>

      <h2 style={{textAlign:"start", marginLeft:"12px", marginTop:"30px",color:"#1A446C"}}>Proje Bazlı Destekler</h2>
        <IpmProjectBasedList recordIpm={recordIpm} IpmProjectBasedList={projectbasedList} />
        <Form.Item
              name="isFinish"
              wrapperCol={{
                span: 3,
                }}
                style={{
                maxWidth: "100%",
                }}>
              <Checkbox checked={isFinish} onChange={() => setIsFinish(!isFinish)} style={{fontWeight:"500",color:"#1A446C"}}>
                Projeyi Bitir
              </Checkbox>
            </Form.Item>         

            <Form.Item
            wrapperCol={{
                offset: 10,
                span: 15,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500",borderRadius:0}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default IpmProjectAdd;