import React, { useState, useEffect } from 'react';
import FirebaseService from "../../../services/firebaseService";
import '../layouts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const WebNumberOds = () => {
  const [numberOdsData, setNumberOdsData] = useState([]);
  useEffect(() => {

    const fetchData = async () => {
      try {
        const data = await FirebaseService.getOdsInNumberDataFilter();
        data.forEach(item => {
        //  console.log("Individual Date:", item.firstTransactionDateEdit);
        });
        const sortedData = data.sort((a, b) => b.firstTransactionDateEdit - a.firstTransactionDateEdit);
        sortedData.forEach(item => {
         // console.log("Sorted Date:", item.firstTransactionDateEdit);
        });
        const closestDates = sortedData.slice(0, 3);
        closestDates.forEach(item => {
         // console.log("Closest Date:", item.firstTransactionDateEdit);
        });

        setNumberOdsData(closestDates);
      } catch (error) {
        console.error('SEKTÖRDE 15.YIL verisi getirme hatası:', error);
      }
    };


    fetchData();
  }, []); 
  

const AutoplaySlider = withAutoplay(AwesomeSlider);
  
return (
    <div>
      <h3 style={{ marginBottom: '0px', textAlign: 'justify', color: '#1A446C' }}>SEKTÖRDE 15.YIL</h3>
      <div className="webnews-carousel-container">
          {numberOdsData.length === 0 ? (
            <p style={{ textAlign: 'justify', color: '#1A446C', marginLeft: '15px' }}>SEKTÖRDE 15.YIL bulunmamaktadır.</p>
          ) : (
           
            <AutoplaySlider
            play={true}
            cancelOnInteraction={false} 
            interval={3000}
            style={{ width: '1050px', height: '300px' }}
          >
            {numberOdsData.map((item, index) => (
              <div key={index} >
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img  style={{width: "1050px",height: "300px", objectFit:"cover", backgroundColor:"white"}} src={item.imageUrl} alt={item.title} /> 
                </a>
              </div>
            ))}  
          </AutoplaySlider>
           
          )}
        </div>
    </div>
  );
};

export default WebNumberOds;
/*
<Carousel autoplay className='numberOds'>
{numberOdsData.map((item) => (
  <div key={item.id}>
    <h3 className='contentStyle'>
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        <img src={item.imageUrl} alt={`Resim ${item.id}`} style={{  }} />
      </a>
    </h3>
  </div>
))}
</Carousel>

*/