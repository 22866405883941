import React, { useEffect, useState } from 'react';
import { Card, Button, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from '../../services/firebaseService';
import moment from 'moment';


const Projects = () => {
  
const [project, setProject] = useState([]);
const navigate = useNavigate();
const [isFinished, setIsFinished] = useState(false);

useEffect(() => {
  fetchData();
}, [isFinished]);

const fetchData = async () => {
    try {
      const userData = await FirebaseService.getUserByUid(localStorage.getItem("uid"));
      if (userData) {
        // tq
        const data = await FirebaseService.getTqProjectsByUserId(userData.userId);
        const updatedData = data.map(record => {
          return { ...record, projectType: 'tq' }; 
        });
  
        const filteredTq = updatedData.filter(record => record.isFinish === isFinished);
       // console.log("filteredTq", filteredTq);
  
        // cd
        const datacd = await FirebaseService.getCdProjectsByUserId(userData.userId);
        const updatedcd = datacd.map(record => {
          return { ...record, projectType: 'cd' }; 
        });
  
        const filteredcd = updatedcd.filter(record => record.isFinish === isFinished);
        //console.log("filteredcd", filteredcd);
  

         // sap
         const datasap = await FirebaseService.getSapProjectsByUserId(userData.userId);
         const updatedsap = datasap.map(record => {
           return { ...record, projectType: 'sap' }; 
         });
   
         const filteredsap = updatedsap.filter(record => record.isFinish === isFinished);
         //console.log("filteredsap", filteredsap);
  

         // ibm
         const dataibm = await FirebaseService.getIbmProjectsByUserId(userData.userId);
         const updatedibm = dataibm.map(record => {
           return { ...record, projectType: 'ibm' }; 
         });
   
         const filteredibm = updatedibm.filter(record => record.isFinish === isFinished);
        // console.log("filteredibm", filteredibm);
  

        // ipm
        const dataipm = await FirebaseService.getIpmProjectsByUserId(userData.userId);
        const updatedipm = dataipm.map(record => {
          return { ...record, projectType: 'ipm' }; 
        });
      
        const filteredipm = updatedipm.filter(record => record.isFinish === isFinished);
        //console.log("filteredipm", filteredipm);
     
        setProject([...filteredTq, ...filteredcd, ...filteredsap, ...filteredibm, ...filteredipm]);

  
      } else {
       // console.error('User not found in Firestore');
      }
    } catch (error) {
     // console.error('Projeleri getirirken bir hata oluştu:', error.message);
    }
  };
  
  
  

  const goDetailPage = (record) => {
    if (record.projectType === 'tq') {
      navigate('/tqdetail', { state: { record } });
    } 
    else if (record.projectType === 'cd') {
      navigate('/cddetail', { state: { record } });
    }
    else if (record.projectType === 'sap') {
        navigate('/sapdetail', { state: { record } });
    }
    else if (record.projectType === 'ipm') {
     navigate('/ipmdetail', { state: { record } });
    }
    else if (record.projectType === 'ibm') {
        navigate('/ibmdetail', { state: { record } });
    }
  };
  
  const calculateProgress = (startDate, endDate) => {
    const currentDate = moment();
    const start = moment(startDate.toDate());
    const end = moment(endDate.toDate());    

    const totalDays = end.diff(start, 'days');
    const elapsedDays = currentDate.diff(start, 'days');
    const progressPercent = (elapsedDays / totalDays) * 100;
    return { progress: progressPercent.toFixed(2), start, end };
};

  const formatDate = (timestamp) => {
    const dateObject = timestamp instanceof Date ? timestamp : timestamp.toDate();
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear().toString();
  
    return `${day}/${month}/${year}`;
  };
  
  

return (
  <>
    <div style={{ margin: '3% 0 0 5%' }}>
  <h3 style={{ textAlign: "start", color: "#A47B5A", fontWeight: "bold" }}>Hizmetlerim</h3>
  <hr style={{ background: "#A47B5A" }} />
  </div>
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
     alignItems: 'center',
    }}
  >
      <div style={{ marginBottom: '16px' }}>
   
      <Button
        onClick={() => setIsFinished(false)}
        style={{
          background: 'transparent',
          backgroundColor : !isFinished ? '#1A446C' : 'white',
          borderColor: !isFinished ?  '#1A446C' : '#1A446C',
          color: !isFinished ?  'white' : '#1A446C',
          fontWeight: 'bold',
          borderRadius: '0px',
          width:"200px",
          height:"50px"
        }}
      >
        Devam Eden Hizmetlerim
      </Button>
      <Button
        onClick={() => setIsFinished(true)}
        style={{
          background: 'transparent',
          backgroundColor :  isFinished ? '#1A446C' : 'white',
          borderColor: isFinished ? '#1A446C' : '#1A446C',
          color: isFinished ? 'white' : '#1A446C',
          fontWeight: 'bold',
          borderRadius: '0px',
          width:"200px",
          height:"50px"
        }}
      >
        Bitmiş Hizmetlerim
      </Button>
    </div>

    {project.map((record) => (
      <Card
         key={record.key}
         title={<h2 style={{ fontWeight: 'bold', color: "#A47B5A" }}>
            {record.projectType === 'tq' && 'Turquality'}
         {record.projectType === 'cd' && 'Corporate Development'}
         {record.projectType === 'ipm' && 'IPM'}
         {record.projectType === 'ibm' && 'IBM'}
         {record.projectType === 'sap' && 'SAP'}
         </h2>}
         style={{ width: "80%", margin: '16px', textAlign: 'left' }}
        extra={
          
          <p  style={{ color:"#1A446C80"}}>{formatDate(record.firstTransactionDate)}</p>
        }
      >
        <div style={{justifyContent:"space-between", display:"flex", padding:"20px"}}>
          <div  style={{display:"flex"}}>
          <div>
                {['tq', 'cd', 'sap'].includes(record.projectType) && (
                    <>
                    <div>
                        Yönetici:
                        {Array.isArray(record.managerName) &&
                        record.managerName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    <div>
                        İş Geliştirme Danışmanı:
                        {Array.isArray(record.businessDevelopmentName) &&
                        record.businessDevelopmentName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    </>
                )}
                {record.projectType === 'ibm' && (
                    <>
                    <div>
                        <div>
                    Takım Lideri:
                        {Array.isArray(record.teamLeadName) &&
                        record.teamLeadName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    <div>
                        İş Geliştirme Danışmanı:
                        {Array.isArray(record.businessDevelopmentName) &&
                        record.businessDevelopmentName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    <div>
                        Pazar Araştırma Danışmanı:
                        {Array.isArray(record.marketResearchConsultantName) &&
                        record.marketResearchConsultantName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    </div>
                    </>
                )}

                {record.projectType === 'ipm' && (
                    <>
                    <div>
                        <div>
                    Operasyon Danışmanı:
                        {Array.isArray(record.operationName) &&
                        record.operationName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    <div>
                        İş Geliştirme Danışmanı:
                        {Array.isArray(record.businessDevelopmentName) &&
                        record.businessDevelopmentName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    
                    </div>
                    </>
                )}
             </div>
     
          </div>
          <div>
          <Button
              style={{ backgroundColor: 'white', border:"none" }}
              onClick={() => goDetailPage(record)}
              icon={<img alt="plus" src="/images/rigtharrow.png" style={{ width: '100%' }} />}

            />
          </div>
        </div>
   
      
        
          <Progress showInfo={false} style={{padding:"0px 20px"}}  strokeColor="#A47B5A" percent={calculateProgress(record.startDate, record.endDate).progress} status="active" />
          <div style={{padding:"0px 20px", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

         <div style={{ flex: '1', textAlign:"left" }}>
                <div style={{ color: "#1A446C" }}>Sözleşme Başlangıcı </div>
                <div style={{ color: "#1A446C" }}>{formatDate(record.startDate)} </div>
            </div>
            
            <div style={{ flex: '1',textAlign:"right"  }}>
                <div style={{ color: "#1A446C" }}>Sözleşme Bitişi</div>
                <div style={{ color: "#1A446C" }}>{formatDate(record.endDate)} </div>

            </div>  
            </div>
     
      </Card>
    ))}
    {project.length === 0 && <p>Hizmet bulunmamaktadır.</p>}
  </div>
  </>
);
};
export default Projects;




