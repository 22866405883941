import React, { useState, useEffect } from 'react';
import './training.css';
import { Card, Carousel } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import FirebaseService from '../../services/firebaseService';

const TrainingListWithCategory = () => {
  const [trainingData, setTrainingData] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    const data = await FirebaseService.getTrainingDataFilter();
    return data;
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchData();
        setTrainingData(fetchedData);
      } catch (error) {
        console.error('Veriyi ayarlama hatası:', error);
       
      }
    };

    getData();
  }, []);
  

  const groupedData = {};
  trainingData.forEach((item) => {
    const category = item.categoryName || 'Other';
    if (!groupedData[category]) {
      groupedData[category] = [];
    }
    groupedData[category].push(item);
  });

  const staticImageUrl = '/images/persona1.png';
  const settings = {
    dots: false,
    nextArrow: <img src='/images/rigtharrow.png' style={{ maxWidth: '400px' }} />,
    prevArrow: <img src='/images/leftarrow.png' style={{ maxWidth: '400px' }} />,
    infinite: false,
    speed: 500,
    slidesToShow: 6.5,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const handleTrainingItemClick = (item) => {
    if (item && item.videoId !== undefined) {
      navigate(`/training-detail/${item.key}`, { state: { trainingItem: item } });
    } else {
    //  console.error("Invalid item or missing videoId property:", item);
    }
  };
  const goDetailPage = (record) => {
    navigate('/training-detail', { state: { record } });
  };

  return (
    <div>
      {Object.entries(groupedData).map(([category, categoryData]) => (
        <div key={category} style={{ marginTop: 80, color: '#1A446C' }}>
          <h4 style={{ textAlign: 'justify', marginLeft: '15px' }}>{category}</h4>
          <div className='training-by-category-card-carousel'>
            <Carousel arrows {...settings}>
              {categoryData.map((item) => (
                 <div key={item.id} onClick={() => goDetailPage(item)}>
                    <Card bordered={false} className='training-by-category-card'>
                      <div className='training-by-category-card-cody'>
                        <img alt='df' style={{objectFit:'contain'}}src={staticImageUrl} className='training-card-img' />
                        <span className='training-card-title'>{item.title}</span>
                        <p className="training-card-trainer">{item.trainer}</p>
                      </div>
                    </Card>
                  
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrainingListWithCategory;
