import React,{useState, useEffect} from "react";
import { Button, Form, Input, Space,Select } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections,categoryName}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";



  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TrainerAndAuthorAdd = () => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [category, setCategory] = useState([]);
    
    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState('option1');

    const handleOptionClick = (option) => {
      setSelectedOption(option);
    };

    useEffect(() => {
      const fetchData = async () => {
        const categories = await FirebaseService.getCategoryData(categoryName.education);
        const categoryOptions = categories.map(category => ({
          value: category,
          label: category,
        }));
        setCategory(categoryOptions)
      };
      fetchData();
    }, []);

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const uploadButton = (
        <div style={{width:"1000px" }}>
          <img alt="plus" src="/images/uploadplus.png" />
          <div
          style={{
            marginTop: 8,
          }}
        >
          Resim Yükle (230x300 pixel boyutları önerilir)
        </div>

        </div>
      );

      const onFinish = async (values) => {
        const newData = {
          firstTransactionDate: new Date(),
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          description: values.description,
          nameSurname: values.nameSurname,
        };
        values.image=fileList.length > 0 ? values.image : null;
        const collection = selectedOption === 'option1' ? odsCollections.trainer : odsCollections.author
      try {
        if (values.image) {
          newData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(collection, values.image.file);
        }
        
         if(collection === odsCollections.trainer)
         {
          newData.educationCategoryName= values.categoryName
         } 

        const result = await FirebaseService.addRecording(collection,newData);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setTimeout(() => {
            navigate("/trainerandauthor");
          }, 2000);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
        }
        // else{
        //   NotificationService.openErrorNotification({
        //     title: 'Geçersiz Resim',
        //     description: "Lütfen resim seçiniz!",
        //     placement: 'topRight'
        //   });
        // }
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
      }
    };    

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

          <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
            >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="partner-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
                    </Modal>
          </Space>

          <Form.Item
              name="nameSurname"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen ad-soyad giriniz!',
                  },
              ]}
              wrapperCol={{
                span: 20,
                }}
                style={{
                maxWidth: "100%",
                }}
              >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}}  placeholder="İsim Soyisim"/>
              </Form.Item>
              <div className="custom-switch">
            <div
                className={`switch-option ${selectedOption === 'option1' ? 'selected' : ''}`}
                onClick={() => handleOptionClick('option1')}
            >
                Eğitmen
            </div>
            <div
                className={`switch-option ${selectedOption === 'option2' ? 'selected' : ''}`}
                onClick={() => handleOptionClick('option2')}
            >
                Yazar
            </div>
            </div>

            {selectedOption === 'option1' && (
              <div className="news-Edit-container">
                <Form.Item 
                  name="categoryName"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen kategori seçiniz!',
                    },
                  ]}
                  wrapperCol={{
                    span: 20,
                  }}
                  style={{
                    maxWidth: "100%",
                  }}
                > 
                  <Select
                    placeholder="Kategori"
                    style={{ borderRadius: 0, borderColor: "#1A446C80" }}
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    size="large"
                    options={category}
                  />                
                </Form.Item>
              </div>
            )}

              <Form.Item
                name="description"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen açıklama giriniz!',
                    },
                ]}
                wrapperCol={{
                    span: 20,
                    }}
                    style={{
                    maxWidth: "100%",
                    }}
            >
            <Input.TextArea rows={10} style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Açıklama"/>
            </Form.Item>

           
           
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default TrainerAndAuthorAdd;