import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";

const IpmProjectBasedList = ({ recordIpm, IpmProjectBasedList,disabled }) => {

  const [ipmProjectBasedList, setIpmProjectBasedListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    setIpmProjectBasedListData(IpmProjectBasedList);
    setTableData(IpmProjectBasedList)
  }, [IpmProjectBasedList]);

  const goAddPage = () => {
     navigate('/ipmprojectbasedadd',{state:{recordIpm}})
  };

  const goEditPage = (record) => {
      navigate('/ipmprojectbasededit', { state: { record, recordIpm } })
  };

  const handleDelete = async (record, isDeleted) => {
    try {
      let result;
      if (isDeleted) {
        result =  await FirebaseService.makeIpmProjectBasedVisible(record.key);
      } else {
        result = await FirebaseService.deleteIpmProjectBased(record.key) ;
      }
      if (result.success) {
        if (result.success) {
          const updatedList = IpmProjectBasedList.map(item => {
            if (item.key === record.key) {
              return { ...item, isDeleted: !isDeleted };
            }
            return item;
          });
          setIpmProjectBasedListData(updatedList);
          setTableData(updatedList);
         // console.log(tableData);
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
    }} catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
  };  

  const columns = [
    {
      title: 'Kurum Adı',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 300,
    },
    {
      title: 'Program Adı',
      dataIndex: 'programName',
      key: 'programName',
      width: 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Gizle/Göster',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        
          record.isDeleted ?    
           <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
          :
          <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Gizle</Button>
      ),
     },
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <a type="link" onClick={()=>!disabled && goEditPage(record)}  style={{
          border: "none",
          backgroundColor: "transparent",
          pointerEvents: record.isDeleted ? "none" : "auto", 
          opacity: record.isDeleted ? 0.5 : 1, 
        }}
        disabled={disabled}>
          <img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
      ),
    }
  ];
  
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"50%"}}/>} style={{background:"transparent",borderColor:"#1A446C80",color:"#1A446C",fontWeight:"bold",borderRadius:"0px",marginLeft:"35%"}}>
          Yeni Ekle
        </Button>
          <Table
            locale={{ emptyText: 'Henüz proje eklemediniz...' }}
            dataSource={tableData}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            style={{width:"70%"}}
            rowClassName="custom-news-row"
            // scroll={{ y: 'calc(100vh - 300px)' }}
        />
    </div>

    };
  export default IpmProjectBasedList;