import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";

const SapProjectList = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getSapProjects();
      data.sort((a, b) => new Date(b.firstTransactionDateEdit) - new Date(a.firstTransactionDateEdit));
      setTableData(data)
    };
    fetchData();
  }, []);

  const goAddPage = () => {
     navigate('/sapprojectadd')
  };

  const goEditPage = (record) => {
      navigate('/sapprojectedit', { state: { record } })
  };

  const handleDelete = async (record, isDeleted) => {
    try {
      let result;
      if (isDeleted) {
        result =  await FirebaseService.makeSapProjectVisible(record.key);
      } else {
        result = await FirebaseService.deleteSapProject(record.key) ;
      }
  
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        const data = await FirebaseService.getSapProjects();
        const combinedData = [...data];
        setTableData(combinedData)
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
  };  

  const columns = [
    {
      title: 'Kullanıcı Adı Soyadı',
      dataIndex: 'userNameSurname',
      key: 'userNameSurname',
      width: 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Proje Adı',
      dataIndex: 'projectName',
      key: 'projectName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 400,
    },
    {
      title: 'Kategori',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 400,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>Sap</span>,
    },
    {
      title: 'Danışman Adı',
      dataIndex: 'businessDevelopmentName',
      key: 'businessDevelopmentName',
      width: 400,
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
        {text?.map((nameObject, index) => (
          <React.Fragment key={index}>
            {nameObject.nameSurname}
            {index < text.length - 1 && ' - '}
          </React.Fragment>
        ))}
      </span>
      ),
    },
    {
      title: 'Gizle/Göster',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        
          record.isDeleted ?    
           <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
          :
          <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Gizle</Button>
      ),
     },
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <a type="link" onClick={()=>goEditPage(record)}  style={{
          border: "none",
          backgroundColor: "transparent",
          pointerEvents: record.isDeleted ? "none" : "auto", 
          opacity: record.isDeleted ? 0.5 : 1, 
        }}><img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
      ),
    }
  ];
  
  
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} style={{background:"transparent",borderColor:"#1A446C80",color:"#1A446C",fontWeight:"bold",borderRadius:"0px",float:"right"}}>
          Yeni Ekle
        </Button>
          <Table
            locale={{ emptyText: 'Henüz proje eklemediniz...' }}
            dataSource={tableData}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

    };
  export default SapProjectList;