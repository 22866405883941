import React,{useState,useEffect,useRef} from "react";
import { Button, Form, Input, Select, DatePicker, Upload, Space, Checkbox, Divider,InputNumber } from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  {odsCollections, Products, Process, SubServicess}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined,MinusOutlined } from '@ant-design/icons';
import FirebaseStorage from "../../../services/storegeService";
const { RangePicker } = DatePicker;

const onFinishFailed = (errorInfo) => {
  NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
};

const SapProjectAdd = () => {

    const [users, setUsers] = useState([]);
    const [advisor, setAdvisor] = useState([]);
    const [isFinish, setIsFinish] = useState(false);
    const [process, setProcess] = useState([]);
    const [subServices, setSubServices] = useState([]);
    const [products, setProducts] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
          const users = await FirebaseService.getUserData();
          const filteredUser = users.filter(f=>f.userType.includes('user')&&f.userType.length===1)
          const usersOption = filteredUser.map(user => ({
            value: user.userId,
            //label: user.nameSurname + "-" + user.email +"-" +user.companyName,
            label: user.nameSurname

          }));
          setUsers(usersOption)

          const advisors = await FirebaseService.getAdvisorData();
          const advisorsOption = advisors.filter(f=>!f.isDeleted).map(advisor => ({
            value: advisor.key,
            label: advisor.nameSurname,
          }));
          setAdvisor(advisorsOption)

          const process = Process;
          const subServicess = SubServicess;
          const product = Products;
          setProducts(product);
          setSubServices(subServicess);
          setProcess(process);
          setUsers(usersOption);
      };

      fetchData();
    }, []);

      const onFinish = async (values) => {
        const currentDate =new Date()

        const selectedAdvisorIds = values.advisorId;
        const selectedManagerIds = values.managerName;

        const advisorData = selectedAdvisorIds.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });

        const managerData = selectedManagerIds.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });
        const projectData = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          projectName: values.projectName,
          serviceSublists : values.serviceSublists.map(step => ({
            name:step.name,
            percent:step.percent
          })),
          projectProcess : [{name:values.process1,date:new Date(values.process1Date)},{name:values.process2,date:new Date(values.process2Date)}],
          userId:values.userId,
          userNameSurname:users.find(f=>f.value===values.userId).label,
          businessDevelopmentName:advisorData,
          isFinish:isFinish,
          startDate:new Date(values.startEndDate[0]),
          endDate:new Date(values.startEndDate[1]),
          managerName:managerData,
          productLists:values.productLists
        };
        
      try {
       const result = await FirebaseService.addRecording(odsCollections.sap,projectData);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setTimeout(() => {
            navigate("/sapprojectlist");
          }, 2000);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });}    
      }
       catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
      }
    }; 

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            <Form.Item
            name="projectName"
            rules={[
                {
                required: true,
                message: 'Lütfen proje adını giriniz!',
                },
            ]}
            wrapperCol={{
              span: 15,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Proje İsmi"/>
            </Form.Item>

            <div key="cdUser" className="news-Edit-container">
              <Form.Item 
              name="userId"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen şirket seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 15,
                }}
                style={{
                maxWidth: "100%",
                }}
              > 
                  <Select
                        placeholder="Kullanıcı Adı"
                        style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        options={users}
                        size="large"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                          </>
                        )}
                      />                
              </Form.Item>
            </div>

            <div className="news-Edit-container">
            <Form.Item 
            name="advisorId"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>Danışman</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"start"
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen danışman seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="Danışman"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div>

              <div className="news-Edit-container">
            <Form.Item 
            name="managerName"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>Yönetici</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen yönetici seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="Yönetici"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div>
              <Form.Item
                name="startEndDate"
                rules={[{ required: true, message: 'Lütfen başlangıç ve bitiş tarihlerini giriniz!' }]}
                wrapperCol={{
                  span: 14,
                  }}
                  style={{
                  display:"left",
                  maxWidth: "100%",
                  }}
              >
                  <RangePicker placeholder={["Sözleşme Başlangıç","Sözleşme Bitiş"]}   
                  separator={<><span style={{ transform: 'rotate(0deg)' }}><MinusOutlined /></span></>}
                allowClear style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} size='middle' />
            </Form.Item>
              <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
              <div className="news-Edit-container">

              <Form.Item 
                name="process1"
                rules={[{ required: true, message: 'Lütfen süreç ismini giriniz!' }]}
                wrapperCol={{
                  span: 30,
                  }}
                  style={{
                  width: "335px",
                  }}
                > 
                  <Select
                        placeholder="Süreç Ekle"
                        style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        options={process}
                        size="large"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                          </>
                        )}
                      />                
              </Form.Item>
              </div>
            <Form.Item  
            name="process1Date"
            rules={[
                  {
                  required: true,
                  message: 'Lütfen tarih seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 25,
                }}
                style={{
                display:"left",
                width: "335px",
                }}>
              <DatePicker 
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} placeholder="Tarih Ekle" size="large"/>
            </Form.Item>  
            </Space>  

            <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
            <div className="news-Edit-container">

            <Form.Item 
              name="process2"
              rules={[{ required: true, message: 'Lütfen süreç ismini giriniz!' }]}
              wrapperCol={{
                span: 25,
                }}
                style={{
                width: "335px",
                }}
              > 
                <Select
                      placeholder="Süreç Ekle"
                      style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      options={process}
                      size="large"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0',
                            }}
                          />
                        </>
                      )}
                    />                
            </Form.Item>
            </div>

            <Form.Item  
            name="process2Date"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen tarih seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 25,
                }}
                style={{
                display:"left",
                width: "335px",
                }}>
              <DatePicker 
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} placeholder="Tarih Ekle" size="large"/>
            </Form.Item>  
            </Space>   

            <h3 style={{textAlign:"start", marginLeft:"18px", marginTop:"30px",color:"#1A446C"}}>Ürünler</h3>

            <Form.List name="productLists">
        {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
               <div className="news-Edit-container">
            <Form.Item 
              {...restField}
              name={[name, 'name']}
              rules={[{ required: true, message: 'Lütfen ürün seçiniz!' }]}
              wrapperCol={{
                span: 30,
              }}
              style={{
                width: "335px",
              }}
            > 
              <Select
                placeholder="Ürün Ekle"
                style={{ borderRadius: 0, borderColor: "#1A446C80", textAlign: "left" }}
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={products}
                size="large"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: '8px 0',
                      }}
                    />
                  </>
                )}
              > 
                {subServices.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
              <Form.Item
                {...restField}
                name={[name, 'piece']}
                rules={[{ required: true, message: 'Lütfen adet giriniz!' }]}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} min={0} placeholder="Adet"/>
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
                ))}
                <Form.Item>
                  <Button style={{width:"50%",float:"left",border:"0"}} size="large" onClick={() => add()} block icon={<img style={{width:"15px",float:"right"}} alt="edit" src="/images/plusbutton.png" />}>
                    Ürün Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

            <h3 style={{textAlign:"start", marginLeft:"18px", marginTop:"30px",color:"#1A446C"}}>Hizmet Alt Başlıkları</h3>

            <Form.List name="serviceSublists">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
               <div className="news-Edit-container">
            <Form.Item 
              {...restField}
              name={[name, 'name']}
              rules={[{ required: true, message: 'Lütfen başlık seçiniz!' }]}
              wrapperCol={{
                span: 30,
              }}
              style={{
                width: "335px",
              }}
            > 
              <Select
                placeholder="Başlık Ekle"
                style={{ borderRadius: 0, borderColor: "#1A446C80", textAlign: "left" }}
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={subServices}
                size="large"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: '8px 0',
                      }}
                    />
                  </>
                )}
              > 
                {subServices.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
              <Form.Item
                {...restField}
                name={[name, 'percent']}
                rules={[{ required: true, message: 'Lütfen yüzde giriniz!' },{
                  type: 'number',
                  min: 0,
                  max: 100,
                  message: 'Yüzdelik değer 0 ile 100 arasında olmalıdır.'
                },
                ]}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} min={0} placeholder="Yüzdelik"/>
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
                ))}
                <Form.Item>
                  <Button style={{width:"50%",float:"left",border:"0"}} size="large" onClick={() => add()} block icon={<img style={{width:"15px",float:"right"}} alt="edit" src="/images/plusbutton.png" />}>
                    Alt Başlık Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
            <Form.Item
              name="isFinish"
              wrapperCol={{
                span: 3,
                }}
                style={{
                maxWidth: "100%",
                }}>
              <Checkbox checked={isFinish} onChange={() => setIsFinish(!isFinish)} style={{fontWeight:"500",color:"#1A446C"}}>
                Projeyi Bitir
              </Checkbox>
            </Form.Item>        

            <Form.Item
            wrapperCol={{
                offset: 10,
                span: 10,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500",borderRadius:0}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default SapProjectAdd;