import React, { useState, useEffect } from 'react';
import './news.css';
import { Card, Carousel } from 'antd';
import { Link, useNavigate } from 'react-router-dom'; 
import FirebaseService from '../../services/firebaseService';

const NewsListWithCategory = () => {
  const [newsData, setNewsData] = useState([]);
  const navigate = useNavigate(); 

  const fetchData = async () => {
    const data = await FirebaseService.getNewDataFilter();
    return data;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchData();
        setNewsData(fetchedData);
      } catch (error) {
        console.error('Veriyi ayarlama hatası:', error);
        // Hata uygun bir şekilde ele alınabilir
      }
    };

    getData();
  }, []);
  

  const groupedData = {};
  newsData.forEach((item) => {
    const category = item.categoryName || 'Other';
    if (!groupedData[category]) {
      groupedData[category] = [];
    }
    groupedData[category].push(item);
  });

  const settings = {
    dots: false,
    nextArrow: <img src='/images/rigtharrow.png' style={{ maxWidth: '400px' }} />,
    prevArrow: <img src='/images/leftarrow.png' style={{ maxWidth: '400px' }} />,
    infinite: false,
    speed: 500,
    slidesToShow: 6.5,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const goDetailPage = (record) => {
    navigate('/news-detail', { state: { record } });
  };

  return (
    <div>
      {Object.entries(groupedData).map(([category, categoryData]) => (
        <div key={category} style={{ marginTop: 80, color: '#1A446C' }}>
          <h4 style={{ textAlign: 'justify', marginLeft: '15px' }}>{category}</h4>
          <div className='news-by-category-card-carousel'>
            <Carousel arrows {...settings}>
              {categoryData.map((item) => (
                <div key={item.id} onClick={() => goDetailPage(item)}>
                  <Card bordered={false} className='news-by-category-card'>
                    <div className='news-by-category-card-cody'>
                      <img  alt='df' src={item.imageUrl} className='news-card-img' />
                      <span className='news-card-title'>{item.title}</span>
                    </div>
                  </Card>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsListWithCategory;
