import React,{ useState, useRef,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Button, Progress, Checkbox } from 'antd';
import moment from 'moment';
import CanvasJSReact from '@canvasjs/react-charts';

const { CanvasJSChart } = CanvasJSReact;

const IbmDetail = () => {
  const location = useLocation();
  const { record } = location.state;
  const [isExpandedOffers, setIsExpandedOffers] = useState(false);
  const [isExpandedSales, setIsExpandedSales] = useState(false);
  const [isExpandedB2b, setIsExpandedB2b] = useState(false);
  const [isExpandedRapor, setIsExpandedRapor] = useState(false);


  const countryData = record.b2bAndf2f.reduce((acc, item) => {
    const country = item.country;
    if (!acc[country]) {
      acc[country] = 0;
    }
    acc[country]++;
    return acc;
  }, {});
  
  const totalCompanies = record.b2bAndf2f.length;
  
  const dataPoints = Object.entries(countryData).map(([country, count]) => ({
    y: (count / totalCompanies) * 100,
    label: country,
  }));

  
  const options = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "Şirketlerin Ülkere göre Dağılımı",
    },
    data: [{
      type: "pie",
      startAngle: 75,
      toolTipContent: "<b>{label}</b>: {y}%",
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}%",
      dataPoints: dataPoints,
    }],
  };

  useEffect(() => {

  }, [record, options]);


  const handleToggleOffers = () => {
    setIsExpandedOffers(!isExpandedOffers);
  };

  const handleToggleSales = () => {
    setIsExpandedSales(!isExpandedSales);
  };

  const handleToggleB2b = () => {
    setIsExpandedB2b(!isExpandedB2b);
  };

  const handleToggleRapor = () => {
    setIsExpandedRapor(!isExpandedRapor);
  };


  const calculateProgress = (startDate, endDate, projectProcess) => {
    if (!startDate || !endDate) {
        return { progress: 'NaN', start: null, end: null, processes: [] };
    }

    const start = moment(new Date(startDate.seconds * 1000 + startDate.nanoseconds / 1000000));
    const end = moment(new Date(endDate.seconds * 1000 + endDate.nanoseconds / 1000000));

    if (!start.isValid() || !end.isValid()) {
        //console.log('Invalid start or end date:', start, end);
        return { progress: 'NaN', start, end, processes: [] };
    }

    const currentDate = moment();
    const totalDays = end.diff(start, 'days');
    const daysFromStart = currentDate.diff(start, 'days');
    const daysFromEnd = end.diff(currentDate, 'days');

    const processes = projectProcess || [];

    const formattedProcesses = processes.map(process => ({
        name: process.name,
        date: process.date ? formatDate(process.date) : null,
    }));

    //console.log('Formatted Processes:', formattedProcesses);

    const sortedProcesses = formattedProcesses
        .filter(process => process.date !== null)
        .sort((a, b) => moment(a.date, 'DD/MM/YYYY').isBefore(moment(b.date, 'DD/MM/YYYY')) ? -1 : 1);

    //console.log('Sorted Processes:', sortedProcesses);

    // Ensure progress is between 0 and 100
    const progressPercent = Math.max(0, Math.min(100, (daysFromStart / totalDays) * 100));

    return { progress: progressPercent.toFixed(2), start, end, processes: sortedProcesses };
};

const formatDate = (timestamp) => {
    if (!timestamp) {
        return null;
    }

    const seconds = timestamp.seconds || 0;
    const nanoseconds = timestamp.nanoseconds || 0;

    const dateObject = new Date(seconds * 1000 + nanoseconds / 1000000);

    if (isNaN(dateObject.getTime())) {
        return null;
    }

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear().toString();

    return `${day}/${month}/${year}`;
};


  const progressData = calculateProgress(record.startDate, record.endDate, record.projectProcess);


  const calculateTotalPriceOffers = (offers) => {
    if (offers.length === 0) {
      return "NaN";
    }
  
    const baseCurrency = offers[0].currency;
  
    const total = offers.reduce((accumulator, offer) => {
      if (offer.currency !== baseCurrency) {
        return NaN;
      }
  
      const offerPrice = parseFloat(offer.price);
      return isNaN(offerPrice) ? NaN : accumulator + offerPrice;
    }, 0);
  
    return isNaN(total) ? "NaN" : total.toFixed(2)+offers[0].currency;
  };
  
  const calculateTotalPriceSales = (sales) => {
    if (sales.length === 0) {
      return "NaN";
    }
  
    const baseCurrency = sales[0].currency;
  
    const total = sales.reduce((accumulator, offer) => {
      if (offer.currency !== baseCurrency) {
        return NaN;
      }
  
      const offerPrice = parseFloat(offer.price);
      return isNaN(offerPrice) ? NaN : accumulator + offerPrice;
    }, 0);
  
    return isNaN(total) ? "NaN" : total.toFixed(2)+sales[0].currency;
  };



  return (
    <div style={{padding:"20px"}}>
    <Card
        title={<h2 style={{ fontWeight: 'bold' , color:"#A47B5A"}}>International Business Management</h2>}
        style={{textAlign: 'left' }}
        extra={
          <p  style={{ color:"#1A446C80"}}>{formatDate(record.firstTransactionDate)}</p>
        }
      >
        <div style={{justifyContent:"space-between", display:"flex", padding:"20px"}}>
          <div  style={{display:"flex"}}>

          <div>
                        <div>
                    Takım Lideri:
                        {Array.isArray(record.teamLeadName) &&
                        record.teamLeadName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    <div>
                        İş Geliştirme Danışmanı:
                        {Array.isArray(record.businessDevelopmentName) &&
                        record.businessDevelopmentName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    <div>
                        Pazar Araştırma Danışmanı:
                        {Array.isArray(record.marketResearchConsultantName) &&
                        record.marketResearchConsultantName.map((item) => (
                            <span key={item.nameSurname}> {item.nameSurname}</span>
                        ))}
                    </div>
                    </div>
          </div>
         
        </div>
        <Progress showInfo={false} style={{padding:"0px 20px"}} strokeColor="#A47B5A" percent={calculateProgress(record.startDate, record.endDate, record.projectProcess).progress} status="active" />

        <div style={{padding:"0px 20px", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

            <div style={{ flex: '1', textAlign:"left" }}>
                <div style={{ color: "#1A446C" }}>Sözleşme Başlangıcı </div>
                <div style={{ color: "#1A446C" }}>{formatDate(record.startDate)} </div>
            </div>

            <div style={{ flex: '1' }}>
            <div style={{ color: "#1A446C" }}>
                {progressData.processes[0] ? progressData.processes[0].name : 'No Name'}
            </div>
            <div style={{ color: "#1A446C" }}>
                {progressData.processes[0] ? (progressData.processes[0].date) : 'No Date'}
            </div>
            </div>


            <div style={{ flex: '1' }}>
                <div style={{ color: "#1A446C" }}>
                {progressData.processes[1] ? progressData.processes[1].name : 'No Name'}
                </div>
                <div style={{ color: "#1A446C" }}>
                {progressData.processes[1] ? (progressData.processes[1].date) : 'No Date'}
            </div>
            </div>

            <div style={{ flex: '1',textAlign:"right"  }}>
                <div style={{ color: "#1A446C" }}>Sözleşme Bitişi</div>
                <div style={{ color: "#1A446C" }}>{formatDate(record.endDate)} </div>

            </div>
        </div>

      </Card>
     
      
      {/* Checkbox Listesi */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop:"40px", marginBottom:"40px" }}>
        <Checkbox checked={record.isFinishedCurrentSituation} style={{ color: "#1A446C" }}> Mevcut Durum Analizi  </Checkbox>
        <Checkbox checked={record.isFinishedMarketAnalysis} style={{ color: "#1A446C" }}> Hedef Pazar Analizi  </Checkbox>
        <Checkbox checked={record.isFinishedCompetitorAnalysis} style={{ color: "#1A446C" }}> Rakip Analizi </Checkbox>
        <Checkbox checked={record.isFinishedColdCallList} style={{ color: "#1A446C" }}> Cold Call List  </Checkbox>
      </div>


    {/* toplam teklif */}
      <div style={{ background: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginBottom: '10px', marginTop:"20px" }}>
            <p onClick={handleToggleOffers} style={{ cursor: 'pointer', textAlign:"left",color: "#1A446C", fontWeight:"bold" }}>
            Toplam Teklif <img src={isExpandedOffers ?  'images/uparrow.png' : 'images/downarrow.png' } width={20} height={10} />
            </p>
            
            {isExpandedOffers && (
                <div style={{ textAlign: "left", color: "#1A446C" }}>
                    {record.offers.map((item) => (
                    <div key={item.name}>
                        {item.companyName} {item.country} {item.price} {item.currency}
                    </div>
                    ))}
                    <div style={{ fontWeight: "bold" }}>
                    Toplam Fiyat: {calculateTotalPriceOffers(record.offers)} 
                    </div>
                </div>
            )}
      </div>
     

    {/* b2b  */}
      <div style={{ background: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginBottom: '10px', marginTop:"20px" }}>
            <p onClick={handleToggleB2b} style={{ cursor: 'pointer', textAlign:"left",color: "#1A446C", fontWeight:"bold" }}>
            B2B / F2F Detayları  <img src={isExpandedB2b ?  'images/uparrow.png': 'images/downarrow.png' } width={20} height={10} />
            </p>
            
            {isExpandedB2b && (
                <div style={{ textAlign: "left", color: "#1A446C" }}>
                    {record.b2bAndf2f.map((item) => (
                    <div key={item.name}>
                    {item.companyName}-{item.country} {formatDate(item.date)}
                </div>
                
                    ))}
                </div>
            )}
      </div>


    {/* toplam satış */}
      <div style={{ background: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginBottom: '10px', marginTop:"20px" }}>
            <p onClick={handleToggleSales} style={{ cursor: 'pointer', textAlign:"left",color: "#1A446C", fontWeight:"bold" }}>
            Toplam Satış <img src={isExpandedSales ?   'images/uparrow.png' : 'images/downarrow.png'} width={20} height={10} />
            </p>
            
            {isExpandedSales && (
                <div style={{ textAlign: "left", color: "#1A446C" }}>
                    {record.sales.map((item) => (
                    <div key={item.name}>
                        {item.companyName} {item.country} {item.price} {item.currency}
                    </div>
                    ))}
                    <div style={{ fontWeight: "bold" }}>
                    Toplam Fiyat: {calculateTotalPriceSales(record.sales)} 
                    </div>
                </div>
            )}
      </div>
     
     


    {/*pazar raporu */}
    <div style={{ background: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginBottom: '10px', marginTop:"20px" }}>
            <p onClick={handleToggleRapor} style={{ cursor: 'pointer', textAlign:"left",color: "#1A446C", fontWeight:"bold" }}>
            Pazar Raporu <img src={isExpandedRapor ?   'images/uparrow.png' : 'images/downarrow.png'} width={20} height={10} />
            </p>
            
            {isExpandedRapor && (
                 <div style={{ textAlign: "left", color: "#1A446C" }}>
                                <CanvasJSChart options={options} />
         

              </div>
            )}
      </div>
      <div>
     
    </div>
     </div>
  );
};

export default IbmDetail;
