import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const TrainerAndAuthorList = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getAuthorData();
      data.forEach(f=>f.trainer=false)

      const trainerData = await FirebaseService.getTrainerData();
      trainerData.forEach(f=>f.trainer=true)

      const combinedData = [...data, ...trainerData];
      combinedData.sort((a, b) => a.nameSurname.localeCompare(b.nameSurname));
      setTableData(combinedData)
      const educationData = await FirebaseService.getEducationData();
     // console.log('Education Data:', educationData);
      const blogsData = await FirebaseService.getBlogData();
     // console.log('Blogs Data:', blogsData);
      const newsData = await FirebaseService.getNewData();
      //console.log('News Data:', newsData);
      const servicesData = await FirebaseService.getServiceData();
      //console.log('Services Data:', servicesData);

    };

    fetchData();
  }, []);

  const goAddPage = () => {
    navigate('/trainerandauthoradd')
  };

  const goEditPage = (record) => {
     navigate('/trainerandauthoredit', { state: { record } })
  };

  const handleDelete = async (record, isDeleted) => {
    try {
     // console.log('Deleting Record:', record);
  
     
      if (record.trainer) {
       
        const educationData = await FirebaseService.getEducationData();
      //  console.log('Education Data:', educationData);
  
      
        const isInEducationData = educationData.some(
          (eduRecord) => eduRecord.trainerNameSurname === record.nameSurname
        );
  
        if (isInEducationData) {
        
         /* console.log(
            'Hiding Trainer. Trainer is associated with education records.'
          );*/
          const hideResult = await FirebaseService.makeTrainerVisible(record.key);
  
          if (hideResult.success) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description:
                'Silmek istediğiniz Eğitmene ait Eğitim Kayıtları olduğundan silme işlemi gerçekleştirilemez.',
              placement: 'topRight',
            });
          } else {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description:
                'İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.',
              placement: 'topRight',
            });
          }
          return;
        }
  
       
        const blogsData = await FirebaseService.getBlogData();
       // console.log('Blogs Data:', blogsData);
  
      
        const isInBlogsData = blogsData.some(
          (blogRecord) => blogRecord.trainerNameSurname === record.nameSurname
        );
  
        if (isInBlogsData) {
          // If the record is in blog data, prevent deletion
       /*   console.log(
            'Cannot delete. Trainer is associated with blog records.'
          );*/
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description:
              'Silmek istediğiniz Eğitmene ait Blog Kayıtları olduğundan silme işlemi gerçekleştirilemez.',
            placement: 'topRight',
          });
          return;
        }
  
       
        const newsData = await FirebaseService.getNewData();
       // console.log('News Data:', newsData);
  
       
        const isInNewsData = newsData.some(
          (newsRecord) => newsRecord.trainerNameSurname === record.nameSurname
        );
  
        if (isInNewsData) {
        /*console.log(
            'Cannot delete. Trainer is associated with news records.'
          );*/
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description:
              'Silmek istediğiniz Eğitmene ait Haber Kayıtları olduğundan silme işlemi gerçekleştirilemez.',
            placement: 'topRight',
          });
          return;
        }
  
      
        const servicesData = await FirebaseService.getServiceData();
        //console.log('Services Data:', servicesData);
  
      
        const isInServicesData = servicesData.some(
          (serviceRecord) => serviceRecord.trainerNameSurname === record.nameSurname
        );
  
        if (isInServicesData) {
        
         /* console.log(
            'Cannot delete. Trainer is associated with services records.'
          );*/
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description:
              'Silmek istediğiniz Eğitmene ait Hizmet Kayıtları olduğundan silme işlemi gerçekleştirilemez.',
            placement: 'topRight',
          });
          return;
        }
      }
  
     
      let result;
      if (isDeleted) {
        result = record.trainer
          ? await FirebaseService.makeTrainerVisible(record.key)
          : await FirebaseService.makeAuthorVisible(record.key);
      } else {
        result = record.trainer
          ? await FirebaseService.deleteTrainer(record.key)
          : await FirebaseService.deleteAuthor(record.key);
      }
  
     // console.log('Deletion Result:', result);
  
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight',
        });
  
       
        const data = await FirebaseService.getAuthorData();
        data.forEach((f) => (f.trainer = false));
  
        const trainerData = await FirebaseService.getTrainerData();
        trainerData.forEach((f) => (f.trainer = true));
  
        const combinedData = [...data, ...trainerData];
        setTableData(combinedData);
  
        //console.log('Table Data Updated:', combinedData);
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description:
            'İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.',
          placement: 'topRight',
        });
      }
    } catch (error) {
      console.error('Error during deletion:', error);
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Bilinmeyen bir hata ile karşılaşıldı.',
        placement: 'topRight',
      });
    }
  };
  
  
  
  
  

  const columns = [
    {
      title: 'İsim Soyisim',
      dataIndex: 'nameSurname',
      key: 'nameSurname',
      render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 400,
    },
    {
      title: 'Eğitmen/Yazar',
      dataIndex: 'trainer',
      key: 'trainer',
      width: 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text?"Eğitmen":"Yazar"}</span>,
    },
    {
      title: 'Kategori',
      dataIndex: 'educationCategoryName',
      key: 'educationCategoryName',
      width: 400,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Gizle/Göster',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        
          record.isDeleted ?    
           <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
          :
          <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Gizle</Button>
      ),
     },
    // {
    //   title: 'Edit',
    //   dataIndex: 'edit',
    //   key: 'edit',
    //   render: (text, record) => (
    //     <a type="link" onClick={()=>goEditPage(record)}  style={{
    //       border: "none",
    //       backgroundColor: "transparent",
    //       pointerEvents: record.isDeleted ? "none" : "auto", 
    //       opacity: record.isDeleted ? 0.5 : 1, 
    //     }}><img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
    //   ),
    // }
  ];
  
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} style={{background:"transparent",borderColor:"#1A446C80",color:"#1A446C",fontWeight:"bold",borderRadius:"0px",float:"right"}}>
          Yeni Ekle
        </Button>
          <Table
            locale={{ emptyText: 'Henüz yazar veya eğitmen eklemediniz...' }}
            dataSource={tableData}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

    };
  export default TrainerAndAuthorList;