import React, { useEffect,useState } from 'react';
import { Table,Button,Input,Space,Checkbox,Select,Form  } from 'antd';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import  {odsCollections}  from "../../environments/environment"
import  {adminMenuSelect,advisorMenuSelect, adminMenu}  from "../../environments/environment"

const UserEditList = () => {
  const [users, setUsers] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [disabledAdmin, setDisabledAdmin] = useState(false);
  const [disabledAdvisor, setDisabledAdvisor] = useState(false);
  const [disabledpages, setDisabledPages] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [adminmenu, setAdminMenu] = useState([]);
  const [advisormenu, setAdvisorMenu] = useState([]);

  const [updatedValues, setUpdatedValues] = useState({ 
    admin: {
    selectedOptions: [],
  },
  advisor: {
    phoneNumber: '',
  },
});

  useEffect(() => {
    window.scrollTo(0, 0);

    setAdminMenu(adminMenuSelect);
    setAdvisorMenu(advisorMenuSelect);
    fetchData();
  }, []);

  const handleUserTypeChange = (userId, type) => {
    const updatedUsers = filteredUsers.map(user => {
      if (user.userId === userId) {

        if (type === 'user') {
          return { ...user, userType: ['user'] };
        } else if (type === 'admin') {
          setDisabledAdmin(!disabledAdmin)
          const adminType= disabledAdmin ? ['user']:['user','admin']
          return { ...user, userType: adminType};
        } else if (type === 'advisor') {
          setDisabledAdvisor(!disabledAdvisor)
          const advisorType= disabledAdvisor ? ['user']:['user','advisor']
          return { ...user, userType: advisorType };
        }
        else if (type === 'headAdmin') {
          const headAdminType= disabledpages ? ['user']:['user','admin','headAdmin']
          return { ...user, userType: headAdminType };
        }
        else if (type === 'admin1') {
          const subAdminType= ['user','admin','admin1']
          return { ...user, userType: subAdminType };
        }
        return user;
      }
      return user;
    });

    setFilteredUsers(updatedUsers);
  };
  
const handleDelete = async (record, isDeleted) => {
  try {
    let result;
    if (isDeleted) {
      result = await FirebaseService.makeUserVisible(record.key);
    } else {
      result = await FirebaseService.deleteUser(record.key);
    }

    if (result.success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: result.message,
        placement: 'topRight'
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      fetchData();
      // const data = await FirebaseService.getUserData();
      // setUsers(data);
      // setFilteredUsers(data);
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
};

const fetchData = async () => {
  const data = await FirebaseService.getUserData();
  const advisorData = await FirebaseService.getAdvisorData();
  const newUpdatedValues = { };

  data.map(m=>{
    if(m.userType.includes('advisor'))
    {
      const advisorInfo = advisorData.find(f=>f.userId===m.userId && f.isDeleted !== true);      
      newUpdatedValues[m.userId] = { ...newUpdatedValues[m.userId] };
      newUpdatedValues[m.userId].advisor = { phoneNumber: advisorInfo.phoneNumber,selectedOptions: m.authorizationPages};
    }
    if(m.userType.includes('admin'))
    {
      newUpdatedValues[m.userId] = { ...newUpdatedValues[m.userId] };
      newUpdatedValues[m.userId].admin = { selectedOptions: m.authorizationPages};
      m.userType=['user','admin','admin1']
    }
  })
  setUpdatedValues(newUpdatedValues);
  setAdvisors(advisorData);
  setUsers(data);
  setFilteredUsers(data);
};

const handleUpdateAdmin = async (record) => { 
  try{
  const updatedUser = updatedValues[record.userId];
  const selectedOptions = record.userType.includes('admin1') ? (updatedUser?.admin?.selectedOptions===undefined ?[]: updatedUser.admin.selectedOptions) : []
  const advisorInfo = advisors.find(f=>f.userId===record.userId && f.isDeleted !== true);
    record.userType = record.userType.includes('admin1') ? ['user','admin'] :['user','headAdmin']
    if(selectedOptions.length === 0 && record.userType.includes('admin'))
    {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Lütfen kullanıcının erişebileceği sayfaları seçiniz.",
        placement: 'topRight'
      });
      return null;
    }

  if(advisorInfo)
  {
    const advisorresult = await FirebaseService.deleteAdvisor(advisorInfo.key);
    if(!advisorresult.success)
    {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
      return null;
    }
  }
    const result = await FirebaseService.updateAuthorizationPages(record.userId,selectedOptions,record.userType);
      if (result.success) {
        fetchData();
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        fetchData();
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
  
      }
      catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
    }
}   

const handleUpdateAdvisor = async (record) => {
  try {
    const advisorPhoneNumber = updatedValues[record.userId].advisor.phoneNumber;
    const selectedOptions = updatedValues[record.userId].advisor.selectedOptions;

    if(!advisorPhoneNumber)
    {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Lütfen telefon numarası giriniz.",
        placement: 'topRight'
      });
      return null;
    }
    const advisorData = {
      nameSurname: record.nameSurname,
      userId: record.userId,
      phoneNumber: advisorPhoneNumber,
      email: record.email,
      isDeleted: false,
      categoryName: selectedOptions
    };
    const resultUserType = await FirebaseService.updateAuthorizationPages(record.userId,selectedOptions,record.userType);

    if(resultUserType.success)
    {
      const result = await FirebaseService.addRecording(odsCollections.advisor,advisorData);

      if (result.success) {
        fetchData();
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        fetchData();
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });}  
    }
} catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
};


const handleUpdateUser = async (record) => {
  try {
    const advisorInfo = advisors.find(f=>f.userId===record.userId && f.isDeleted !== true);
    if(advisorInfo)
    {
      const advisorresult = await FirebaseService.deleteAdvisor(advisorInfo.key);
      if(!advisorresult.success)
      {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        return null;
      }}

    const resultUserType = await FirebaseService.updateAuthorizationPages(record.userId,[],["user"]);

    if (resultUserType.success) {  
      fetchData();
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: resultUserType.message,
        placement: 'topRight'
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    
    } else {
      fetchData();
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
  }} catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
};

const columns = [
  {
    title: 'İsim Soyisim',
    dataIndex: 'nameSurname',
    key: 'nameSurname',
    width: 400,
    render: (text, record) => (
      <span style={{ color: record.isDeleted ? "#00000080" : "#000000", fontWeight: "bold" }}>{text}</span>
    ),
  },
  {
    title: 'Kullanıcı Tipi',
    dataIndex: 'userType',
    key: 'userType',
    width: 700,
    render: (text, record) => (
      <div disabled={record.isDeleted}>
        <Checkbox
        className={`userCheckbox-${record.key}`}
          style={{ marginRight: '30px' }}
          onChange={() => handleUserTypeChange(record.userId, 'user')}
          checked={record.userType.includes('user')}
          disabled={record.userType.includes('user')||record.isDeleted}
        >
          Kullanıcı
        </Checkbox>
        <Checkbox
        className={`adminCheckbox-${record.key}`}
          style={{ marginRight: '30px' }}
          onChange={() => handleUserTypeChange(record.userId, 'admin')}
          checked={record.userType.includes('admin') || record.userType.includes('headAdmin')}
          disabled={record.userType.includes('advisor')||record.isDeleted}
        >
          Admin
        </Checkbox>
        <Checkbox
        className={`advisorCheckbox-${record.key}`}
          onChange={() => handleUserTypeChange(record.userId, 'advisor')}
          checked={record.userType.includes('advisor')}
          disabled={record.userType.includes('admin')||record.isDeleted}
        >
          Danışman
        </Checkbox>
      </div>
    ),
  },
  {
    title: 'Gizle/Göster',
    dataIndex: 'delete',
    key: 'delete',
    render: (text, record) => (
      
        record.isDeleted ?    
         <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
        :
        <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Gizle</Button>
    ),
  },
];


  return (
    <div  style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding:"3%"
    }}>
      <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="Kullanıcı Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = users.filter(user => {
            const fullName = user.nameSurname.toLowerCase();
            return fullName.includes(searchValue);
          });
          setFilteredUsers(filteredData);
        }}
      />

      <Table            
        locale={{ emptyText: 'Kullanıcı bulunmamaktadır...' }}
        pagination={false}
        showHeader={true}
        columns={columns}
        dataSource={filteredUsers}
        className="custom-news-table"
        rowClassName="custom-news-row"
        expandable={{
          expandedRowRender: (record) => {
            if ((record.userType.includes('admin') || record.userType.includes('headAdmin') || (record.userType.includes('admin1'))) && !record.isDeleted) {
              return (
                <div style={{display:'flex'}} className="news-Edit-container">
                <Checkbox
                    className={`userauthCheckbox-${record.key}`}
                      style={{ marginRight: '30px',marginTop: "5px" }}
                      onChange={() => handleUserTypeChange(record.userId, 'headAdmin')}
                      checked={record.userType.includes('headAdmin')}
                    >
                      Ana Admin
                    </Checkbox>
                    <Checkbox
                      className={`adminauthCheckbox-${record.key}`}
                      style={{ marginRight: '30px',marginTop: "5px"  }}
                      onChange={() => handleUserTypeChange(record.userId, 'admin1')}
                      checked={record.userType.includes('admin1')}
                    >
                      Admin
                    </Checkbox>
                     <Select
                        defaultValue={updatedValues[record.userId]?.admin?.selectedOptions || []}
                        name = {'selectedOptions'+ `${record.userId}`}
                        mode="tags"
                        disabled={record.userType.includes('headAdmin')||record.isDeleted}
                        placeholder="Kategori Seç (Birden fazla seçilebilir.)"
                        style={{ borderRadius: 0, borderColor: "#1A446C80", width: "40%" }}
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        options={adminmenu}
                        value={updatedValues[record.userId]?.admin?.selectedOptions || []}
                        onChange={(selectedOptions) => {
                        const newUpdatedValues = { ...updatedValues };
                          newUpdatedValues[record.userId] = { ...newUpdatedValues[record.userId] };
                          newUpdatedValues[record.userId].admin = { selectedOptions};
                          newUpdatedValues.admin = { selectedOptions };
                        setUpdatedValues(newUpdatedValues);
                      }}
                      />
                    <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0,marginLeft:"80px"}}onClick={() => handleUpdateAdmin(record)}>Kaydet</Button>
                </div>
              );
            } else if (record.userType.includes('advisor') && !record.isDeleted) {
              return (
                <div>
                  <Form
                      name="basic"
                      labelCol={{
                      span: 4,
                      }}
                      wrapperCol={{
                      span: 20,
                      }}
                      style={{
                      maxWidth: "100%",
                      padding:"5% 0 0 5%"
                      }}          
                      autoComplete="off"
                  >
                   <Form.Item 
                      name="proheNumber"
                      rules={[
                        {
                        required: true,
                        message: 'Lütfen telefon numarası giriniz!',
                        },
                    ]}
                      >
                    <div style={{display:'flex'}} className="news-Edit-container">
                      <Input 
                       onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                        }}
                      defaultValue={updatedValues[record.userId]?.advisor?.phoneNumber || ''}
                      name = {'phonenumber'+ `${record.userId}`}
                      value = {updatedValues[record.userId]?.advisor?.phoneNumber || ''}
                      onChange={(e) => {
                        const newUpdatedValues = { ...updatedValues };
                        newUpdatedValues[record.userId] = { ...newUpdatedValues[record.userId] };
                        newUpdatedValues[record.userId].advisor = { phoneNumber: e.target.value, selectedOptions:newUpdatedValues[record.userId].advisor?.selectedOptions};
                        setUpdatedValues(newUpdatedValues);
                      }}
                      style={{ borderRadius: 0, borderColor: "#1A446C80", width: "40%" }} 
                      placeholder="Telefon Numarası" />
                       <Select
                        defaultValue={updatedValues[record.userId]?.advisor?.selectedOptions || []}
                        mode="tags"
                        name = {'selectedOptions'+ `${record.userId}`}
                        placeholder="Kategori Seç (Birden fazla seçilebilir.)"
                        style={{ borderRadius: "0", borderColor: "#1A446C80", width: "40%",marginLeft:"25px" }}
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        options={advisormenu}
                        value={updatedValues[record.userId]?.advisor?.selectedOptions || []}
                        onChange={(selectedOptions) => {
                        const newUpdatedValues = { ...updatedValues };
                          newUpdatedValues[record.userId] = { ...newUpdatedValues[record.userId] };
                          newUpdatedValues[record.userId].advisor = { selectedOptions, phoneNumber:newUpdatedValues[record.userId].advisor?.phoneNumber};
                          newUpdatedValues.advisor = { selectedOptions };
                        setUpdatedValues(newUpdatedValues);}}
                      />
                    <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0,marginLeft:"80px"}} onClick={() => handleUpdateAdvisor(record)}>Kaydet</Button>
                    </div>
                  </Form.Item>
                  </Form>
                </div>
              );}
              else if (record.userType.includes('user')&& !record.isDeleted)
              {  return (<div>
                <p>Bu kullanıcının yetkileri sadece kullanıcı olarak güncellenecektir.</p>
                <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0,float:"right"}} onClick={() => handleUpdateUser(record)}>Güncelle</Button>
                </div>)
              }
              else if(record.isDeleted)
              {  return (<div>
                <p>Bu kullanıcı gizlenmiştir. Yetkilerini güncelleyebilmek için kaydı aktifleştirin</p>
                </div>)
              }
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
            ) : (
              <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
            ),
            expandIconColumnIndex: 5
        }}

      />
      </div>
  );
};
export default UserEditList;