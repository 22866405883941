export const environment = {
  //test
/*  
   firebase : {
     apiKey: "AIzaSyD0BKdrrxVht6ExPV-bHjbKLohCEv5AMto",
     authDomain: "ods-971c1.firebaseapp.com",
     projectId: "ods-971c1",
     storageBucket: "ods-971c1.appspot.com",
     messagingSenderId: "208620318351",
     appId: "1:208620318351:web:70fcb5648e85b480a14afa",
     measurementId: "G-K5HG3Q398S"
   }
*/

   
   //canlı
   
   
   firebase : {
     apiKey: "AIzaSyAav4tgtJJtFe54x6ChjyJV4mY_AXBYoIs",
     authDomain: "odsapp-18686.firebaseapp.com",
     projectId: "odsapp-18686",
     storageBucket: "odsapp-18686.appspot.com",
     messagingSenderId: "90841550937",
     appId: "1:90841550937:web:031c781e2e170dcb672148",
     measurementId: "G-4FHRHPXCN0"
   }
   
   
 }

export const odsCollections = {
  partner: "partners",
  new:"news",
  blog:"blogs",
  service:"services",
  education:"educations",
  notifation:"notifications",
  educationRequest:"educationRequests",
  category:"category",
  ourInvestment:"ourInvestments",
  user:"users",
  odsInNumber:"numbersods",
  author:"authors",
  trainer:"trainer",
  advisor:"advisor",
  address:"maps",
  purchaseRequest:"purchaseRequests",
  projects:"projects",
  projectType:"projecttype",
  story:"stories",
  cd:"cd",
  tq:"tq",
  sap:"sap",
  ibm:"ibm",
  ipm:"ipm",
  ipmProjectBased:"ipmProjects",
  campaigns:"campaigns",
  survey:"survey"
}

export const categoryDocumentId ={
  id:""
}

export const categoryName ={
  new:"new",
  blog:"blog",
  education:"education",
  notification:"notification",
  service:"service",
  survey:"survey",
  trainer:"trainer"
}

export const adminMenu ={
  project:"Hizmetlerim",
  odsinnumber:'SEKTÖRDE 15.YIL',
  useredit:"Kullanıcı Düzenle",
  address:"Adresler",
  news:"Haberler",
  blogs:"Blog",
  services:"Hizmetler",
  trainings:"Eğitimler",
  // trainingrequestlist:"Eğitim Talepleri",
  notifications:"Bildirimler",
  trainerandauthor:"Eğitmen/Yazar",
  surveys:"Anketler",
  purchaseRequest:"Satın Alma Talepleri",
  story:"Hikaye",
  homePage:"Ana Sayfa",
  campaign:"Kampanyalar",
  webpage:"Web"

}

export const emailJs ={
   serviceId : 'service_4nesbds',
   templateId : 'template_hsbcgnv',
   publicKey :'RdDO8vkek1GZgWZwI'
}


export const oneSignal ={
  apiKey:'OWM4ZGM1NzAtZWUwNy00NjFlLWJiNzYtOTIwODgwZDM5NWU1',
  appId:"4e01c130-fb6d-40fb-ac54-13d284aace43"
}

export const map ={
  apiKey:'AIzaSyB5KNg2z0mmF5so5NnYq6XiUE4cXRDTOnI'
}
export const adminMenuSelect = [
  {
    value: adminMenu.homePage,
    label: adminMenu.homePage,
  },
  {
  value: adminMenu.project,
  label: adminMenu.project,
  },
  {
    value:adminMenu.address,
    label:adminMenu.address
  },
  {
    value:adminMenu.blogs,
    label:adminMenu.blogs
  },
  {
    value:adminMenu.news,
    label:adminMenu.news
  },
  {
    value:adminMenu.notifications,
    label:adminMenu.notifications
  },
  {
    value:adminMenu.odsinnumber,
    label:adminMenu.odsinnumber
  },
  {
    value:adminMenu.services,
    label:adminMenu.services
  },
  {
    value:adminMenu.surveys,
    label:adminMenu.surveys
  },
  {
    value:adminMenu.trainerandauthor,
    label:adminMenu.trainerandauthor,
  },
  {
    value:adminMenu.trainings,
    label:adminMenu.trainings,
  },
  {
    value:adminMenu.useredit,
    label:adminMenu.useredit,
  },
  {
    value:adminMenu.purchaseRequest,
    label:adminMenu.purchaseRequest,
  },
  {
    value:adminMenu.story,
    label:adminMenu.story,
  },
  {
    value:adminMenu.campaign,
    label:adminMenu.campaign,
  },
  ]

export const advisorMenu = {
  cd:"CD",
  tq:"TQ",
  sap:"SAP",
  ibm:"IBM",
  ipm:"IPM",
  businessDevelopment:"İş Geliştirme"
}

  export const advisorMenuSelect = [
    {
      value: advisorMenu.cd,
      label: advisorMenu.cd,
    },
    {
    value: advisorMenu.tq,
    label: advisorMenu.tq,
    },
    {
      value:advisorMenu.sap,
      label:advisorMenu.sap
    },
    {
      value:advisorMenu.ibm,
      label:advisorMenu.ibm
    },
    {
      value:advisorMenu.ipm,
      label:advisorMenu.ipm
    },
    {
      value:advisorMenu.businessDevelopment,
      label:advisorMenu.businessDevelopment
    }]
  

export const Products = [
  { value: "SAP Business One Professional User", label: "SAP Business One Professional User" },
  { value: "SAP Business One Limited User", label: "SAP Business One Limited User" },
  { value: "SAP Business One Starter Package", label: "SAP Business One Starter Package" },
  { value: "SAP Business One Indirect Access User", label: "SAP Business One Indirect Access User" },
  { value: "SAP Business One Limited to Professional Upgrade", label: "SAP Business One Limited to Professional Upgrade" },
  { value: "SAP Business One HANA Engine", label: "SAP Business One HANA Engine" },
  { value: "E-Fatura", label: "E-Fatura" },
  { value: "E-Defter", label: "E-Defter" },
  { value: "E-Arşiv", label: "E-Arşiv" },
  { value: "E-İhracat", label: "E-İhracat" },
  { value: "E-İrsaliye", label: "E-İrsaliye" },
  { value: "Bordro Add On", label: "Bordro Add On" },
  { value: "Hazine ve Kredi Yönetimi Add On", label: "Hazine ve Kredi Yönetimi Add On" },
  { value: "Banka Ödeme Yönetimi Add On", label: "Banka Ödeme Yönetimi Add On" },
  { value: "Gelen Banka Hareketleri Add On", label: "Gelen Banka Hareketleri Add On" },
  { value: "Üçlü Eşleştirme Add On", label: "Üçlü Eşleştirme Add On" },
  { value: "Masraff Uygulaması SAP Business One Entegrasyonu", label: "Masraff Uygulaması SAP Business One Entegrasyonu" },
  { value: "Online Mütabakat ve BA/BS", label: "Online Mütabakat ve BA/BS" },
  { value: "Finansal Ek Çözümler", label: "Finansal Ek Çözümler" },
  { value: "ProcessForce Pro", label: "ProcessForce Pro" },
  { value: "ProcessForce Limited", label: "ProcessForce Limited" },
  { value: "Upgrade from Limited to Professional", label: "Upgrade from Limited to Professional" },
  { value: "Computec PDC/WMS Server", label: "Computec PDC/WMS Server" },
  { value: "Computec PDC/WMS Terminal", label: "Computec PDC/WMS Terminal" },
  { value: "Computec Labels Server", label: "Computec Labels Server" },
  { value: "Computec Labels Printer", label: "Computec Labels Printer" },
  { value: "B1 Usability Package - Enterprise (Ana Paket - 5 kullanıcı)", label: "B1 Usability Package - Enterprise (Ana Paket - 5 kullanıcı)" },
  { value: "B1 Usability Package - Enterprise (Ek 5 kullanıcı)", label: "B1 Usability Package - Enterprise (Ek 5 kullanıcı)" },
  { value: "B1 Print and Delivery (Ana Paket - 5 kullanıcı)", label: "B1 Print and Delivery (Ana Paket - 5 kullanıcı)" },
  { value: "B1 Print and Delivery (Ek 5 kullanıcı)", label: "B1 Print and Delivery (Ek 5 kullanıcı)" },
  { value: "B1 MailChimp (Sınırsız kullanıcı)", label: "B1 MailChimp (Sınırsız kullanıcı)" },
  { value: "Ek 5 database", label: "Ek 5 database" },
  { value: "CRM for Outlook", label: "CRM for Outlook" },
  { value: "Varyant Add On", label: "Varyant Add On" },
  { value: "İnşaat Proje Yönetimi Add On", label: "İnşaat Proje Yönetimi Add On" },
  { value: "İhale Yönetimi Add On", label: "İhale Yönetimi Add On" },
  { value: "Sözleşme Yönetimi Add On", label: "Sözleşme Yönetimi Add On" },
  { value: "Hakkediş Add On", label: "Hakkediş Add On" },
];

export const Process = [
  {
  value: "Sözleşme başlangıç",
  label: "Sözleşme başlangıç"},
  {
    value: "Kickoff",
    label: "Kickoff"},
  {
    value: "Mdd Toplantısı",
    label: "Mdd Toplantısı"},
  {
    value: "Pdr Sunumu",
    label: "Pdr Sunumu"},
  {
    value: "Denetim Hazırlığı",
    label: "Denetim Hazırlığı"},
  {
    value: "Canlıya Geçiş",
    label: "Canlıya Geçiş"},
  {
    value: "Sözleşme Bitiş",
    label: "Sözleşme Bitiş"},
];


export const SubServicess = [
  {
  value: "Mevcut Durum Analizi",
  label: "Mevcut Durum Analizi"},
  {
    value: "Stratejik Planlama",
    label: "Stratejik Planlama"},
  {
    value: "Bütçe Çalışması",
    label: "Bütçe Çalışması"},
  {
    value: "Pazarlama ve Marka Yönetimi",
    label: "Pazarlama ve Marka Yönetimi"},
  {
    value: "Operasyon Yönetimi",
    label: "Operasyon Yönetimi"},
  {
    value: "AR/GE Tasarım Çalışmaları",
    label: "AR/GE Tasarım Çalışmaları"},
  {
    value: "İnsan Kaynakları Yönetimi",
    label: "İnsan Kaynakları Yönetimi"},
];

export const ProjectNames = [
  { value: "Hb1 modeli ihracat danışmanlığı", label: "Hb1 modeli ihracat danışmanlığı" },
  { value: "Hb2 modeli ihracat danışmanlığı", label: "Hb2 modeli ihracat danışmanlığı" },
  { value: "Hb3 modeli ihracat danışmanlığı", label: "Hb3 modeli ihracat danışmanlığı" },
  { value: "Tradewise", label: "Tradewise" },
];

export const Currencies = [
  { value: "USD", label: "United States Dollar" },
  { value: "EUR", label: "Euro" },
  { value: "JPY", label: "Japanese Yen" },
  { value: "GBP", label: "British Pound Sterling" },
  { value: "AUD", label: "Australian Dollar" },
  { value: "CAD", label: "Canadian Dollar" },
  { value: "CNY", label: "Chinese Yuan" },
  { value: "INR", label: "Indian Rupee" },
  { value: "BRL", label: "Brazilian Real" },
  { value: "RUB", label: "Russian Ruble" },
  { value: "TRY", label: "Turkish Lira" },
  { value: "ZAR", label: "South African Rand" },
  { value: "MXN", label: "Mexican Peso" },
  { value: "SGD", label: "Singapore Dollar" },
  { value: "NZD", label: "New Zealand Dollar" },
  { value: "SEK", label: "Swedish Krona" },
  { value: "CHF", label: "Swiss Franc" },
  { value: "NOK", label: "Norwegian Krone" },
  { value: "DKK", label: "Danish Krone" },
  { value: "KRW", label: "South Korean Won" },
  { value: "AED", label: "United Arab Emirates Dirham" },
];


export const consultingOptions = [
  { value: "3 Projeli Yıllık Danışmanlık", label: "3 Projeli Yıllık Danışmanlık" },
  { value: "2 Projeli Yıllık Danışmanlık" , label: "2 Projeli Yıllık Danışmanlık" },
  { value: "4 Projeli Yıllık Danışmanlık", label: "4 Projeli Yıllık Danışmanlık" },
  { value: "Sınırsız Projeli Yıllık Danışmanlık", label: "Sınırsız Projeli Yıllık Danışmanlık" },
];

export const adminMenuLogin = [
  {
    value: adminMenu.homePage,
    navigate: "'/adminpanel'",
  },
  {
  value: adminMenu.project,
  navigate: '/cdprojectlist',
  },
  {
    value:adminMenu.address,
    navigate:'/address'
  },
  {
    value:adminMenu.blogs,
    navigate:'/blogs'
  },
  {
    value:adminMenu.news,
    navigate:'/news'
  },
  {
    value:adminMenu.notifications,
    navigate:'/notifications'
  },
  {
    value:adminMenu.odsinnumber,
    navigate:'/odsinnumbers'
  },
  {
    value:adminMenu.services,
    navigate:'/services'
  },
  {
    value:adminMenu.surveys,
    navigate:'/surveys'
  },
  {
    value:adminMenu.trainerandauthor,
    navigate:'/trainerandauthor',
  },
  {
    value:adminMenu.trainings,
    navigate:'/trainings',
  },
  {
    value:adminMenu.useredit,
    navigate:'/useredit',
  },
  {
    value:adminMenu.purchaseRequest,
    navigate:'/purchaserequest',
  },
  {
    value:adminMenu.story,
    navigate:'/stories',
  },
  {
    value:adminMenu.campaign,
    navigate:'/campaignlist',
  },
  ]

  export const advisorMenuLogin = [
    {
      value: advisorMenu.cd,
      navigate: '/cdprojectlist',
    },
    {
    value: advisorMenu.tq,
    navigate: '/tqprojectlist',
    },
    {
      value:advisorMenu.sap,
      navigate:'/sapprojectlist'
    },
    {
      value:advisorMenu.ibm,
      navigate:'/ibmprojectlist'
    },
    {
      value:advisorMenu.ipm,
      navigate:'/ipmprojectlist'
    },
    {
      value:advisorMenu.businessDevelopment,
      navigate:'/businessdevelopmentlist'
    }]

   export const allCities = [
      { value: "Adana", label: "Adana" },
      { value: "Adıyaman", label: "Adıyaman" },
      { value: "Afyonkarahisar", label: "Afyonkarahisar" },
      { value: "Ağrı", label: "Ağrı" },
      { value: "Amasya", label: "Amasya" },
      { value: "Ankara", label: "Ankara" },
      { value: "Antalya", label: "Antalya" },
      { value: "Ardahan", label: "Ardahan" },
      { value: "Artvin", label: "Artvin" },
      { value: "Aydın", label: "Aydın" },
      { value: "Balıkesir", label: "Balıkesir" },
      { value: "Bartın", label: "Bartın" },
      { value: "Batman", label: "Batman" },
      { value: "Bayburt", label: "Bayburt" },
      { value: "Bilecik", label: "Bilecik" },
      { value: "Bingöl", label: "Bingöl" },
      { value: "Bitlis", label: "Bitlis" },
      { value: "Bolu", label: "Bolu" },
      { value: "Burdur", label: "Burdur" },
      { value: "Bursa", label: "Bursa" },
      { value: "Çanakkale", label: "Çanakkale" },
      { value: "Çankırı", label: "Çankırı" },
      { value: "Çorum", label: "Çorum" },
      { value: "Denizli", label: "Denizli" },
      { value: "Diyarbakır", label: "Diyarbakır" },
      { value: "Düzce", label: "Düzce" },
      { value: "Edirne", label: "Edirne" },
      { value: "Elazığ", label: "Elazığ" },
      { value: "Erzincan", label: "Erzincan" },
      { value: "Erzurum", label: "Erzurum" },
      { value: "Eskişehir", label: "Eskişehir" },
      { value: "Gaziantep", label: "Gaziantep" },
      { value: "Giresun", label: "Giresun" },
      { value: "Gümüşhane", label: "Gümüşhane" },
      { value: "Hakkâri", label: "Hakkâri" },
      { value: "Hatay", label: "Hatay" },
      { value: "Iğdır", label: "Iğdır" },
      { value: "Isparta", label: "Isparta" },
      { value: "İstanbul", label: "İstanbul" },
      { value: "İzmir", label: "İzmir" },
      { value: "Kahramanmaraş", label: "Kahramanmaraş" },
      { value: "Karabük", label: "Karabük" },
      { value: "Karaman", label: "Karaman" },
      { value: "Kars", label: "Kars" },
      { value: "Kastamonu", label: "Kastamonu" },
      { value: "Kayseri", label: "Kayseri" },
      { value: "Kırıkkale", label: "Kırıkkale" },
      { value: "Kırklareli", label: "Kırklareli" },
      { value: "Kırşehir", label: "Kırşehir" },
      { value: "Kilis", label: "Kilis" },
      { value: "Kocaeli", label: "Kocaeli" },
      { value: "Konya", label: "Konya" },
      { value: "Kütahya", label: "Kütahya" },
      { value: "Malatya", label: "Malatya" },
      { value: "Manisa", label: "Manisa" },
      { value: "Mardin", label: "Mardin" },
      { value: "Mersin", label: "Mersin" },
      { value: "Muğla", label: "Muğla" },
      { value: "Muş", label: "Muş" },
      { value: "Nevşehir", label: "Nevşehir" },
      { value: "Niğde", label: "Niğde" },
      { value: "Ordu", label: "Ordu" },
      { value: "Osmaniye", label: "Osmaniye" },
      { value: "Rize", label: "Rize" },
      { value: "Sakarya", label: "Sakarya" },
      { value: "Samsun", label: "Samsun" },
      { value: "Siirt", label: "Siirt" },
      { value: "Sinop", label: "Sinop" },
      { value: "Sivas", label: "Sivas" },
      { value: "Şanlıurfa", label: "Şanlıurfa" },
      { value: "Şırnak", label: "Şırnak" },
      { value: "Tekirdağ", label: "Tekirdağ" },
      { value: "Tokat", label: "Tokat" },
      { value: "Trabzon", label: "Trabzon" },
      { value: "Tunceli", label: "Tunceli" },
      { value: "Uşak", label: "Uşak" },
      { value: "Van", label: "Van" },
      { value: "Yalova", label: "Yalova" },
      { value: "Yozgat", label: "Yozgat" },
      { value: "Zonguldak", label: "Zonguldak" },
    ];