import React, { useState, useEffect } from 'react';
import './services.css';
import { Card, Carousel } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import FirebaseService from '../../services/firebaseService';

const ServiceListWithCategory = () => {
  const [serviceData, setServiceData] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    const data = await FirebaseService.getServiceDataFilter();
    return data;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchData();
        setServiceData(fetchedData);
      } catch (error) {
        console.error('Veriyi ayarlama hatası:', error);
       
      }
    };

    getData();
  }, []);
  

  const groupedData = {};
  
  const filteredData = serviceData.filter(item => item.categoryName !== '-');
  filteredData.forEach((item) => {
    console.log("item",item.categoryName)
    const category = item.categoryName || 'Other';
    if (!groupedData[category]) {
      console.log("jnjn")
      groupedData[category] = [];
    }
    groupedData[category].push(item);
  });

  const settings = {
    dots: false,
    nextArrow: <img src='/images/rigtharrow.png' style={{ maxWidth: '400px' }} />,
    prevArrow: <img src='/images/leftarrow.png' style={{ maxWidth: '400px' }} />,
    infinite: false,
    speed: 500,
    slidesToShow: 6.5,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const goDetailPage = (record) => {
    navigate('/service-detail', { state: { record } });
  };

  return (
    <div>
      {Object.entries(groupedData).map(([category, categoryData]) => (
        <div key={category} style={{ marginTop: 80, color: '#1A446C' }}>
          <h4 style={{ textAlign: 'justify', marginLeft: '15px' }}>{category}</h4>
          <div className='service-by-category-card-carousel'>
            <Carousel arrows {...settings}>
              {categoryData.map((item) => (
                <div key={item.id} onClick={() => goDetailPage(item)}>
                    <Card bordered={false} className='service-by-category-card'>
                      <div className='service-by-category-card-cody'>
                        <img alt='df'  src={item.imageUrl} className='service-card-img' />
                        <span className='service-card-title'>{item.title}</span>
                      </div>
                    </Card>
                 
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceListWithCategory;
