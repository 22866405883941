import React, { useState, useEffect } from 'react';
import './news.css';
import {  Row, Col } from 'antd';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';


const LastNews = () => {
  const [newsData, setNewsData] = useState([]);
  const navigate = useNavigate(); 


  const goDetailPage = (record) => {
    navigate('/news-detail', { state: { record } });
  };
 
  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const data = await FirebaseService.getNewDataFilter();
        const filteredData = data
          .filter(item => !item.isDeleted)
          .sort((a, b) => new Date(a.releaseDate) - new Date(b.releaseDate))
          .slice(0, 3);

        setNewsData(filteredData);
      } catch (error) {
        //console.error('Haber verilerini çekme hatası:', error);
      }
    };

    fetchNewsData();
  }, []);
  const filteredAndSortedNewsData = newsData
  ? newsData
  .sort((a, b) => (b.favoriteUserId ? b.favoriteUserId.length : 0) - (a.favoriteUserId ? a.favoriteUserId.length : 0))
      .slice(0, 3)
  : [];

console.log("favrolisteuzunkampanya", filteredAndSortedNewsData);

const AutoplaySlider = withAutoplay(AwesomeSlider);

  return (
    <div>
      <h3 style={{ marginBottom: '0px', textAlign: 'justify', color: '#1A446C' }}>Popüler Haberler</h3>
      <Row >
        <Col span={35}>
          <div className="last-news-carausel" style={{cursor:"pointer"}}>
            {newsData.length === 0 ? (
              <p style={{ textAlign: 'justify', color: '#1A446C', justifyContent:'center' }}>Haber bulunmamaktadır.</p>
            ) : (

              <AutoplaySlider
              play={true}
              cancelOnInteraction={false} 
              interval={3000}
              style={{ width: '1050px', height: '300px', }}
            >
              {filteredAndSortedNewsData.map((item, index) => (
                <div key={index}  onClick={() => goDetailPage(item)} style={{ backgroundColor:"white"}}>
                    <img  style={{width: "1050px",height: "250px", objectFit:"cover", backgroundColor:"white"}} src={item.imageUrl} alt={item.title} /> 
                    <h2 className="last-news-card-title">{item.title}</h2>             
                </div>
              ))}  
            </AutoplaySlider>

            
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LastNews;