import React, { useState, useEffect } from 'react';
import './training.css';
import { Row, Col } from 'antd';
import FirebaseService from '../../services/firebaseService';
import { useNavigate } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const PopularTraining = () => {
  const [trainingData, setTrainingData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate(); 

  const fetchData = async () => {
    const data = await FirebaseService.getTrainingDataFilter();
    return data;
  };
 

 
const filteredAndSortedTrainingData = trainingData && trainingData.length > 0 ?
trainingData
 
.sort((a, b) => (b.favoriteUserId ? b.favoriteUserId.length : 0) - (a.favoriteUserId ? a.favoriteUserId.length : 0))
  .slice(0, 3)
: [];

console.log("favrolisteuzun", filteredAndSortedTrainingData);


  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchData();
        setTrainingData(fetchedData);
      } catch (error) {
        console.error('Veriyi ayarlama hatası:', error);
       
      }
    };

    getData();
  }, []);
  const staticImageUrl = '/images/odslogo.png';

 
  const goDetailPage = (record) => {
    navigate('/training-detail', { state: { record } });
  };
  
  const AutoplaySlider = withAutoplay(AwesomeSlider);


  return (
    <div>
      <h3 style={{ marginBottom: '0px', textAlign: 'justify', color: '#1A446C' }}>Popüler Eğitimler</h3>
      <Row>
        <Col span={35}>
          <div className='popular-training-carausel' style={{cursor:"pointer"}}>
            {trainingData.length === 0 ? (
              <p style={{ textAlign: 'justify', color: '#1A446C', justifyContent:'center',marginTop: '20px' }}>Eğitim bulunmamaktadır.</p>
            ) : (

              
              <AutoplaySlider
              play={true}
              cancelOnInteraction={false} 
              interval={3000}
              style={{ width: '1050px', height: '300px', }}
            >
              {filteredAndSortedTrainingData.map((item, index) => (
                <div key={index}  onClick={() => goDetailPage(item)} style={{ backgroundColor:"white"}}>
                    <img  style={{width: "1050px",height: "250px", objectFit:"contain", backgroundColor:"white"}} src={staticImageUrl} alt={item.title} /> 
                    <h2 className="last-news-card-title">{item.title}</h2>             
                </div>
              ))}  
            </AutoplaySlider>

            
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PopularTraining;