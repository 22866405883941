import React,{useEffect,useState}  from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import  {odsCollections,categoryName}  from "../../environments/environment"

const ServiceList = () => {
  const [service, setService] = useState([]);
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getServiceData();
      data.sort((a, b) => new Date(b.releaseDateEdit) - new Date(a.releaseDateEdit));
      setService(data)

      const categories = await FirebaseService.getCategoryData(categoryName.service);
      const categoryOptions = categories.map(category => ({
        categoryName: category,
       
      }));
      setCategory(categoryOptions)
    };

    fetchData();
  }, []);

const goAddPage = () => {
  navigate('/serviceadd')
};

const goEditPage = (record) => {
  navigate('/serviceedit', { state: { record } })
};

const handleDelete = async (record, isDeleted) => {
  try {
    let result;
    if (isDeleted) {
      result = await FirebaseService.makeServiceVisible(record.key);
    } else {
      result = await FirebaseService.deleteService(record.key);
    }

    if (result.success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: result.message,
        placement: 'topRight'
      });
      const data = await FirebaseService.getServiceData();
      setService(data)
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
};



const goEditPageCategory = (record) => {
  navigate('/categoryserviceedit', { state: { record } })
};

const handleDeleteCategory = async (record) => {
  console.log("resulttttt", record)
  
  try {
    let result;
      result = await FirebaseService.deleteCategoryItem(record.categoryName);
      if (result) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        
       setTimeout(() => {
          window.location.reload();
        }, 2000);
        
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }

  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
  
};


const columns = [
  {
    title: 'Resim',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (img,record) => <img src={img} width={50} style={{opacity: record.isDeleted ? "60%" : null}}/>,
    width: 150,
  },
  {
    title: 'Başlık',
    dataIndex: 'title',
    key: 'title',
    render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
    width: 400,
  },
  {
    title: 'Yayınlanma Tarihi',
    dataIndex: 'releaseDate',
    key: 'releaseDate',
    width: 200,
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
  {
    title: 'Kategori',
    dataIndex: 'categoryName',
    key: 'categoryName',
    width: 300,
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
  {
    title: 'Gizle/Göster',
    dataIndex: 'delete',
    key: 'delete',
    render: (text, record) => (
      
        record.isDeleted ?    
          <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
        :
        <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Gizle</Button>
    ),
  },
  {
    title: 'Güncelle',
    dataIndex: 'edit',
    key: 'edit',
    render: (text, record) => (
      <a type="link" onClick={()=>goEditPage(record)} style={{
        border: "none",
        backgroundColor: "transparent",
        pointerEvents: record.isDeleted ? "none" : "auto", 
        opacity: record.isDeleted ? 0.5 : 1, 
      }}><img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
    ),
  }
];


const columnsCategory = [
  
  {
    title: 'Kategori',
    dataIndex: 'categoryName',
    key: 'categoryName',
    render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
    width: 400,
  },
 
  {
    title: 'Gizle/Göster',
    dataIndex: 'delete',
    key: 'delete',
    render: (text, record) => (
        <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDeleteCategory(record)}>Sil</Button>
    ),
  },
  {
    title: 'Güncelle',
    dataIndex: 'edit',
    key: 'edit',
    render: (text, record) => (
      <a type="link" onClick={()=>goEditPageCategory(record)} style={{
        border: "none",
        backgroundColor: "transparent",
        pointerEvents: record.isDeleted ? "none" : "auto", 
        opacity: record.isDeleted ? 0.5 : 1, 
      }}><img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
    ),
  }
];

   return <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>

<Table
            locale={{ emptyText: 'Henüz kategori eklemediniz...' }}
            dataSource={category}
            columns={columnsCategory}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />



        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} style={{background:"transparent",borderColor:"#1A446C80",color:"#1A446C",fontWeight:"bold",borderRadius:"0px",float:"right"}}>
          Yeni Ekle
        </Button>
          <Table
            locale={{ emptyText: 'Henüz hizmet eklemediniz...' }}
            dataSource={service}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>
};
  export default ServiceList;