import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";


const OdsInNumberList = () => {
  const [odsInNumber, setOdsInNumber] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const data = await FirebaseService.getOdsInNumberData();
      data.sort((a, b) => new Date(b.firstTransactionDateEdit) - new Date(a.firstTransactionDateEdit));
      setOdsInNumber(data)
    };
    fetchData();
  }, []);

  const goAddPage = () => {
    navigate('/odsinnumberadd')
  };

  
const handleDelete = async (record, isDeleted) => {
  try {
    let result;
    if (isDeleted) {
      result = await FirebaseService.makeOdsInNumberVisible(record.key);
    } else {
      result = await FirebaseService.deleteOdsInNumber(record.key);
    }

    if (result.success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: result.message,
        placement: 'topRight'
      });
      const data = await FirebaseService.getOdsInNumberData();
      data.sort((a, b) => new Date(b.firstTransactionDateEdit) - new Date(a.firstTransactionDateEdit));
      setOdsInNumber(data)
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
};


const columns = [
  {
    title: 'Resim',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (img,record) => <img src={img} width={50} style={{opacity: record.isDeleted ? "60%" : null}}/>,
    width: 200,
  },
  {
    title: 'Başlık',
    dataIndex: 'title',
    key: 'title',
    render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000" }}>SEKTÖRDE 15.YIL</span>,
    width: 550,
  },
  {
    title: 'Oluşturulma Tarihi',
    dataIndex: 'firstTransactionDate',
    key: 'firstTransactionDate',
    width: 250,
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
  {
    title: 'Gizle/Göster',
    dataIndex: 'delete',
    key: 'delete',
    render: (text, record) => (
      
        record.isDeleted ?    
         <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
        :
        <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Gizle</Button>
    ),
  }
];

   return <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} style={{background:"transparent",borderColor:"#1A446C80",color:"#1A446C",fontWeight:"bold",borderRadius:"0px",float:"right"}}>
          Yeni Ekle
        </Button>
          <Table
            locale={{ emptyText: 'Henüz SEKTÖRDE 15.YIL eklemediniz...' }}
            dataSource={odsInNumber}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

    };
  export default OdsInNumberList;