import React, { useEffect,useState } from 'react';
import { Table,Button } from 'antd';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const TrainingRequestList = () => {
  const [trainingRequest, setTrainingRequest] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getTrainingRequestData();
      data.sort((a, b) => new Date(b.createdDateEdit) - new Date(a.createdDateEdit));
      setTrainingRequest(data);
    };

    fetchData();
  }, []);

  
const handleDelete = async (record, isDeleted) => {
  try {
    let result;
    if (isDeleted) {
      result = await FirebaseService.makeEducationRequestVisible(record.key);
    } else {
      result = await FirebaseService.deleteEducationRequest(record.key);
    }

    if (result.success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: result.message,
        placement: 'topRight'
      });
      const data = await FirebaseService.getTrainingRequestData();
      setTrainingRequest(data);
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
};

const columns = [
  {
    title: 'Başlık',
    dataIndex: 'educationCategoryName',
    key: 'educationCategoryName',
    width: 400,
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>
  },
  {
    title: 'Tarih',
    dataIndex: 'trainerName',
    key: 'trainerName',
    width: 300,
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
  {
    title: 'Kategori',
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: 300,
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  }
];


  return (
    <div  style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding:"3%"
    }}>
      <Table            
        locale={{ emptyText: 'Eğitim isteği bulunmamaktadır...' }}
        pagination={false}
        showHeader={false}
        columns={columns}
        dataSource={ trainingRequest }
        className="custom-news-table"
        rowClassName="custom-news-row"
        expandable={{
          expandedRowRender: record => (
            <div style={{ display: 'flex' }}>
            <div style={{ flex: 0.5,marginLeft:"55px"}}>
             <p style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.userNameSurname}</p>
             </div>
            <div style={{ flex: 1, border:"1px solid #1A446C" }}>
             <p style={{marginLeft:"30px",color: record.isDeleted ? "#00000080" : "#000000"}}>{record.description}</p>
            </div>
        </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
            ) : (
              <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
            ),
            expandIconColumnIndex: 5
        }}

      />
      </div>
  );
};
export default TrainingRequestList;