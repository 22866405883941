import React from 'react';
import PopularTraining from './popularTraining';
import TrainingListWithCategory from './trainingListWithCategory';


const Trainings = () => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px',marginLeft:"75px"  }}>
    <PopularTraining/>
    <TrainingListWithCategory/>
</div>
);
export default Trainings;