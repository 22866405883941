import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { useLocation,Link,useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";

const Newdetail = () => {
  const location = useLocation();
  const { state } = location;
  const newsItem = state && state.record;
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  
  const currentUser = { id: localStorage.getItem("uid") }; 
  const [liked, setLiked] = useState(false);
  const [favoriteUserIdCount, setFavoriteUserIdCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (newsItem && newsItem.favoriteUserId) {
      if (newsItem.favoriteUserId.includes(currentUser.id)) {
        setLiked(true);
      } else {
        setLiked(false);
      }
  
      setFavoriteUserIdCount(newsItem.favoriteUserId.length);
    } else {
     
      setLiked(false);
      setFavoriteUserIdCount(0);
    }
  }, [newsItem, currentUser.id]);
  const handleLikeToggle = async () => {
  
    setLiked((prevLiked) => !prevLiked);
  
    await updateFavoriteStatus(newsItem.key, currentUser.id, !liked);
  
 
    setFavoriteUserIdCount(
      !liked ? favoriteUserIdCount + 1 : favoriteUserIdCount - 1
    );
  };

  const updateFavoriteStatus = async (newsItemId, userId, liked) => {
    try {
      if (!newsItemId) {
       // console.error('Invalid newsItemId');
        return;
      }
   
      await FirebaseService.updateNewsFavoriteStatus(newsItemId, userId, liked);
      //console.log('Favorite status updated successfully.başarılı');
    } catch (error) {
    //  console.error('Error updating favorite status:', error);
    }
  };

  const goAuthorDetailPage = (record) => {
    navigate('/author-detail', { state: { record } });
  };
  


  return (
    <div style={{ margin: "3% 0 0 5%" }}>
      {newsItem && (
        <Card
          style={{
            width: 1000,
            border: "none"
          }}
        >
         {newsItem.imageUrl && (
  <div
    style={{
      width: '1050px',
      height: '250px',
      borderRadius: '15px',
      overflow: 'hidden', 
    }}
  >
    <img
      src={newsItem.imageUrl}
      alt="Haber Resmi"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',  
      }}
    />
  </div>
)}



          <div style={{ textAlign: "start", marginTop:"30px" }}>
          {currentUser.id ? (
         <div style={{ display: "flex", alignItems: "center", float: "right", marginTop: 0 }}>
         <img
           alt="like"
           src={liked ? "../images/likedIcon.png" : "/images/likeicon.png"}
           style={{ width: "38px", cursor: 'pointer' }}
           onClick={handleLikeToggle}
         />
         <h4 style={{ marginLeft: "10px", marginTop: "10px", color:'#1A446C' }}>{favoriteUserIdCount}</h4>
       </div>
        ) : (
         <></>
        )}

            <h3 style={{ color: '#1A446C' }}>{newsItem.title}</h3>
          
            <p
      style={{
        color: isHovered ? '#001F3F' : '#1A446C', 
        cursor: 'pointer',
      }}
      onClick={() => newsItem.authorNameSurname && goAuthorDetailPage(newsItem)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
        {newsItem.authorNameSurname ? (
          <span>{newsItem.authorNameSurname}</span>
        ) : (
          <span>Yazar bulunmamaktadır</span>
        )}

            </p>

          <div style={{ color: '#1A446C', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', height: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: newsItem.description }} />
          </div>

            {newsItem.url && (
            <div>
              <p style={{ color: '#1A446C' }}>Haberin Devamına Buradan Ulaşabilirsiniz;</p>
              <p><a href={newsItem.url} target="_blank" rel="noopener noreferrer">{newsItem.url}</a></p>
            </div>
          )}
          </div>
        </Card>
      )}
    </div>
  );
};
  
export default Newdetail;
