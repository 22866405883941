import { initializeApp } from 'firebase/app';
import { environment, odsCollections } from "../environments/environment"
import { getFirestore, collection, query, orderBy, limit, getDocs, where,Timestamp,updateDoc,setDoc,doc,getDoc,addDoc,arrayUnion, arrayRemove ,serverTimestamp } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword,GoogleAuthProvider,signInWithPopup,signInWithRedirect ,getRedirectResult} from "firebase/auth";

import { parse, isAfter, isToday, format,compareDesc } from 'date-fns';

const app = initializeApp(environment.firebase);

const db = getFirestore(app);


//generic get
const fetchData = async (collectionName) => {
 const querySnapshot = await getDocs(collection(db, collectionName));
 const data = [];

 querySnapshot.forEach(doc => {
 const docData = doc.data();
 docData.key = doc.id;

 if (docData.firstTransactionDate && docData.firstTransactionDate.toDate) {
 const firstTransactionDate = docData.firstTransactionDate.toDate();
 const formattedReleaseDate = `${firstTransactionDate.getDate().toString().padStart(2, '0')}.${firstTransactionDate.getMonth() + 1}.${firstTransactionDate.getFullYear()}`;
 docData.firstTransactionDate = formattedReleaseDate;
 docData.firstTransactionDateEdit = firstTransactionDate;
 }

 if (docData.releaseDate && docData.releaseDate.toDate) {
 const releaseDate = docData.releaseDate.toDate();

 const day = releaseDate.getDate().toString().padStart(2, '0');
 const month = (releaseDate.getMonth() + 1).toString().padStart(2, '0');
 const year = releaseDate.getFullYear();
 
 const formattedReleaseDate = `${day}.${month}.${year}`;
 
 docData.releaseDate = formattedReleaseDate;
 docData.releaseDateEdit = releaseDate;

 }

 if (docData.createdDate && docData.createdDate.toDate) {
 const createdDate = docData.createdDate.toDate();

 const formattedCreatedDate = `${createdDate.getDate().toString().padStart(2, '0')}.${createdDate.getMonth() + 1}.${createdDate.getFullYear()}`;

 docData.createdDate = formattedCreatedDate;
 docData.createdDateEdit = createdDate;
 }

 if (docData.startDate && docData.startDate.toDate) {
 const startDate = docData.startDate.toDate();
 
 const formattedendDate = `${startDate.getDate().toString().padStart(2, '0').toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
 docData.startDate = formattedendDate;
 docData.startDateEdit = startDate;
 }

 if (docData.endDate && docData.endDate.toDate) {
 const endDate = docData.endDate.toDate();
 const formattedendDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
 docData.endDate = formattedendDate;
 docData.endDateEdit = endDate;
 }

 if (docData.meetingDate && docData.meetingDate.toDate) {
 const meetingDate = docData.meetingDate.toDate();
 const formattedMeetingDateDate = `${meetingDate.getDate().toString().padStart(2, '0')}/${(meetingDate.getMonth() + 1).toString().padStart(2, '0')}/${meetingDate.getFullYear()}`;
 docData.meetingDate = formattedMeetingDateDate;
 docData.meetingDateEdit = meetingDate;
 }

 if (docData.auditDate && docData.auditDate.toDate) {
 const auditDate = docData.auditDate.toDate();
 const formattedauditDateDate = `${auditDate.getDate().toString().padStart(2, '0')}/${(auditDate.getMonth() + 1).toString().padStart(2, '0')}/${auditDate.getFullYear()}`;
 docData.auditDate = formattedauditDateDate;
 docData.auditDateEdit = auditDate;
 }

 if (docData.projectProcess) {
 docData.projectProcess.map(dte=>{
 if(dte.date.toDate())
 {
 const date = dte.date.toDate();
 const formattedendDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
 dte.date = formattedendDate;
 dte.dateEdit = date;
 } 
 }) 
 }

 if (docData.b2bAndf2f) {
 docData.b2bAndf2f.map(dte=>{
 if(dte.date.toDate())
 {
 const date = dte.date.toDate();
 const formattedendDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
 dte.date = formattedendDate;
 dte.dateEdit = date;
 } 
 }) 
 }

 if (docData.companyDates) {
 docData.companyDates.map(dte=>{
 if(dte.date.toDate())
 {
 const date = dte.date.toDate();
 const formattedendDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
 dte.date = formattedendDate;
 dte.dateEdit = date;
 } 
 }) 
 }

 if (docData.deliveryDate && docData.deliveryDate.toDate) {
 const deliverydate = docData.deliveryDate.toDate();
 const formattedendDate = `${deliverydate.getDate().toString().padStart(2, '0')}/${deliverydate.getMonth() + 1}/${deliverydate.getFullYear()}`;
 docData.deliveryDate = formattedendDate;
 docData.deliveryDateEdit = deliverydate;
 }
 data.push(docData);
 });

 return data;
};

//generic delete
const updateIsDeleted = async (collectionName, documentId,visibility) => {
 const documentRef = doc(db, collectionName, documentId);
 const updatedFields = {
 isDeleted: visibility
 };

 try {
 await updateDoc(documentRef, updatedFields);
 return {message:"Kayıdın gizlilik durumu başarıyla güncellendi.",success:true};
 } catch (error) {
 return error;
 }
};

//generic add
const addRecording = async (collectionName,recordingObject) => {
 try {
 const collectionRef = collection(db, collectionName);
 const docRef = await addDoc(collectionRef, recordingObject);
 return { message: "Kayıt başarıyla oluşturuldu.", success: true, docId: docRef.id };
 } catch (error) {
 return error;
 }
};

//generci update 

const updateRecording= async (collectionName, docId, updatedData) => {
    console.log("update",updatedData)
 try {
 const documentRef = doc(db, collectionName, docId)
 await updateDoc(documentRef,updatedData); 
 return { success: true, message: 'Kayıt başarıyla güncellendi' };
 } catch (error) {
 return { success: false, message: 'Kayıt güncellenirken bir hata oluştu' };
 }
};

//haber
const getNewData = async () => {
 const data = await fetchData(odsCollections.new);
 return data;
};
const getNewDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.new);

 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);

 const today = new Date();

 const finalData = filteredData.filter(item => {
 if (item.releaseDate) {
 try {
 const releaseDate = parse(item.releaseDate, 'dd.MM.yyyy', new Date());
 //console.log('Item Release Date:', item.releaseDate);
 //console.log('Parsed Release Date:', releaseDate);
 //console.log('Today:', today);
 const isAfterResult = isAfter(releaseDate, today);
 // console.log('isAfter Result:', isAfterResult);
 return !(isAfterResult );
 } catch (error) {
 console.log('Failed to parse releaseDate:', item.releaseDate);
 }
 }
 return true;
 });
 //console.log('Final Data:', finalData);
 return finalData;
 } 
 catch (error) {
 console.error('Error in getNewDataFilter:', error);
 throw error;
 }
};

 
const deleteNew = async (docId) => {
 const result = await updateIsDeleted(odsCollections.new,docId,true);
 return result;
};

const makeNewVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.new,docId,false);
 return result;
};

const updateNew = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.new, docId, updatedData)
 return result;
};

//stories - şuanlık haberler için kullanılıyor
const getStoryData = async () => {
 const data = await fetchData(odsCollections.story);
 return data;
};
const getStoriesData = async () => {
 const data = await fetchData(odsCollections.stories);
 return data;
};

const deleteStory = async (docId) => {
const result = await updateIsDeleted(odsCollections.story,docId,true);
return result;
};

const makeStoryVisible = async (docId) => {
const result = await updateIsDeleted(odsCollections.story,docId,false);
return result;
};

const updateStory = async (docId, updatedData) => {
const result = await updateRecording(odsCollections.story, docId, updatedData)
return result;
};

//user
const getUserData = async () => {
 const data = await fetchData(odsCollections.user);
 return data;
};

const deleteUser = async (docId) => {
 const result = await updateIsDeleted(odsCollections.user,docId,true);
 return result;
 };
 
 const makeUserVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.user,docId,false);
 return result;
 };

 const updateAuthorizationPages = async (docId,authorizationPage,userType) => {
 const documentRef = doc(db, odsCollections.user, docId);
 const updatedFields = {
 authorizationPages : authorizationPage,
 userType: userType
 };
 
 try {
 await updateDoc(documentRef, updatedFields);
 return {message:"Kullanıcının erişebilirlik sayfaları güncellendi.",success:true};
 } catch (error) {
 return error;
 }
 };

 const updateUserType = async (docId,userType) => {
 const documentRef = doc(db, odsCollections.user, docId);
 const updatedFields = {
 userType: userType
 };

 try {
 await updateDoc(documentRef, updatedFields);
 return {message:"Kullanıcının yetkisi güncellendi.",success:true};
 } catch (error) {
 return error;
 }
 };



 const updateUserImageUrl = async (docId, newImageUrl) => {
 const documentRef = doc(db, odsCollections.user, docId);
 const updatedFields = {
 imageUrl: newImageUrl
 };
 
 try {
 await updateDoc(documentRef, updatedFields);
 return { message: "Profil fotoğrafı başarıyla değiştirildi.", success: true };
 } catch (error) {
 return error;
 }
 };

 

 const getUserByUid = async (uid) => {
 const userDocRef = doc(db, odsCollections.user, uid);
 const userDocSnapshot = await getDoc(userDocRef);
 
 if (userDocSnapshot.exists()) {
 const userData = userDocSnapshot.data();
 return userData;
 } else {
 return null; 
 }
 };
 
 
 
 const getNewsById = async (id) => {
 const newsDocRef = doc(db, odsCollections.new, id);
 const newsDocSnapshot = await getDoc(newsDocRef);
 
 if (newsDocSnapshot.exists()) {
 const newsData = newsDocSnapshot.data();
 return newsData;
 } else {
 return null;
 }
 };


 const getBlogsById = async (id) => {
 const docRef = doc(db, odsCollections.blog, id);
 const docSnapshot = await getDoc(docRef);
 
 if (docSnapshot.exists()) {
 const data = docSnapshot.data();
 return data;
 } else {
 return null;
 }
 };


 const getEducationsById = async (id) => {
 const docRef = doc(db, odsCollections.education, id);
 const docSnapshot = await getDoc(docRef);
 
 if (docSnapshot.exists()) {
 const data = docSnapshot.data();
 return data;
 } else {
 return null;
 }
 };

 const getCampaignsById = async (id) => {
 const docRef = doc(db, odsCollections.campaigns, id);
 const docSnapshot = await getDoc(docRef);
 
 if (docSnapshot.exists()) {
 const data = docSnapshot.data();
 return data;
 } else {
 return null;
 }
 };



 
//odsinNumber
const getOdsInNumberData = async () => {
 const data = await fetchData(odsCollections.odsInNumber);
 return data;
};

const getOdsInNumberDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.odsInNumber);

 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);
 console.log("filter odsInNumber", filteredData)
 return filteredData;
 } catch (error) {
 console.error('Error in odsInNumber:', error);
 throw error;
 }
}; 
const deleteOdsInNumber = async (docId) => {
const result = await updateIsDeleted(odsCollections.odsInNumber,docId,true);
return result;
};

const makeOdsInNumberVisible = async (docId) => {
const result = await updateIsDeleted(odsCollections.odsInNumber,docId,false);
return result;
};


//projectType
const getProjectType = async () => {
 const data = await fetchData(odsCollections.projectType);
 return data;
};


//blog
const getBlogData = async () => {
const data = await fetchData(odsCollections.blog);
return data;
};

const getBlogDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.blog);
 
 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);
 
 const today = new Date();
 
 const finalData = filteredData.filter(item => {
 if (item.releaseDate) {
 try {
 const releaseDate = parse(item.releaseDate, 'dd.MM.yyyy', new Date());
 //console.log('Item Release Date:', item.releaseDate);
 //console.log('Parsed Release Date:', releaseDate);
 //console.log('Today:', today);
 const isAfterResult = isAfter(releaseDate, today);
 // console.log('isAfter Result:', isAfterResult);
 return !(isAfterResult );
 } catch (error) {
 console.log('Failed to parse releaseDate:', item.releaseDate);
 }
 }
 return true;
 });
 //console.log('Final Data:', finalData);
 return finalData;
 } 
 catch (error) {
 console.error('Error in getNewDataFilter:', error);
 throw error;
 }
};


const deleteBlog = async (docId) => {
 const result = updateIsDeleted(odsCollections.blog,docId,true);
 return result;
};

const makeBlogVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.blog,docId,false);
 return result;
};

const updateBlog = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.blog, docId, updatedData)
 return result;
};

//Campaign
 const getCampaignData = async () => {
 const data = await fetchData(odsCollections.campaigns);
 return data;
 };
 
 const getCampaignDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.campaigns);
 
 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);
 
 const today = new Date();
 
 const finalData = filteredData.filter(item => {
 if (item.releaseDate) {
 try {
 const releaseDate = parse(item.releaseDate, 'dd.MM.yyyy', new Date());
 //console.log('Item Release Date:', item.releaseDate);
 //console.log('Parsed Release Date:', releaseDate);
 //console.log('Today:', today);
 const isAfterResult = isAfter(releaseDate, today);
 // console.log('isAfter Result:', isAfterResult);
 return !(isAfterResult );
 } catch (error) {
 console.log('Failed to parse releaseDate:', item.releaseDate);
 }
 }
 return true;
 });
 //console.log('Final Data:', finalData);
 return finalData;
 } 
 catch (error) {
 console.error('Error in getNewDataFilter:', error);
 throw error;
 }
 };
 
 
 const deleteCampaign = async (docId) => {
 const result = updateIsDeleted(odsCollections.campaigns,docId,true);
 return result;
 };
 
 const makeCampaignVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.campaigns,docId,false);
 return result;
 };
 
 const updateCampaign = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.campaigns, docId, updatedData)
 return result;
 };

//hizmet
const getServiceData = async () => {
const data = await fetchData(odsCollections.service);
return data;
};
const getServiceDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.service);

 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);

 const today = new Date();

 const finalData = filteredData.filter(item => {
 if (item.releaseDate) {
 try {
 const releaseDate = parse(item.releaseDate, 'dd.MM.yyyy', new Date());
 //console.log('Item Release Date:', item.releaseDate);
 //console.log('Parsed Release Date:', releaseDate);
 //console.log('Today:', today);
 const isAfterResult = isAfter(releaseDate, today);
 // console.log('isAfter Result:', isAfterResult);
 return !(isAfterResult );
 } catch (error) {
 console.log('Failed to parse releaseDate:', item.releaseDate);
 }
 }
 return true;
 });
 //console.log('Final Data:', finalData);
 return finalData;
 } 
 catch (error) {
 console.error('Error in getNewDataFilter:', error);
 throw error;
 }
};


const getServiceDataFilterHomePageLast4 = async () => {
    try {
        const data = await fetchData(odsCollections.service);
        console.log("service",data)
        const showHomePageItems = data.filter(item => item.showHomePage === true && (item.isDeleted === false || item.isDeleted === null));
        const sortedData = showHomePageItems.sort((a, b) => compareDesc(parse(a.firstTransactionDate, 'dd.MM.yyyy', new Date()), parse(b.firstTransactionDate, 'dd.MM.yyyy', new Date())));
        const latest4Items = sortedData.slice(0, 4);
        const today = new Date();
        const finalData = latest4Items.filter(item => {
            if (item.releaseDate) {
                try {
                    const releaseDate = parse(item.releaseDate, 'dd.MM.yyyy', new Date());
                    const isAfterResult = isAfter(releaseDate, today);
                    return !(isAfterResult);
                } catch (error) {
                    console.log('Failed to parse releaseDate:', item.releaseDate);
                }
            }
            return true;
        });

        console.log("finaldata",finalData);
        return finalData;
    } catch (error) {
        console.error('Error fetching service data for homepage:', error);
        return [];
    }
};

const getServiceDataFilterHomePage = async () => {
 try {
 const data = await fetchData(odsCollections.service);

 const showhomepage = data.filter(item => item.showHomePage === true);
 const filteredData = showhomepage.filter(item => item.isDeleted === false || item.isDeleted === null);

 const today = new Date();

 const finalData = filteredData.filter(item => {
 if (item.releaseDate) {
 try {
 const releaseDate = parse(item.releaseDate, 'dd.MM.yyyy', new Date());
 //console.log('Item Release Date:', item.releaseDate);
 //console.log('Parsed Release Date:', releaseDate);
 //console.log('Today:', today);
 const isAfterResult = isAfter(releaseDate, today);
 // console.log('isAfter Result:', isAfterResult);
 //|| item.showhomepage ===true
 return !(isAfterResult );
 } catch (error) {
 console.log('Failed to parse releaseDate:', item.releaseDate);
 }
 }
 return true;
 });
 //console.log('Final Data:', finalData);
 return finalData;
 } 
 catch (error) {
 console.error('Error in getNewDataFilter:', error);
 throw error;
 }
};

 

const deleteService = async (docId) => {
 const result = updateIsDeleted(odsCollections.service,docId,true)
 return result;
};

const makeServiceVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.service,docId,false);
 return result;
};

const updateService = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.service, docId, updatedData)
 return result;
};

//eğitim
const getTrainingData = async () => {
const data = await fetchData(odsCollections.education);
return data;
};


const getTrainingDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.education);

 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);

 const today = new Date();

 const finalData = filteredData.filter(item => {
 if (item.releaseDate) {
 try {
 const releaseDate = parse(item.releaseDate, 'dd.MM.yyyy', new Date());
 //console.log('Item Release Date:', item.releaseDate);
 //console.log('Parsed Release Date:', releaseDate);
 //console.log('Today:', today);
 const isAfterResult = isAfter(releaseDate, today);
 // console.log('isAfter Result:', isAfterResult);
 return !(isAfterResult );
 } catch (error) {
 console.log('Failed to parse releaseDate:', item.releaseDate);
 }
 }
 return true;
 });
 //console.log('Final Data:', finalData);
 return finalData;
 } 
 catch (error) {
 console.error('Error in getNewDataFilter:', error);
 throw error;
 }
};

const deleteTraining = async (docId) => {
 const result = updateIsDeleted(odsCollections.education,docId,true);
 return result;
};

const makeTrainingVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.education,docId,false);
 return result;
};

const updateTraining = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.education, docId, updatedData)
 return result;
};

//eğitmen
const getTrainerUnDeleted = async () => {
 const data = await fetchData(odsCollections.trainer);
 const undeletedTrainers = data.filter(trainer => !trainer.isDeleted);

 return undeletedTrainers;
 };

const getTrainerData = async () => {
 const data = await fetchData(odsCollections.trainer);
 return data;
 };
 

const deleteTrainer = async (docId) => {
 const result = updateIsDeleted(odsCollections.trainer,docId,true)
 return result;
};
 
const makeTrainerVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.trainer,docId,false);
 return result;
};
 
//anket
const getSurveyData = async () => {
const data = await fetchData(odsCollections.survey);
return data;
};


const updateSurveyAnswers = async (surveyKey, selectedChoices, userId) => {
 try {
 const surveyRef = doc(collection(db, odsCollections.survey), surveyKey);
 const surveyDoc = await getDoc(surveyRef);

 if (!surveyDoc.exists) {
 throw new Error('Anket bulunamadı');
 }

 const surveyData = surveyDoc.data();
 const updatedQuestions = surveyData.questions.map((question, questionIndex) => {
 if (selectedChoices[questionIndex] !== undefined) {
 const selectedChoiceIndex = selectedChoices[questionIndex];
 const updatedAnswer = question.answer.map((choice, choiceIndex) => ({
 ...choice,
 userIds: choiceIndex === selectedChoiceIndex ? [...choice.userIds, userId] : choice.userIds,
 }));

 return {
 ...question,
 answer: updatedAnswer,
 };
 }

 return question;
 });

 const updatedUsers = [...surveyData.users, userId];

 const updatedData = {
 ...surveyData,
 questions: updatedQuestions,
 users: updatedUsers,
 };

 await updateDoc(surveyRef, updatedData);
 return { key: surveyDoc.id, ...surveyData };
 } catch (error) {
 console.error('Anket güncellenirken bir hata oluştu:', error.message);
 throw error;
 }
};
//bildirim
const getNotificationData = async () => {
const data = await fetchData(odsCollections.notifation);
return data;
};

const deleteNotification = async (docId) => {
 const result = updateIsDeleted(odsCollections.notifation,docId,true)
 return result;
};

const makeNotificationVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.notifation,docId,false);
 return result;
};

//partner
const getPartnerData = async () => {
 const data = await fetchData(odsCollections.partner);
 return data;
 };

const getPartnerDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.partner);
 
 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);
 console.log("filter partner", filteredData)
 return filteredData;
 } catch (error) {
 console.error('Error in partner:', error);
 throw error;
 }
};
 
const getEducationData = async () => {
 const data = await fetchData(odsCollections.education);
 return data;
};
 
const deletePartner = async (docId) => {
 const result = updateIsDeleted(odsCollections.partner,docId,true)
 return result;
};

const makePartnerVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.partner,docId,false);
 return result;
};

const updatePartner = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.partner, docId, updatedData)
 return result;
};

//advisor
const getAdvisorData = async () => {
 const data = await fetchData(odsCollections.advisor);
 return data;
 };

const makeAdvisorVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.advisor,docId,false);
 return result;
};

const deleteAdvisor = async (docId) => {
 const result = updateIsDeleted(odsCollections.advisor,docId,true)
 return result;
};

//satın alma talepleri
const getPurchaseRequestData = async () => {
 const data = await fetchData(odsCollections.purchaseRequest);
 return data;
 };

 const deletePurchaseRequest = async (docId) => {
 const result = updateIsDeleted(odsCollections.purchaseRequest,docId,true)
 return result;
 };
 
 const makePurchaseRequestVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.purchaseRequest,docId,false);
 return result;
 };

 const getPurchaseRequestDataByUserId = async (userId) => {
 const projectsQuery = query(collection(db, odsCollections.purchaseRequest), where('userId', '==', userId));
 const projectsSnapshot = await getDocs(projectsQuery);
 const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
 return projectsData;
 };
 
 
// eğitim tapleri
const getTrainingRequestData = async () => {
const data = await fetchData(odsCollections.educationRequest);
return data;
};

const deleteEducationRequest = async (docId) => {
 const result = updateIsDeleted(odsCollections.educationRequest,docId,true)
 return result;
};

const makeEducationRequestVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.educationRequest,docId,false);
 return result;
};

//address
const getAddressData = async () => {
 const data = await fetchData(odsCollections.address);
 return data;
 };

const getAddressDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.address);
 
 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);
 console.log("filter adres", filteredData)
 return filteredData;
 } catch (error) {
 console.error('Error in adres:', error);
 throw error;
 }
};

const makeAddressVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.address,docId,false);
 return result;
};

const deleteAddress = async (docId) => {
 const result = updateIsDeleted(odsCollections.address,docId,true)
 return result;
};

const updateAddress = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.address, docId, updatedData)
 return result;
};

//yazar
const getAuthorsUndeleted = async () => {
 const data = await fetchData(odsCollections.author);
 const undeletedAuthors = data.filter(author => !author.isDeleted);
 return undeletedAuthors;
 };

 

const getAuthorData = async () => {
 const data = await fetchData(odsCollections.author);
 return data;
}

const deleteAuthor= async (docId) => {
 const result = updateIsDeleted(odsCollections.author,docId,true)
 return result;
};

const makeAuthorVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.author,docId,false);
 return result;
};

//proje takip
const getProjects = async () => {
 const data = await fetchData(odsCollections.projects);
 return data;
 };

const deleteProject= async (docId) => {
 const result = updateIsDeleted(odsCollections.projects,docId,true)
 return result;
};

const makeProjectVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.projects,docId,false);
 return result;
};

const updateProject = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.projects, docId, updatedData)
 return result;
};

//cd

const getCdProjects = async () => {
 const data = await fetchData(odsCollections.cd);
 return data;
 };

 const deleteCdProject= async (docId) => {
 const result = updateIsDeleted(odsCollections.cd,docId,true)
 return result;
 };
 
 const makeCdProjectVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.cd,docId,false);
 return result;
 };

 const updateCdProject = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.cd, docId, updatedData)
 return result;
 };

 const getCdProjectsByUserId = async (userId) => {
  const projectsQuery = query(
    collection(db, odsCollections.cd),
    where('userId', '==', userId),
    where('isDeleted', '==', false),
);

const projectsSnapshot = await getDocs(projectsQuery);
const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

return projectsData;
 };
 
//tq

const getTqProjects = async () => {
 const data = await fetchData(odsCollections.tq);
 return data;
 };

 const deleteTqProject= async (docId) => {
 const result = updateIsDeleted(odsCollections.tq,docId,true)
 return result;
 };
 
 const makeTqProjectVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.tq,docId,false);
 return result;
 };

 const updateTqProject = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.tq, docId, updatedData)
 return result;
 };

 const getTqProjectsByUserId = async (userId) => {
    const projectsQuery = query(
        collection(db, odsCollections.tq),
        where('userId', '==', userId),
        where('isDeleted', '==', false),
    );

    const projectsSnapshot = await getDocs(projectsQuery);
    const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return projectsData;


 };
 

 //sap

const getSapProjects = async () => {
 const data = await fetchData(odsCollections.sap);
 return data;
 };

 const deleteSapProject= async (docId) => {
 const result = updateIsDeleted(odsCollections.sap,docId,true)
 return result;
 };
 
 const makeSapProjectVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.sap,docId,false);
 return result;
 };

 const updateSapProject = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.sap, docId, updatedData)
 return result;
 };

 const getSapProjectsByUserId = async (userId) => {
    const projectsQuery = query(
        collection(db, odsCollections.sap),
        where('userId', '==', userId),
        where('isDeleted', '==', false),
    );

    const projectsSnapshot = await getDocs(projectsQuery);
    const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return projectsData;
 };
 

 //ibm

 const getIbmProjects = async () => {
 const data = await fetchData(odsCollections.ibm);
 return data;
 };
 
 const deleteIbmProject= async (docId) => {
 const result = updateIsDeleted(odsCollections.ibm,docId,true)
 return result;
 };
 
 const makeIbmProjectVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.ibm,docId,false);
 return result;
 };
 const updateIbmProject = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.ibm, docId, updatedData)
 return result;
 };

 const getIbmProjectsByUserId = async (userId) => {
    const projectsQuery = query(
        collection(db, odsCollections.ibm),
        where('userId', '==', userId),
        where('isDeleted', '==', false),
    );

    const projectsSnapshot = await getDocs(projectsQuery);
    const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return projectsData;
 };
 

 //ipm

 const getIpmProjects = async () => {
 const data = await fetchData(odsCollections.ipm);
 return data;
 };
 
 const deleteIpmProject= async (docId) => {
 const result = updateIsDeleted(odsCollections.ipm,docId,true)
 return result;
 };
 
 const makeIpmProjectVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.ipm,docId,false);
 return result;
 };
 const updateIpmProject = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.ipm, docId, updatedData)
 return result;
 };
 const updateIpmProjectIdsBased = async (docId,projectIdsBased) => {
 const documentRef = doc(db, odsCollections.ipm, docId);
 const updatedFields = {
 ipmProjectsId: projectIdsBased
 };
 
 try {
 await updateDoc(documentRef, updatedFields);
 return {message:"Kayıdın başarıyla eklendi.",success:true};
 } catch (error) {
 return error;
 }
 };

 const getIpmProjectsByUserId = async (userId) => {
    const projectsQuery = query(
        collection(db, odsCollections.ipm),
        where('userId', '==', userId),
        where('isDeleted', '==', false),
    );

    const projectsSnapshot = await getDocs(projectsQuery);
    const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return projectsData;
 };
 
 //ipm project

 const getIpmProjectBased = async () => {
 const data = await fetchData(odsCollections.ipmProjectBased);
 return data;
 };

 const getIpmProjectBasedById = async (docIds) => {
 const ipmProjectsBased = await fetchData(odsCollections.ipmProjectBased);
 const filteredProjects = ipmProjectsBased.filter((project) => docIds.includes(project.key));
 // console.log(filteredProjects);
 return filteredProjects;
 };
 
 const deleteIpmProjectBased= async (docId) => {
 const result = updateIsDeleted(odsCollections.ipmProjectBased,docId,true)
 return result;
 };
 
 const makeIpmProjectBasedVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.ipmProjectBased,docId,false);
 return result;
 };
 const updateIpmProjectBased = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.ipmProjectBased, docId, updatedData)
 return result;
 };

// kategori
const getCategoryData = async (categoryName) => {
 const data = await fetchData(odsCollections.category);
 const items = data[0][categoryName] || [];
 return items;
 };




let categoryDocumentId;

 const getCategoryIdData = async () => {
    const data = await fetchData(odsCollections.category);
    if (data && data.length > 0) {
         categoryDocumentId = data[0].key;
        console.log(" category id" , categoryDocumentId);
        return categoryDocumentId;
    } else {
        console.error("No data returned from fetchData");
        return null;
    }
};


getCategoryIdData().then(result => {
    categoryDocumentId = result;
});



const updateServiceCategoryArray = async (categoryName, newCategoryName) => {
    try {  
        const docRef = doc(db, odsCollections.category, categoryDocumentId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const updatedServiceArray = [];

            data.service.forEach((item) => {
                if (item === categoryName) {
                    updatedServiceArray.push(newCategoryName);
                } else {
                    updatedServiceArray.push(item);
                }
            });

            await updateDoc(docRef, { service: updatedServiceArray });
            console.log('Belge başarıyla güncellendi.');
        

            const dataServices= await fetchData(odsCollections.service);
            console.log("dataservices", dataServices);           
             const updatePromises = dataServices.map(async (item) => {
                if (item.categoryName === categoryName) {
                    item.categoryName = newCategoryName;
                    item.releaseDate = item.releaseDateEdit;
                    item.firstTransactionDate = item.firstTransactionDateEdit;

                    const resulttt=  await updateService(item.key, item);
                    return resulttt;
                }
            });
    
            await Promise.all(updatePromises);

            return data;       
        } else {
            console.log('Belge bulunamadı.');
            return null;
        }
        
    } catch (error) {
        console.error('Belge güncelleme hatası:', error);
        return null;
    }
};

const deleteCategoryItem = async (categoryName) => {
    try {
        const docRef = doc(db, odsCollections.category, categoryDocumentId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const updatedServiceArray = data.service.filter(item => item !== categoryName);

            await updateDoc(docRef, { service: updatedServiceArray });
            console.log('Belge başarıyla güncellendi.');

            const dataServices= await fetchData(odsCollections.service);
            console.log("dataservices", dataServices);
            
            const updatePromises = dataServices.map(async (item) => {
                console.log("item njkfsnkjdnjknkjnfkjnfdjkfdn ",item.releaseDate,item.firstTransactionDate, item.categoryName, categoryName)
                if (item.categoryName === categoryName) {

                    const date1 = new Date(item.releaseDate);
                    const date2 = new Date(item.firstTransactionDate);

                    const releaseDate = date1.getTime();
                    const firstTransactionDate = date2.getTime();

                    console.log("timstmappp releaseDate",releaseDate, item.releaseDateEdit);
                    console.log("timetstamp firstTransactionDate",firstTransactionDate, item.firstTransactionDateEdit);

                    console.log("item category name değişti")
                    item.categoryName = "-";
                    item.isDeleted = true; 
                    item.releaseDate = item.releaseDateEdit;
                    item.firstTransactionDate = item.firstTransactionDateEdit;

                    const result = await updateService(item.key, item);
                    return result;
                }
            });

            await Promise.all(updatePromises);
            
            console.log('Tüm veri işlendi.'); // Tüm veri işlendiğinde log mesajı

            return data;
        } else {
            console.log('Belge bulunamadı.');
            return null;
        }

    } catch (error) {
        console.error('Belge güncelleme hatası:', error);
        return null;
    }
};


const addNewDataToCategory = async (pageName, categoryName) => {
    try {
        if (!categoryDocumentId) {
            console.log("Category doesn't exist, creating a new one");

            const collectionRef = collection(db, odsCollections.category);
            const docRef = await addDoc(collectionRef, { [pageName]: [categoryName] });

            // Update categoryDocumentId with the newly created document's ID
            categoryDocumentId = docRef.id;

            console.log('New category created successfully!');
            return { message: "Kategori başarıyla güncellendi.", success: true };

        } else {
            console.log("Category exists, updating");

            const categoryRef = doc(db, odsCollections.category, categoryDocumentId);

            try {
                const categoryDoc = await getDoc(categoryRef);

                if (categoryDoc.exists()) {
                    console.log("Category document exists");

                    const categoryData = categoryDoc.data();
                    const items = categoryData[pageName] || [];

                    items.push(categoryName);

                    const updateObject = {};
                    updateObject[pageName] = items;

                    await updateDoc(categoryRef, updateObject);

                    console.log('Category updated successfully!');
                    return { message: "Kategori başarıyla güncellendi.", success: true };
                } else {
                    console.log("Category document does not exist");
                    return { message: "Category document does not exist", success: false };
                }
            } catch (error) {
                console.error('Error updating category document:', error);
                throw error;
            }
        }
    } catch (error) {
        console.error("Error adding new data to category:", error);
        return { message: "Error adding new data to category", success: false };
    }
};

//yatırım

const getInvestment = async () => {
 const data = await fetchData(odsCollections.ourInvestment);
 return data;
};
const getInvestmentDataFilter = async () => {
 try {
 const data = await fetchData(odsCollections.ourInvestment);

 const filteredData = data.filter(item => item.isDeleted === false || item.isDeleted === null);
 console.log("filter investmer", filteredData)
 return filteredData;
 } catch (error) {
 console.error('Error in investemtn:', error);
 throw error;
 }
};


const updateInvestment = async (docId, updatedData) => {
 const result = await updateRecording(odsCollections.ourInvestment, docId, updatedData)
 return result;
};

const deleteInvestment= async (docId) => {
 const result = updateIsDeleted(odsCollections.ourInvestment,docId,true)
 return result;
};

const makeInvestmentVisible = async (docId) => {
 const result = await updateIsDeleted(odsCollections.ourInvestment,docId,false);
 return result;
};

//dashboard
const getLastDataFromCollection = async (collectionName,editPageUrl,tableCategoryName) => {
 const q = query(collection(db, collectionName), orderBy("releaseDate", "desc"), limit(1));
 const querySnapshot = await getDocs(q);
 const data = [];

 querySnapshot.forEach(doc => {
 const docData = doc.data();
 docData.key = doc.id;
 docData.editPageUrl=editPageUrl;
 docData.tableCategoryName=tableCategoryName;
 if (docData.firstTransactionDate && docData.firstTransactionDate.toDate) {
 const firstTransactionDate = docData.firstTransactionDate.toDate();
 const formattedReleaseDate = `${firstTransactionDate.getDate()}.${firstTransactionDate.getMonth() + 1}.${firstTransactionDate.getFullYear()}`;
 docData.firstTransactionDate = formattedReleaseDate;
 docData.firstTransactionDateEdit = firstTransactionDate;
 }

 if (docData.releaseDate && docData.releaseDate.toDate) {
 const releaseDate = docData.releaseDate.toDate();

 const day = releaseDate.getDate();
 const month = (releaseDate.getMonth() + 1).toString().padStart(2, '0');
 const year = releaseDate.getFullYear();
 
 const formattedReleaseDate = `${day}.${month}.${year}`;
 
 docData.releaseDate = formattedReleaseDate;
 docData.releaseDateEdit = releaseDate;

 }

 data.push(docData);
 });
 return data;
};


 const getAllLastData = async () => {
 const servicesData = await getLastDataFromCollection("services","/serviceedit",'Hizmet');
 const blogsData = await getLastDataFromCollection("blogs",'/blogedit','Blog');
 const educationData = await getLastDataFromCollection("educations",'/trainingedit','Eğitim');
 const newsData = await getLastDataFromCollection("news",'/newsedit','Haber');
 const notificationData = await getLastDataFromCollection("campaigns",'/campaignedit','Kampanya');

 const allData = [...servicesData, ...blogsData, ...educationData, ...newsData, ...notificationData];
 
 return allData;
 };

 const getEducationCount = async () => {
 const educationCollection = collection(db, odsCollections.educationRequest);
 const querySnapshot = await getDocs(educationCollection);
 const count = querySnapshot.size;
 return count;
 };

 const getLastFourServices = async () => {
 const q = query(collection(db, odsCollections.service), orderBy("releaseDate", "desc"), limit(4));
 const querySnapshot = await getDocs(q);
 const data = [];
 
 querySnapshot.forEach(doc => {
 const docData = doc.data();
 docData.key = doc.id;
 data.push(docData);

 if (docData.firstTransactionDate && docData.firstTransactionDate.toDate) {
 const firstTransactionDate = docData.firstTransactionDate.toDate();
 const formattedReleaseDate = `${firstTransactionDate.getDate()}.${firstTransactionDate.getMonth() + 1}.${firstTransactionDate.getFullYear()}`;
 docData.firstTransactionDate = formattedReleaseDate;
 docData.firstTransactionDateEdit = firstTransactionDate;
 }
 
 if (docData.releaseDate && docData.releaseDate.toDate) {
 const releaseDate = docData.releaseDate.toDate();
 
 const day = releaseDate.getDate();
 const month = (releaseDate.getMonth() + 1).toString().padStart(2, '0');
 const year = releaseDate.getFullYear();
 
 const formattedReleaseDate = `${day}.${month}.${year}`;
 
 docData.releaseDate = formattedReleaseDate;
 docData.releaseDateEdit = releaseDate;
 
 }
 
 });
 
 return data;
 };
 const getFeaturedServices = async () => {
 const q = query(
 collection(db, odsCollections.service),
 where("showHomePage", "==", true)
 );
 
 const querySnapshot = await getDocs(q);
 const data = [];
 
 querySnapshot.forEach((doc) => {
 const docData = doc.data();
 docData.key = doc.id;
 
 
 if (docData.firstTransactionDate && docData.firstTransactionDate.toDate) {
 
 }
 
 if (docData.releaseDate && docData.releaseDate.toDate) {
 
 }
 
 data.push(docData);
 });
 
 return data;
 };
 
 
 const getLastThreePartner = async () => {
 const q = query(collection(db, odsCollections.partner), orderBy("firstTransactionDate", "desc"), limit(3));
 const querySnapshot = await getDocs(q);
 const data = [];
 
 querySnapshot.forEach(doc => {
 const docData = doc.data();
 docData.key = doc.id;
 data.push(docData);
 });
 return data;
 };

 const getLastThreeInvestment = async () => {
 const q = query(collection(db, odsCollections.ourInvestment), orderBy("firstTransactionDate", "desc"), limit(3));
 const querySnapshot = await getDocs(q);
 const data = [];
 
 querySnapshot.forEach(doc => {
 const docData = doc.data();
 docData.key = doc.id;
 data.push(docData);
 });
 return data;
 };

 const getLastMonthUsers = async () => {
 const currentDate = new Date();
 currentDate.setMonth(currentDate.getMonth() - 1);
 
 const lastMonthTimestamp = Timestamp.fromDate(currentDate);
 
 const q = query(
 collection(db, odsCollections.user),
 where("createdDate", ">=", lastMonthTimestamp)
 );
 
 const querySnapshot = await getDocs(q);
 const count = querySnapshot.size;
 
 return count;
 };
 const updateNewsFavoriteStatus = async (newsId, userId, liked) => {
 try {
 // console.log('updateNewsFavoriteStatus başlatıldı. newsId:', newsId, 'userId:', userId, 'liked:', liked);

 if (!newsId) {
 //console.error('Geçersiz newsId:', newsId);
 return;
 }

 const collectionRef = collection(db, odsCollections.new);
 const documentRef = doc(collectionRef, newsId);
 // console.log('documentRef:', documentRef);

 const newsDoc = await getDoc(documentRef);
 //console.log('newsDoc:', newsDoc);

 if (!newsDoc.exists()) {
 // console.error('News document not found for newsId:', newsId);
 return;
 }

 const favoriteUserIdArray = newsDoc.data().favoriteUserId || [];
 const isUserLiked = favoriteUserIdArray.includes(userId);

 if (liked && !isUserLiked) {
 await updateDoc(documentRef, {
 favoriteUserId: arrayUnion(userId)
 });

 const userCollectionRef = collection(db, odsCollections.user);
 const userDocRef = doc(userCollectionRef, userId);

 await updateDoc(userDocRef, {
 favoriteNewsId: arrayUnion(newsId)
 });

 // console.log('Favorite status and user favoriteNewsId updated successfully.');
 }

 if (!liked && isUserLiked) {
 await updateDoc(documentRef, {
 favoriteUserId: arrayRemove(userId)
 });

 const userCollectionRef = collection(db, odsCollections.user);
 const userDocRef = doc(userCollectionRef, userId);

 await updateDoc(userDocRef, {
 favoriteNewsId: arrayRemove(newsId)
 });

 // console.log('Favorite status and user favoriteNewsId updated successfully.');
 }

 } catch (error) {
 // console.error('Error updating favorite status:', error);
 }
};

const updateEducationFavoriteStatus = async (educationId, userId, liked) => {
 try {
 // console.log('updateEducationFavoriteStatus başlatıldı. educationId:', educationId, 'userId:', userId, 'liked:', liked);

 if (!educationId) {
 //console.error('Geçersiz educationId:', educationId);
 return;
 }

 const collectionRef = collection(db, odsCollections.education);
 const documentRef = doc(collectionRef, educationId);
 // console.log('documentRef:', documentRef);

 // Belge verilerini al
 const educationDoc = await getDoc(documentRef);
 // console.log('educationDoc:', educationDoc);

 if (!educationDoc.exists()) {
 // console.error('Education document not found for educationId:', educationId);
 return;
 }

 // favoriteUserId dizisini al
 const favoriteUserIdArray = educationDoc.data().favoriteUserId || [];

 // Beğenme durumunu kontrol et
 const isUserLiked = favoriteUserIdArray.includes(userId);

 // Beğenildi ve kullanıcı ID'si dizide yoksa ekleyin
 if (liked && !isUserLiked) {
 await updateDoc(documentRef, {
 favoriteUserId: arrayUnion(userId)
 });

 // Kullanıcının favori eğitimlerini yöneten diziyi güncelle
 const userCollectionRef = collection(db, odsCollections.user);
 const userDocRef = doc(userCollectionRef, userId);

 await updateDoc(userDocRef, {
 favoriteEducationsId: arrayUnion(educationId)
 });

 // console.log('Favorite status and user favoriteEducationsId updated successfully.');
 }

 // Beğenilmedi ve kullanıcı ID'si dizide varsa çıkarın
 if (!liked && isUserLiked) {
 await updateDoc(documentRef, {
 favoriteUserId: arrayRemove(userId)
 });

 // Kullanıcının favori eğitimlerini yöneten diziyi güncelle
 const userCollectionRef = collection(db, odsCollections.user);
 const userDocRef = doc(userCollectionRef, userId);

 await updateDoc(userDocRef, {
 favoriteEducationsId: arrayRemove(educationId)
 });

 // console.log('Favorite status and user favoriteEducationsId updated successfully.');
 }

 } catch (error) {
 // console.error('Error updating favorite status:', error);
 }
};
const updateBlogFavoriteStatus = async (blogId, userId, liked) => {
 try {
 // console.log('updateBlogFavoriteStatus başlatıldı. blogId:', blogId, 'userId:', userId, 'liked:', liked);

 if (!blogId) {
 // console.error('Geçersiz blogId:', blogId);
 return;
 }

 const collectionRef = collection(db, odsCollections.blog);
 const documentRef = doc(collectionRef, blogId);
 // console.log('documentRef:', documentRef);

 // Belge verilerini al
 const blogDoc = await getDoc(documentRef);
 // console.log('blogDoc:', blogDoc);

 if (!blogDoc.exists()) {
 //console.error('Blog document not found for blogId:', blogId);
 return;
 }

 // favoriteUserId dizisini al
 const favoriteUserIdArray = blogDoc.data().favoriteUserId || [];

 // Beğenme durumunu kontrol et
 const isUserLiked = favoriteUserIdArray.includes(userId);

 // Beğenildi ve kullanıcı ID'si dizide yoksa ekleyin
 if (liked && !isUserLiked) {
 await updateDoc(documentRef, {
 favoriteUserId: arrayUnion(userId)
 });

 // Kullanıcının favori bloglarını yöneten diziyi güncelle
 const userCollectionRef = collection(db, odsCollections.user);
 const userDocRef = doc(userCollectionRef, userId);

 await updateDoc(userDocRef, {
 favoriteBlogsId: arrayUnion(blogId)
 });

 // console.log('Favorite status and user favoriteBlogsId updated successfully.');
 }

 // Beğenilmedi ve kullanıcı ID'si dizide varsa çıkarın
 if (!liked && isUserLiked) {
 await updateDoc(documentRef, {
 favoriteUserId: arrayRemove(userId)
 });

 // Kullanıcının favori bloglarını yöneten diziyi güncelle
 const userCollectionRef = collection(db, odsCollections.user);
 const userDocRef = doc(userCollectionRef, userId);

 await updateDoc(userDocRef, {
 favoriteBlogsId: arrayRemove(blogId)
 });

 // console.log('Favorite status and user favoriteBlogsId updated successfully.');
 }

 } catch (error) {
 // console.error('Error updating favorite status:', error);
 }
};
const updateCampaignFavoriteStatus = async (campaignId, userId, liked) => {
 try {
 // console.log('updateBlogFavoriteStatus başlatıldı. campaignId:', campaignId, 'userId:', userId, 'liked:', liked);

 if (!campaignId) {
 //console.error('Geçersiz blogId:', campaignId);
 return;
 }

 const collectionRef = collection(db, odsCollections.campaigns);
 const documentRef = doc(collectionRef, campaignId);
 // console.log('documentRef:', documentRef);

 // Belge verilerini al
 const campaignDoc = await getDoc(documentRef);
 // console.log('campaignDoc:', campaignDoc);

 if (!campaignDoc.exists()) {
 // console.error('campaign document not found for blogId:', campaignId);
 return;
 }

 // favoriteUserId dizisini al
 const favoriteUserIdArray = campaignDoc.data().favoriteUserId || [];

 // Beğenme durumunu kontrol et
 const isUserLiked = favoriteUserIdArray.includes(userId);

 // Beğenildi ve kullanıcı ID'si dizide yoksa ekleyin
 if (liked && !isUserLiked) {
 await updateDoc(documentRef, {
 favoriteUserId: arrayUnion(userId)
 });

 // Kullanıcının favori bloglarını yöneten diziyi güncelle
 const userCollectionRef = collection(db, odsCollections.user);
 const userDocRef = doc(userCollectionRef, userId);

 await updateDoc(userDocRef, {
 favoriteCampaignsId: arrayUnion(campaignId)
 });

 // console.log('Favorite status and user favoriteBlogsId updated successfully.');
 }

 // Beğenilmedi ve kullanıcı ID'si dizide varsa çıkarın
 if (!liked && isUserLiked) {
 await updateDoc(documentRef, {
 favoriteUserId: arrayRemove(userId)
 });

 // Kullanıcının favori bloglarını yöneten diziyi güncelle
 const userCollectionRef = collection(db, odsCollections.user);
 const userDocRef = doc(userCollectionRef, userId);

 await updateDoc(userDocRef, {
 favoriteCampaignsId: arrayRemove(campaignId)
 });

 // console.log('Favorite status and user favoriteBlogsId updated successfully.');
 }

 } catch (error) {
 // console.error('Error updating favorite status:', error);
 }
};
const getFilteredUserData = async () => {
 const firstUserId = localStorage.getItem("uid");
 
 
 const data = await fetchData(odsCollections.user);


 const filteredUser = data.find(user => user.userId === firstUserId);


 // console.log("Filtered User Data:", filteredUser);

 return filteredUser;
};
const addPurchaseRequest = async (purchaseRequest) => {
 try {
 const collectionRef = collection(db, 'purchaseRequests'); 
 const docRef = await addDoc(collectionRef, purchaseRequest);

 //console.log('Satın alma talebi başarıyla eklendi!');
 } catch (error) {
 // console.error('Satın alma talebi eklenirken hata oluştu:', error);
 throw error;
 }
};
const getTrainerDataById = async (trainerId) => {
 try {
 const trainerData = await fetchData('trainer');

 const trainer = trainerData.find(doc => doc.key === trainerId);

 if (trainer) {
 return trainer;
 } else {
 
 return null;
 }
 } catch (error) {
 
 return null;
 }
};
const getTrainerDataByIdFilter = async (trainerId) => {
 try {
 const trainerData = await fetchData('trainer');

 const trainer = trainerData.find(doc => doc.key === trainerId && (doc.isDeleted === false || doc.isDeleted === null));

 if (trainer) {
 return trainer;
 } else {
 return null;
 }
 } catch (error) {
 console.error("Trainer verisi çekme hatası:", error);
 return null;
 }
};

const getAuthorDataById = async (authorId) => {
 try {
 const trainerData = await fetchData('authors');
 // console.log('Trainer Data:', trainerData);

 const trainer = trainerData.find(doc => doc.key === authorId);
 // console.log('Found Trainer:', trainer);

 if (trainer) {
 return trainer;
 } else {
 // console.error('ID için yazar bulunamadı:', authorId);
 return null;
 }
 } catch (error) {
 // console.error('Yazar verisi getirme hatası:', error);
 return null;
 }
};
const getAuthorDataByIdFilter = async (authorId) => {
 try {
 const trainerData = await fetchData('authors');
 // console.log('Trainer Data:', trainerData);

 const trainer = trainerData.find(doc => doc.key === authorId && (doc.isDeleted === false || doc.isDeleted === null));
 // console.log('Found Trainer:', trainer);

 if (trainer) {
 return trainer;
 } else {
 // console.error('ID için yazar bulunamadı:', authorId);
 return null;
 }
 } catch (error) {
 // console.error('Yazar verisi getirme hatası:', error);
 return null;
 }
};
const auth = getAuth(); 

const registerUser = async (email, additionalInfo) => {
 try {
 const userCredential = await createUserWithEmailAndPassword(auth, email);
 const user = userCredential.user;
 await FirebaseService.createUser(user, additionalInfo);
 console.log("Kullanıcı başarıyla kaydedildi:", user);
 
 return user;
 } catch (error) {
 console.error("Kullanıcı kaydı sırasında hata oluştu:", error);
 throw error;
 }
};


const registerUserWithGoogle = async () => {
 const googleProvider = new GoogleAuthProvider();
 try {
 // await signInWithRedirect(auth, googleProvider);
 // const userCredential = await getRedirectResult(auth);
 const userCredential = await signInWithPopup(auth, googleProvider);
 const user = userCredential.user;
 const existingUserData = await FirebaseService.getUserByUid(user.uid);

 if (!existingUserData) {
 await FirebaseService.createUserGoogle(user, user.displayName);
 console.log("Google ile Kullanıcı başarıyla kaydedildi:");
 return user;
 }
 return user;

 } catch (error) {
 console.error('Google registration failed:', error);
 throw error;
 }
};


const createUser = async (userData) => {
 try {
 const { name, surname, companyName, email, city, userType, isDeleted, loginType, oneSignalPlayerId, createdDate } = userData;
 const nameSurname = `${name} ${surname}`;

 const usersCollectionRef = collection(db, 'users');
 const userId = auth.currentUser.uid; 
 const docRef = doc(usersCollectionRef, userId);

 await setDoc(docRef, {
 nameSurname,
 companyName,
 email,
 city,
 userType: ["user"],
 isDeleted: false, 
 loginType: "normal-web",
 oneSignalPlayerId: "",
 createdDate: serverTimestamp(),
 userId : userId,
 imageUrl: ""
 });

 //console.log('User successfully registered!');
 } catch (error) {
 // console.error('Error occurred while registering user:', error);
 throw error;
 }
};


const createUserGoogle = async (userData, name) => {
 try {
 const usersCollectionRef = collection(db, 'users');
 const userId = auth.currentUser.uid; 
 const docRef = doc(usersCollectionRef, userId);

 const nameSurname = name ;

 await setDoc(docRef, {
 nameSurname,
 companyName: "",
 email: userData.email || "",
 city: "",
 userType: ["user"],
 isDeleted: false, 
 loginType: "google-web",
 oneSignalPlayerId: "",
 createdDate: serverTimestamp(),
 userId : userId,
 imageUrl: ""
 });
 
 console.log('User successfully registered!');
 } catch (error) {
 console.error('Error occurred while registering user:', error);
 throw error;
 }
};





 const FirebaseService = {
 getNewData,
 getUserData,
 getBlogData,
 getServiceData,
 getTrainingData,
 getSurveyData,
 updateSurveyAnswers, 
 getNotificationData,
 getPartnerData,
 getPartnerDataFilter,
 getAuthorData,
 getTrainerData, 
 getTrainingRequestData,
 getAdvisorData,
 getAllLastData,
 getAddressData,
 getAddressDataFilter,
 getEducationCount,
 getLastFourServices,
 getFeaturedServices,
 getLastThreePartner,
 getLastThreeInvestment,
 getLastMonthUsers,
 getPurchaseRequestData,
 getProjects,
 getProjectType,
 getCdProjects,
 getTqProjects,
 getSapProjects,
 getIbmProjects,
 getIpmProjects,
 getIpmProjectBased,
 getInvestment,
 getInvestmentDataFilter,
 deleteNew,
 deleteBlog,
 deleteNotification,
 deleteService,
 deleteTraining,
 deleteEducationRequest,
 deletePartner,
 deleteAuthor,
 deleteAdvisor,
 deleteAddress,
 deleteProject,
 deletePurchaseRequest,
 deleteUser,
 deleteCdProject,
 deleteTqProject,
 deleteSapProject,
 deleteIbmProject,
 deleteIpmProject,
 deleteIpmProjectBased,
 updateNew,
 updateBlog,
 updateService,
 updateTraining,
 updateAddress,
 makeNewVisible,
 makeBlogVisible,
 makeNotificationVisible,
 makeServiceVisible,
 makeTrainingVisible,
 makeEducationRequestVisible,
 makePartnerVisible,
 makeAuthorVisible,
 makeAdvisorVisible,
 makeAddressVisible,
 makeProjectVisible,
 makeTqProjectVisible,
 makeUserVisible,
 makePurchaseRequestVisible,
 makeCdProjectVisible,
 makeSapProjectVisible,
 makeIbmProjectVisible,
 makeIpmProjectVisible,
 makeIpmProjectBasedVisible,
 getCategoryData,
 updateServiceCategoryArray,
 deleteCategoryItem,
 addNewDataToCategory,
 getCategoryIdData,
 addRecording,
 getOdsInNumberData,
 getOdsInNumberDataFilter,
 makeOdsInNumberVisible,
 deleteOdsInNumber,
 deleteTrainer,
 makeTrainerVisible,
 getAuthorsUndeleted,
 getTrainerUnDeleted,
 getStoryData,
 getStoriesData,
 updateStory,
 makeStoryVisible,
 deleteStory,
 getCampaignData,
 deleteCampaign,
 makeCampaignVisible,
 updateCampaign,
 updatePartner,
 updateProject,
 getEducationData,
 updateInvestment,
 updateAuthorizationPages,
 updateUserType,
 updateUserImageUrl,
 getUserByUid,
 getNewsById,
 getBlogsById,
 getEducationsById,
 getCampaignsById,
 updateProject,
 deleteInvestment,
 makeInvestmentVisible,
 updateCdProject,
 updateTqProject,
 updateSapProject,
 updateIbmProject,
 updateIpmProjectBased,
 updateIpmProject,
 getIpmProjectBasedById,
 updateIpmProjectIdsBased,
 getCdProjectsByUserId,
 getTqProjectsByUserId,
 getSapProjectsByUserId,
 getIbmProjectsByUserId,
 getIpmProjectsByUserId,
 updateNewsFavoriteStatus,
 updateEducationFavoriteStatus,
 updateBlogFavoriteStatus,
 updateCampaignFavoriteStatus,
 getFilteredUserData,
 addPurchaseRequest,
 getTrainerDataById,
 getAuthorDataById,
 createUser,
 createUserGoogle,
 registerUser,
 registerUserWithGoogle,
 getPurchaseRequestDataByUserId,
 getNewDataFilter,
 getServiceDataFilter,
 getServiceDataFilterHomePage,
 getServiceDataFilterHomePageLast4,
 getTrainingDataFilter,
 getBlogDataFilter,
 getCampaignDataFilter,
 getTrainerDataByIdFilter,
 getAuthorDataByIdFilter

};

export default FirebaseService;