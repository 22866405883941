import React, { useEffect,useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {useNavigate} from "react-router-dom"
import NotificationService from "../services/antNotificationService";
import { adminMenu, advisorMenu } from "../environments/environment"
import FirebaseService from "../services/firebaseService";

const { SubMenu } = Menu;

function getItem(link,label, key, children, type) {
  return {
    link,
    key,
    children,
    label,
    type,
  };
}
const items = [
  getItem('/adminpanel',adminMenu.homePage, '1'),
  getItem('',adminMenu.project, '2', [
    getItem('/cdprojectlist', 'CD','16'),
    getItem('/tqprojectlist', 'TQ ','17'),
    getItem('/sapprojectlist', 'SAP','18'),
    getItem('/ibmprojectlist', 'IBM','19'),
    getItem('/ipmprojectlist', 'IPM','20'),
    getItem('/businessdevelopmentlist', 'İş Geliştirme','21'),
  ]),
  getItem('/odsinnumbers',adminMenu.odsinnumber, '3'),
  getItem('/useredit',adminMenu.useredit, '4'),
  getItem('/address',adminMenu.address, '5'),
  getItem('/news',adminMenu.news, '6'),
  getItem('/blogs',adminMenu.blogs, '7'),
  getItem('/services',adminMenu.services, '8'),
  getItem('/trainings',adminMenu.trainings, '9'),
  // getItem('/trainingrequestlist',adminMenu.trainingrequestlist, '10'),
  getItem('/notifications',adminMenu.notifications, '11'),
  getItem('/trainerandauthor',adminMenu.trainerandauthor, '12'),
  getItem('/surveys',adminMenu.surveys, '13'),
  getItem('/purchaserequest',adminMenu.purchaseRequest, '14'),
  getItem('/stories',adminMenu.story, '15'),
  getItem('/campaignlist',adminMenu.campaign, '22'),
 // getItem('/home',adminMenu.webpage, '23')

];

const AdminMenu = () => {
  const navigate=useNavigate();
  const signout=()=>{
    NotificationService.openInfoNotification({title:'',description:'Çıkış Yapılıyor',placement:'topRight'})
    localStorage.clear();
    navigate('/login');
  }
  const [activeUserNameSurname, setActiveUserNameSurname] = useState();
  const [adminPages, setAdminPages] = useState();
  const [subAdvisorPages, setSubAdvisorPages] = useState();
  const [activeUserEmail, setActiveUserEmail] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const [isadvisor, setIsAdvisor] = useState(false);

  const [selectedKey, setSelectedKey] = useState(''); //1 
  const location = useLocation();
  const [userData, setUserData] = useState({});


  useEffect(() => {
    const userName = localStorage.getItem('nameSurname');
    const email = localStorage.getItem('email');
    const authorizationPages = localStorage.getItem('authorizationPages');

    setActiveUserNameSurname(userName);
    setActiveUserEmail(email);
    setAdminPages(authorizationPages ? authorizationPages.split(',') : []);

  }, []);


  useEffect(() => {
   
    const authorizationPages = localStorage.getItem('authorizationPages');
    const loginType = localStorage.getItem('userType');
    const adminmenupages = authorizationPages ? authorizationPages.split(',') : []
    if (adminmenupages && loginType.includes('admin')) {
    // console.log("admin")
      const filteredMenu = items.filter((item) => adminmenupages.includes(item.label));
      setFilteredItems(filteredMenu);
    } 
    else if(loginType.includes('advisor')&& adminmenupages)
    {
      console.log("advisor")

      setIsAdvisor(true)
      const filteredMenu = items.filter((item) => item.label===adminMenu.project);
      const subfilteredMenu = filteredMenu[0].children.filter((item) => adminmenupages.includes(item.label));
      setSubAdvisorPages(subfilteredMenu);
     // console.log("advisor");

      setFilteredItems(filteredMenu);
    }
    else if(loginType.includes('headAdmin'))
    {
     // console.log("headadmin")

      setFilteredItems(items)
    }
    else {
      NotificationService.openErrorNotification({title:'',description:'Erişilebilirlik sayfaları çekilemedi',placement:'topRight'})
      localStorage.clear();
      navigate('/login');
    }
  }, [adminPages]);

    
  useEffect(() => {
    const pathname = location.pathname;
    const menuItem = items.find(item => item.link === pathname);
    if (menuItem) {
      setSelectedKey(menuItem.key);
    }
  }, [location.pathname]);

  const handleMenuClick = (key) => {
    console.log("key",key)
    setSelectedKey(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await FirebaseService.getUserByUid(localStorage.getItem("uid"));
        if (userData) {
          setUserData(userData);  
        } else {
         // console.error('User not found in Firestore');
        }
      } catch (error) {
       // console.error('Error fetching user data:', error.message);
      } finally {
        // console.error('User not found in Firestore');
      }
    };

    fetchData();
  }, []);



  const renderMenuItems = (menuItems, isSubmenu = false) => {
    return menuItems.map((item, index) => {
      const isLastItem = index === menuItems.length - 1;
      if (isadvisor && item.label === adminMenu.project) {
        return (
          <SubMenu
            key={item.key}
            title={item.label}
            style={{
              textAlign: 'left',
              borderRadius: '0px',
            }}
          >
            {renderMenuItems(subAdvisorPages, true)}
          </SubMenu>
        );
      }
      else if(!isadvisor && item.children){
        return (
          <SubMenu
            key={item.key}
            title={item.label}
            style={{
              textAlign: 'left',
              borderBottom: item.children.label!=="8" ? '0.5px solid #000000' : 'none',
              borderRadius: '0px',
            }}
          >
            {renderMenuItems(item.children, true)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item
          key={item.key}
          style={{
            textAlign: 'left',
            borderBottom: item.label!=="İş Geliştirme" ? '0.5px solid #000000' : 'none',
            borderRadius: '0px',
            height: '50px',
          }}
        >
          <Link style={{textDecoration:"none"}} to={item.link}>{item.label}</Link>
        </Menu.Item>
      );
    });
  };
  

  return (
    <div
      style={{
        minWidth: 256,
        overflowY: 'auto',
      }}
    >
      <Menu
              selectedKeys={[selectedKey]}

       // defaultSelectedKeys={['1']}
      //  defaultOpenKeys={['1']}
        mode="inline"
        theme="dark"
        style={{background:"#1A446C" , height: '100%', overflowY: 'auto'}}
        className='admin-menu'
        onClick={({ key }) => handleMenuClick(key)} 
      >
          <Menu.Item key={"icon"} style={{height:"100px", pointerEvents: "none",marginBottom: "-20px",marginTop:"25px"}}>
           
          {userData.imageUrl ? (
              <img alt="" src={userData.imageUrl} style={{ marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover" ,marginTop:"20px"}} />
            ) : (
              <img alt="" src="/images/menuusericon.png" style={{ marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover" , background:"#1A446C"}} />
            )}
          </Menu.Item >
          <Menu.Item key={"name"} style={{ pointerEvents: "none",marginBottom: "-20px", marginTop:"20px"}}>
            <span style={{ color: "white", fontSize: "18px", pointerEvents: "none"}}>{activeUserNameSurname}</span>
          </Menu.Item>
          <Menu.Item key={"email"} style={{pointerEvents: "none",marginBottom:"20px"}}>
            <span style={{ color: "white", fontSize: "12px" }}>{activeUserEmail}</span>
          </Menu.Item>
          {renderMenuItems(filteredItems)}
          <Menu.Item key="/home" style={{ textAlign: "left" }}>
      <Link style={{textDecoration:"none"}} to="/home">Web</Link>
    </Menu.Item>
          <Menu.Item key={"signout"} style={{textAlign:"left"}} onClick={()=>{signout()}}>
            <span>Çıkış Yap</span>
          </Menu.Item>
      </Menu>
    </div>
  );
};
export default AdminMenu;

/*
            <img alt="" src="/images/menuusericon.png" style={{marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover", marginTop:"20px"}} />

*/