import './App.css';
import React, { useEffect, useState } from "react";
import AdminMenu from './layouts/adminMenu';
import Login from './admin/Auth/login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BlogList from './admin/Blogs/blogList';
import ServiceList from './admin/Services/serviceList';
import NewList from './admin/News/newList';
import TrainingList from './admin/Trainings/trainingList';
import Dashboard from './layouts/dashboars';
import PrivateComponent from './layouts/privatePage';
import BlogAdd from './admin/Blogs/blogAdd';
import BlogEdit from './admin/Blogs/blogEdit';
import NewAdd from './admin/News/newAdd';
import ServiceAdd from './admin/Services/serviceAdd';
import ServiceEdit from './admin/Services/serviceEdit';
import TrainingAdd from './admin/Trainings/trainingAdd';
import TrainingEdit from './admin/Trainings/trainingEdit';
import NewEdit from './admin/News/newEdit';
import NotificationList from './admin/Notification/notificationList';
import TrainingRequestList from './admin/Trainings/trainingRequestsList';
import Register from './web/auth/register';
import News from './web/news/news';
import Services from './web/services/services';
import Trainings from './web/training/training';
import Blogs from './web/blogs/blogs';
import TrainingRequest from './web/training/trainingRequest';
import Newdetail from './web/news/newDetail';
import BlogDetail from './web/blogs/blogDetail';
import ServiceDetail from './web/services/serviceDetail';
import TrainingDetail from './web/training/trainingDetail';
import Campaign from './web/campaings/campaign';
import WebMenu from './web/layouts/webMenu';
import WebNavi from './web/layouts/webNavi';
import Authordetail from './web/author/authorDetail';
import Trainerdetail from './web/trainerDetail';
import HomePage from './web/layouts/homePage';
import Profile from './web/auth/profile';
import ChangePassword from './web/auth/changepassword';

import Projects from './web/projects/projects';
import TqDetail from './web/projects/tqdetail';
import CdDetail from './web/projects/cddetail';
import SapDetail from './web/projects/sapdetail';
import IbmDetail from './web/projects/ibmdetail';
import IpmDetail from './web/projects/ipmdetail';
import IpmProjectDetail from './web/projects/ipmprojectdetail';

//iletişim normal user için
import Contact from './web/contact/contact';
import AskAdvisor from './web/contact/askadvisor';
import Survey from './web/contact/survey';
import SurveyDetail from './web/contact/surveydetail';
import Map from './web/contact/map';

import SurveyList from './admin/Surveys/surveyList';
import PartnerAdd from './admin/Partners/partnerAdd';
import NotificationAdd from './admin/Notification/notificationAdd';
import NotificationEdit from './admin/Notification/notificationEdit';
import ForgotPassword from './web/auth/forgotPassword';
import SurveyAdd from './admin/Surveys/surveyAdd';
import SurveyEdit from './admin/Surveys/surveyEdit';
import OdsInNumberAdd from './admin/OdsinNumbers/odsinNumbersAdd';
import OdsInNumberList from './admin/OdsinNumbers/odsInNumberList';
import TrainerAndAuthorList from './admin/TrainerAndAuthor/trainerAndAuthorList';
import AddressList from './admin/Address/addressList';
import AddressAdd from './admin/Address/addressAdd';
import TrainerAndAuthorAdd from './admin/TrainerAndAuthor/trainerAndAuthorAdd';
import TrainerAndAuthorEdit from './admin/TrainerAndAuthor/trainerAndAuthorEdit';
import AddressEdit from './admin/Address/addressEdit';
import UserEditList from './admin/UserEdit/userEditList';
import PurchaseRequestList from './admin/PurchaseRequests/purchaseRequestList';
import StoryList from './admin/Story/storyList';
import StoryAdd from './admin/Story/storyAdd';
import StoryEdit from './admin/Story/storyEdit';
import CdProjectAdd from './admin/Projects/CdProject/cdProjectAdd';
import IBMProjectAdd from './admin/Projects/IbmProject/ibmProjectAdd';
import IPMProjectAdd from './admin/Projects/IpmProject/ipmProjectAdd';
import TqProjectAdd from './admin/Projects/TqProject/tqProjectAdd';
import SapProjectAdd from './admin/Projects/SapProject/sapProject.Add';
import BusinessDevelopmentList from './admin/Projects/BusinessdDevelopment/businessDevelopmentList';
import IpmProjectBasedAdd from './admin/Projects/IpmProject/ProjectBasedSupports/ipmProjectBasedAdd';
import CdProjectList from './admin/Projects/CdProject/cdProjectList';
import TqProjectList from './admin/Projects/TqProject/tqProjectList';
import SapProjectList from './admin/Projects/SapProject/sapProjectList';
import IbmProjectList from './admin/Projects/IbmProject/ibmProjectList';
import IpmProjectList from './admin/Projects/IpmProject/ipmProjectList';
import IpmProjectBasedList from './admin/Projects/IpmProject/ProjectBasedSupports/ipmProjectBasedList';
import CampaignList from './admin/Campaigns/campaignList';
import CampaignEdit from './admin/Campaigns/campaignEdit';
import CampaignAdd from './admin/Campaigns/campaignAdd';
import PartnerEdit from './admin/Partners/partnerEdit';
import InvestmentAdd from './admin/Investments/investmentAdd';
import InvestmentEdit from './admin/Investments/investmentEdit';
import CdProjectEdit from './admin/Projects/CdProject/cdProjectEdit';
import TqProjectEdit from './admin/Projects/TqProject/tqProjectEdit';
import SapProjectEdit from './admin/Projects/SapProject/sapProjectEdit';
import IbmProjectEdit from './admin/Projects/IbmProject/ibmProjectEdit';
import IpmProjectBasedEdit from './admin/Projects/IpmProject/ProjectBasedSupports/ipmProjectBasedEdit';
import IpmProjectEdit from './admin/Projects/IpmProject/ipmProjectEdit';
import PrivateWebPageComponent from './layouts/privateWebPage';
import PrivateWebContactPageComponent from './layouts/privateWebContactPage';

import CampaignDetail from './web/campaings/campaignDetail';
import TrainerDetail from './web/trainerDetail';
import CategoryServiceEdit from './admin/Services/categoryServiceEdit';

function App() {
  const [auth, setAuth] = useState(localStorage.getItem('accessToken'));

  useEffect(() => {
    setAuth(localStorage.getItem('accessToken'));
  }, []);

  return (
    <div className="App">
      <Router>
       
            <Routes>
              <Route element={<PrivateComponent/>}>
                <Route path="/" element={<HomePage />} />
                <Route path="/adminpanel" element={<Dashboard />} />
                <Route path="/blogs" element={<BlogList />} />
                <Route path="/services" element={<ServiceList />} />
                <Route path="/news" element={<NewList />} />
                <Route path="/trainings" element={<TrainingList />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/blogadd" element={<BlogAdd />} />
                <Route path="/blogedit" element={<BlogEdit />} />
                <Route path="/newsadd" element={<NewAdd />} />
                <Route path="/newsedit" element={<NewEdit />} />
                <Route path="/serviceadd" element={<ServiceAdd />} />
                <Route path="/serviceedit" element={<ServiceEdit />} />
                <Route path="/categoryserviceedit" element={<CategoryServiceEdit />} />
                <Route path="/trainingadd" element={<TrainingAdd />} />
                <Route path="/trainingedit" element={<TrainingEdit />} />
                <Route path="/notifications" element={<NotificationList />} />
                <Route path="/trainingrequestlist" element={<TrainingRequestList />} />
                <Route path="/surveys" element={<SurveyList />} />
                <Route path="/partneradd" element={<PartnerAdd />} />
                <Route path='/notificationsedit'element={<NotificationEdit/>}/>
                <Route path='/notificationadd'element={<NotificationAdd/>}/>
                <Route path='/surveyadd'element={<SurveyAdd/>}/>
                <Route path='/surveyedit'element={<SurveyEdit/>}/>
                <Route path='/odsinnumberadd'element={<OdsInNumberAdd/>}/>
                <Route path='/odsinnumbers'element={<OdsInNumberList/>}/>
                <Route path='/trainerandauthor'element={<TrainerAndAuthorList/>}/>
                <Route path='/address'element={<AddressList/>}/>
                <Route path='/addressadd'element={<AddressAdd/>}/>
                <Route path='/trainerandauthoradd'element={<TrainerAndAuthorAdd/>}/>
                <Route path='/trainerandauthoredit'element={<TrainerAndAuthorEdit/>}/>
                <Route path='/addressedit'element={<AddressEdit/>}/>
                <Route path='/useredit'element={<UserEditList/>}/>
                <Route path='/purchaserequest'element={<PurchaseRequestList/>}/>
                <Route path='/campaignlist'element={<CampaignList/>}/>
                <Route path='/campaignadd'element={<CampaignAdd/>}/>
                <Route path='/campaignedit'element={<CampaignEdit/>}/>
                <Route path='/stories'element={<StoryList/>}/>
                <Route path='/storyadd'element={<StoryAdd/>}/>
                <Route path='/investmentadd'element={<InvestmentAdd/>}/>
                <Route path='/investmentedit'element={<InvestmentEdit/>}/>
                <Route path='/partneredit'element={<PartnerEdit/>}/>
                <Route path='/storyedit'element={<StoryEdit/>}/>
                <Route path='/cdprojectadd'element={<CdProjectAdd/>}/>
                <Route path='/cdprojectedit'element={<CdProjectEdit/>}/>
                <Route path='/ibmprojectadd'element={<IBMProjectAdd/>}/>
                <Route path='/ibmprojectedit'element={<IbmProjectEdit/>}/>
                <Route path='/ipmprojectadd'element={<IPMProjectAdd/>}/>
                <Route path='/ipmprojectedit'element={<IpmProjectEdit/>}/>
                <Route path='/ipmprojectbasedadd'element={<IpmProjectBasedAdd/>}/>
                <Route path='/ipmprojectbasededit'element={<IpmProjectBasedEdit/>}/>
                <Route path='/tqprojectadd'element={<TqProjectAdd/>}/>
                <Route path='/tqprojectedit'element={<TqProjectEdit/>}/>
                <Route path='/sapprojectadd'element={<SapProjectAdd/>}/>
                <Route path='/sapprojectedit'element={<SapProjectEdit/>}/>
                <Route path='/businessdevelopmentlist'element={<BusinessDevelopmentList/>}/>
                <Route path='/cdprojectlist'element={<CdProjectList/>}/>
                <Route path='/tqprojectlist'element={<TqProjectList/>}/>
                <Route path='/sapprojectlist'element={<SapProjectList/>}/>
                <Route path='/ibmprojectlist'element={<IbmProjectList/>}/>
                <Route path='/ipmprojectlist'element={<IpmProjectList/>}/>
                <Route path='/ipmprojectbasedlist'element={<IpmProjectBasedList/>}/>
              </Route>
                <Route path="/login" element={<Login />} />
                <Route path='/forgotpassword'element={<ForgotPassword />}/>
                <Route path="/register" element={<Register />} />
                <Route path="/changepassword" element={<ChangePassword/>} /> 


              <Route element={<PrivateWebPageComponent/>}>
                <Route path="/newslist" element={<News />} />
                <Route path="/servicelist" element={<Services />} />
                <Route path="/traininglist" element={<Trainings />} />
                <Route path="/bloglist" element={<Blogs />} />
                <Route path="/campaigns" element={<Campaign />} />
                <Route path="/trainingrequest" element={<TrainingRequest />} />

                <Route path="/home" element={<HomePage />} />

                <Route path="/projects" element={<Projects/>} /> 
                <Route path="/tqdetail" element={<TqDetail/>} /> 
                <Route path="/cddetail" element={<CdDetail/>} /> 
                <Route path="/sapdetail" element={<SapDetail/>} /> 
                <Route path="/ibmdetail" element={<IbmDetail/>} /> 
                <Route path="/ipmdetail" element={<IpmDetail/>} /> 
                <Route path="/ipmprojectdetail" element={<IpmProjectDetail/>} /> 

                <Route path="/news-detail" element={<Newdetail />} />

                <Route path="/service-detail" element={<ServiceDetail />} />
                <Route path="/training-detail" element={<TrainingDetail />} />
                <Route path="/blog-detail" element={<BlogDetail />} />
                <Route path="/campaign-detail" element={<CampaignDetail />} />
                <Route path="/author-detail" element={<Authordetail />} />
                <Route path="/trainer-detail" element={<TrainerDetail />} />
                <Route path="/profile" element={<Profile />} /> 
                
                <Route path="/askadvisor" element={<AskAdvisor />} /> 
                <Route path="/survey" element={<Survey />} /> 
                <Route path="/map" element={<Map />} /> 
                <Route path="/surveydetail" element={<SurveyDetail />} /> 
                <Route path="/contact" element={<Contact />} /> 

              </Route>

            
            </Routes>
           
      </Router>
    </div>
  );
}

export default App;
/*

  <Route element={<PrivateWebContactPageComponent/>}>
              

              </Route>

              */