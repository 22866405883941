import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Input,notification } from 'antd';
import { useLocation,Link,useNavigate } from 'react-router-dom';
import FirebaseService from '../../services/firebaseService';
import emailjs from '@emailjs/browser';
import { emailJs } from '../../environments/environment';

const ServiceDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const serviceItem = state && state.record;
  const currentUser = { id: localStorage.getItem("uid") }; 

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userEmail, setUserEmail] = useState(""); 
  const [userFirstName, setUserFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleInputChange = (e) => {
   
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNumber(onlyNumbers);
  };


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchUserData = async () => {
      try {
        const userData = await FirebaseService.getFilteredUserData();
        //console.log("User Data:", userData);

       
        setUserEmail(userData.email);        
        setUserFirstName(userData.nameSurname);
        
      } catch (error) {
       // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);
  const handleFirstNameChange = (event) => {
    setUserFirstName(event.target.value);
  };



  const showModal = () => {
    setIsModalVisible(true);
  };
  const isValidPhoneNumber = (phoneNumber) => {
  
    const phoneNumberRegex = /^(05\d{9})$/;
  
    return phoneNumberRegex.test(phoneNumber);
  };
  
  const handleTalepEt = async () => {
    const ad = document.getElementById("ad").value;
    const eposta = document.getElementById("eposta").value;
    const telefon = document.getElementById("telefon").value;
    const aciklama = document.getElementById("aciklama").value;
  
    if (!ad  || !eposta || !telefon) {
      notification.error({
        message: 'Lütfen tüm zorunlu alanları doldurunuz.',
      });
      return;
    }
    if (telefon.length < 10) {
      notification.warning({
        message: 'Telefon numarası en az 10 karakter olmalıdır.',
      });
      return;
    }
  
  
    const userData = await FirebaseService.getFilteredUserData();
  
    const purchaseRequest = {
      serviceId: serviceItem.key,
      createdDate: new Date(),
      educationId: "",
      campaignsId: "",
      email: eposta,
      note: aciklama,
      phoneNumber: telefon,
      title: serviceItem.title,
      type: "service",
      userId: userData.userId,
      userNameSurname: `${ad}`,
    };
  
    try {
      await FirebaseService.addPurchaseRequest(purchaseRequest);
      notification.success({
        message: 'Talebiniz başarıyla oluşturuldu.',
      });
      setIsModalVisible(false);

      /*
      const data = await FirebaseService.getUserData();
      const filteredData = data.filter(item =>
        ((item.userType.includes('admin') && item.authorizationPages.includes('Satın Alma Talepleri'))) || item.userType.includes('headAdmin')
      );
      console.log("filteredData", filteredData);
      
      const headAdminEmailsSet = new Set(filteredData.map(item => item.email));
      const headAdminEmails = Array.from(headAdminEmailsSet);
      
      console.log(headAdminEmails);

   const serviceId = emailJs.serviceId;
    const templateId = emailJs.templateId;
    const publicKey = emailJs.publicKey;
    
    headAdminEmails.forEach(email => {
      const templateParams = {
        user_email: email,
        from_name: 'ODS',
        message: 'Hizmet için satın alma talebi gelmiştir.',
      };
    
      emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then(
          (response) => {
            console.log('Email sent successfully:', response);
          },
          (error) => {
            console.error('Failed to send email:', error);
          }
        );
    });
*/

    } catch (error) {
     // console.error("Satın alma talebi eklenirken hata oluştu:", error);
      notification.error({
        message: 'Talep oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.',
      });
    }
  };
  const goAuthorDetailPage = (record) => {
    navigate('/author-detail', { state: { record } });
  };


  if (!serviceItem) {
    return <div>Veri bulunamadı</div>;
  }

   return (
    <div style={{ margin: '3% 0 0 5%' }}>
      <Card
        style={{
          width: 1000,
          border: 'none',
        }}
      >
                {serviceItem.imageUrl && (
  <div
    style={{
      width: '1050px',
      height: '250px',
      borderRadius: '15px',
      overflow: 'hidden', 
    }}
  >
    <img
      src={serviceItem.imageUrl}
      alt="Blog Resmi"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',  
      }}
    />
  </div>
)}
        <div style={{ textAlign: 'start' , marginTop:"30px"}}>
          <h3 style={{  color: '#1A446C' ,marginBottom: '1%' }}>{serviceItem.title}</h3>
          
              <div style={{ color: '#1A446C', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', height: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: serviceItem.description }} />
          </div>
{serviceItem.url && (
            <div>
              <p>Servisin Devamına Buradan Ulaşabilirsiniz;</p>
              <p><a href={serviceItem.url} target="_blank" rel="noopener noreferrer">{serviceItem.url}</a></p>
            </div>
          )}
        </div>
        {currentUser.id ? (
         <Button htmlType="submit" style={{ background: '#1A446C', color: 'white', float: 'right' }} onClick={showModal}>
         CTA Form
       </Button>
        ) : (
         <></>
        )}    
       
        <Modal

          title={<span style={{ color: '#A47B5A' }}>Satın Alma Formu</span>}
          visible={isModalVisible}
  onCancel={() => setIsModalVisible(false)}  
          onOk={null}
        
          style={{ color: '#A47B5A', height: '1000px'}}
          footer={null} 
          closeIcon={<img src="/images/popupcloseicon.png" alt="popupcloseicon" style={{ width: '16px', height: '16px', color: '#A47B5A' }} />}
        >
          
          
          <div>
            
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', marginTop: '45px' }}>
      <input
        id="ad"
        placeholder="Ad Soyad"
        style={{ marginRight: '10px', width: '48%', padding: '10px' }}
        value={userFirstName}
        onChange={handleFirstNameChange}
      />
     
    </div>

            <Input id="eposta" placeholder="E-posta" style={{ marginBottom: '20px', padding:'10px' }} value={userEmail} disabled />

            <Input
      id="telefon"
      placeholder="Telefon numarası: (05xxxxxxxx)"
      style={{ marginBottom: '20px', padding: '10px' }}
      value={phoneNumber}
      onChange={handleInputChange}
    />

            <Input.TextArea id="aciklama" placeholder="Açıklama" />
            <Button type="primary" style={{ bottom: '0px', right: '0px',marginLeft:'300px',marginTop:'30px', background:'#1A446C'}} onClick={handleTalepEt}>
           Satın Alma Talep Et 
          </Button>
          </div>
         
        </Modal>
        
      </Card>
    </div>
  );
};


export default ServiceDetail;
