import React, { useState, useEffect , useRef} from 'react';
import { Card, Row, Col, Button, Carousel } from 'antd';
import FirebaseService from '../../services/firebaseService';
import { useNavigate } from 'react-router-dom';
import NotificationService from "../../services/antNotificationService";

const OurInvestors = () => {
  const [lastData, setLastData] = useState([]);
  const navigate = useNavigate();
  const carouselRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const allLastData = await FirebaseService.getInvestment();
      setLastData(allLastData);
    };

    fetchData();
  }, []);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  const goEditPage = (record) => {
    navigate('/investmentedit', { state: { record } });
  };

  const customArrowStyles = `
  :where(.css-dev-only-do-not-override-18iikkb) .ant-carousel .slick-prev {
    top: 40%;
    width: 10px;
  }

  :where(.css-dev-only-do-not-override-18iikkb) .ant-carousel .slick-next {
    margin-right: 23px;
    width: 10px;
    top: 40%;
  }
`;

const handleDelete = async (record, isDeleted) => {
 // console.log(record,isDeleted);
  try {
    let result;
    if (isDeleted) {
      result = await FirebaseService.makeInvestmentVisible(record.key);
    } else {
      result = await FirebaseService.deleteInvestment(record.key);
    }
    if (result.success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: result.message,
        placement: 'topRight'
      });

      const data = await FirebaseService.getInvestment();
      setLastData(data);
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
}; 

const settings = {
  dots: false,
  nextArrow: <img src='/images/rigtharrow.png' style={{ width: '100px' }} />,
  prevArrow: <img src='/images/leftarrow.png' style={{ width: '1010' }} />,
  infinite: true,
  speed: 200,
  slidesToShow: lastData.length === 2 ? 2 : lastData.length === 1 ? 1 : 3,
  slidesToScroll: 3,
  initialSlide: 0,
};


  
  const goAddPage = (record) => {
    navigate('/investmentadd', { state: { record } })
  };
  const handleNext = () => {
    carouselRef.current.next();
  };
  const handlePrev = () => {
    carouselRef.current.prev();
  };

  return (
    <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
          <style>{customArrowStyles}</style>

      <h3 style={{ marginBottom: '16px', textAlign: 'justify', color: '#1A446C' }}>Yatırımlarımız</h3>
      <Card style={{ border: 'none' }} className="admin-dash-investors">
        <Row gutter={15}  style={{ position: 'relative'}}>
        <div > 
        <Carousel arrows autoplay {...settings}  style={{width:"400px"}}>
            {lastData.map((item) => (
              <div key={item.key}>
                <div style={{display:"flex", position: 'absolute',marginLeft:"55px",marginTop: "5px"}}>
                  <a
                    type="link"
                    onClick={() => goEditPage(item)}
                    style={{
                      display: "inline-block",
                      border: "none",
                      backgroundColor: "transparent",
                      pointerEvents: item.isDeleted ? "none" : "auto",
                      opacity: item.isDeleted ? 0.5 : 1,
                      marginRight:"5px",
                      zIndex: 2,
                    }}
                  >
                    <img style={{ width: "25px" }} alt="edit" src="/images/dashboardEdit.png" />
                  </a>
                  {item.isDeleted ?    
                      <a
                      type="link"
                      style={{
                        display: "inline-block", 
                        border: "none",
                        backgroundColor: "transparent",
                       
                      }}
                      onClick={() => handleDelete(item,true)}
                      >
                      <img style={{ width: "25px" }} alt="edit" src="/images/hideShow.png" />
                      </a>
                      :
                      <a
                          type="link"
                          style={{
                            display: "inline-block", 
                            border: "none",
                            backgroundColor: "transparent",
                            pointerEvents: item.isDeleted ? "none" : "auto",
                            opacity: item.isDeleted ? 0.5 : 1,
                            zIndex: 2,
                          }}
                          onClick={() => handleDelete(item,false)}                          >
                          <img style={{ width: "25px" }} alt="edit" src="/images/hideShow.png" />
                          </a>
                    }
                </div>
                <Card bordered={false} className="admin-dash-our-investors" style={{ padding: 0,opacity: item.isDeleted ? 0.6 : 1,marginTop:"20px" }} >               
                  <div className="admin-dash-investor-body">
                    <img alt="df" src={item.imageUrl} className="img-admin-dash-img-investor" />
                  </div>
                </Card>
              </div>
            ))}
          </Carousel>
          </div>
          <Col span={3} key="add">
            <Card bordered={false} className="custom-card-investor" style={{marginLeft:"20px",marginTop:"15px",cursor:"pointer"}} onClick={()=>goAddPage()}> 
              <Button
                shape="circle"
                icon={<img alt="plus" src="/images/plusbutton.png" />}
                style={{ alignSelf: 'center', background: 'transparent', border: 'none',color:"#1A446C" }}
                href="/investmentadd"
                >
                Yatırım Ekle
            </Button>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default OurInvestors;