import React, { useState } from 'react';
import WebMenu from '../layouts/webMenu';
import WebContactNavi from '../layouts/webContactNavi';
import Map from './map';
import Askadvisor from './askadvisor';
import Survey from './survey';

const Contact = () => {
  const [selectedItem, setSelectedItem] = useState('map');

  const handleMenuClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '1', padding: '20px' }}>
    
        {selectedItem === 'map' && <Map />}
        {selectedItem === 'askadvisor' && <Askadvisor />}
        {selectedItem === 'survey' && <Survey />}
      </div>
    </div>
  );
};

export default Contact;
