import React,{useState,useEffect} from "react";
import { Table } from 'antd';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

  const columns = [
    {
      title: 'Resim',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (img) => <img src={img} width={50} />,
    },
    {
      title: 'Başlık',
      dataIndex: 'title',
      key: 'title',
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      )
    },
    {
      title: 'Kategori',
      dataIndex: 'tableCategoryName',
      key: 'tableCategoryName',
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      )
    },
    {
      title: 'Yayınlanma Tarihi',
      dataIndex: 'releaseDate',
      key: 'releaseDate',
      render: (text) => <span style={{opacity:"40%"}}>{text}</span>,
    },
  ];

const LastUploads = () => {
  const [lastData, setLastData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const allLastData = await FirebaseService.getAllLastData();
  
      const sortedData = allLastData.sort((a, b) => {
        const dateA = parseDate(a.releaseDate);
        const dateB = parseDate(b.releaseDate);
  
       
        return dateB - dateA;
      });  
      setLastData(sortedData);
    };
    
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split('.');
      return new Date(year, month - 1, day); 
    };
  
    fetchData();
  }, []);

  const goEditPage = (record) => {
    navigate(record.editPageUrl, { state: { record } })
  };

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
    <h3 style={{ marginBottom: '16px',textAlign:'justify',color:"#1A446C" }}>Son Yüklemeleri Düzenle</h3>
       <Table
            locale={{ emptyText: 'Son yüklemeler bulunmamaktadır...' }}
            dataSource={lastData}
            columns={columns}
            showHeader={false}
            pagination={false} 
            className="custom-table"
            rowClassName="custom-row"
            onRow={(record) => ({
              onClick: () => goEditPage(record),
            })}
            style={{cursor:"pointer"}}
            />
    </div>

  };
  export default LastUploads;