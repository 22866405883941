import React from "react";
import { Card, Row, Button,Form, Input, Select } from 'antd';
import NotificationService from "../../services/antNotificationService";
import FirebaseService from "../../services/firebaseService";

const { Option } = Select;

const CategoryAdd = () => {

    const formRef = React.useRef(null);

    const onFinish = async (values) => {
         try {
          //("values.page,values.categoryName"+values.page +values.categoryName)
            const result = await FirebaseService.addNewDataToCategory(values.page,values.categoryName);
            if (result.success) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: result.message,
                placement: 'topRight'
              });
              formRef.current.resetFields();
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
          }
        };

    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };

   return <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:15
      }}>
            <h3 style={{ marginBottom: '16px',textAlign:'justify',color:"#1A446C" }}>Kategori Ekle</h3>
        <Card style={{width:'400px',height:"500px",borderRadius:'0px',border:"1px solid #000000"}} >
         <Row gutter={16}>
         <Form
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{
                maxWidth: 400,
             margin:"auto",
             marginTop:"150px"
               
            }}
            >
         <Form.Item
                name="page"
                rules={[
                {
                    required: true,
                    message: 'Lütfen sayfa seçiniz !',
                },
                ]}
                className="category-add-select"
            >
                <Select
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                placeholder="Sayfa seç"
                style={{
                    width: 240,
                    border:"1px solid",
                    borderColor:"#1A446C",
                    borderRadius:"0px",
                    textAlign:"left"
                    }}
                >
                <Option value="blog">Blog</Option>
                <Option value="new">Haber</Option>
                <Option value="service">Hizmet</Option>
                <Option value="education">Eğitim</Option>
                {/*  <Option value="notification">Bildirim</Option> */}
                {/* <Option value="survey">Anket</Option> */}
                </Select>
            </Form.Item>
        
            <Form.Item
                name="categoryName"
                rules={[
                {
                    required: true,
                    message: 'Lütfen kategori ismini giriniz!',
                },
                ]}
               
            >
                <Input  style={{
                    width: 240,
                    border:"1px solid",
                    borderColor:"#1A446C",
                    borderRadius:"0px",
                    height:"45px"
    
                    }} placeholder="Kategori gir"/>
            </Form.Item>
            
            <Form.Item style={{float:"right",marginRight:"0px !important"}}>
                <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>
                    Kaydet
                </Button>
            </Form.Item>
            </Form>
        </Row>
        </Card>
    </div>

            };
  export default CategoryAdd;