import React, { useState, useEffect } from 'react';
import './services.css';
import { Row, Col } from 'antd';
import FirebaseService from '../../services/firebaseService';
import { useNavigate } from 'react-router-dom'; 
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';


import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';


const PopularServices = () => {
  const [serviceData, setServiceData] = useState([]);
  const navigate = useNavigate(); 

  const goDetailPage = (record) => {
    navigate('/service-detail', { state: { record } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await FirebaseService.getServiceDataFilter();
       
        const popularServices = data.filter((service) => service.popularService === true);
        setServiceData(popularServices);
      } catch (error) {
       // console.error('Error fetching service data:', error);
      }
    };

    fetchData();
  }, []);

  const AutoplaySlider = withAutoplay(AwesomeSlider);
  
  return (
    <div style={{ textAlign: 'center' }}>
      <h3 style={{ marginBottom: '0px', textAlign: 'justify', color: '#1A446C' }}>Popüler Hizmetler</h3>
      <Row >
        <Col span={35}>
          <div className='popular-services-carausel' style={{cursor:"pointer"}}>
            {serviceData.length === 0 ? (
            
              <p style={{ textAlign: 'justify', color: '#1A446C', justifyContent:'center',marginTop: '20px' }}>Hizmetimiz bulunmamaktadır.</p>
            ) : (


              <AutoplaySlider
              play={true}
              cancelOnInteraction={false} 
              interval={3000}
              style={{ width: '1050px', height: '300px', }}
            >
              {serviceData.map((item, index) => (
                <div key={index}  onClick={() => goDetailPage(item)} style={{ backgroundColor:"white"}}>
                    <img  style={{width: "1050px",height: "250px", objectFit:"cover", backgroundColor:"white"}} src={item.imageUrl} alt={item.title} /> 
                    <h2 className="last-news-card-title">{item.title}</h2>             
                </div>
              ))}  
            </AutoplaySlider>


            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PopularServices;