import React,{useState} from "react";
import { Button, Form, Input, Space } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const PartnerAdd = () => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const navigate = useNavigate();

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const uploadButton = (
        <div style={{width:"1000px" }}>
          <img alt="plus" src="/images/uploadplus.png" />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Logo Yükle(100x100 pixel boyutları önerilir)
          </div>
        </div>
      );

      const onFinish = async (values) => {
        console.log("editor",editorContent)

        const newPartnerData = {
          firstTransactionDate: new Date(),
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          description: editorContent,
          title: values.title
        };
        values.image=fileList.length > 0 ? values.image : null;
      try {
        if (values.image) {
          newPartnerData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(odsCollections.partner, values.image.file);
        
        const result = await FirebaseService.addRecording(odsCollections.partner,newPartnerData);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setTimeout(() => {
            navigate("/adminpanel");
          }, 2000);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
        }}
        else{
          NotificationService.openErrorNotification({
            title: 'Geçersiz Resim',
            description: "Lütfen resim seçiniz!",
            placement: 'topRight'
          });
        }
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
      }
    };    


    const [editorContent, setEditorContent] = useState('');

    const handleEditorChange = (content) => {
      console.log("cotent",content)
      setEditorContent(content);
    };

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

          <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
            rules={[
              {
              required: true,
              message: 'Lütfen resim seçiniz!',
              },
          ]}
            >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="partner-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
                    </Modal>
          </Space>
            <Form.Item
            name="title"
            rules={[
                {
                required: true,
                message: 'Lütfen partner giriniz!',
                },
            ]}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
            <Input style={{border:0,height:"45px"}} placeholder="Partner İsmi"/>
            </Form.Item>

            <Form.Item
            name="description"
           /* rules={[
                {
                required: true,
                message: 'Lütfen açıklama giriniz!',
                },
            ]}*/
            >

                <SunEditor
                  setContents={editorContent}
                  onChange={handleEditorChange}
                  placeholder="Partner İçeriği"
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'fontSize', 'formatBlock'],
                      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                      ['removeFormat'],
                      ['fontColor', 'hiliteColor'],
                      ['outdent', 'indent'],
                      ['align', 'horizontalRule', 'list', 'lineHeight'],
                      ['link'],
                      ['fullScreen', 'showBlocks', 'codeView'],
                    ],
                  }}
                />                    </Form.Item>
           
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default PartnerAdd;