import React, { useEffect, useState } from 'react';
import { Button, Carousel, Card, Modal } from 'antd';
import { CameraOutlined } from '@ant-design/icons'; 
import './profile.css';
import FirebaseService from '../../services/firebaseService';
import FirebaseStorage from '../../services/storegeService';
import  {odsCollections,categoryName}  from "../../environments/environment"
import NotificationService from "../../services/antNotificationService";

import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [visiblePurchaseRequests, setVisiblePurchaseRequests] = useState([]);
  const [favoriteNewsData, setFavoriteNewsData] = useState([]);
  const [favoriteBlogsData, setFavoriteBlogsData] = useState([]);
  const [favoriteEducationsData, setFavoriteEducationsData] = useState([]);
  const [favoriteCampaignsData, setFavoriteCampaignsData] = useState([]);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);



  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await FirebaseService.getUserByUid(localStorage.getItem("uid"));
        if (userData) {
          setUserData(userData);
          const purchaseRequestsData = await FirebaseService.getPurchaseRequestDataByUserId(localStorage.getItem("uid"));
          console.log("puuchase", purchaseRequestsData)
        
        /*  const sortedPurchaseRequests = purchaseRequestsData.sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA; 
          });*/


          const sortedPurchaseRequests = purchaseRequestsData.sort((a, b) => b.createdDate.seconds - a.createdDate.seconds);

          console.log("Sıralanmış Satın Alma Talepleri:", sortedPurchaseRequests);
          setVisiblePurchaseRequests(sortedPurchaseRequests);

        //  setVisiblePurchaseRequests(purchaseRequestsData);

          if (userData.favoriteNewsId && userData.favoriteNewsId.length > 0) {
            const data = await Promise.all(
              userData.favoriteNewsId.map(async (newsId) => {
                return await FirebaseService.getNewsById(newsId);
              })
            );
            setFavoriteNewsData(data);
          } else {
           // console.log("Favori haber bulunamadı");
          }

          if (userData.favoriteBlogsId && userData.favoriteBlogsId.length > 0) {
            const data = await Promise.all(
              userData.favoriteBlogsId.map(async (blogsId) => {
                return await FirebaseService.getBlogsById(blogsId);
              })
            );
            setFavoriteBlogsData(data);
          } else {
           // console.log("Favori blog bulunamadı");
          }


          if (userData.favoriteEducationsId && userData.favoriteEducationsId.length > 0) {
            const data = await Promise.all(
              userData.favoriteEducationsId.map(async (educationsId) => {
                return await FirebaseService.getEducationsById(educationsId);
              })
            );
            setFavoriteEducationsData(data);
          } else {
          //  console.log("Favori eğitim bulunamadı");
          }

          
          if (userData.favoriteCampaignsId && userData.favoriteCampaignsId.length > 0) {
            const data = await Promise.all(
              userData.favoriteCampaignsId.map(async (id) => {
                return await FirebaseService.getCampaignsById(id);
              })
            );
            setFavoriteCampaignsData(data);
          } else {
           // console.log("Favori kampanya bulunamadı");
          }


        
          
        } else {
         // console.error('User not found in Firestore');
        }
      } catch (error) {
       // console.error('Error fetching user data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);




  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
  
      const imageUrl = await FirebaseStorage.uploadImageAndGetURL("usersprofile", file);
  
      const userData = await FirebaseService.getUserByUid(localStorage.getItem("uid"));
      const result = await FirebaseService.updateUserImageUrl(userData.userId, imageUrl);
  
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
    } catch (error) {
     // console.error("Hata:", error);
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  };

  

  const CustomNextArrow = ({ onClick }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src='/images/rigtharrow.png' style={{ maxWidth: '400px', cursor: 'pointer', height: '40px' }} onClick={onClick} alt="Next Arrow" />
    </div>
  );
  
  const CustomPrevArrow = ({ onClick }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src='/images/leftarrow.png' style={{ maxWidth: '400px', cursor: 'pointer',height:"40px"  }} onClick={onClick} alt="Previous Arrow" />
    </div>
  );

  const goDetailPage = (record) => {
    navigate('/changepassword');
 
  };

  const goDetailPageNew = (record) => {
    navigate('/news-detail', { state: { record } });
  };

  const goDetailPageEducation = (record) => {
    navigate('/training-detail', { state: { record } });
  };

  const goDetailPageBlog = (record) => {
    navigate('/blog-detail', { state: { record } });
  };
  const goDetailPageCampaign = (record) => {
    navigate('/campaign-detail', { state: { record } });
  };
    
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const dateObject = new Date(dateString);
    return dateObject.toLocaleString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    //  hour: 'numeric',
    //  minute: 'numeric',
    //  second: 'numeric',
    });
  };

  
  if (loading) {
    return <p>Loading...</p>;
  }

  
  const settings = {
    dots: false,
    nextArrow: <img src='/images/rigtharrow.png' style={{ maxWidth: '400px' }} />,
    prevArrow:<img src='/images/leftarrow.png' style={{ maxWidth: '400px' }} />,
    infinite: false,
    speed: 500,
    slidesToShow: 6.5, 
    slidesToScroll: 1,
    initialSlide: 0, 
  }; 
  return (
    <div style={{ margin: '3% 0 0 5%' }}>
      <h3 style={{ textAlign: "start", color: "#A47B5A", fontWeight: "bold" }}>Profilim</h3>
      <hr style={{ background: "#A47B5A" }} />

      <Card style={{ width: 1000, border: 'none' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'block' }}>
            {userData.imageUrl ? (
              <img alt="" src={userData.imageUrl} style={{ marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover" }} />
            ) : (
              <img alt="" src="/images/menuusericon.png" style={{ marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover" , background:"#1A446C"}} />
            )}
        <div>
            <label htmlFor="fileInput">
              <Button icon={<CameraOutlined />} onClick={() => document.getElementById('fileInput').click()} />
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            </div>
          </div>
          <div style={{ textAlign: 'start', marginLeft: '20px' }}>
            <h3 style={{ marginLeft: '15px', color: "#A47B5A", fontWeight: "bold" }}>{userData.nameSurname}</h3>
            <Button       onClick={() => goDetailPage()} style={{ backgroundColor: 'white', color: '#1A446C', fontWeight: '500', border: "none" }}>Şifre Değiştir</Button>
            <p style={{ color: "#1A446C", marginLeft:"15px",fontWeight:"bold" }}>Firma Adı: {userData.companyName}</p>

          </div>
        </div>
       
      </Card>

    
      <h3 style={{ textAlign: "start", color: "#1A446C", fontWeight: "bold", marginTop: '20px' }}>Satın Alma Talepleri</h3>
      <div className='news-by-category-card-carousel'>
      {visiblePurchaseRequests.length > 0 ? (
        <Carousel arrows {...settings}>
          {visiblePurchaseRequests.map((purchaseRequest) => (
            
            <div key={purchaseRequest.id} > 
              <Card bordered={true} className="news-by-category-card" style={{ padding: "0px 5px", display: "flex", flexDirection: "column", height: "200px", pointerEvents:"none" }}>
                  <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                    {purchaseRequest.type === 'service' && <h3 style={{ textAlign: "left", color: "#A47B5A", fontWeight: "bold" }}>Hizmetler</h3>}
                    {purchaseRequest.type === 'education' && <h3 style={{ textAlign: "left", color: "#A47B5A", fontWeight: "bold" }}>Eğitim</h3>}
                    {purchaseRequest.type === 'campaigns' && <h3 style={{ textAlign: "left", color: "#A47B5A", fontWeight: "bold" }}>Kampanya</h3>}
                      
                        <span style={{
                      textAlign: "left",
                      overflow: 'hidden',
                      height: '8em',
                      whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis'
                    
                    }}>
                      {purchaseRequest.title}
                    </span>
                    <span>{new Date(purchaseRequest.createdDate.toDate().getTime()).toLocaleDateString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' })}</span>

                </div>

                <div>
                </div>
              </Card>  
            </div>
          ))}
        </Carousel>
         ) : (
          <p style={{float:'left'}}>Satın alma talebi bulunamaktadır.</p>
        )}
      </div>



      <h3 style={{ textAlign: "start", color: "#1A446C", fontWeight: "bold", marginTop: '80px' }}>Favori Haberlerim</h3>

      <div className='news-by-category-card-carousel'>
        {favoriteNewsData.length > 0 ? (
          <Carousel arrows {...settings}>
            {favoriteNewsData.map((item) => (
              <div key={item.id}  onClick={() => goDetailPageNew(item)}>
                <Card bordered={false} className="news-by-category-card">
                  <div className="news-by-category-card-cody">
                  <img alt="df" src={item.imageUrl} className="news-card-img" />
                    <span  className='news-card-title'>{item.title}</span>
                  </div>
                </Card>
              </div>
            ))}
          </Carousel>
        ) : (
          <p style={{float:'left'}}>Haber bulunamaktadır.</p>
        )}
      </div>


      <h3 style={{ textAlign: "start", color: "#1A446C", fontWeight: "bold", marginTop: '80px' }}>Favori Bloglarım</h3>
    <div className='news-by-category-card-carousel'>
      {favoriteBlogsData.length > 0 ? (
        <Carousel arrows {...settings}>
          {favoriteBlogsData.map((item) => (
            <div key={item.id}  onClick={() => goDetailPageBlog(item)}>
              <Card bordered={false} className="news-by-category-card">
                <div className="news-by-category-card-cody">
                <img alt="df" src={item.imageUrl} className="news-card-img" />
                  <span  className='news-card-title'>{item.title}</span>
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      ) : (
        <p style={{float:'left'}}>Blog bulunamaktadır.</p>
      )}
    </div>


    <h3 style={{ textAlign: "start", color: "#1A446C", fontWeight: "bold", marginTop: '80px' }}>Favori Eğitimlerim</h3>
    <div className='news-by-category-card-carousel'>
      {favoriteEducationsData.length > 0 ? (
        <Carousel arrows {...settings}>
          {favoriteEducationsData.map((item) => (
            <div key={item.id}  onClick={() => goDetailPageEducation(item)}>
              <Card bordered={false} className="news-by-category-card">
                <div className="news-by-category-card-cody">
                <img alt="df" src="/images/odslogo.png" className="news-card-img" style={{objectFit:"contain"}} />
                  <span  className='news-card-title'>{item.title}</span>
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      ) : (
        <p style={{float:'left'}}>Eğitim bulunamaktadır.</p>
      )}
    </div>



    <h3 style={{ textAlign: "start", color: "#1A446C", fontWeight: "bold", marginTop: '80px' }}>Favori Kampanyalarım</h3>
    <div className='news-by-category-card-carousel'>
      {favoriteCampaignsData.length > 0 ? (
        <Carousel arrows {...settings}>
          {favoriteCampaignsData.map((item) => (
            <div key={item.id}  onClick={() => goDetailPageCampaign(item)}>
              <Card bordered={false} className="news-by-category-card">
                <div className="news-by-category-card-cody">
                <img alt="df" src={item.imageUrl} className="news-card-img" />
                  <span  className='news-card-title'>{item.title}</span>
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      ) : (
        <p style={{float:'left'}}>Kampanya bulunamaktadır.</p>
      )}
    </div>

    <h3 style={{ textAlign: "start", color: "#1A446C", fontWeight: "bold"}}>
      
  <Button
    type="link"
    style={{ color: '#1A446C', fontWeight: 'bold' , fontSize:'17px', marginTop:"80px" }}
    onClick={showModal}
  >
    Hesabımı Nasıl Silerim?
  </Button>
</h3>


     
      <Modal
        title="Hesap Silme Talimatları"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null} 
      >
         <p>
    Hesabınızı silmek için aşağıdaki adımları takip edebilirsiniz:
        </p>
  <p>1. Uygulama ayarlarına gidin.</p>
  <p>2. Sayfanın en altında bulunan "Hesabı Sil" seçeneğine tıklayın.</p>
  <p>3. Çıkan onay kutusuna "hesabimisil" yazın.</p>
  <p>4. "Sil" butonuna tıklayın.</p>
  <p>
    Not: Bu işlemi gerçekleştirmeden önce, hesabınızı silmenin kalıcı olduğunu
    ve geri dönüşü olmadığını lütfen unutmayın.
  </p>
      </Modal>

   


      </div>
     
      
  );
};

export default Profile;
