import React from 'react';
import PopularServices from './popularServices';
import ServiceListWithCategory from './serviceListWithCategory';

const Services = () => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px',marginLeft:"75px" }}>
    <PopularServices/>
    <ServiceListWithCategory/>
</div>
);
export default Services;