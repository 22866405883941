import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Row, Button, Carousel } from 'antd';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import NotificationService from "../../services/antNotificationService";
const OurPartner = () => {
  const [lastPartner, setLastPartner] = useState([]);
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      const allLastData = await FirebaseService.getPartnerData();
      console.log("partners",allLastData)
      setLastPartner(allLastData);
    };
    fetchData();
  }, []);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  const goEditPage = (record) => {
    navigate('/partneredit', { state: { record } })
  };
  const handleDelete = async (record, isDeleted) => {
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makePartnerVisible(record.key);
      } else {
        result = await FirebaseService.deletePartner(record.key);
      }
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        const data = await FirebaseService.getPartnerData();
        setLastPartner(data);
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
  };
  const settings = {
    dots: false,
    nextArrow: <img src='/images/rigtharrow.png' style={{ width: '100px' }} />,
    prevArrow:<img src='/images/leftarrow.png' style={{ width: '1010' }} />,
    infinite: true,
    speed: 300,
    slidesToShow: 3.2,
    slidesToScroll: 3,
    initialSlide: 0,
  };
  const goAddPage = (record) => {
    navigate('/partneradd', { state: { record } })
  };
  const handleNext = () => {
    carouselRef.current.next();
  };
  const handlePrev = () => {
    carouselRef.current.prev();
  };
   return (
   <div style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <h3 style={{ marginBottom: '16px',textAlign:'justify',color:'#1A446C' }}>Partnerlerimiz</h3>
         <Row gutter={16}>
         <div className='admin-ourinvestor-carousel'>
         <Carousel  dots={false}
            autoplay
            slidesToShow={lastPartner.length === 2 ? 2 : lastPartner.length === 1 ? 1 : 3}
            ref={carouselRef}
            style={{marginLeft:"30px", marginRight:"30px"}}
            >
          {lastPartner.map((item) => (
              <div span={8} key={item.key}  style={{ position: 'relative'}}>
                <div style={{display:"flex", position: 'absolute',marginLeft:"55px",marginTop: "5px"}}>
                  <a
                    type="link"
                    onClick={() => goEditPage(item)}
                    style={{
                      display: "inline-block",
                      border: "none",
                      backgroundColor: "transparent",
                      pointerEvents: item.isDeleted ? "none" : "auto",
                      opacity: item.isDeleted ? 0.5 : 1,
                      marginRight:"5px"
                    }}
                  >
                    <img style={{ width: "25px" }} alt="edit" src="/images/dashboardEdit.png" />
                  </a>
                  {item.isDeleted ?
                      <a
                      type="link"
                      style={{
                        display: "inline-block",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => handleDelete(item,true)}
                      >
                      <img style={{ width: "25px" }} alt="edit" src="/images/hideShow.png" />
                      </a>
                      :
                      <a
                          type="link"
                          style={{
                            display: "inline-block",
                            border: "none",
                            backgroundColor: "transparent",
                            pointerEvents: item.isDeleted ? "none" : "auto",
                            opacity: item.isDeleted ? 0.5 : 1,
                          }}
                          onClick={() => handleDelete(item,false)}                          >
                      <img style={{ width: "25px" }} alt="edit" src="/images/hideShow.png" />
                          </a>
                    }
                </div>
                <Card bordered={false} className="custom-card-partner" style={{opacity: item.isDeleted ? 0.6 : 1,marginTop:"20px"}}>
                  <div className="ant-card-body-style-2" style={{paddingTop:"20px", paddingBottom:"15px"}}>
                    <img alt="df" src={item.imageUrl} className="ant-card-img-partner " />
                    <div style={{color:"darkblue"}} className="ant-card-desc ">
                    <div dangerouslySetInnerHTML={{ __html: item.description }} />

                      </div>
                  </div>
                </Card>
              </div>
            ))}
            </Carousel>
            <div style={{
            position: 'relative',
            bottom: '50%',
           // transform: 'translateY(-50%)',
            right: 200,
            cursor: 'pointer',
          }}>
            <img
              src="/images/leftarrow.png"
              alt="Next"
              onClick={handlePrev}
              style={{ width: '20px', height: '30px' }}
            />
          </div>
          <div style={{
            position: 'relative',
            bottom: '60%',
           // transform: 'translateY(-50%)',
            left: 200,
            cursor: 'pointer',
          }}>
            <img
              src="/images/rigtharrow.png"
              alt="Previous"
              onClick={handleNext}
              style={{ width: '20px', height: '30px ' }}
            />
          </div>
            </div>
          <Col  style={{marginLeft:'20px'}} key="add">
              <Card bordered={false} className="custom-card-partner" style={{  background: "#1A446C4D",marginTop:"20px",cursor:"pointer",justifyContent:"center"}} onClick={()=>goAddPage()}>
                  <Button
                      shape="circle"
                      icon={<img alt="plus" src="/images/plusbutton.png" />}
                      style={{ alignSelf: 'center',background:"transparent",border:"none", color:"#1A446C" }}
                      href="/partneradd"
                      >
                      Partner Ekle
                  </Button>
              </Card>
          </Col>
        </Row>
    </div>
    );
};
  export default OurPartner;