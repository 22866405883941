import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Carousel } from 'antd';
import FirebaseService from '../../../services/firebaseService';
import '../layouts.css';
const WebOurInvestors = () => {
  const [investorsData, setInvestorsData] = useState([]);
  const carouselRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await FirebaseService.getInvestmentDataFilter();
        setInvestorsData(data);
      } catch (error) {
       // console.error('Yatırım verisi getirme hatası:', error);
      }
    };
    fetchData();
  }, []);
  const handleNext = () => {
    carouselRef.current.next();
  };
  const handlePrev = () => {
    carouselRef.current.prev();
  };
  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    }}>
      <h3 style={{ marginBottom: '16px', textAlign: 'justify', color: '#1A446C' }}>Yatırımlarımız</h3>
      {investorsData.length === 0 ? (
        <p style={{ textAlign: 'justify', color: '#1A446C' }}>Yatırımlarımız bulunmamaktadır.</p>
      ) : (
        <div className='admin-ourinvestor-carousel' style={{cursor:"pointer"}}>
        <Carousel  dots={false}
           autoplay
           slidesToShow={investorsData.length === 2 ? 2 : investorsData.length === 1 ? 1 : 3}
           ref={carouselRef}
           style={{marginLeft:"30px", marginRight:"30px"}}
           >
         {investorsData.map((item) => (
             <div span={8} key={item.key}  style={{ position: 'relative'}}>
               <div style={{display:"flex", position: 'absolute',marginLeft:"55px",marginTop: "5px"}}>
                
               
               </div>
               <Card bordered={false} className="custom-card-our-investors" style={{opacity: item.isDeleted ? 0.6 : 1,marginTop:"20px"}}>
               <a href={item.url} target="_blank" rel="noopener noreferrer">
                 <div className="ant-card-body-style-2" style={{paddingTop:"40px", paddingBottom:"15px", boxShadow:"none"}}>
                   <img alt="df" src={item.imageUrl} className="ant-card-img-partner-deniz"  />
                   <span style={{ color: "#1A446C", fontWeight: 'bold', fontSize: '13px' }}>{item.title}</span>
                 </div>
                 </a>
               </Card>


          

             </div>
           ))}
           </Carousel>
           
        </div>
      )}
      {investorsData.length > 0 && (
        <>
          <div style={{
            position: 'absolute',
           bottom: '35px',
            transform: 'translateY(-50%)',
            left: 0,
            cursor: 'pointer',
          }}>
            <img
              src="/images/leftarrow.png"
              alt="Next"
              onClick={handlePrev}
              style={{ width: '20px', height: '30px' }}
            />
          </div>
          <div style={{
            position: 'absolute',
            bottom: '35px',
            transform: 'translateY(-50%)',
            right: 0,
            cursor: 'pointer',
          }}>
            <img
              src="/images/rigtharrow.png"
              alt="Previous"
              onClick={handleNext}
              style={{ width: '20px', height: '30px ' }}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default WebOurInvestors;







