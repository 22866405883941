import React from 'react';
import NewsStories from './newsStories';
import LastNews from './lastNews';
import NewsListWithCategory from './newsListWithCategory';
const News = () => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px',marginLeft:"75px" }}>
    <div style={{float:"right"}}>
      <LastNews />
    </div>
    <NewsListWithCategory />
</div>
);
export default News;