import React from "react";
import { Button, Form, Select,Table } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';


const SurveyEdit = () => {
  const location = useLocation();

  const record = location.state && location.state.record;
console.log(record);
   return ( 
    <div style={{
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      marginLeft: "50px"
    }}>
      <div style={{ marginTop: "50px",textAlign:"start" }}>
        <label style={{ fontWeight: "700",fontSize:"20px" }}>{record.title}</label>
      </div>
      <hr style={{ width: "80%", margin: "20px 0", borderColor: "#1A446C" }} />

      <div style={{  marginTop: "20px",textAlign:"start" }}>
        <p style={{ fontWeight: "700" }}>Durumu:         
          <label style={{ color: "#A47B5A", fontWeight: "600", marginTop: "30px", marginLeft: "50px",marginLeft:"36%" }}>{record.status}</label>
        </p>
      </div>

      <div style={{  marginTop: "20px",textAlign:"start"  }}>
        <p style={{ fontWeight: "700" }}>Katılan Kişi Sayısı:        
          <label style={{ color: "#A47B5A", fontWeight: "600", marginTop: "30px",marginLeft:"50px",marginLeft:"31%", padding:'30px'}}>{record?.users?.length ?? 0}</label> 
        </p>
      </div>

      <div style={{  marginTop: "20px",textAlign:"start"  }}>
        <p style={{ fontWeight: "700" }}>Cevap Detayları:</p>
      </div>
      <div style={{  marginTop: "20px",textAlign:"start" ,marginLeft:"30px" }}>
        <label style={{ fontWeight: "700" }}>Soru <label style={{ fontWeight: "700",marginLeft:"42%" }}>Yüzdeler</label></label>
      </div>
      <hr style={{ width: "80%", margin: "20px 0", borderColor: "#1A446C",marginLeft:"30px" }} />
      {record.questions.map((question, index) => (
              <div key={index}style={{textAlign:"start"}} >
                <label style={{ fontWeight: "700", textAlign: "start",marginLeft:"30px" }}>{question.question}</label>
                {question.answer.map((choice, choiceIndex) => (
                  <div key={choiceIndex} style={{ alignItems: 'baseline', width:'250px', marginLeft:'400px' }}>
                    <label style={{ fontWeight: "700", color: "#1A446C" }}>   
                    {`%${choice.userIds.length ?(((choice.userIds.length / record.users.length) * 100)).toFixed(0):0} - ${choice.choice}`}
                    </label>
                    <label style={{float:"right"}}>{`${choice?.userIds?.length ?? 0} Kişi`}</label>
                  </div>
                ))}
               {question.type==="rating"&& <label style={{ fontWeight: "700",color:"#1A446C",float:"right",marginRight:"42%",marginTop:"30px" }}>Ortalama: <label style={{ fontWeight: "700",color:"#A47B5A" }}>{question.averageChoice}</label></label>}
                <hr style={{ width: "80%", margin: "20px 0", borderColor: "#1A446C", marginLeft: "30px" }} />
              </div>
            ))}
        </div>
        )};
  export default SurveyEdit;