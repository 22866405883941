import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select, Checkbox } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import { Calendar } from 'antd';
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections,categoryName, categoryDocumentId}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";
import dayjs from 'dayjs';

const CategoryServiceEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
 

    const record = location.state && location.state.record;
   console.log("reccord",record)

    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
      };
    
    useEffect(() => {
      
     
     
    }, []);


    const onFinish = async (values) => {
        const result = await FirebaseService.updateServiceCategoryArray(record.categoryName,values.categoryName);        
        
        
        if (result) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: result.message,
                placement: 'topRight'
              });
              setTimeout(() => {
                navigate("/services");
              }, 2000);
        } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
         }       
    };


   return ( <div>
    
         <Form
            name="basic"
            initialValues={{
             
              categoryName:record.categoryName,
             
            }}
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >   

            <Form.Item
            name="categoryName"
            rules={[
                {
                required: true,
                message: 'Lütfen kategori ismini giriniz!',
                },
            ]}
            >
              <Input  style={{borderRadius:0, borderColor:"#1A446C80"}}/>
            </Form.Item>         

            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default CategoryServiceEdit;