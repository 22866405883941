import React,{useEffect,useState} from "react";
import { Button, Form, Input, Card } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { auth } from "../../services/authService";
import { signInWithEmailAndPassword } from "firebase/auth";
import {useNavigate} from "react-router-dom"
import FirebaseService from "../../services/firebaseService";
import { adminMenuLogin,advisorMenuLogin } from "../../environments/environment"
import Item from "antd/es/list/Item";


const Login = () => {
  const navigate=useNavigate();
  const [filteredItems, setFilteredItems] = useState([]);

  /*
  const onFinish = async (values) => {

    await signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        localStorage.setItem('accessToken',userCredential.user.accessToken)
        localStorage.setItem('email',userCredential.user.email)
        localStorage.setItem('uid',userCredential.user.uid)

          const data = FirebaseService.getUserByUid(userCredential.user.uid).then((data)=>{
            if(!data.userType.includes('admin') && !data.userType.includes('headAdmin') && !data.userType.includes('advisor') && !data.userType.includes("user"))
            {
              //console.log('data:', data);
              localStorage.clear();
              NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetkiniz bulunmamaktadır.',placement:'topRight'})
              return null;
            }

            
            localStorage.setItem('nameSurname',data.nameSurname)
            localStorage.setItem('userType',data.userType)
            localStorage.setItem('authorizationPages',data.authorizationPages)
            setFilteredItems(data.authorizationPages);
            
 
        NotificationService.openSuccessNotification({title:'',description:'Giriş Başarılı',placement:'topRight'})
        const userType= localStorage.getItem('userType')

        if(data.userType.includes('headAdmin'))
        {
          setTimeout(() => {
            navigate("/adminpanel");
          }, 3000);
        }
        else if(data.userType.includes('admin'))
        { 
          var pages=data.authorizationPages;
          const filteredMenu = adminMenuLogin.filter((item) => pages.includes(item.value));
          if(filteredMenu)
          {
            setTimeout(() => {
              navigate(filteredMenu[0].navigate);
            }, 3000);
          }
        }
        else if(data.userType.includes('advisor'))
        {
          var pages=data.authorizationPages;
          const filteredMenu = advisorMenuLogin.filter((item) => pages.includes(item.value));
          if(filteredMenu)
          {
            setTimeout(() => {
              navigate(filteredMenu[0].navigate);
            }, 3000);
          }
        }
        else if(data.userType.length===1 && data.userType.includes("user"))
        {
          setTimeout(() => {
            navigate("/home");
          }, 3000);
        }
        else{
          localStorage.clear();
          NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetki bilgisi alınamadı',placement:'topRight'})
          return null;
        }
          });
       
      })
      .catch((error) => {
        localStorage.clear();
        if (error.code === 'auth/wrong-password') {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: 'Şifre yanlış. Lütfen şifreyi doğru girin.', placement: 'topRight' });
        } else if (error.code === 'auth/configuration-not-found') {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: 'Kullanıcı bulunamadı. Firebase konfigürasyon hatası.', placement: 'topRight' });
        } else if (error.code === 'auth/invalid-login-credentials') {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: 'Geçersiz giriş bilgileri. Lütfen e-posta ve şifreyi kontrol edin.', placement: 'topRight' });
        } else {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: `${error.message}`, placement: 'topRight' });
        }
      });
  };
  */
  

  const onFinish = async (values) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
      const user = userCredential.user;

     /*
      console.log("email doğrulanmış")
      localStorage.setItem('accessToken', user.accessToken);
      localStorage.setItem('email', user.email);
      localStorage.setItem('uid', user.uid);

      const data = await FirebaseService.getUserByUid(user.uid);

      if (!data.userType.includes('admin') && !data.userType.includes('headAdmin') && !data.userType.includes('advisor') && !data.userType.includes("user")) {
        localStorage.clear();
        NotificationService.openErrorNotification({
          title: 'Giriş Başarısız',
          description: 'Yetkiniz bulunmamaktadır.',
          placement: 'topRight'
        });
        return null;
      }

      localStorage.setItem('nameSurname', data.nameSurname);
      localStorage.setItem('userType', data.userType);
      localStorage.setItem('authorizationPages', data.authorizationPages);
      setFilteredItems(data.authorizationPages);
      NotificationService.openSuccessNotification({title:'',description:'Giriş Başarılı',placement:'topRight'})
      const userType= localStorage.getItem('userType')

      if(data.userType.includes('headAdmin'))
      {
        setTimeout(() => {
          navigate("/adminpanel");
        }, 3000);
      }
      else if(data.userType.includes('admin'))
      { 
        var pages=data.authorizationPages;
        const filteredMenu = adminMenuLogin.filter((item) => pages.includes(item.value));
        if(filteredMenu)
        {
          setTimeout(() => {
            navigate(filteredMenu[0].navigate);
          }, 3000);
        }
      }
      else if(data.userType.includes('advisor'))
      {
        var pages=data.authorizationPages;
        const filteredMenu = advisorMenuLogin.filter((item) => pages.includes(item.value));
        if(filteredMenu)
        {
          setTimeout(() => {
            navigate(filteredMenu[0].navigate);
          }, 3000);
        }
      }
      else if(data.userType.length===1 && data.userType.includes("user"))
      {
        setTimeout(() => {
          navigate("/home");
        }, 3000);
      }
      else{
        localStorage.clear();
        NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetki bilgisi alınamadı',placement:'topRight'})
        return null;
      }
      */
//user.emailVerified
      if (user.emailVerified) {
        console.log("email doğrulanmış")
        localStorage.setItem('accessToken', user.accessToken);
        localStorage.setItem('email', user.email);
        localStorage.setItem('uid', user.uid);
  
        const data = await FirebaseService.getUserByUid(user.uid);
  
        if (!data.userType.includes('admin') && !data.userType.includes('headAdmin') && !data.userType.includes('advisor') && !data.userType.includes("user")) {
          localStorage.clear();
          NotificationService.openErrorNotification({
            title: 'Giriş Başarısız',
            description: 'Yetkiniz bulunmamaktadır.',
            placement: 'topRight'
          });
          return null;
        }
  
        localStorage.setItem('nameSurname', data.nameSurname);
        localStorage.setItem('userType', data.userType);
        localStorage.setItem('authorizationPages', data.authorizationPages);
        setFilteredItems(data.authorizationPages);
        NotificationService.openSuccessNotification({title:'',description:'Giriş Başarılı',placement:'topRight'})
        const userType= localStorage.getItem('userType')

        if(data.userType.includes('headAdmin'))
        {
          setTimeout(() => {
            navigate("/adminpanel");
          }, 3000);
        }
        else if(data.userType.includes('admin'))
        { 
          var pages=data.authorizationPages;
          const filteredMenu = adminMenuLogin.filter((item) => pages.includes(item.value));
          if(filteredMenu)
          {
            setTimeout(() => {
              navigate(filteredMenu[0].navigate);
            }, 3000);
          }
        }
        else if(data.userType.includes('advisor'))
        {
          var pages=data.authorizationPages;
          const filteredMenu = advisorMenuLogin.filter((item) => pages.includes(item.value));
          if(filteredMenu)
          {
            setTimeout(() => {
              navigate(filteredMenu[0].navigate);
            }, 3000);
          }
        }
        else if(data.userType.length===1 && data.userType.includes("user"))
        {
          setTimeout(() => {
            navigate("/home");
          }, 3000);
        }
        else{
          localStorage.clear();
          NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetki bilgisi alınamadı',placement:'topRight'})
          return null;
        }
  
      } 
      else {
        console.log("email doğrulaanmamış")
        localStorage.clear();
        NotificationService.openErrorNotification({
          title: 'Giriş Başarısız',
          description: 'E-posta adresiniz henüz doğrulanmamış. Lütfen e-postanızı kontrol ederek doğrulama işlemini tamamlayın.',
          placement: 'topRight'
        });
      }
      

      
    }
     catch (error) {
      localStorage.clear();
      if (error.code === 'auth/wrong-password') {
        NotificationService.openErrorNotification({
          title: 'Giriş Başarısız',
          description: 'Şifre yanlış. Lütfen şifreyi doğru girin.',
          placement: 'topRight'
        });
      } else if (error.code === 'auth/configuration-not-found') {
        NotificationService.openErrorNotification({
          title: 'Giriş Başarısız',
          description: 'Kullanıcı bulunamadı. Firebase konfigürasyon hatası.',
          placement: 'topRight'
        });
      } else if (error.code === 'auth/invalid-login-credentials') {
        NotificationService.openErrorNotification({
          title: 'Giriş Başarısız',
          description: 'Geçersiz giriş bilgileri. Lütfen e-posta ve şifreyi kontrol edin.',
          placement: 'topRight'
        });
      } 
      else if (error.code === 'auth/user-not-found') {
        NotificationService.openErrorNotification({
          title: 'Giriş Başarısız',
          description: 'Bu mail adresine ait bir kullancı bulunmamaktadır.',
          placement: 'topRight'
        });
      }else {
        NotificationService.openErrorNotification({
          title: 'Giriş Başarısız',
          description: `${error.message}`,
          placement: 'topRight'
        });
      }
    }
  };
  
  
  const onFinishFailed = (errorInfo) => {
    localStorage.clear();
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  useEffect(()=>{
    const auth=localStorage.getItem('accessToken');
    const userType=localStorage.getItem('userType');
   // const categoryId =  FirebaseService.getCategoryIdData(); //categorydocumentid için gerekli
    

    if(auth && userType)
    {
      if(userType.includes('headAdmin'))
      {
        setTimeout(() => {
          navigate("/adminpanel");
        }, 3000);
      }
      else if(userType.includes('admin'))
      { 
        var pages=localStorage.getItem('authorizationPages');
        const filteredMenu = adminMenuLogin.filter((item) => pages.includes(item.value));
        if(filteredMenu)
        {
          setTimeout(() => {
            navigate(filteredMenu[0].navigate);
          }, 3000);
        }
      }
      else if(userType.includes('advisor'))
      {
        var pages=localStorage.getItem('authorizationPages');
        const filteredMenu = advisorMenuLogin.filter((item) => pages.includes(item.value));
        if(filteredMenu)
        {
          setTimeout(() => {
            navigate(filteredMenu[0].navigate);
          }, 3000);
        }
      }
      else if(userType.includes("user"))
      {
        setTimeout(() => {
          navigate("/home");
        }, 3000);
      }
      else{
        localStorage.clear();
        NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetki bilgisi alınamadı',placement:'topRight'})
        return null;
      }
    }
})
  
const handleGoogleLogin = async () => {
  try {
    const user = await FirebaseService.registerUserWithGoogle();
    console.log("basarılı user", user);
    
    localStorage.setItem('accessToken', user.accessToken)
    localStorage.setItem('email',user.email)
    localStorage.setItem('uid',user.uid)
    const data = FirebaseService.getUserByUid(user.uid).then((data)=>{
      if(!data.userType.includes('admin') && !data.userType.includes('headAdmin') && !data.userType.includes('advisor') && !data.userType.includes("user"))
      {
        console.log('data:', data);
        localStorage.clear();
        NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetkiniz bulunmamaktadır.',placement:'topRight'})
        return null;
      }

      
      localStorage.setItem('nameSurname',data.nameSurname)
      localStorage.setItem('userType',data.userType)
      localStorage.setItem('authorizationPages',data.authorizationPages)
      setFilteredItems(data.authorizationPages);
      

    NotificationService.openSuccessNotification({title:'',description:'Giriş Başarılı',placement:'topRight'})
    const userType= localStorage.getItem('userType')

    if(data.userType.includes('headAdmin'))
    {
    setTimeout(() => {
      navigate("/adminpanel");
    }, 3000);
    }
    else if(data.userType.includes('admin'))
    { 
    var pages=data.authorizationPages;
    const filteredMenu = adminMenuLogin.filter((item) => pages.includes(item.value));
    if(filteredMenu)
    {
      setTimeout(() => {
        navigate(filteredMenu[0].navigate);
      }, 3000);
    }
    }
    else if(data.userType.includes('advisor'))
    {
    var pages=data.authorizationPages;
    const filteredMenu = advisorMenuLogin.filter((item) => pages.includes(item.value));
    if(filteredMenu)
    {
      setTimeout(() => {
        navigate(filteredMenu[0].navigate);
      }, 3000);
    }
    }
    else if(data.userType.length===1 && data.userType.includes("user"))
    {
    setTimeout(() => {
      navigate("/home");
    }, 3000);
    }
    else{
    localStorage.clear();
    NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetki bilgisi alınamadı',placement:'topRight'})
    return null;
    }
    });
   /*NotificationService.openSuccessNotification({
      title: '',
      description: 'Giriş Başarılı',
      placement: 'topRight',
    });
    navigate('/home');*/
  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'Invalid Form',
      description: 'Please check your form information.',
      placement: 'topRight',
    });
    console.error('Registration error:', error.message);
  }
};


   return  <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
      }}
    >
        <Card style={{borderRadius:0, border: "none",borderBottom:"1px solid", borderColor:"#A47B5A",width:"80%" }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <img width={209} height={112} alt="logo" src="/images/odslogo.png" style={{margin:"5%"}}/>
          <Form
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Lütfen mail adresinizi doğru formatta giriniz!',
                },
              ]}
            >
              <Input placeholder="E-posta" style={{ width: '450px', height: '50px',borderColor:"#1A446C" }} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Lütfen şifrenizi giriniz!',
                },
              ]}
            >
              <Input.Password placeholder="Şifre" style={{ width: '450px', height: '50px',borderColor:"#1A446C" }} />
            </Form.Item>

            <Form.Item
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 5,
              }}
            >
             <Button type="link" href="/forgotpassword" htmlType="button" style={{ color: '#A47B5A', alignSelf: 'flex-start' }}>Şifremi unuttum</Button>
            </Form.Item>
            <Form.Item      
              style={{ marginBottom: 5, textAlign: 'right',float:"right" }}
            >
               
              <Button htmlType="submit" style={{background:"#1A446C",color:"white"}}>
                Giriş Yap
              </Button>

            </Form.Item>
            
          
          </Form>

          </div>
          <Button htmlType="button" onClick={handleGoogleLogin}  style={{background:"transparent", borderColor:"#000000",borderRadius:"4px", width:"300px",marginBottom:"1%", marginTop:"2%"}}>
          <img alt="plus" style={{width:"25px",float:"left"}} src="/images/googleicon.png" /> Google İle Giriş Yap
          </Button>
        </Card>
        <span style={{ marginTop: '16px' }}>Hesabınız yok mu? <Button type="link" htmlType="button" style={{ color: '#A47B5A' }} href="/register">Kayıt Ol</Button></span>
        <span style={{ marginTop: '16px' }}> <Button type="link" htmlType="button" style={{ color: '#A47B5A' }} href="/home">Ziyaretçi</Button> olarak devam et</span>

  </div>

};
  export default Login;
