import React, { useEffect, useState } from 'react';
import { Menu, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import NotificationService from "../../services/antNotificationService";
import FirebaseService from '../../services/firebaseService';

function getItem(link, label, key, children, type) {
  return {
    link,
    key,
    children,
    label,
    type,
  };
}

const WebMenu = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [userData, setUserData] = useState({});
  const [selectedKey, setSelectedKey] = useState(''); //1 
  const location = useLocation();

  const [items, setItems] = useState([
    getItem('/home', 'Ana Sayfa', '1'),
    getItem('/projects', 'Hizmetlerim', '2'),
    getItem('/profile', 'Profilim', '3'),
    getItem('/contact', 'İletişim', '7'),
  ]);


  const handleMenuClick = (key) => {
    setSelectedKey(key);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const signout = () => {
    NotificationService.openInfoNotification({ title: '', description: 'Çıkış Yapılıyor', placement: 'topRight' });
    localStorage.clear();
    navigate('/login');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedUserData = await FirebaseService.getUserByUid(localStorage.getItem("uid"));
        if (fetchedUserData) {
          setUserData(fetchedUserData);
          const isAdmin = fetchedUserData.userType.includes('admin') || fetchedUserData.userType.includes('headAdmin') || fetchedUserData.userType.includes('advisor');
          setItems((prevItems) => {
            const updatedItems = [...prevItems];
            if (isAdmin && !prevItems.some(item => item.link === '/adminpanel')) {
              console.log("menu guncellendi")
              updatedItems.push(getItem('/adminpanel', 'Admin Panel', '8'));
            } else if (!isAdmin) {
              return updatedItems.filter(item => item.link !== '/adminpanel');
            }

            return updatedItems;
          });
        } else {
          
           console.log('User not found in Firestore');
        }
      } catch (error) {
         console.log('Error fetching user data:', error.message);
         setItems([
          getItem('/login', 'Ana Sayfa', '1'),
          getItem('/login', 'Hizmetlerim', '2'),
          getItem('/login', 'Profilim', '3'),
          getItem('/login', 'İletişim', '7'),
        ]);
      }
    };

    fetchData();
  }, []);

  
  useEffect(() => {
    const pathname = location.pathname;
    const menuItem = items.find(item => item.link === pathname);
    if (menuItem) {
      setSelectedKey(menuItem.key);
    }
  }, [location.pathname]);



  return (
    <div style={{
      overflowY: 'auto',
    }}>
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{ display: 'block', zIndex:20, padding:"0px 0px",marginTop:"-5px"}}
        className='menu-icon'
      >
        {collapsed ?     
        <div style={{ background: "white", width:"40px", textAlign:"left" }}>
         <img src='/images/menu.png' style={{ cursor: 'pointer', backgroundColor:"white", width:"30px", height:"20px", marginTop:"5px", marginLeft:"5px" }}  alt="menu" />
         </div>
         : 
         <div   style={{ background: "#1A446C", width:"220px" , textAlign:"left"}}>
         <img src='/images/whitemenu.png' style={{ cursor: 'pointer',  width:"30px", height:"20px" ,  marginTop:"5px", marginLeft:"5px" }}  alt="menu" />
      </div>
      }
      </Button>

      {!collapsed && (
        <Menu
        selectedKeys={[selectedKey]}
       //   defaultSelectedKeys={['1']}
          defaultOpenKeys={['1']}
          mode="inline"
          theme="dark"
          style={{ background: "#1A446C", height: '2100px', width:"220px",overflowY: 'auto',position:"relative", top:"-4px" }}
          inlineCollapsed={collapsed}
          className=''
          onClick={({ key }) => handleMenuClick(key)} 
        >
          <div style={{ marginBottom: "60px", marginTop: "40px" }}>
          <Menu.Item key={"icon"} style={{ height: "100px", pointerEvents: "none", marginBottom: "-20px" }}>
 
        {userData.imageUrl ? (
    <img alt="" src={userData.imageUrl} style={{ marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover", marginTop:"20px" }} />
  ) : (
    <img alt="" src="/images/menuusericon.png" style={{marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover", marginTop:"20px" }} />
  )}
          
        </Menu.Item>
        <Menu.Item key={"name"} style={{ pointerEvents: "none", marginBottom: "-20px",  marginTop:"20px" }}>
          <span style={{ color: "white", fontSize: "18px", pointerEvents: "none" }}>{userData.nameSurname}</span>
        </Menu.Item>

            <Menu.Item key={"email"} style={{ pointerEvents: "none" }}>
              <span style={{ color: "white", fontSize: "12px" }}>{userData.email}</span>
            </Menu.Item>
          </div>
          {items.map((item) => (
            <Menu.Item key={item.key} style={{ textAlign: "left" }}>
              <Link style={{textDecoration:"none"}} to={item.link}>{item.label}</Link>
            </Menu.Item>
          ))}
            {userData.email ? (
          <Menu.Item key={"signout"} style={{ textAlign: "left" }} onClick={() => { signout() }}>
            <span>Çıkış Yap</span>
          </Menu.Item>
        ) : (
          <Menu.Item key={"login"} style={{ textAlign: "left" }}>
            <Link style={{textDecoration:"none"}} to="/login">Giriş Yap</Link>
          </Menu.Item>
        )}
        </Menu>
      )}
    </div>
  );
};

export default WebMenu;


