import React from 'react';
import { Button, Form, Input, Select,Card } from 'antd';
import NotificationService from "../../services/antNotificationService";
import './training.css';

const { Option } = Select;

const onFinish = (values) => {
    NotificationService.openSuccessNotification({title:'',description:'Eğitim Talebiniz Gönderildi',placement:'topRight'})

   // console.log('Success:', values);
  };

const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
};

const data = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2' },
    { value: '3', label: 'Option 3' },
  ];
const TrainingRequest = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
      }}
    >
        <Card style={{borderRadius:0, border: "none",width:"60%" }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h3  style={{ textAlign: 'left',color:"#1A446C",minWidth: "-webkit-fill-available",marginBottom:"50px"}}>Eğitim Talebi Oluştur</h3>
          <Form
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 20,
            }}
            style={{
            maxWidth: "100%",
            textAlign:"justify"
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="horizontal"        
          >
            <Form.Item  
            name="category"
              rules={[
                {
                  required: true,
                  message: 'Lütfen eğitim kategorisini seçiniz!',
                },
              ]}>
                <Select
                showSearch
                style={{
                width: 540,
                border:"1px solid",
                borderColor:"#1A446C",
                borderRadius:"7px"
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={[
                {
                    value: '1',
                    label: 'Not Identified',
                },
                {
                    value: '2',
                    label: 'Closed',
                },
                {
                    value: '3',
                    label: 'Communicated',
                },
                {
                    value: '4',
                    label: 'Identified',
                },
                {
                    value: '5',
                    label: 'Resolved',
                },
                {
                    value: '6',
                    label: 'Cancelled',
                },
                ]}
            />
            </Form.Item>
            <Form.Item  
            name="trainer"
              rules={[
                {
                  required: true,
                  message: 'Lütfen eğitimen seçiniz!',
                },
              ]}>
                <Select
                showSearch
                style={{
                width: 540,
                border:"1px solid",
                borderColor:"#1A446C",
                borderRadius:"7px"

                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={[
                {
                    value: '1',
                    label: 'Not Identified',
                },
                {
                    value: '2',
                    label: 'Closed',
                },
                {
                    value: '3',
                    label: 'Communicated',
                },
                {
                    value: '4',
                    label: 'Identified',
                },
                {
                    value: '5',
                    label: 'Resolved',
                },
                {
                    value: '6',
                    label: 'Cancelled',
                },
                ]}
            />
            </Form.Item>
            <Form.Item
              name="desc"
              rules={[
                {
                  required: true,
                  message: 'Lütfen açıklama giriniz!',
                },
              ]}
              wrapperCol={{
                span: 25,
              }}
            >
              <Input.TextArea rows={13} placeholder="Açıklama" style={{ width:"880",borderColor:"#1A446C" }} />
            </Form.Item>

            <Form.Item      
              style={{ float:"right" }}
            >
               
              <Button  htmlType="submit" style={{background:"#1A446C",color:"white"}}>
                Gönder
              </Button>
            </Form.Item>
          </Form>
          </div>
        </Card>
  </div>
);
export default TrainingRequest;