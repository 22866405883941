import React from 'react';
import PopularBlogs from './popularBlogs';
import BlogListWithCategory from './blogListWithCategory';
import BlogStories from './blogStories';

const Blogs = () => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px',marginLeft:"75px" }}>
    <PopularBlogs />
    <BlogListWithCategory />
</div>
);
export default Blogs;