import React, { useState, useEffect } from 'react';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import '../layouts.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import {  Row, Col } from 'antd';


import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';


const WebCampaigns = () => {
  const [campaignsData, setCampaignsData] = useState([]);
  const navigate = useNavigate();

  const goDetailPage = (record) => {
    navigate('/campaign-detail', { state: { record } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await FirebaseService.getCampaignDataFilter();

        const parsedData = data.map(item => ({
          ...item,
          releaseDate: new Date(item.releaseDate.split('.').reverse().join('-')),
        }));

        //console.log("Individual Dates (Before Sorting):", parsedData.map(item => item.releaseDate));
        const sortedData = parsedData.sort((a, b) => b.releaseDate - a.releaseDate);
       // console.log("Individual Dates (After Sorting):", sortedData.map(item => item.releaseDate));
        const closestDates = sortedData.slice(0, 3);
       // console.log("Closest Dates:", closestDates.map(item => item.releaseDate));

        setCampaignsData(closestDates);
      } catch (error) {
       // console.error('Kampanya verisi getirme hatası:', error);
      }
    };

    fetchData();
  }, []); 

  const AutoplaySlider = withAutoplay(AwesomeSlider);


  return (
    <div>
      <div style={{ color: '#1A446C' }}>
        <h3 style={{ textAlign: "justify", marginLeft: "15px" }}>Kampanyalar</h3>
        <div className="webnews-carousel-container" style={{marginRight:"40px"}}>
          {campaignsData.length === 0 ? (
            <p style={{ textAlign: 'justify', color: '#1A446C', marginLeft: '15px' }}>Kampanya bulunmamaktadır.</p>
          ) : (
            <Row >
            <Col span={35}>
              <div className="last-news-carausel" style={{cursor:"pointer"}}>
                {campaignsData.length === 0 ? (
                  <p style={{ textAlign: 'justify', color: '#1A446C', justifyContent:'center' }}>Kampanya bulunmamaktadır.</p>
                ) : (
                  <AutoplaySlider
                  play={true}
                  cancelOnInteraction={false} 
                  interval={3000}
                  style={{ width: '1050px', height: '300px', }}
                >
                  {campaignsData.map((item, index) => (
                    <div key={index}  onClick={() => goDetailPage(item)} style={{ backgroundColor:"white"}}>     
                        <img  style={{width: "1050px",height: "250px", objectFit:"cover", backgroundColor:"white"}} src={item.imageUrl} alt={item.title} /> 
                        <h2 className="last-news-card-title">{item.title}</h2>                   
                    </div>
                  ))}  
                </AutoplaySlider>
                )}
              </div>
            </Col>
          </Row>
       
           
          )}
        </div>
      </div>
    </div>
  );
  
};

export default WebCampaigns;
