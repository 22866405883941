import React from 'react';
import WebOurPartner from './dasboardPages/webOurPartners';
import WebOurServices from './dasboardPages/webOurServices';
import WebNumberOds from './dasboardPages/webNumberOds';
import WebOurInvestors from './dasboardPages/webOurInvestors';
import WebNews from './dasboardPages/webnews';
import WebCampaigns from './dasboardPages/webCampaigns';
import WebMenu from './webMenu';
import WebNavi from './webNavi';

const HomePage = () => (
 
            <div style={{ marginTop: '20px', marginLeft:"20px", paddingLeft:"50px" }}>
           
                  <WebNumberOds />
              
                  <WebNews />
              
              <WebOurServices />
              <div style={{ display: 'flex', marginTop:"150px" }}>
                <div>
                <WebOurPartner />
                </div>
                <div style={{marginLeft:"40px"}}>
                <WebOurInvestors />
                </div>
            </div>
            <div style={{ marginTop:"30px" }}>
            <WebCampaigns/>
            </div>
          </div>          
          
               
               
            
);
export default HomePage;