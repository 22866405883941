import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './layouts.css';

const items = [
  {
    label: 'İletişim',
    key: 'map',
    link: '/map'
  },
  {
    label: 'Danışmana Sor',
    key: 'askadvisor',
    link: '/askadvisor'
  },
  {
    label: 'Anketler',
    key: 'survey',
    link: '/survey'
  }
];

const WebContactNavi = () => {
  const location = useLocation();
  const [current, setCurrent] = useState('map');

  useEffect(() => {
    const path = location.pathname.split('/')[1];
   // console.log("pat",path)
    if(path == "contact"){
      setCurrent('map')
    }
    else{
      setCurrent(path || 'map');
    }
   
  }, [location.pathname]);

  const onClick = (e) => {
   // console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[current]}
      onClick={onClick}
      className="custom-menu"
    >
      {items.map((item) => (
        <Menu.Item key={item.key}>
          <Link style={{textDecoration:"none"}} to={item.link}>{item.label}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default WebContactNavi;
