import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import FirebaseService from '../services/firebaseService';
import { useParams, useLocation } from 'react-router-dom';


const TrainerDetail = () => {
    const { trainerId } = useParams();
    const location = useLocation();
    const { state } = location;
    const item = state && state.record;
    const [author, setAuthor] = useState(null);
  


  useEffect(() => {
    const fetchAuthorData = async () => {
      try {
       // console.log('Trainer ID için veri getiriliyor:', item.trainerId);
      
        const authorData = await FirebaseService.getTrainerDataByIdFilter(item.trainerId);

       
        if (authorData) {
          setAuthor(authorData);
        } else {
         // console.error('ID için yazar bulunamadı:', item.trainerId);
        }
      } catch (error) {
       // console.error('Yazar verisi getirme hatası:', error);
      }
    };

    fetchAuthorData();
  }, [item.trainerId]);
  


  return (
    <div style={{ margin: '3% 0 0 5%' }}>
      {author && (
        <Card style={{ width: 1000, border: 'none' }}>
          <div style={{ display: 'flex' }}>
          
{author.imageUrl ? (
              <div
                style={{
                  width: '230px',
                  height: '300px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={author.imageUrl}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ) : (
              <img
                src="/images/odslogo.png" 
                alt="ODS Logo"
                style={{
                  width: '230px',
                  height: '300px',
                  borderRadius: '10px',
                  objectFit: 'contain',
                }}
              />

            )}

            <div style={{ textAlign: 'start', marginLeft: '20px', marginTop: '25px' }}>
              <h4>{author.nameSurname}</h4>
            </div>
          </div>
          <p style={{ textAlign: 'start', marginTop: '8%' }}>
            {author.description}
          </p>
        </Card>
      )}
    </div>
  );
};

export default TrainerDetail;
