import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";

const BusinessDevelopmentList = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const ipm = (await FirebaseService.getIpmProjects()).map(project => ({ ...project, category: 'IPM' }));
      const ibm = (await FirebaseService.getIbmProjects()).map(project => ({ ...project, category: 'IBM' }));
      const sap = (await FirebaseService.getSapProjects()).map(project => ({ ...project, category: 'SAP' }));
      const cd = (await FirebaseService.getCdProjects()).map(project => ({ ...project, category: 'CD' }));
      const tq = (await FirebaseService.getTqProjects()).map(project => ({ ...project, category: 'TQ' }));
      let combinedData=[]
      const userId=localStorage.getItem("uid");
      const userType=localStorage.getItem('userType');
      

      const isAdvisor=userType.includes('advisor')
      combinedData = [...ipm, ...ibm,...sap,...cd,...tq];

      if (isAdvisor) {
        const advisors = await FirebaseService.getAdvisorData();
        const advisorsOption = advisors.filter(f=>!f.isDeleted && f.userId===userId);
        combinedData = combinedData.filter(item =>
          item.businessDevelopmentName.some(advisor => advisor.advisorId === advisorsOption[0].key)
        );
      }

      setTableData(combinedData)
    };
    fetchData();
  }, []);

  const goAddPage = () => {
    // navigate('/projectadd')
  };

  const goEditPage = (record) => {
    let editPageUrl = '/businessdevelopmentlist';
  
    switch (record.category) {
      case 'CD':
        editPageUrl = '/cdprojectedit';
        break;
      case 'SAP':
        editPageUrl = '/sapprojectedit';
        break;
      case 'TQ':
        editPageUrl = '/tqprojectedit';
        break;
      case 'IBM':
        editPageUrl = '/ibmprojectedit';
        break;
      case 'IPM':
        editPageUrl = '/ipmprojectedit';
        break;  
    }
  
    navigate(editPageUrl, { state: { record } });
  };

  const handleDelete = async (record, isDeleted) => {
    try {
      let result;
      if (isDeleted) {
        result =  await FirebaseService.makeProjectVisible(record.key);
      } else {
        result = await FirebaseService.deleteProject(record.key) ;
      }
  
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        const data = await FirebaseService.getProjects();
        const combinedData = [...data];
        setTableData(combinedData)
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
  };  

  const columns = [
     {
      title: 'Kullanıcı Adı Soyadı',
      dataIndex: 'userNameSurname',
      key: 'userNameSurname',
      width: 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Proje Adı',
      dataIndex: 'projectName',
      key: 'projectName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 400,
    },
    {
      title: 'Kategori',
      dataIndex: 'category',
      key: 'category',
      width: 400,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Danışman Adı',
      dataIndex: 'businessDevelopmentName',
      key: 'businessDevelopmentName',
      width: 400,
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
        {text?.map((nameObject, index) => (
          <React.Fragment key={index}>
            {nameObject.nameSurname}
            {index < text.length - 1 && ' - '}
          </React.Fragment>
        ))}
      </span>
      ),
    },
    {
      title: 'Detay',
      dataIndex: 'detail',
      key: 'detail',
      render: (text, record) => (
        <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => goEditPage(record)}>Detay</Button> 
     )},
  ];
  
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        
          <Table
            locale={{ emptyText: 'Henüz proje eklemediniz...' }}
            dataSource={tableData}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

    };
  export default BusinessDevelopmentList;