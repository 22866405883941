import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import AdminMenu from './adminMenu';
import { useNavigate, useLocation } from "react-router-dom";
import NotificationService from "../services/antNotificationService";
import { adminMenuLogin, advisorMenuLogin } from "../environments/environment";
import WebMenu from "../web/layouts/webMenu";
import WebNavi from "../web/layouts/webNavi";

const PrivateComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isUser, setIsUser] = useState(false);
  const [isAdminPanel, setAdminPanel] = useState(false);
  const auth = localStorage.getItem('accessToken');
  const userType = localStorage.getItem('userType');
  const [selectedMenu, setSelectedMenu] = useState(null);

  useEffect(() => {
    if (auth && userType) {
      if (userType.includes('headAdmin')) {
        setAdminPanel(true);
        setSelectedMenu("adminpanel");
      } else if (userType.includes('admin')) {
        var pages = localStorage.getItem('authorizationPages');
        const filteredMenu = adminMenuLogin.filter((item) => pages.includes(item.value));
        setAdminPanel(true);
        if (filteredMenu.length > 0) {
          setSelectedMenu(filteredMenu[0].value);
        }
      } else if (userType.includes('advisor')) {
        setAdminPanel(true);
        var pages = localStorage.getItem('authorizationPages');
        const filteredMenu = advisorMenuLogin.filter((item) => pages.includes(item.value));
        if (filteredMenu.length > 0) {
          setSelectedMenu(filteredMenu[0].value);
        }
      } else if (userType.includes("user")) {
        setIsUser(true);
        setSelectedMenu("home");
      } else {
        localStorage.clear();
        NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: 'Yetki bilgisi alınamadı', placement: 'topRight' });
      }
    }
    else {
      // Kullanıcı oturum açmamışsa veya yetki bilgisi alınamamışsa login sayfasına yönlendir.
      navigate('/login');
    }
  }, [auth, userType]);

  const handleMenuClick = (menuValue) => {
    setSelectedMenu(menuValue);
  };

  return (
    <>
      {auth && !isUser && isAdminPanel && (
        <div style={{ display: "flex" }}>
          <AdminMenu style={{ flex: '0 0 auto', width: 256, overflowY: 'auto', display: 'flex' }} selectedMenu={selectedMenu} onMenuClick={handleMenuClick} />
          <div style={{ flex: '1', padding: '20px' }}>
            <Outlet />
          </div>
        </div>
      )}

      {isUser && (
        <div style={{ display: "flex" }}>
          <WebMenu selectedMenu={selectedMenu} onMenuClick={handleMenuClick} />
          <div style={{ flex: '1', padding: '20px' }}>
            <WebNavi />
            <div style={{ marginTop: '20px' }}>
              <Outlet />
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default PrivateComponent;


