import React, { useEffect, useState } from 'react';
import { Card, Button, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from '../../services/firebaseService';
import moment from 'moment';
import NotificationService from "../../services/antNotificationService";

const Survey = () => {
  const [survey, setSurvey] = useState([]);
  const navigate = useNavigate();
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    fetchData();
  }, [isFinished]);

  const fetchData = async () => {
    const currentDate = new Date();
    try {
      const currentUser = { id: localStorage.getItem("uid") }; 
      const data = await FirebaseService.getSurveyData();
      const updatedData = data.map(record => {
        const startDate = new Date(record.startDateEdit);
        const endDate = new Date(record.endDateEdit);
        let status = "";

        if (currentDate < startDate) {
          status = "Yayınlanmadı";
        } else if (currentDate > endDate) {
          status = "Tamamlandı";
        } else if (currentDate >= startDate && currentDate <= endDate) {
          status = "Yayında";
        } else {
          status = "Bilgi Alınamadı";
        }

      
        const isCurrentUserDisabled = Array.isArray(record.users) &&
          record.users.includes(currentUser.id);

        return { ...record, status, isCurrentUserDisabled };
      });

      const filteredSurvey = isFinished
        ? updatedData.filter(record => record.status === "Tamamlandı")
        : updatedData;

      setSurvey(filteredSurvey);
    } catch (error) {
      console.error(error);
    }
  };

  const goDetailPage = (record) => {
    if (record.isCurrentUserDisabled) {
      NotificationService.openErrorNotification({
        title: 'Detaya Yönlendirilemiyorsunuz.',
        description: 'Bu ankete daha önce katıldınız.',
        placement: 'topRight'
      });
    } else if (record.status === "Tamamlandı") {
      NotificationService.openErrorNotification({
        title: 'Anket Tamamlandı.',
        description: 'Bu anket tamamlandığı için detaylarına gidemezsiniz.',
        placement: 'topRight'
      });
    } else {
      navigate('/surveydetail', { state: { record } });
    }
  };
  
  


  const calculateProgress = (startDate, endDate) => {
    const currentDate = moment();
    const start = moment(startDate);
    const end = moment(endDate);
    const totalDays = end.diff(start, 'days');
    const elapsedDays = currentDate.diff(start, 'days');
    const progressPercent = (elapsedDays / totalDays) * 100;
    return progressPercent.toFixed(2);
  };
  

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
       alignItems: 'center',
      }}
    >
        <div style={{ marginBottom: '16px' }}>
       
        <Button
          onClick={() => setIsFinished(false)}
          style={{
            background: 'transparent',
            backgroundColor : !isFinished ? '#1A446C' : 'white',
            borderColor: !isFinished ?  '#1A446C' : '#1A446C',
            color: !isFinished ?  'white' : '#1A446C',
            fontWeight: 'bold',
            borderRadius: '0px',
            width:"200px",
            height:"50px"
          }}
        >
          Devam Eden Anketler
        </Button>
        <Button
          onClick={() => setIsFinished(true)}
          style={{
            background: 'transparent',
            backgroundColor :  isFinished ? '#1A446C' : 'white',
            borderColor: isFinished ? '#1A446C' : '#1A446C',
            color: isFinished ? 'white' : '#1A446C',
            fontWeight: 'bold',
            borderRadius: '0px',
            width:"200px",
            height:"50px"
          }}
        >
          Bitmiş Anketler
        </Button>
      </div>

      {survey.map((record) => (
         <Card
         key={record.key}
         title={<span style={{ fontWeight: 'bold', color:"#A47B5A"}}>{record.title}</span>}
         style={{ width: "80%", margin: '16px', textAlign: 'left', backgroundColor:(record.isCurrentUserDisabled || record.status === "Tamamlandı") ?  'lightgray' : 'white' }}
         extra={<p style={{ color:"#1A446C80"}}>{record.endDate}</p>}
       >
          <div style={{justifyContent:"space-between", display:"flex", padding:"20px"}}>
            <div  style={{display:"flex"}}>
              <img src="/images/survey.png" alt="Image" style={{ width: '100px', height: '100px' }} />

            <div style={{marginLeft:"20px"}}>
              <p>Oluşturan: {record.firstUserNameSurname}</p>
              <p>Soru Adedi: {record.questions.length}</p>
              </div>
            </div>
            <div>
           
            <Button
          style={{ backgroundColor: (record.isCurrentUserDisabled || record.status === "Tamamlandı") ? 'lightgray' : 'white', border: "none" }}
          onClick={() => goDetailPage(record)}
          icon={<img alt="plus" src="/images/rigtharrow.png" style={{ width: '100%' }} />}
        />




            </div>
          </div>
          <Progress showInfo={false} strokeColor="#A47B5A" percent={calculateProgress(record.startDate, record.endDate)} status="active" />

        </Card>
      ))}
      {survey.length === 0 && <p>Henüz anket bulunmamaktadır.</p>}
    </div>
  );
};

export default Survey;
