import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select, Checkbox } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import { Calendar, theme } from 'antd';
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections,categoryName}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import FirebaseStorage from "../../services/storegeService";
import dayjs from 'dayjs';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


const dateFormat = 'DD/MM/YYYY';

const onPanelChange = (value, mode) => {
 // console.log(value.format('YYYY-MM-DD'), mode);
};

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const { Option } = Select;


const CampaignEdit = () => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [authors, setAuthors] = useState([]);
    const [category, setCategory] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const record = location.state && location.state.record;
    const [fileList, setFileList] = useState([]);
    const [newFileList, setNewFileList] = useState([]);

    const [showCalendar, setShowCalendar] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: record.imageUrl,
          }
        ]);
      };
      fetchData();
    }, []);

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => {
      setFileList(newFileList)
      setNewFileList(newFileList)
    };

    const uploadButton = (
      <div style={{width:"1000px" }}>
        <img alt="plus" src="/images/uploadplus.png" />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Resim Yükle
        </div>
      </div>
      );   
      
      const [editorContent, setEditorContent] = useState(record.description);
      
      const handleEditorChange = (content) => {
        console.log("cotent",content)
        setEditorContent(content);
      };

    const onFinish = async (values) => {
      console.log("editor",editorContent)

      const currentDate =new Date()
      const campaignData = {
        firstTransactionDate: record.firstTransactionDateEdit??currentDate,
        firstUserId: record.firstUserId??localStorage.getItem("uid"),
        lastTransactionDate: currentDate,
        lastUserId: localStorage.getItem("uid"),
        isDeleted: record.isDeleted,
        description: editorContent,
        title: values.title,
        releaseDate: values.releaseDate === undefined ? record.releaseDateEdit: new Date(values.releaseDate),
        companyName:values.companyName
      };

      values.image= newFileList.length > 0 ? values.image : fileList.length>0 ? record.imageUrl : null;
        
        if(values.image===null){
          NotificationService.openErrorNotification({
            title: 'Geçersiz Resim',
            description: "Lütfen resim seçiniz!",
            placement: 'topRight'
          });}
        try {
          if (newFileList.length>0) {
            campaignData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(odsCollections.campaigns, values.image.file);}
          const result = await FirebaseService.updateCampaign(record.key,campaignData);
          if (result.success) {
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });
            setTimeout(() => {
              navigate("/campaignlist");
            }, 2000);
          } else {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });}
          
          }
        catch (error) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
        }
      
    };
   return ( <div>
    
         <Form
            name="basic"
            initialValues={{
              title: record.title ,
              description:record.description,
              release:true,
              companyName:record.companyName
            }}
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
          >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="generic-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>               
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
                    </Modal>
          </Space>

            <Form.Item
            name="title"
            rules={[
                {
                required: true,
                message: 'Lütfen kampanya başlığını giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
            <Input style={{border:0}} placeholder="Başlık"/>
            </Form.Item>

            <Form.Item
            name="companyName"
            rules={[
                {
                required: true,
                message: 'Lütfen firma ismini giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
            <Input style={{border:0}} placeholder="Firma"/>
            </Form.Item>

            <Form.Item
            name="description"
           /* rules={[
                {
                required: true,
                message: 'Lütfen kampanya içeriğini giriniz!',
                },
            ]}*/
            >
            <SunEditor
              setContents={editorContent}
              onChange={handleEditorChange}
              placeholder="Kampanya İçeriği"
              setOptions={{
                buttonList: [
                  ['undo', 'redo'],
                  ['font', 'fontSize', 'formatBlock'],
                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                  ['removeFormat'],
                  ['fontColor', 'hiliteColor'],
                  ['outdent', 'indent'],
                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                  ['link'],
                  ['fullScreen', 'showBlocks', 'codeView'],
                ],
              }}
           />           
            </Form.Item>

            <Form.Item
              name="release"
              valuePropName="checked"
              wrapperCol={{
                span: 4,
                }}
                style={{
                maxWidth: "100%",
                }}>
              <Checkbox checked={showCalendar} onChange={() => setShowCalendar(!showCalendar)} style={{fontWeight:"500",color:"#1A446C"}}>
                İleri Tarihte Yayımla
              </Checkbox>
            </Form.Item>
          {showCalendar && (
            <Form.Item 
            name="releaseDate"
            >            
              <Calendar fullscreen={false} onPanelChange={onPanelChange} defaultValue={dayjs(record.releaseDate, dateFormat)} />
            </Form.Item>
            )} 
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default CampaignEdit;