import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const StoryList = () => {
  const [stories, setStories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getStoryData();
      data.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
      setStories(data)
    };

    fetchData();
  }, []);


  const handleDelete = async (record, isDeleted) => {
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makeStoryVisible(record.key);
      } else {
        result = await FirebaseService.deleteStory(record.key);
      }
  
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        const data = await FirebaseService.getStoryData();
        setStories(data)
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
  };

  const goEditPage = (record) => {
    navigate('/storyedit', { state: { record } });
  };

  
  const columns = [
    {
        title: 'Resim',
        dataIndex: 'imageUrl',
        key: 'imageUrl',
        render: (img,record) => <img src={img} width={50} style={{opacity: record.isDeleted ? "60%" : null}}/>,
        width: 150,
      },
      {
        title: 'Kategori',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) => (
          <>
            {record.categoryName === 'blog' && <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Blog</span>}
            {record.categoryName === 'new' && <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Haber</span>}
          </>
        ),
        width: 550,
      },  
    {
      title: 'Son Yayın Tarihi',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 450,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Gizle/Göster',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        
          record.isDeleted ?    
           <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
          :
          <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Gizle</Button>
      ),
    },
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
         <a type="link" onClick={()=>goEditPage(record)}  
        style={{
          border: "none",
          backgroundColor: "transparent",
          pointerEvents: record.isDeleted ? "none" : "auto", 
          opacity: record.isDeleted ? 0.5 : 1, 
        }}><img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
      ),
    }
  ];

  const goAddPage = () => {
    navigate('/storyadd')
  };

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()}  icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} style={{background:"transparent",borderColor:"#1A446C80",color:"#1A446C",fontWeight:"bold",borderRadius:"0px",float:"right"}}>
          Yeni Ekle
        </Button>
          <Table
            locale={{ emptyText: 'Henüz story eklemediniz.' }}
            dataSource={stories}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default StoryList;


  /*
 {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
      render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 400,
    },
  */