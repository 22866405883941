import React,{useEffect,useState} from "react";
import { Table, Button,Input,Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import { SearchOutlined } from '@ant-design/icons';


const PurchaseRequestList = () => {
  const [PurchaseRequests, setPurchaseRequests] = useState([]);
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getPurchaseRequestData();
      data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

      const updatedData = data.map(item => ({
        ...item,
        type: item.type === 'campaigns' ? 'Kampanya' : 
              item.type === 'service' ? 'Hizmet' :
              item.type === 'education' ? 'Eğitim' :
              item.type
      }));

      console.log("updatedData", updatedData);
      setPurchaseRequests(updatedData);
      setFilteredItems(updatedData);
    };

    fetchData();
  }, []);


  const handleDelete = async (record, isDeleted) => {
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makePurchaseRequestVisible(record.key);
      } else {
        result = await FirebaseService.deletePurchaseRequest(record.key);
      }
  
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        const data = await FirebaseService.getPurchaseRequestData();
        setPurchaseRequests(data)
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = PurchaseRequests.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(PurchaseRequests);
  };

  const placeholderMap = {
    userNameSurname: 'Talep Eden',
    type: 'Kategori',
    title: 'Başlık',
  };

  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  
  const columns = [
    {
      title: 'Talep Eden',
      dataIndex: 'userNameSurname',
      key: 'userNameSurname',
      width: 300,
      ...getColumnSearchProps('userNameSurname'),

      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
  {
    title: 'Kategori',
    dataIndex: 'type',
    key: 'type',
    width: 200,
    ...getColumnSearchProps('type'),
    render: (text, record) => (
      <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
        {text}
      </span>
    ),
  },
  
    {
      title: 'Başlık',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title'),
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 400,
    },
    {
      title: 'Oluşturulma Tarihi',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 300,
      sorter: (a, b) => {
        const dateA = new Date(a.createdDate.split('.').reverse().join('/')).getTime();
        const dateB = new Date(b.createdDate.split('.').reverse().join('/')).getTime();
        return dateA - dateB;
      },
      
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>
      ),
    }
    
    // {
    //   title: 'Delete',
    //   dataIndex: 'delete',
    //   key: 'delete',
    //   render: (text, record) => (
        
    //       record.isDeleted ?    
    //        <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
    //       :
    //       <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Gizle</Button>
    //   ),
    // }
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
          <Table
            locale={{ emptyText: 'Henüz satın alma talebi bulunmamaktadır.' }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={{
              pageSizeOptions: ['10', '20', '50', '100'], 
              showSizeChanger: true, 
              showQuickJumper: true, 
              showTotal: (total, range) => `Toplam ${total} kayıt, ${range[0]}-${range[1]} arası gösteriliyor`,
              pageSize: 10, 
              onChange: (page, pageSize) => {
                console.log('Page:', page, 'PageSize:', pageSize);
              },
              onShowSizeChange: (current, size) => {
                console.log('Current:', current, 'Size:', size);
              },
              position: ['bottomCenter'] 
            }}
            className="custom-news-table"
            rowClassName="custom-news-row"
            expandable={{
              expandedRowRender: record => (
                <div style={{ display: 'flex' }}>
                <div style={{ flex: 0.5,marginLeft:"55px"}}>
                  <span style={{fontWeight:"bold"}}>Email:</span>
                 <p style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.email}</p>
                 </div>
                 <div style={{ flex: 0.5,marginLeft:"55px"}}>
                 <span style={{fontWeight:"bold"}}>Telefon Numarası:</span>
                 <p style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.phoneNumber}</p>
                 </div>
                <div style={{ flex: 1,marginLeft:"55px" }}>
                <span style={{fontWeight:"bold"}}>Not:</span>
                 <p style={{color: record.isDeleted ? "#00000080" : "#000000", width:"500px"}}>{record.note}</p>
                </div>
            </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
                ) : (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
                ),
                expandIconColumnIndex: 5
            }}


        />
    </div>

  };
  export default PurchaseRequestList;