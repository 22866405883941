import React, { useState,useEffect } from 'react';
import { Menu } from 'antd';
import './layouts.css';
import { Link, useLocation } from 'react-router-dom';

const items = [
  {
    label: '',
    key: 'home',
    link:'/home',
    imgSrc: '/images/home.png'
  },
  {
    label: 'Hizmetler',
    key: 'services',
    link:'/servicelist'
  },

  {
    label: 'Kampanyalar',
    key: 'campaigns',
    link:'/campaigns'
  },

  {
    label: 'Akademi',
    key: 'trainings',
    link:'/traininglist'
  },

    {
      label: 'Öne Çıkanlar',
      key: 'news',
      link:'/newslist'
    },
   
  
    {
      label: 'Blog',
      key: 'blogs',
      link:'/bloglist'
    },
    
   
  ];
const WebNavi = () => {
  const location = useLocation();
    const [current, setCurrent] = useState('home');


  useEffect(() => {
    const path = location.pathname.split('/')[1];
   // console.log("pat",path)
    if(path == "home"){
      setCurrent('home')
    }
    else{
      setCurrent(path || 'home');
    }
   
  }, [location.pathname]);

    const onClick = (e) => {
      setCurrent(e.key);
    };
    
    return (
    <Menu
      mode="horizontal"
      selectedKeys={[current]}
      onClick={onClick}
      className="custom-menu"
      
    >
     {items.map((item) => (
        <Menu.Item key={item.key}>      
          {item.imgSrc && <img src={item.imgSrc} alt={item.label} style={{ position: "relative", left:"5px",bottom:"4px", width: 25, height: 20 }} />}
          <Link to={item.link} style={{textDecoration:"none"}}>{item.label}</Link>
        </Menu.Item>
      ))}

    </Menu>
   );
  };
export default WebNavi;