import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select, Checkbox } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import FirebaseService from "../../services/firebaseService";
import  {map}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { useLocation  } from 'react-router-dom';
import { EnvironmentFilled  } from '@ant-design/icons';

const onPanelChange = (value, mode) => {
 // console.log(value.format('YYYY-MM-DD'), mode);
};

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };
  

  const { Option } = Select;

    const handleChangeSelect = (value) => {
    // console.log(`selected ${value}`);
    };

const AddressEdit = () => {

    const [markerPosition, setMarkerPosition] = useState(null);
    const [global, setGlobal] = useState(false);
    const location = useLocation();

    const record = location.state && location.state.record;

    const handleMapClick = ({ lat, lng }) => {
      
      setMarkerPosition({ lat, lng });
      //console.log("Clicked on the map at:", lat, lng);
    };

    const navigate = useNavigate();

    const AnyReactComponent = ({ lat, lng, onClick }) => (
      <div className="marker-container" onClick={onClick}>
        {markerPosition && (
            <EnvironmentFilled style={{ fontSize: '40px', color: '#1A446C' }} />  
              )}
      </div>
    );
    
      const defaultProps = {
        center: {
          lat: 38.433791,
          lng: 27.140573,
        },
        zoom: 17,
      };

      useEffect(() => {
        //console.log("Setting marker position:", record.latitude, record.longitude);
        setGlobal(record.global)
        setMarkerPosition({ lat: record.latitude, lng: record.longitude })
      }, []);
      
   

      const onFinish = async (values) => {
        const currentDate =new Date()
        const addressData = {
          firstTransactionDate: record.firstTransactionDateEdit??currentDate,
          firstUserId: record.firstUserId??localStorage.getItem("uid"),
          isDeleted: record.isDeleted,
          lastTransactionDate: currentDate,
          lastUserId: localStorage.getItem("uid"),
          address: values.address,
          corporateLine: values.corporateLine,
          corporateEmail: values.corporateEmail,
          city: values.city,
          country: values.country,
          global: global,
          latitude: markerPosition.lat,
          longitude: markerPosition.lng
        };
        try {
          const result = await FirebaseService.updateAddress(record.key,addressData);
          if (result.success) {
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });
            setTimeout(() => {
              navigate("/address");
            }, 2000);
          } else {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });}
          
          }
         catch (error) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
        }
    }; 

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
              address: record.address ,
              city:record.city,
              country:record.country,
              corporateLine:record.corporateLine,
              corporateEmail:record.corporateEmail,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
              <Form.Item name="map">
          <div style={{ width: '100%', height: '300px' }}>
            <GoogleMapReact
        bootstrapURLKeys={{ key: map.apiKey }}      
        defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              center={markerPosition || defaultProps.center}
              onClick={handleMapClick}
            >
              
              <AnyReactComponent
                lat={markerPosition?.lat || record.latitude}
                lng={markerPosition?.lng || record.longitude}
                onClick={() => {}}
              />
            </GoogleMapReact>
          </div>
        </Form.Item>
            <Form.Item
            name="address"
            rules={[
                {
                required: true,
                message: 'Lütfen adresi giriniz!',
                },
            ]}
            >
            <Input.TextArea rows={10} style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Adres Girin"/>
            </Form.Item>

            <Form.Item
            name="corporateLine"
            rules={[
                {
                required: true,
                message: 'Lütfen telefon numarası giriniz!',
                },
            ]}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input  onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                   style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Telefon Numarası"/>
            </Form.Item>

            <Form.Item
            name="corporateEmail"
            rules={[
                {
                type:"email",
                required: true,
                message: 'Lütfen mail adresini giriniz!',
                },
            ]}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Mail Adresi"/>
            </Form.Item>

            <Form.Item
              name="global"
              wrapperCol={{
                span: 1,
                }}
                style={{
                maxWidth: "100%",
                }}>
              <Checkbox checked={global} onChange={() => setGlobal(!global)} style={{fontWeight:"500",color:"#1A446C"}}>
                Global
              </Checkbox>
            </Form.Item>

            <Form.Item
            name="country"
            rules={[
                {
                required: true,
                message: 'Lütfen ülke giriniz!',
                },
            ]}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Ülke"/>
            </Form.Item>

            <Form.Item
            name="city"
            rules={[
                {
                required: true,
                message: 'Lütfen şehir giriniz!',
                },
            ]}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Şehir"/>
            </Form.Item>



            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default AddressEdit;