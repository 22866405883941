import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import FirebaseService from "../../services/firebaseService";
import OneSignalService from "../../services/oneSignalService";
import  {odsCollections, categoryName, allCities}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";

const onPanelChange = (value, mode) => {
 // console.log(value.format('YYYY-MM-DD'), mode);
};

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };
  


  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const { Option } = Select;

    const handleChangeSelect = (value) => {
     // console.log(`selected ${value}`);
    };

const NotificationAdd = () => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [userType, setUserType] = useState([]);
    const [cities, setCities] = useState([]);
    const [category, setCategory] = useState([]);
    const [users, setUsers] = useState([]);
    const [cd, setcd] = useState([]);
    const [tq, settq] = useState([]);
    const [sap, setsap] = useState([]);
    const [ibm, setibm] = useState([]);
    const [ipm, setipm] = useState([]);

    const [oneSignalIds, setOneSignalIds] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
          setCities(allCities);

        const categoryOptions = [{
          value: "CD",
          label: "CD",
        },
        {
          value: "TQ",
          label: "TQ",
        },
        {
          value: "SAP",
          label: "SAP",
        },
        {
          value: "IBM",
          label: "IBM",
        },
        {
          value: "IPM",
          label: "IPM",
        },
      ];
        setCategory(categoryOptions)
      };
      const fetchUserData = async () => {
        const data = await FirebaseService.getUserData();
        const undeletedUsers = data.filter(user => !user.isDeleted)
        setUsers(undeletedUsers);
      };
      const fetchCdData = async () => {
        const data = await FirebaseService.getCdProjects();
        const undeletedProjects = data.filter(project => !project.isDeleted)
        setcd(undeletedProjects);
      };
      const fetchTqData = async () => {
        const data = await FirebaseService.getTqProjects();
        const undeletedProjects = data.filter(project => !project.isDeleted)
        settq(undeletedProjects);
      };
      const fetchSapData = async () => {
        const data = await FirebaseService.getSapProjects();
        const undeletedProjects = data.filter(project => !project.isDeleted)
        setsap(undeletedProjects);
      };
      const fetchIbmData = async () => {
        const data = await FirebaseService.getIbmProjects();
        const undeletedProjects = data.filter(project => !project.isDeleted)
        setibm(undeletedProjects);
      };
      const fetchIpmData = async () => {
        const data = await FirebaseService.getIpmProjects();
        const undeletedProjects = data.filter(project => !project.isDeleted)
        setipm(undeletedProjects);
      };
      fetchUserData();
      fetchData();
      fetchTqData();
      fetchCdData();
      fetchIbmData();
      fetchIpmData();
      fetchSapData();
    }, []);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const uploadButton = (
      <div style={{width:"1000px" }}>
      <img alt="plus" src="/images/uploadplus.png" />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Resim Yükle
      </div>
    </div>
      );

      const onFinish = async (values) => {
        const currentDate =new Date()
        const notificationData = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          description: values.description,
          title: values.title,
          city:values.cityName??null,
          productName:values.productName??null
        };

        // values.image=fileList.length > 0 ? values.image : null;
        let user=[];
        if(values.cityName!==undefined)
          {
             user = users.map(user =>
                  {
                    if(user.city!=undefined)
                    {
                      const result = user.city.includes(values.cityName)
                        if(result)
                      {
                        return {oneSignalId:user?.oneSignalPlayerId,userId:user.key}
                      }
                    }
                  }
                );
          }
          else{
            user = users.map(user =>
              {       
                return {oneSignalId:user?.oneSignalPlayerId,userId:user.key}
              }
            );
          }
        
            const controlUsers = user.filter(f=>f!==undefined);
            let sendUser = []
            
            if (values.productName === "TQ") {
              sendUser = controlUsers
                .filter(u => {
                  const matchingTQ = tq.find(t => t.userId.includes(u.userId));
                  if(matchingTQ!==undefined)
                  {
                    return u;
                  }
                });     
          }
          else if(values.productName==="CD")
          {
            sendUser = controlUsers
                .filter(u => {
                  const matchingTQ = cd.find(t => t.userId.includes(u.userId));
                  if(matchingTQ!==undefined)
                  {
                    return u;
                  }
                });  
          }
          else if(values.productName==="SAP")
          {
            sendUser = controlUsers
                .filter(u => {
                  const matchingTQ = sap.find(t => t.userId.includes(u.userId));
                  if(matchingTQ!==undefined)
                  {
                    return u;
                  }
                });  
          }
          else if(values.productName==="IBM")
          {
            sendUser = controlUsers
                .filter(u => {
                  const matchingTQ = ibm.find(t => t.userId.includes(u.userId));
                  if(matchingTQ!==undefined)
                  {
                    return u;
                  }
                });  
          }
          else if(values.productName==="IPM")
          {
            sendUser = controlUsers
                .filter(u => {
                  const matchingTQ = ipm.find(t => t.userId.includes(u.userId));
                  if(matchingTQ!==undefined)
                  {
                    return u;
                  }
                });  
          }
          else{
           sendUser=controlUsers;
          }
        if(sendUser!==undefined){
          try {
            const oneSignalIdUsers = sendUser
            .filter(f => f.oneSignalId !== undefined)
            .map(f => f.oneSignalId);

          const userIds = sendUser.map(f=>{return f.userId});
           const oneSignalResult = await OneSignalService.sendNotification(notificationData.title, notificationData.description,oneSignalIdUsers)
          notificationData.sentUserIds = userIds;  
            // if (values.image) {
            //   notificationData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(odsCollections.notifation, values.image.file);
           // console.log(notificationData);
            const result = await FirebaseService.addRecording(odsCollections.notifation,notificationData);
           // console.log(result);
            if (result.success) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: result.message,
                placement: 'topRight'
              });
              setTimeout(() => {
                navigate("/notifications");
              }, 2000);
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });}
            
            // }else{
            //   NotificationService.openErrorNotification({
            //     title: !values.image ? 'Geçersiz Resim': 'Geçersiz Kullanıcı Grubu',
            //     description: !values.image ? "Lütfen resim seçiniz!":"Lütfen gönderilecek grupları seçiniz.",
            //     placement: 'topRight'
            //   });
            }
           catch (error) {
           // console.log(error);
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
          }
          }
          else{
               NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Kullanıcı bilgileri alınamadı.",
                placement: 'topRight'
              });
          }
      
    }; 

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        {/* <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
            rules={[
              {
              required: true,
              message: 'Lütfen resim seçiniz!',
              },
          ]}
            >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="generic-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
            </Modal>
          </Space> */}
            <Form.Item
            name="title"
            rules={[
                {
                required: true,
                message: 'Lütfen bildirim başlığını giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
            <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Bildirim Başlığı"/>
            </Form.Item>

            <Form.Item
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen bildirim içeriğini giriniz!',
                },
            ]}
            >
            <Input.TextArea rows={10} style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Bildirim"/>
            </Form.Item>

            <div className="news-Edit-container">
            <Form.Item 
            name="productName"
            wrapperCol={{
              span: 7,
              }}
              style={{
              maxWidth: "100%",
              }}
            > 
                <Select
                      placeholder="Ürün Seç (Zorunlu değildir.)"
                      style={{borderRadius:0, borderColor:"#1A446C80"}}
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      options={category}
                    />                
            </Form.Item>
              </div>

            <div className="news-Edit-container">
            <Form.Item 
            name="cityName"
            wrapperCol={{
              span: 7,
              }}
              style={{
              maxWidth: "100%",
              }}
            > 
              <Select
                placeholder="Gönderilecek Lokasyon Seç (Zorunlu değildir.)"
                style={{borderRadius:0, borderColor:"#1A446C80"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                onChange={handleChangeSelect}
                options={cities}
              />
            </Form.Item>
              </div>

            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default NotificationAdd;