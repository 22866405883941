import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select, Checkbox } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import { Calendar } from 'antd';
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections,categoryName}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";
import dayjs from 'dayjs';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


const dateFormat = 'DD/MM/YYYY';

const onPanelChange = (value, mode) => {
  //console.log(value.format('YYYY-MM-DD'), mode);
};

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const { Option } = Select;


const TrainingEdit = () => {

    // const [previewOpen, setPreviewOpen] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    // const [previewTitle, setPreviewTitle] = useState('');
    const [trainers, setTrainers] = useState([]);
    const [category, setCategory] = useState([]);
    const location = useLocation();
    // const [newFileList, setNewFileList] = useState([]);
    const navigate = useNavigate();

    const record = location.state && location.state.record;

    const [fileList, setFileList] = useState([]);
    const [showCalendar, setShowCalendar] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        const data = await FirebaseService.getTrainerUnDeleted();
        const trainerOptions = data.map(trainer => ({
          value: trainer.key,
          label: trainer.nameSurname,
        }));
        setTrainers(trainerOptions);

        const categories = await FirebaseService.getCategoryData(categoryName.education);
        const categoryOptions = categories.map(category => ({
          value: category,
          label: category,
        }));
        setCategory(categoryOptions)
        // setFileList([
        //   {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: record.imageUrl,
        //   }
        // ]);
      };
      fetchData();
    }, []);

    // const handleCancel = () => setPreviewOpen(false);
    // const handlePreview = async (file) => {
    //   if (!file.url && !file.preview) {
    //     file.preview = await getBase64(file.originFileObj);
    //   }
    //   setPreviewImage(file.url || file.preview);
    //   setPreviewOpen(true);
    //   setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    // };
    // const handleChange = ({ fileList: newFileList }) => {
    //   setFileList(newFileList)
    //   setNewFileList(newFileList)
    // };
    // const uploadButton = (
    //   <div style={{width:"1000px" }}>
    //     <img alt="plus" src="/images/uploadplus.png" />
    //     <div
    //       style={{
    //         marginTop: 8,
    //       }}
    //     >
    //       Resim Yükle
    //     </div>
    //   </div>
    //   );

    const onFinish = async (values) => {
      console.log("editor",editorContent)

      const currentDate =new Date()
      const trainingData = {
        firstTransactionDate: record.firstTransactionDateEdit??currentDate,
        firstUserId: record.firstUserId??localStorage.getItem("uid"),
        lastTransactionDate: currentDate,
        lastUserId: localStorage.getItem("uid"),
        isDeleted: record.isDeleted,
        description: editorContent,
        title: values.title,
        categoryName:values.categoryName,
        trainerId:values.trainer !==undefined ? values.trainer:null,
        trainerNameSurname:values.trainer !==undefined && values.trainer!==null ? trainers.find(trainer=>trainer.value===values.trainer).label : null,
        releaseDate: values.releaseDate === undefined ? record.releaseDateEdit: new Date(values.releaseDate),
        videoId:values.videoId,
        url:values.url??null
      };
      // values.image= newFileList.length > 0 ? values.image : fileList.length>0 ? record.imageUrl : null;
        // if(trainingData.releaseDate.getDate()< currentDate.getDate())
        // {
        //   NotificationService.openErrorNotification({
        //     title: 'Geçersiz Tarih',
        //     description: "Lütfen gelecekten bir tarih seçiniz.",
        //     placement: 'topRight'
        //   });
        //   return null;
        // }
        // if(values.image===null){
        //   NotificationService.openErrorNotification({
        //     title: 'Geçersiz Resim',
        //     description: "Lütfen resim seçiniz!",
        //     placement: 'topRight'
        //   });
        // }
        try {
          // if (newFileList.length>0) {
          //   trainingData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(odsCollections.education, values.image.file);}
          const result = await FirebaseService.updateTraining(record.key, trainingData);
          if (result.success) {
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });
            setTimeout(() => {
              navigate("/trainings");
            }, 2000);
          } else {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });}
          
        
        } catch (error) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
        }         
       };

       const validateURL = (rule, value, callback) => {
        if (value === undefined || value === null || value === '') {
          callback();
          return;
        }
      
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      
        if (!urlPattern.test(value)) {
          callback('Lütfen geçerli bir URL giriniz.');
          return;
        }
      
        callback();
      };
      const [editorContent, setEditorContent] = useState(record.description);
      
      const handleEditorChange = (content) => {
        console.log("cotent",content)
        setEditorContent(content);
      };
   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
              title: record.title ,
              description:record.description,
              trainer:record.trainerId,
              categoryName:record.categoryName,
              release:true,
              userType:record.userType,
              videoId:record.videoId,
              url:record.url
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            <Form.Item
            name="title"
            rules={[
                {
                required: true,
                message: 'Lütfen eğitim başlığını giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
            <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Başlık"/>
            </Form.Item>

            
            <Form.Item
            name="trainer"
            wrapperCol={{
              span: 3,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
           <Select
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                className="news-author"
                showSearch
                placeholder="Eğitmen Seçiniz"
                optionFilterProp="children"
                filterOption={filterOption}
                options={trainers}
              />
            </Form.Item>

            <Form.Item
            name="description"
          /*  rules={[
                {
                required: true,
                message: 'Lütfen eğitim içeriğini giriniz!',
                },
            ]}*/
            >
            <SunEditor
          setContents={editorContent}
          onChange={handleEditorChange}
          placeholder="Eğitim İçeriği"
          setOptions={{
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['removeFormat'],
              ['fontColor', 'hiliteColor'],
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'lineHeight'],
              ['link'],
              ['fullScreen', 'showBlocks', 'codeView'],
            ],
          }}
        />       
            </Form.Item>

            <Form.Item
            name="videoId"
            rules={[
                {
                required: true,
                message: 'Lütfen yotube idsini giriniz!',
                },
            ]}
            wrapperCol={{
              span: 5,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Youtube Id"/>
            </Form.Item>

            <Form.Item
            name="url"
            rules={[
              { validator: validateURL }
            ]}
            wrapperCol={{
              span: 5,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Url Ekle"/>
            </Form.Item>

            <div className="news-Edit-container">
            <Form.Item 
            name="categoryName"
            rules={[
                {
                required: true,
                message: 'Lütfen eğitim kategorisini seçiniz !',
                },
            ]}
            wrapperCol={{
              span: 5,
              }}
              style={{
              maxWidth: "100%",
              }}
            > 
                   <Select
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      placeholder="Kategori Seç"
                      style={{borderRadius:0, borderColor:"#1A446C80"}}
                      options={category}
                    />     
            </Form.Item>
            </div>


            <Form.Item
              name="release"
              valuePropName="checked"
              wrapperCol={{
                span: 4,
                }}
                style={{
                maxWidth: "100%",
                }}>
              <Checkbox checked={showCalendar} onChange={() => setShowCalendar(!showCalendar)} style={{fontWeight:"500",color:"#1A446C"}}>
                İleri Tarihte Yayımla
              </Checkbox>
            </Form.Item>
          {showCalendar && (
            <Form.Item 
            name="releaseDate"
            >            
              <Calendar fullscreen={false} onPanelChange={onPanelChange} defaultValue={dayjs(record.releaseDate, dateFormat)}/>                 
            </Form.Item> )}

            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default TrainingEdit;