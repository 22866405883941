import React, { useState, useEffect } from 'react';
import './campaign.css';
import {  Row, Col } from 'antd';
import FirebaseService from '../../services/firebaseService';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';


const PopularCampaigns = () => {
  const [campaignData, setCampaignData] = useState([]);
  const navigate = useNavigate(); 

  const fetchData = async () => {
    const data = await FirebaseService.getCampaignDataFilter(); 
    return data;
  };
  
  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchData();
        setCampaignData(fetchedData);
      } catch (error) {
        console.error('Veriyi ayarlama hatası:', error);
       
      }
    };

    getData();
  }, []);
 
  const sortedCampaigns = [...campaignData];
  sortedCampaigns.sort((a, b) => b.favoriteCount - a.favoriteCount);
  const topThreeCampaigns = sortedCampaigns.slice(0, 3);

  const goDetailPage = (record) => {
    navigate('/campaign-detail', { state: { record } });
  };
  const filteredAndSortedCampaignData = campaignData && campaignData.length > 0 ?
  campaignData
    .sort((a, b) => (b.favoriteUserId ? b.favoriteUserId.length : 0) - (a.favoriteUserId ? a.favoriteUserId.length : 0))
    .slice(0, 3)
  : [];

console.log("favrolisteuzunkampanya", filteredAndSortedCampaignData);

const AutoplaySlider = withAutoplay(AwesomeSlider);

  return (
    <div>
      <h3 style={{ marginBottom: '0px', textAlign: 'justify', color: '#1A446C' }}>Popüler Kampanyalar</h3>
      <Row>
        <Col span={35}>
          <div className='popular-campaign-carausel' style={{cursor:"pointer"}}>
            {topThreeCampaigns.length === 0 ? (
             <p style={{ textAlign: 'justify', color: '#1A446C', justifyContent:'center',marginTop: '20px' }}>Popüler Kampanya bulunmamaktadır.</p>
            ) : (

              <AutoplaySlider
              play={true}
              cancelOnInteraction={false} 
              interval={3000}
              style={{ width: '1050px', height: '300px', }}
            >
              {filteredAndSortedCampaignData.map((item, index) => (
                <div key={index}  onClick={() => goDetailPage(item)} style={{ backgroundColor:"white"}}>
                    <img  style={{width: "1050px",height: "250px", objectFit:"cover", backgroundColor:"white"}} src={item.imageUrl} alt={item.title} /> 
                    <h2 className="last-news-card-title">{item.title}</h2>             
                </div>
              ))}  
            </AutoplaySlider>


           
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PopularCampaigns;