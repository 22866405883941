import React from 'react';
import CampaignStories from './campaignStories';
import PopularCampaign from './popularCampaign';
import CampaignListWithCategory from './campaignListWithCategory';


const Campaign = () => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px',marginLeft:"75px" }}>
    <PopularCampaign />
    <CampaignListWithCategory />
</div>
);
export default Campaign;