import React,{useState} from "react";
import { Button, Form, Space, Input } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections}  from "../../environments/environment"
import {useNavigate} from "react-router-dom"
import FirebaseStorage from "../../services/storegeService";


  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });






  

const OdsInNumberAdd = () => {
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList }) => setFileList(fileList);

  const navigate=useNavigate();
    const [previewOpen, setPreviewOpen] = useState(false);
  
 /*  

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]); 
  const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
*/
    const uploadButton = (
        <div style={{width:"1000px" }}>
          <img alt="plus" src="/images/uploadplus.png" />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Fotoğraf / GIF Yükle (1050x250 pixel boyutları önerilir)
          </div>
        </div>
      );

      const validateURL = (rule, value, callback) => {
        if (value === undefined || value === null || value === '') {
          callback();
          return;
        }
      
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      
        if (!urlPattern.test(value)) {
          callback('Lütfen geçerli bir URL giriniz.');
          return;
        }
      
        callback();
      };

      const onFinish = async (values) => {
        const newOdsInNumberData = {
          firstTransactionDate: new Date(),
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          url: values.url
        };
        values.image=fileList.length > 0 ? values.image : null;
        try {
          if (values.image) {
            newOdsInNumberData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(odsCollections.odsInNumber, values.image.file);
          
          const result = await FirebaseService.addRecording(odsCollections.odsInNumber,newOdsInNumberData);
          if (result.success) {
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });
            setTimeout(() => {
              navigate("/odsinnumbers");
            }, 2000);
          } else {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });
          }}
          else{
            NotificationService.openErrorNotification({
              title: 'Geçersiz Resim',
              description: "Lütfen resim seçiniz!",
              placement: 'topRight'
            });
          }
        } catch (error) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
        }
      };
      

   return ( <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '95vh',
  }}>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

<Space style={{ display: 'flex' }}>
      <Form.Item
        name="image"
        rules={[
          {
            required: true,
            message: 'Lütfen resim seçiniz!',
          },
        ]}
      >
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          className="odsnumber-add-upload"
          beforeUpload={() => false}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </Form.Item>

      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        {previewImage && (
          <video controls style={{ width: '100%' }}>
            <source src={previewImage} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </Modal>
    </Space>
          <Form.Item
            name="url"
            rules={[
              { validator: validateURL},
               {
                required: true,
                message: 'Lütfen url giriniz!',
                }
            ]}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Url Ekle"/>
            </Form.Item>
           
            <Form.Item
            wrapperCol={{
                offset: 4,
                span: 12,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default OdsInNumberAdd;