import React, { useState, useEffect, useRef } from "react";
import { Card, Carousel, Modal } from 'antd';
import FirebaseService from "../../../services/firebaseService";
import '../layouts.css';
const WebOurPartner = () => {
  const [partnerData, setPartnerData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const carouselRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await FirebaseService.getPartnerDataFilter();
        setPartnerData(data);
      } catch (error) {
       // console.error('Partner verisi getirme hatası:', error);
      }
    };
    fetchData();
  }, []);
  const handleCardClick = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };
  const handleModalCancel = () => {
    setModalVisible(false);
  };
  const handleNext = () => {
    carouselRef.current.next();
  };
  const handlePrev = () => {
    carouselRef.current.prev();
  };
  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '45vh',
      position: 'relative',
    }}>
      <h3 style={{ marginBottom: '16px', textAlign: 'justify', color: '#1A446C' }}>Partnerlerimiz</h3>
      {partnerData.length === 0 ? (
        <p style={{ textAlign: 'justify', color: '#1A446C'}}>Partnerlerimiz bulunmamaktadır.</p>
      ) : (
        <>
       <div className='admin-ourinvestor-carousel' style={{cursor:"pointer"}}>
         <Carousel  dots={false}
            autoplay
            slidesToShow={partnerData.length === 2 ? 2 : partnerData.length === 1 ? 1 : 3}
            ref={carouselRef}
            style={{marginLeft:"30px", marginRight:"30px"}}
            >
          {partnerData.map((item) => (
              <div span={8} key={item.key}  style={{ position: 'relative'}}>
                <div style={{display:"flex", position: 'absolute',marginLeft:"55px",marginTop: "5px"}}>
                 
                
                </div>
                <Card bordered={false} className="custom-card-partner" style={{opacity: item.isDeleted ? 0.6 : 1,marginTop:"20px"}} onClick={() => handleCardClick(item)}>
                  <div className="ant-card-body-style-2" style={{paddingTop:"20px", paddingBottom:"15px"}}>
                    <img alt="df" src={item.imageUrl} className="ant-card-img-partner " width={100} height={100} />
                    <div style={{color:"darkblue"}} className="ant-card-desc ">
                    <div dangerouslySetInnerHTML={{ __html: item.description }} />

                    </div>
                  </div>
                </Card>
              </div>
            ))}
            </Carousel>
            
         </div>
          <div style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0,
            cursor: 'pointer',
          }}>
            <img
              src="/images/leftarrow.png"
              alt="Next"
              onClick={handlePrev}
              style={{ width: '20px', height: '30px' }}
            />
          </div>
          <div style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 0,
            cursor: 'pointer',
          }}>
            <img
              src="/images/rigtharrow.png"
              alt="Previous"
              onClick={handleNext}
              style={{ width: '20px', height: '30px ' }}
            />
          </div>
        </>
      )}
      {selectedItem && (
        <Modal
          title={selectedItem.title}
          visible={modalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <img alt={selectedItem.title} src={selectedItem.imageUrl}  width={100} height={100}  style={{margin:"auto"}}/>
          <p dangerouslySetInnerHTML={{ __html: selectedItem.description }} ></p>

        </Modal>
      )}
    </div>
  );
};
export default WebOurPartner;