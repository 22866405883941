import React,{useState,useEffect,useRef} from "react";
import { Button, Form, Input, Select, DatePicker, Upload, Space, Checkbox, Divider,InputNumber } from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  {odsCollections, ProjectNames, Process, Currencies}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined,MinusOutlined } from '@ant-design/icons';
import { useLocation  } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

const onFinishFailed = (errorInfo) => {
  NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
};

const IbmProjectEdit = () => {

    const [users, setUsers] = useState([]);
    const [advisor, setAdvisor] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [process, setProcess] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [isFinish, setIsFinish] = useState(false);
    const [isFinishedCurrentSituation, setIsFinishedCurrentSituation] = useState(false);
    const [isFinishedMarketAnalysis , setIsFinishedMarketAnalysis] = useState(false);
    const [isFinishedCompetitorAnalysis , setIsFinishedCompetitorAnalysis] = useState(false);
    const [isFinishedColdCallList , setIsFinishedColdCallList] = useState(false);
    const navigate = useNavigate();
    
    const location = useLocation();

    const record = location.state && location.state.record;

    const defaultStartValue = dayjs(record.startDate, 'DD/MM/YYYY');
    const defaultEndValue = dayjs(record.endDate, 'DD/MM/YYYY');
  
    useEffect(() => {
      const fetchData = async () => {
          const users = await FirebaseService.getUserData();
          const filteredUser = users.filter(f=>f.userType.includes('user')&&f.userType.length===1)
          const usersOption = filteredUser.map(user => ({
            value: user.userId,
           // label: user.nameSurname + "-" + user.email +"-" +user.companyName,
           label: user.nameSurname

          }));
          setUsers(usersOption)

         setProjectNames(ProjectNames);
         setCurrencies(Currencies);
         setProcess(Process);

          const advisors = await FirebaseService.getAdvisorData();
          const advisorsOption = advisors.filter(f=>!f.isDeleted).map(advisor => ({
            value: advisor.key,
            label: advisor.nameSurname,
          }));
          setAdvisor(advisorsOption)
          setIsFinishedColdCallList(record.isFinishedColdCallList);
          setIsFinishedCompetitorAnalysis(record.isFinishedCompetitorAnalysis);
          setIsFinishedCurrentSituation(record.isFinishedCurrentSituation);
          setIsFinishedMarketAnalysis(record.isFinishedMarketAnalysis);
      };

      setIsFinish(record.isFinish)

      fetchData();
    }, []);

      const onFinish = async (values) => {
       // console.log(values.b2bAndf2f);
        
        const currentDate =new Date()

        const selectedAdvisorIds = values.advisorId;
        const selecteTeamLeadIds = values.teamLeadName;
        const selectedMarketResearch = values.marketResearch;


        const advisorData = selectedAdvisorIds.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });

        const teamLeadData = selecteTeamLeadIds.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });
        const marketResearch = selectedMarketResearch.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });

        values.b2bAndf2f.map(b=>{
          if (b.date1 !== undefined && b.date1 === null) {          
            NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Lütfen tarih bilgilerini boş bırakmayınız.",
            placement: 'topRight'
          });
          return null;
        }    
          }
        )

        const projectData = {
          firstTransactionDate: record.firstTransactionDateEdit??currentDate,
          firstUserId: record.firstUserId??localStorage.getItem("uid"),
          lastTransactionDate: currentDate,
          lastUserId: localStorage.getItem("uid"),
          isDeleted: record.isDeleted,
          projectName: values.projectName,
          offers: values.offers.map(step => ({
            companyName:step.companyName,
            country:step.country,
            price:step.price,
            currency:step.currency
          })),
          sales: values.sales.map(step => ({
            companyName:step.companyName,
            country:step.country,
            price:step.price,
            currency:step.currency
          })),
          b2bAndf2f: values.b2bAndf2f.map(step => ({
            companyName:step.companyName,
            country:step.country,
            date: step.date1 && step.date1 !== undefined ? new Date(step.date1) : step.dateEdit
          })),
          projectProcess : [
            {name:values.process1,date: values.process1Date === undefined ? record.projectProcess[0].dateEdit: new Date(values.process1Date)},
            {name:values.process2,date: values.process2Date === undefined ? record.projectProcess[1].dateEdit: new Date(values.process2Date)}
          ],
          userId:values.userId,
          userNameSurname:users.find(f=>f.value===values.userId).label,
          businessDevelopmentName:advisorData,
          isFinish:isFinish,
          startDate: values.startEndDate === undefined ? record.startDateEdit: new Date(values.startEndDate[0]),
          endDate:values.startEndDate === undefined ? record.endDateEdit: new Date(values.startEndDate[1]),
          teamLeadName:teamLeadData,
          marketResearchConsultantName:marketResearch,
          isFinishedCurrentSituation:isFinishedCurrentSituation??false,
          isFinishedMarketAnalysis:isFinishedMarketAnalysis??false,
          isFinishedCompetitorAnalysis:isFinishedCompetitorAnalysis??false,
          isFinishedColdCallList:isFinishedColdCallList??false
        };
      try {
       const result = await FirebaseService.updateIbmProject(record.key, projectData);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setTimeout(() => {
            navigate("/ibmprojectlist");
          }, 2000);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });}    
      }
       catch (error) {

        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
      }
    }; 

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
              projectName:record.projectName,
              userId:record.userId,
              isFinish:record.isFinish,
              process1:record.projectProcess[0].name,
              process2:record.projectProcess[1].name,
              serviceSublists:record.serviceSublists,
              teamLeadName:record.teamLeadName.map(user=>user.advisorId),
              advisorId:record.businessDevelopmentName.map(user=>user.advisorId),
              marketResearch: record.marketResearchConsultantName.map(user=>user.advisorId),
              offers:record.offers,
              sales:record.sales,
              b2bAndf2f:record.b2bAndf2f,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            disabled={record.category?true:false}
        >

          <div className="news-Edit-container">
              <Form.Item 
              name="projectName"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen proje adı seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 15,
                }}
                style={{
                maxWidth: "100%",
                }}
              > 
                  <Select
                        placeholder="Proje Adı"
                        style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        options={projectNames}
                        size="large"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                          </>
                        )}
                      />                
              </Form.Item>
            </div>

            <div className="news-Edit-container">
              <Form.Item 
              name="userId"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen şirket seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 15,
                }}
                style={{
                maxWidth: "100%",
                }}
              > 
                  <Select
                        placeholder="Kullanıcı Adı"
                        style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        options={users}
                        size="large"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />                         
                          </>
                        )}
                      />                
              </Form.Item>
            </div>

            <div className="news-Edit-container">
            <Form.Item 
            name="teamLeadName"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>Takım Lideri</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"start"
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen takım lideri seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="Takım Lideri Seç"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div>

           <div className="news-Edit-container">
            <Form.Item 
            name="advisorId"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>İş Geliştirme Danışmanı</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen iş geliştirme danışmanı seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="Danışman Seç"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div>
              <div className="news-Edit-container">
            <Form.Item 
            name="marketResearch"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>Pazar Araştırma Danışmanı</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen pazar araştırma danışmanı seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="Danışman Seç"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div>
              <Form.Item
                name="startEndDate"
                wrapperCol={{
                  span: 14,
                  }}
                  style={{
                  display:"left",
                  maxWidth: "100%",
                  }}
              >
                  <RangePicker placeholder={["Sözleşme Başlangıç","Sözleşme Bitiş"]}   
                  separator={<><span style={{ transform: 'rotate(0deg)' }}><MinusOutlined /></span></>}
                allowClear style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} size='middle' 
                defaultValue={[defaultStartValue, defaultEndValue]}
                />
            </Form.Item>
            <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
              <div className="news-Edit-container">

              <Form.Item 
                name="process1"
                rules={[{ required: true, message: 'Lütfen süreç ismini giriniz!' }]}
                wrapperCol={{
                  span: 30,
                  }}
                  style={{
                  width: "335px",
                  }}
                > 
                  <Select
                        placeholder="Süreç Ekle"
                        style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        options={process}
                        size="large"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                          </>
                        )}
                      />                
              </Form.Item>
              </div>
            <Form.Item  
            name="process1Date"
              wrapperCol={{
                span: 25,
                }}
                style={{
                display:"left",
                width: "335px",
                }}>
              <DatePicker 
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} placeholder="Tarih Ekle" size="large"
                defaultValue={dayjs(record.projectProcess[0].date, dateFormat)} format={dateFormat}/>
            </Form.Item>  
            </Space>  

            <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
            <div className="news-Edit-container">

            <Form.Item 
              name="process2"
              rules={[{ required: true, message: 'Lütfen süreç ismini giriniz!' }]}
              wrapperCol={{
                span: 25,
                }}
                style={{
                width: "335px",
                }}
              > 
                <Select
                      placeholder="Süreç Ekle"
                      style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      options={process}
                      size="large"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0',
                            }}
                          />
                        </>
                      )}
                    />                
            </Form.Item>
            </div>

            <Form.Item  
            name="process2Date"
              wrapperCol={{
                span: 25,
                }}
                style={{
                display:"left",
                width: "335px",
                }}>
              <DatePicker 
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} placeholder="Tarih Ekle" size="large"
                defaultValue={dayjs(record.projectProcess[1].date, dateFormat)} format={dateFormat}/>
            </Form.Item>  
            </Space> 
            <Form.Item
              name="isFinishedCurrentSituation"
              wrapperCol={{
                span: 5,
                }}
                style={{
                maxWidth: "100%",
                }}>
              <Checkbox checked={isFinishedCurrentSituation} onChange={() => setIsFinishedCurrentSituation(!isFinishedCurrentSituation)} style={{fontWeight:"500",color:"#1A446C"}}>
              Mevcut Durum Analizi
              </Checkbox>
            </Form.Item>  
            <Form.Item
              name="isFinishedMarketAnalysis"
              wrapperCol={{
                span: 5,
                }}
                style={{
                maxWidth: "100%",
                marginTop:"-20px",
                marginLeft:"-10px"
                }}>
              <Checkbox checked={isFinishedMarketAnalysis} onChange={() => setIsFinishedMarketAnalysis(!isFinishedMarketAnalysis)} style={{fontWeight:"500",color:"#1A446C"}}>
              Hedef Pazar Analizi
              </Checkbox>
            </Form.Item>    
            <Form.Item
              name="isFinishedCompetitorAnalysis"
              wrapperCol={{
                span: 5,
                }}
                style={{
                maxWidth: "100%",
                marginTop:"-20px",
                marginLeft:"-30px"
                }}>
              <Checkbox checked={isFinishedCompetitorAnalysis} onChange={() => setIsFinishedCompetitorAnalysis(!isFinishedCompetitorAnalysis)} style={{fontWeight:"500",color:"#1A446C"}}>
              Rakip Analizi
              </Checkbox>
            </Form.Item>    
            <Form.Item
              name="isFinishedColdCallList"
              wrapperCol={{
                span: 5,
                }}
                style={{
                maxWidth: "100%",
                marginTop:"-20px",
                marginLeft:"-30px"
                }}>
              <Checkbox checked={isFinishedColdCallList} onChange={() => setIsFinishedColdCallList(!isFinishedColdCallList)} style={{fontWeight:"500",color:"#1A446C"}}>
              Cold Call List
              </Checkbox>
            </Form.Item>   
            {/* <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
            <label style={{ color: "#1A446C",fontWeight:"500" }}>Teklif sayısı</label>
              <Form.Item
                name='commitment1'
                rules={[{ required: true, message: 'Lütfen taahhüt sayısını giriniz!' }]}
                style={{marginLeft:"20px"}}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Taahhüt edilen Sayı"/>
              </Form.Item>  
              <Form.Item
                name='happening1'
                rules={[{ required: true, message: 'Lütfen gerçekleşen sayıyı giriniz!' }]}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Gerçekleşen sayı"/>
              </Form.Item>  
            </Space>

            <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
            <label style={{ color: "#1A446C",fontWeight:"500" }}>B2B/F2F sayısı</label>
              <Form.Item
                name='commitment2'
                rules={[{ required: true, message: 'Lütfen taahhüt sayısını giriniz!' }]}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Taahhüt edilen Sayı"/>
              </Form.Item>  
              <Form.Item
                name='happening2'
                rules={[{ required: true, message: 'Lütfen gerçekleşen sayıyı giriniz!' }]}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Gerçekleşen sayı"/>
              </Form.Item>  
            </Space> */}

            <h3 style={{textAlign:"start", marginLeft:"18px", marginTop:"30px",color:"#1A446C"}}>Teklifler</h3>

            <Form.List name="offers">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">           
                  <Form.Item
                    {...restField}
                    name={[name, 'companyName']}
                    rules={[{ required: true, message: 'Lütfen firma ismini giriniz!' }]}
                  >
                    <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Firma"/>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'country']}
                    rules={[{ required: true, message: 'Lütfen ülke ismini giriniz!' }]}
                  >
                    <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Ülke"/>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    rules={[{ required: true, message: 'Lütfen tutar giriniz!' }]}
                  >
                    <InputNumber 
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                      }}
                      size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Tutar"/>
                  </Form.Item>
                  <div className="news-Edit-container">
                  <Form.Item 
                  {...restField}
                  name={[name, 'currency']}
                  rules={[{ required: true, message: 'Lütfen para birimini seçiniz!' }]}
                  wrapperCol={{
                    span: 15,
                    }}
                    style={{
                    width:"250px"
                    }}
                  > 
                    <Select
                      placeholder="Para Birimi"
                      style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      options={currencies}
                      size="large"
                    />
                  </Form.Item>
                    </div>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button style={{width:"50%",float:"left",border:"0"}} size="large" onClick={() => add()} block icon={<img style={{width:"15px",float:"right"}} alt="edit" src="/images/plusbutton.png" />}>
                  Teklif Ekle
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

    <h3 style={{textAlign:"start", marginLeft:"18px", marginTop:"30px",color:"#1A446C"}}>B2B / F2F</h3>
      <Form.List name="b2bAndf2f">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                    {...restField}
                    name={[name, 'companyName']}
                    rules={[{ required: true, message: 'Lütfen firma ismini giriniz!' }]}
                  >
                    <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Firma"/>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'country']}
                    rules={[{ required: true, message: 'Lütfen ülke ismini giriniz!' }]}
                  >
                    <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Ülke"/>
                  </Form.Item>
                  <Form.Item  
                     {...restField}
                     name={[name, 'date1']}
                    >
                      <DatePicker 
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        style={{ borderRadius: 0, borderColor: "#1A446C80", textAlign: "left", width: "100%" }}
                        placeholder="Tarih Ekle"
                        size="large"
                        defaultValue={
                          record.b2bAndf2f && 
                          record.b2bAndf2f[key] &&
                          record.b2bAndf2f[key].date 
                            ? dayjs(record.b2bAndf2f[key].date, dateFormat)
                            : undefined
                        }
                      />                  
                      </Form.Item>  
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button style={{width:"50%",float:"left",border:"0"}} size="large" onClick={() => add()} block icon={<img style={{width:"15px",float:"right"}} alt="edit" src="/images/plusbutton.png" />}>
              Teklif Ekle
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

    <h3 style={{textAlign:"start", marginLeft:"18px", marginTop:"30px",color:"#1A446C"}}>Satışlar</h3>

    <Form.List name="sales">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">           
                  <Form.Item
                    {...restField}
                    name={[name, 'companyName']}
                    rules={[{ required: true, message: 'Lütfen firma ismini giriniz!' }]}
                  >
                    <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Firma"/>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'country']}
                    rules={[{ required: true, message: 'Lütfen ülke ismini giriniz!' }]}
                  >
                    <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Ülke"/>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    rules={[{ required: true, message: 'Lütfen tutar giriniz!' }]}
                  >
                    <InputNumber
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                      }}
                     size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Tutar"/>
                  </Form.Item>
                  <div className="news-Edit-container">
                  <Form.Item 
                  {...restField}
                  name={[name, 'currency']}
                  rules={[{ required: true, message: 'Lütfen para birimini seçiniz!' }]}
                  wrapperCol={{
                    span: 15,
                    }}
                    style={{
                    width:"250px"
                    }}
                  > 
                    <Select
                      placeholder="Para Birimi"
                      style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      options={currencies}
                      size="large"
                    />
                  </Form.Item>
                    </div>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button style={{width:"50%",float:"left",border:"0"}} size="large" onClick={() => add()} block icon={<img style={{width:"15px",float:"right"}} alt="edit" src="/images/plusbutton.png" />}>
                  Teklif Ekle
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
          <Form.Item
              name="isFinish"
              wrapperCol={{
                span: 3,
                }}
                style={{
                maxWidth: "100%",
                }}>
              <Checkbox checked={isFinish} onChange={() => setIsFinish(!isFinish)} style={{fontWeight:"500",color:"#1A446C"}}>
                Projeyi Bitir
              </Checkbox>
            </Form.Item>       

            <Form.Item
            wrapperCol={{
                offset: 10,
                span: 10,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500",borderRadius:0}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default IbmProjectEdit;