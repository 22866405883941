import React,{useState} from "react";
import { Button, Form, Input, Space, Select } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';

const onPanelChange = (value, mode) => {
  //console.log(value.format('YYYY-MM-DD'), mode);
};
const onFinish = (values) => {
    NotificationService.openSuccessNotification({title:'Başarılı',description:'Bildirim başarıyla güncellendi.',placement:'topRight'})
  };
  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const { Option } = Select;


  const options = [];
    for (let i = 10; i < 36; i++) {
      options.push({
        value: i.toString(36) + i,
        label: i.toString(36) + i,
      });
    }
    const handleChangeSelect = (value) => {
     // console.log(`selected ${value}`);
    };

const NotificationEdit = () => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChangeFile = ({ fileList: newFileList }) => setFileList(newFileList);

    const uploadButton = (
      <div style={{width:"1000px" }}>
        <img alt="plus" src="/images/uploadplus.png" />
        <div
          style={{
            marginTop: 8,
          }}
        >
          Logo Yükle
        </div>
    </div>
      );


   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
       <Space style={{display:"flex"}}>
              <Form.Item 
              name="image">
                      <Upload 
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onChange={handleChangeFile}
                          className="generic-add-upload"
                          >
                          {fileList.length >= 1 ? null : uploadButton}
                      </Upload>                
              </Form.Item>

              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                        />
                      </Modal>
            </Space>


            <Form.Item
            name="title"
            rules={[
                {
                required: true,
                message: 'Lütfen haber başlığını giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
            <Input style={{border:0}} placeholder="Bildirim Başlığı"/>
            </Form.Item>

            <Form.Item
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen bildirim içeriğini giriniz!',
                },
            ]}
            >
            <Input.TextArea rows={10} style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Bildirim"/>
            </Form.Item>

            <div className="news-Edit-container">
            <Form.Item 
            name="category"
            rules={[
                {
                required: true,
                message: 'Lütfen haber kategorisini seçiniz !',
                },
            ]}
            wrapperCol={{
              span: 5,
              }}
              style={{
              maxWidth: "100%",
              }}
            > 
                <Select
                      placeholder="Kategori Seç"
                      style={{borderRadius:0, borderColor:"#1A446C80"}}
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                    >
                  <Option value="male">male</Option>
                  <Option value="female">female</Option>
                  <Option value="other">other</Option>
                </Select>
                
            </Form.Item>
            </div>

            <Form.Item 
            name="usercategory"
            wrapperCol={{
              span: 5,
              }}
              style={{
              maxWidth: "100%",
              }}
            > 
            <div className="news-Edit-container">

              <Select
                mode="tags"
                placeholder="Gönderilecek Gruplar"
                style={{borderRadius:0, borderColor:"#1A446C80"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                onChange={handleChangeSelect}
                options={options}
              />
              </div>
            </Form.Item>

            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default NotificationEdit;