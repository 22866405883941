import React, { useState, useEffect } from 'react';
import { Card, Carousel, Row, Col } from 'antd';
import FirebaseService from '../../services/firebaseService'; 
import { Link, useNavigate } from 'react-router-dom'; 

const CampaignListWithCategory = () => {
  const [campaignData, setCampaignData] = useState([]);
  const navigate = useNavigate(); 

  const fetchData = async () => {
    const data = await FirebaseService.getCampaignDataFilter();
    return data;
  };

  
  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchData();
        setCampaignData(fetchedData);
      } catch (error) {
        console.error('Veriyi ayarlama hatası:', error);
       
      }
    };

    getData();
  }, []);
  const settings = {
    dots: false,
    nextArrow: <img src='/images/rigtharrow.png' style={{ maxWidth: '400px' }} />,
    prevArrow: <img src='/images/leftarrow.png' style={{ maxWidth: '400px' }} />,
    infinite: false,
    speed: 500,
    slidesToShow: 6.5,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const goDetailPage = (record) => {
    navigate('/campaign-detail', { state: { record } });
  };

  return (
    <div>
      <h3 style={{ textAlign: 'justify', color: '#1A446C',marginTop:"80px" }}>Kampanyalar</h3>
        {campaignData.length === 0 ? (
          <p style={{ textAlign: 'justify', color: '#1A446C', justifyContent:'center',marginTop: '20px' }}>Kampanya bulunmamaktadır.</p>
        ) : (

          <div className='news-by-category-card-carousel'>
          <Carousel arrows {...settings}>
            {campaignData.map((item) => (
              <div key={item.id} onClick={() => goDetailPage(item)}>
                <Card bordered={false} className='news-by-category-card'>
                  <div className='news-by-category-card-cody'>
                    <img  alt='df' src={item.imageUrl} className='news-card-img' />
                    <span className='news-card-title'>{item.title}</span>
                  </div>
                </Card>
              </div>
            ))}
          </Carousel>
        </div>

        
        )}
     
    </div>
  );
};

export default CampaignListWithCategory;