import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";

const SurveyList = () => {
  const [survey, setSurvey] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const currentDate = new Date();

    const fetchData = async () => {
      const data = await FirebaseService.getSurveyData();
      const updatedData = data.map(record => {
        const startDate = new Date(record.startDateEdit);
        const endDate = new Date(record.endDateEdit);
    
        if (currentDate < startDate) {
          return { ...record, status: "Yayınlanmadı" };
        } else if (currentDate > endDate) {
          return { ...record, status: "Tamamlandı" };
        } else if (currentDate >= startDate && currentDate <= endDate) {
          return { ...record, status: "Yayında" };
        } else {
          return { ...record, status: "Bilgi Alınamadı" };
        }
      });
    
      const questionAverages = updatedData.map(record => {
        const questions = record.questions.map(question => {
          const totalWeightedChoices = question.answer.reduce((sum, answer) => sum + (answer.choice * answer.userIds.length), 0);
          const totalUserIds = question.answer.reduce((sum, answer) => sum + answer.userIds.length, 0);
          const averageChoice = totalWeightedChoices / totalUserIds || 0; 
          return { ...question, averageChoice };
        });
    
        return { ...record, questions };
      });
    
      setSurvey(questionAverages);
    };

    fetchData();
  }, []);
  const goEditPage = (record) => {
    navigate('/surveyedit', { state: { record } });
  };

  const goAddPage = () => {
    navigate('/surveyadd')
  };

  const columns = [
    {
      title: 'Anket Başlığı',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <span style={{fontWeight:"bold"}}>{text}</span>,
      width: 400,
    },
    {
      title: 'Oluşturan',
      dataIndex: 'firstUserNameSurname',
      key: 'date',
      width: 200,
    },
    {
      title: 'Süresi',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 300,
      render: (text,record) => <span>{record.startDate} - {record.endDate}</span>,
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      width: 300,
    },
    {
      title: 'Detay',
      dataIndex: 'detail',
      key: 'detail',
      render: (text, record) => (
        <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={()=>goEditPage(record)}>Detay</Button>
      ),
    }
  ];
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} style={{background:"transparent",borderColor:"#1A446C80",color:"#1A446C",fontWeight:"bold",borderRadius:"0px",float:"right"}}>
          Yeni Ekle
        </Button>
          <Table
            locale={{ emptyText: 'Henüz anket eklemediniz...' }}
            dataSource={survey}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

    };
  export default SurveyList;