import React from "react";
import LastRecord from "./dashboardpages/lastRecord";
import LastUploads from "./dashboardpages/lastUploads";
import OurServices from "./dashboardpages/ourServices";
import OurPartner from "./dashboardpages/ourPartner";
import OurInvestors from "./dashboardpages/ourInvertors";
import CategoryAdd from "./dashboardpages/categoryAdd";

const Dashboard = () => (
    <div
  style={{
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft:"25px"
  }}
    >
      {/* <div>
        <LastRecord />
      </div> */}
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: "45px" }}>
          <LastUploads />
        </div>
        <div style={{ marginRight: "25px" }}>
          <OurServices />
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div  style={{ marginRight: "25px" }}>
          <OurPartner />
          <OurInvestors />
        </div>
        <div style={{flex: 1,marginLeft:"7px"}}>
          <CategoryAdd />
        </div>
      </div>
    </div>
  );
  export default Dashboard;