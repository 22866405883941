import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import WebMenu from '../web/layouts/webMenu';
import WebNavi from '../web/layouts/webNavi';
import WebContactNavi from '../web/layouts/webContactNavi';

const PrivateWebPageComponent = () => {
    const location = useLocation();

    const isProfilePage = location.pathname === "/profile";
    const isContactPage = location.pathname === "/contact";
    const isProjectsPage = location.pathname === "/projects";
    const isCdPage = location.pathname === "/cddetail";
    const isTqPage = location.pathname === "/tqdetail";
    const isSapPage = location.pathname === "/sapdetail";
    const isIbmPage = location.pathname === "/ibmdetail";
    const isIpmPage = location.pathname === "/ipmdetail";
  //  const isChangePasswordPage = location.pathname === "/changepassword";
  // const isHomePage = location.pathname === "/home";

  const isAskAdvisorPage = location.pathname === "/askadvisor";
  const isMapPage = location.pathname === "/map";
  const isSurveyPage = location.pathname === "/survey";
  const isSurveyDetailPage = location.pathname === "/surveydetail";


    return (
        <div style={{ display: "flex" }}>
            <WebMenu />
            <div style={{ flex: '1', padding: '0px' }}>
                {!isProfilePage   && !isContactPage && !isProjectsPage && !isCdPage && !isTqPage && !isSapPage && !isIbmPage && !isIpmPage && !isSurveyPage && !isAskAdvisorPage && !isMapPage  && !isSurveyDetailPage    &&  <WebNavi />}
                {isContactPage  && <WebContactNavi />}
                {isAskAdvisorPage  && <WebContactNavi />}
                {isMapPage  && <WebContactNavi />}
                {isSurveyPage  && <WebContactNavi />}

                <div style={{ marginTop: '20px' }}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default PrivateWebPageComponent;
