import React, {useState, useEffect} from "react";
import { Button, Form, Input, Space, DatePicker, Select } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";
import { useLocation  } from 'react-router-dom';
import  {categoryName}  from "../../environments/environment"

import dayjs from 'dayjs';
const dateFormat = 'DD/MM/YYYY';

const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const StoryEdit = () => {
    const location = useLocation();

    const record = location.state && location.state.record;

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [newFileList, setNewFileList] = useState([]);
    const [category, setCategory] = useState([]);

    const navigate = useNavigate();

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        setNewFileList(newFileList)
      };
      useEffect(() => {
      //  console.log(record);
        const fetchData = async () => {
          setFileList([
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: record.imageUrl,
            }
          ]);  
        };
      
        fetchData();

        setCategory([
          {
            value: categoryName.new,
            label: "Haber",
          },
          {
          value: categoryName.blog,
          label: "Blog",
          }
        ])
      }, []);

    const uploadButton = (
        <div style={{width:"1000px" }}>
          <img alt="plus" src="/images/uploadplus.png" />
          <div
            style={{
              marginTop: 8,
            }}
          >
          </div>
        </div>
      );

      const onFinish = async (values) => {
        const currentDate =new Date()
        const storyData = {
          firstTransactionDate: record.firstTransactionDateEdit ?? currentDate,
          firstUserId: record.firstUserId??localStorage.getItem("uid"),
          lastTransactionDate: currentDate,
          lastUserId: localStorage.getItem("uid"),
          isDeleted: record.isDeleted,
         // description: values.description,
          endDate: values.endDate === undefined ? record.endDateEdit: new Date(values.endDate),
          categoryName: values.categoryName

        };
        values.image = newFileList.length > 0 ? values.image : fileList.length>0 ? record.imageUrl : null;

        // if(storyData.endDate.getDate()< currentDate.getDate())
        // {
        //   NotificationService.openErrorNotification({
        //     title: 'Geçersiz Tarih',
        //     description: "Lütfen gelecekten bir tarih seçiniz.",
        //     placement: 'topRight'
        //   });
        //   return null;
        // }

        if(values.image===null){
            NotificationService.openErrorNotification({
              title: 'Geçersiz Resim',
              description: "Lütfen resim seçiniz!",
              placement: 'topRight'
            });
            return null;
          }

          try {
              if(newFileList.length>0)
              {
                storyData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(odsCollections.story, values.image.file);
              }
            const result = await FirebaseService.updateStory(record.key, storyData);
            if (result.success) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: result.message,
                placement: 'topRight'
              });
              setTimeout(() => {
                navigate("/stories");
              }, 2000);
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });}
            
            
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
          }      
    };    

    const onChange = (date, dateString) => {
     //   console.log(date, dateString);
      };

   return ( <div>
         <Form
            name="basic"
            initialValues={{
                description: record.description ,
                categoryName: record.categoryName
              }}
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

          <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
            >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="partner-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
                    </Modal>
          </Space>

          <Form.Item
            name="endDate"

            wrapperCol={{
              span: 3,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
                <DatePicker 
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} 
                onChange={onChange}
                defaultValue={dayjs(record.endDate, dateFormat)} format={dateFormat} />
            </Form.Item>

            <div className="news-Edit-container">
            <Form.Item 
              name="categoryName"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen blog kategorisini seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 5,
                }}
                style={{
                maxWidth: "100%",
                }}
              > 
                <Select
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      placeholder="Kategori Seç"
                      style={{borderRadius:0, borderColor:"#1A446C80"}}
                      options={category}
                    />
                
            </Form.Item>
            </div>

         
           
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default StoryEdit;

/**
 
   <Form.Item
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen açıklama giriniz!',
                },
            ]}
            >
            <Input.TextArea rows={10} style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Açıklama"/>
            </Form.Item>
 */