  import React, { useState, useEffect } from 'react';
  import { useNavigate } from 'react-router-dom'; 
  import FirebaseService from '../../../services/firebaseService';
  import '../layouts.css';
  import {  Row, Col } from 'antd';

  import Carousel from 'react-bootstrap/Carousel';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'react-toastify/dist/ReactToastify.css';
  

  import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

  const WebNews = () => {
    const [newsData, setNewsData] = useState([]);
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const goDetailPage = (record) => {
      navigate('/news-detail', { state: { record } });
    };
    
    const getNewData = async () => {
      try {
        const data = await FirebaseService.getNewDataFilter();
       
        const parsedData = data.map(item => ({
          ...item,
          releaseDate: new Date(item.releaseDate.split('.').reverse().join('-')),
        }));

        console.log("Individual Dates (Before Sorting):", parsedData.map(item => item.releaseDate));
        const sortedData = parsedData.sort((a, b) => b.releaseDate - a.releaseDate);
        console.log("Individual Dates (After Sorting):", sortedData.map(item => item.releaseDate));
        const closestDates = sortedData.slice(0, 3);
        console.log("Closest Dates:", closestDates.map(item => item.releaseDate));

        setNewsData(closestDates);
      } catch (error) {
       // console.error('Haber verisi getirme hatası:', error);
      }
    };
    

    useEffect(() => {
      getNewData();
    }, []); 


const AutoplaySlider = withAutoplay(AwesomeSlider);
  

    return (
      <div>
        <h3 style={{ marginBottom: '0px', textAlign: 'justify', color: '#1A446C' , marginTop:"30px"}}>Haberler</h3>
        <div className="webnews-carousel-container" style={{marginRight:"40px"}}>
          {newsData.length === 0 ? (
            <p style={{ textAlign: 'justify', color: '#1A446C', marginLeft: '15px' }}>Haber bulunmamaktadır.</p>
          ) : (
            <Row >
            <Col span={35}>
              <div className="last-news-carausel" style={{cursor:"pointer"}}>
                {newsData.length === 0 ? (
                  <p style={{ textAlign: 'justify', color: '#1A446C', justifyContent:'center' }}>Haber bulunmamaktadır.</p>
                ) : (

                  <AutoplaySlider
                  play={true}
                  cancelOnInteraction={false} 
                  interval={3000}
                  style={{ width: '1050px', height: '300px', }}
                >
                  {newsData.map((item, index) => (
                    <div key={index}  onClick={() => goDetailPage(item)} style={{ backgroundColor:"white"}}>
                        <img  style={{width: "1050px",height: "250px", objectFit:"cover", backgroundColor:"white"}} src={item.imageUrl} alt={item.title} /> 
                        <h2 className="last-news-card-title">{item.title}</h2>
                    </div>
                  ))}  
                </AutoplaySlider>

                
                )}
              </div>
            </Col>
          </Row>
       
           
          )}
        </div>
      </div>
    );
  };
  
  export default WebNews;