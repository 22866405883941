import React,{ useState, useRef,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Button, Progress, Checkbox } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import FirebaseService from '../../services/firebaseService';

const IpmDetail = () => {
  const location = useLocation();
  const { record } = location.state;
  const [isExpandedProject, setIsExpandedProject] = useState(false);
  const [isExpandedGenel, setIsExpandedGenel] = useState(false);
  const navigate = useNavigate();
  const [project, setProject] = useState([]);



  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
      try {
          const data = await FirebaseService.getIpmProjectBasedById(record.ipmProjectsId);   
          setProject(data);
       
      } catch (error) {
        console.error('Projeleri getirirken bir hata oluştu:', error.message);
      }
};
  
    

  const handleToggleProject = () => {
    setIsExpandedProject(!isExpandedProject);
  };


  const handleToggleGenel = () => {
    setIsExpandedGenel(!isExpandedGenel);
  };


  const calculateProgress = (startDate, endDate, projectProcess) => {
    if (!startDate || !endDate) {
        return { progress: 'NaN', start: null, end: null, processes: [] };
    }

    const start = moment(new Date(startDate.seconds * 1000 + startDate.nanoseconds / 1000000));
    const end = moment(new Date(endDate.seconds * 1000 + endDate.nanoseconds / 1000000));

    if (!start.isValid() || !end.isValid()) {
      //  console.log('Invalid start or end date:', start, end);
        return { progress: 'NaN', start, end, processes: [] };
    }

    const currentDate = moment();
    const totalDays = end.diff(start, 'days');
    const daysFromStart = currentDate.diff(start, 'days');
    const daysFromEnd = end.diff(currentDate, 'days');

    const processes = projectProcess || [];

    const formattedProcesses = processes.map(process => ({
        name: process.name,
        date: process.date ? formatDate(process.date) : null,
    }));

   // console.log('Formatted Processes:', formattedProcesses);

    const sortedProcesses = formattedProcesses
        .filter(process => process.date !== null)
        .sort((a, b) => moment(a.date, 'DD/MM/YYYY').isBefore(moment(b.date, 'DD/MM/YYYY')) ? -1 : 1);

   // console.log('Sorted Processes:', sortedProcesses);

    // Ensure progress is between 0 and 100
    const progressPercent = Math.max(0, Math.min(100, (daysFromStart / totalDays) * 100));

    return { progress: progressPercent.toFixed(2), start, end, processes: sortedProcesses };
};

const formatDate = (timestamp) => {
    if (!timestamp) {
        return null;
    }

    const seconds = timestamp.seconds || 0;
    const nanoseconds = timestamp.nanoseconds || 0;

    const dateObject = new Date(seconds * 1000 + nanoseconds / 1000000);

    if (isNaN(dateObject.getTime())) {
        return null;
    }

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear().toString();

    return `${day}/${month}/${year}`;
};

  const progressData = calculateProgress(record.startDate, record.endDate, record.projectProcess);


  const calculateTotalPriceReferenced = (item) => {
    if (item.length === 0) {
      return "NaN";
    }
  
    const baseCurrency = item[0].currency;
  
    const total = item.reduce((accumulator, offer) => {
      if (offer.currency !== baseCurrency) {
        return NaN;
      }
  
      const offerPrice = parseFloat(offer.price);
      return isNaN(offerPrice) ? NaN : accumulator + offerPrice;
    }, 0);
  
    return isNaN(total) ? "NaN" : total.toFixed(2)+item[0].currency;
  };
  
  const calculateTotalPriceApproved = (item) => {
    if (item.length === 0) {
      return "NaN";
    }
  
    const baseCurrency = item[0].currency;
  
    const total = item.reduce((accumulator, offer) => {
      if (offer.currency !== baseCurrency) {
        return NaN;
      }
  
      const offerPrice = parseFloat(offer.price);
      return isNaN(offerPrice) ? NaN : accumulator + offerPrice;
    }, 0);
  
    return isNaN(total) ? "NaN" : total.toFixed(2)+item[0].currency;
  };



  const goDetailPage = (record) => {
      navigate('/ipmprojectdetail', { state: { record } });
   
  };

  return (
    <div style={{padding:"20px"}}>
    <Card
        title={<h2 style={{ fontWeight: 'bold' , color:"#A47B5A"}}>IPM</h2>}
        style={{textAlign: 'left' }}
        extra={
          <p  style={{ color:"#1A446C80"}}>{formatDate(record.firstTransactionDate)}</p>
        }
      >
        <div style={{justifyContent:"space-between", display:"flex", padding:"20px"}}>
          <div  style={{display:"flex"}}>

            <div>
                            <div>
                        Operasyon Danışmanı:
                            {Array.isArray(record.operationName) &&
                            record.operationName.map((item) => (
                                <span key={item.nameSurname}> {item.nameSurname}</span>
                            ))}
                        </div>
                        <div>
                            İş Geliştirme Danışmanı:
                            {Array.isArray(record.businessDevelopmentName) &&
                            record.businessDevelopmentName.map((item) => (
                                <span key={item.nameSurname}> {item.nameSurname}</span>
                            ))}
                        </div>
                        <div>
                            Kazandırılan Toplam Tutar: 
                            {record.totalAmountEarned}
                        </div>
            </div>
          </div>
         
        </div>
        <Progress showInfo={false} style={{padding:"0px 20px"}} strokeColor="#A47B5A" percent={calculateProgress(record.startDate, record.endDate, record.projectProcess).progress} status="active" />

        <div style={{padding:"0px 20px", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

            <div style={{ flex: '1', textAlign:"left" }}>
                <div style={{ color: "#1A446C" }}>Sözleşme Başlangıcı </div>
                <div style={{ color: "#1A446C" }}>{formatDate(record.startDate)} </div>
            </div>

            <div style={{ flex: '1' }}>
            <div style={{ color: "#1A446C" }}>
                {progressData.processes[0] ? progressData.processes[0].name : 'No Name'}
            </div>
            <div style={{ color: "#1A446C" }}>
                {progressData.processes[0] ? (progressData.processes[0].date) : 'No Date'}
            </div>
            </div>


            <div style={{ flex: '1' }}>
                <div style={{ color: "#1A446C" }}>
                {progressData.processes[1] ? progressData.processes[1].name : 'No Name'}
                </div>
                <div style={{ color: "#1A446C" }}>
                {progressData.processes[1] ? (progressData.processes[1].date) : 'No Date'}
            </div>
            </div>

            <div style={{ flex: '1',textAlign:"right"  }}>
                <div style={{ color: "#1A446C" }}>Sözleşme Bitişi</div>
                <div style={{ color: "#1A446C" }}>{formatDate(record.endDate)} </div>

            </div>
        </div>

      </Card>
     
      
     


    {/* proje bazlıı */}
      <div style={{ background: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginBottom: '10px', marginTop:"20px" }}>
            <p onClick={handleToggleProject} style={{ cursor: 'pointer', textAlign:"left",color: "#1A446C", fontWeight:"bold" }}>
           Proje Bazlı Destekler <img src={isExpandedProject ?  'images/uparrow.png' : 'images/downarrow.png' } width={20} height={10} />
            </p>
            
            {isExpandedProject && (
                <div style={{ textAlign: "left", color: "#1A446C" }}>
                  

                  {project.map((record) => (
                <Card
                    key={record.key} 
                    title={
                    <>
                    <h2 style={{ fontWeight: 'bold', color: "#A47B5A" }}> {record.companyName}</h2>
                    <h3 style={{ fontWeight: 'bold', color: "#A47B5A" }}>{record.programName}</h3>
                    </>
                   }
                    style={{ textAlign: 'left' }}
                    extra={
                        <p  style={{ color:"#1A446C80"}}>{(record.firstTransactionDate)}</p>
                      }
                >
                <div style={{justifyContent:"space-between", display:"flex", padding:"20px"}}>
                    <div  style={{display:"flex"}}>
                    <div>
                         <div style={{color:"#1A446C"}}>
                        Operasyon Danışmanı
                            {Array.isArray(record.operationName) &&
                            record.operationName.map((item) => (
                                <span key={item.nameSurname}> {item.nameSurname}</span>
                            ))}
                        </div>
                        <div style={{color:"#1A446C"}}>
                            Proje Geliştirme Danışmanı:
                            {Array.isArray(record.businessDevelopmentName) &&
                            record.businessDevelopmentName.map((item) => (
                                <span key={item.nameSurname}> {item.nameSurname}</span>
                            ))}
                        </div>
                        <div style={{color:"#1A446C",fontSize:"18px"}}>
                           <span style={{color:"#A47B5A"}}> Durum:  </span> {record.processStatus}
                        </div>
                       
                    </div>

                    </div>
                <div>
                <Button
                    style={{ backgroundColor: 'white', border:"none" }}
                    onClick={() => goDetailPage(record)}
                    icon={<img alt="plus" src="/images/rigtharrow.png" style={{ width: '100%' }} />}

                    />
                </div>
                </div>
        
            
              
            
            </Card>
            ))}
            {project.length === 0 && <p>Hizmet Bulunmamaktadır.</p>}
                        
                </div>
            )}
      </div>
     

    {/* genel destekler  */}
      <div style={{ background: '#fff', border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginBottom: '10px', marginTop:"20px" }}>
            <p onClick={handleToggleGenel} style={{ cursor: 'pointer', textAlign:"left",color: "#1A446C", fontWeight:"bold" }}>
            Genel Destekler  <img src={isExpandedGenel ?   'images/uparrow.png' :'images/downarrow.png'} width={20} height={10} />
            </p>
            {isExpandedGenel && (
                
                <div style={{ textAlign: "left", color: "#1A446C" }}>
                    <p style={{fontWeight:"bold"}}>Başvurulan Genel Destek Tutarı: {calculateTotalPriceReferenced(record.genelSupportReferenced)} </p>

                    {record.genelSupportReferenced.map((item) => (
                    <div key={item.name}>
                     <span style={{fontWeight:"bold"}}>{item.text}</span>  {item.price} {item.currency}
                    </div>
                
                    ))}

                    <p style={{fontWeight:"bold"}}>Onaylanan Genel Destek Tutarı: {calculateTotalPriceApproved(record.genelSupportApproved)}</p>
                    {record.genelSupportApproved.map((item) => (
                    <div key={item.name}>
                   <span style={{fontWeight:"bold"}}>{item.text}</span>  {item.price} {item.currency}
                    </div>

                    ))}



                </div>
            )}
      </div>



      <div>
     
    </div>
     </div>
  );
};

export default IpmDetail;
