import React from "react";
import { Button, Form, Select, Input,InputNumber,DatePicker,Space } from "antd";
import NotificationService from "../../services/antNotificationService";
import  {odsCollections}  from "../../environments/environment"
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const SurveyAdd = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const normalizeQuestions = (questions) => {
    return questions.map((question) => {
      let options = [];
  
      if (question.type === 'multipleChoice') {
        options = Object.keys(question)
          .filter((key) => key.startsWith('multipleChoiceOptions'))
          .map((key) => question[key]);
  
        if (question.multiplechoiceadd && Array.isArray(question.multiplechoiceadd)) {
          options = options.concat(question.multiplechoiceadd);
        }
      }  else if (question.type === 'rating') {
        options = Object.keys(question)
        .filter((key) => key.startsWith('ratingOptions'))
        .map((key) => question[key]);
      }
  
      const answer = options.map((option) => ({ choice: option, userIds: [] }));
  
      return {
        question: question.question,
        type: question.type,
        answer: answer,
      };
    });
  };
  const max6input = async () => {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Maksimum 10 tane cevap eklenebilir.",
      placement: 'topRight'
    });
  }
  const onFinish = async (values) => {
    const normalizedQuestions = normalizeQuestions(values.questions);
    if(normalizedQuestions.length===0)
    {
          NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Lütfen soru ekleyiniz.",
          placement: 'topRight'
        });
        return null;
    }

    if(new Date(values.endDate)<new Date(values.startDate))
    {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Başlangıç tarihi bitiş tarihinden büyük olammalı.",
          placement: 'topRight'
        });
        return null;
    }

    const newSurveyData = {
      firstTransactionDate: new Date(),
      firstUserId: localStorage.getItem("uid"),
      firstUserNameSurname:localStorage.getItem("nameSurname"),
      isDeleted: false,
      title: values.title,
      endDate: values.endDate ? new Date(values.endDate) : new Date(),
      startDate: values.startDate ? new Date(values.startDate) : new Date(),
      questions :normalizedQuestions,
      users:[]
    };

  try {
      const result = await FirebaseService.addRecording(odsCollections.survey,newSurveyData);
    if (result.success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: result.message,
        placement: 'topRight'
      });
      setTimeout(() => {
        navigate("/surveys");
      }, 2000);
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  } catch (error) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Bilinmeyen bir hata ile karşılaşıldı.",
      placement: 'topRight'
    });
  }
  };

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: "Geçersiz Form",
      description: "Lütfen form bilgilerini kontrol ediniz.",
      placement: "topRight",
    });
  };

  return (
    <div
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "94vh",
      }}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
        style={{
          maxWidth: "100%",
          padding: "5% 0 0 5%",
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        class="survey-form"
      >
        <div className="news-Edit-container">
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: "Lütfen başlık giriniz!",
              },
            ]}
            wrapperCol={{
              span: 10,
            }}
            style={{
              maxWidth: "100%",
            }}
          >
            <Input
              style={{ borderRadius: 0, borderColor: "#1A446C80", marginLeft: "30px",fontWeight:"700" }}
              placeholder="Başlık Ekle"
            />
          </Form.Item>
          <div style={{ marginTop: "20px", textAlign: "start", marginLeft: "30px" }}>
            <label style={{ fontWeight: "700" }}>
              Soru <label style={{ fontWeight: "700", marginLeft: "40%" }}>Cevap Tipi</label>
            </label>
          </div>
          <hr style={{ width: "90%", margin: "20px 0", borderColor: "#1A446C", marginLeft: "30px" }} />
        </div>
        <Form.List name="questions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key}>
                   <a type="link"  onClick={() => remove(name)}  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    pointerEvents: "auto", 
                    float:"right",
                    marginRight:"100px"
                  }}><img style={{width:"17px"}} alt="edit" src="/images/closeicon.png" /></a><br/>
                  <div style={{display:"flex"}}>
                  <Form.Item
                    {...restField}
                    name={[name, "question"]}
                    fieldKey={[fieldKey, "question"]}
                    style={{ display: "inline-block", width: "50%" }}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen soru ekleyiniz!",
                      },
                    ]}
                  >
                    <Input style={{ borderRadius: 0, borderColor: "#1A446C80", marginLeft: "40px",marginBottom: "2px",fontWeight:"700" }} placeholder="Soru Ekle" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                    initialValue="multipleChoice"
                    style={{ display: "inline-block", width: "50%" }}
                  >
                    <Select
                      style={{ width: "100%",textAlign:"left" }}
                      suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                      className="surveyAddSelect news-author"
                      onChange={(value) => {
                        if (value === "rating") {
                          form.setFieldsValue({
                            [name]: {
                              ratingOptions: Array.from({ length: 10 }, (_, i) => `${i + 1}`),
                            },
                          });
                        } else if (value === "multipleChoice") {
                          form.setFieldsValue({
                            [name]: {
                              multipleChoiceOptions: ["İyi", "Çok İyi", "Kötü", "Çok Kötü"],
                            },
                          });
                        }
                      }}
                    >                      
                      <Option value="multipleChoice" style={{fontWeight:"600",color:"#1A446C"}}>Çoktan Seçmeli</Option>
                      <Option value="rating" style={{fontWeight:"600",color:"#1A446C"}}>Derecelendirme</Option>
                    </Select>
                  </Form.Item>
                  </div>         
                {form.getFieldValue(["questions", name, "type"]) === "rating" ? (
                 Array.from({ length: 10 }, (_, i) => (
                  <Form.Item
                    key={i + 10}
                    {...restField}
                    name={[name, `ratingOptions[${i}]`]}
                    fieldKey={[fieldKey, `ratingOptions[${i}]`]}
                    style={{ display: "inline-block", width: "80px", marginLeft: "10px",fontWeight:"600 !important" }}
                    initialValue={i + 1}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen cevap ekleyiniz!",
                      },
                    ]}
                  >
                    <InputNumber 
                     onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                        }}  
                        className="custom-input-number" 
                        placeholder={`${i + 1}`} 
                        style={{ borderRadius: 0, borderColor: "#1A446C80", width: "100%",color:"#1A446C",fontWeight:"600" }} defaultValue={i + 1} />
                  </Form.Item>
                ))
                  ) : (
                    <>
                    {Array.from({ length: 4 }, (_, i) => (
                      <Form.Item
                        key={i}
                        {...restField}
                        name={[name, `multipleChoiceOptions[${i}]`]}
                        fieldKey={[fieldKey, `multipleChoiceOptions[${i}]`]}
                        style={{ display: "inline-block", width: "15%",marginTop:"5%",marginRight:"2%" }}
                        initialValue={i === 0 ? "İyi" : i === 1 ? "Çok İyi" : i === 2 ? "Kötü" : "Çok Kötü"}
                        rules={[
                          {
                            required: true,
                            message: "Lütfen cevap ekleyiniz!",
                          },
                        ]}
                      >
                        <Input
                          placeholder={`Seçenek ${i + 1}`}
                          style={{display: "flex", borderRadius: 0, borderColor: "#1A446C80",color:"#1A446C",fontWeight:"600" }}
                          defaultValue={i === 0 ? "İyi" : i === 1 ? "Çok İyi" : i === 2 ? "Kötü" : "Çok Kötü"}
                        />
                      </Form.Item>
                    ))}</>
                  )}
        {(form.getFieldValue(["questions", name, "type"]) === "multipleChoice" ||form.getFieldValue(["questions", name, "type"]) === undefined)  && (
          <>
       <Form.List
          name={[name, 'multiplechoiceadd']}
          fieldKey={[fieldKey, 'multiplechoiceadd']}
          >
      {(subFields, { add: subAdd, remove: subRemove }) => (
     <>
            <Form.Item>
                  <Button
                          onClick={() => {
                              if (subFields.length < 6) { 
                                subAdd();
                              }
                              else{
                                max6input()
                              }
                          }}
                          style={{marginTop: "-120px",float:"right", background: "transparent", borderColor: "#1A446C80", color: "#1A446C", fontWeight: "bold", borderRadius: "0px" }}
                      >
                          Yeni Cevap Ekle
                      </Button>
                  </Form.Item>
                  <Space style={{ marginBottom: 8, display: 'flex', marginLeft: '40px' }} align="baseline">
                  {subFields.map(({ key: subKey, name: subName, ...subRestField }) => (
                    <div key={subKey} style={{ display: 'flex', width: '160px', marginTop: '-30px' }}>
                        <Form.Item
                          {...subRestField}
                          name={subName}
                          fieldKey={subKey}
                          rules={[
                            {
                              required: true,
                              message: 'Lütfen cevap ekleyiniz!',
                            },
                          ]}
                        >
                          <Input
                            size="middle"
                            style={{ borderRadius: 0, borderColor: '#1A446C80', width: '140px', marginRight: '8px' }}
                            min={0}
                            placeholder="Cevap Ekle"
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => subRemove(subName)} style={{ marginBottom: '15%', marginLeft: '1%' }} />
                      </div>
                    ))}
                  </Space>
                </>
              )}
            </Form.List>
                  <br />
              </>
            )}
      <hr style={{ width: "90%", margin: "20px 0", borderColor: "#1A446C", marginLeft: "30px" }} />
                </div>
              ))}
              <Button
                onClick={() => add()}
                icon={<img alt="plus" src="/images/plusbutton.png" style={{ width: "60%" }} />}
                style={{ background: "transparent", borderColor: "#1A446C80", color: "#1A446C", fontWeight: "bold", borderRadius: "0px", justifyContent: "center" }}
              >
                Yeni Soru Ekle
              </Button>
            </>
          )}
        </Form.List><br/>
        <div style={{display:"flex",marginTop:"60px"}}>
        <label style={{textAlign:"left",float:"left", fontWeight: "600",marginRight:"70px",marginLeft:"40px"}}> Tarih Aralığı :</label>
        <Form.Item  
            name="startDate"
            rules={[
                  {
                  required: true,
                  message: 'Lütfen tarih seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 25,
                }}
                style={{
                display:"left",
                width: "335px",
                marginRight:"70px"
                }}>
              <DatePicker 
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} placeholder="Başlangıç Tarihi Ekle" size="middle"/>
            </Form.Item>  
            <Form.Item  
            name="endDate"
            rules={[
                  {
                  required: true,
                  message: 'Lütfen tarih seçiniz !',
                  },
              ]}
              wrapperCol={{
                span: 25,
                }}
                style={{
                display:"left",
                width: "335px",
                }}>
              <DatePicker 
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"100%"}} placeholder="Bitiş Tarihi Ekle" size="middle"/>
            </Form.Item>  
            </div>
        <Form.Item wrapperCol={{ offset: 15, span: 18 }}>
          <Button htmlType="submit" style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500", borderRadius: "0" }}>
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SurveyAdd;
