import React from 'react';
import GoogleMapReact from 'google-map-react';
import  {map}  from "../../environments/environment"

const MapsComponent = ({ coordinates }) => {
  if (!coordinates || coordinates.length !== 2) {
    return <div>No valid coordinates</div>;
  }

  return (
    <div style={{ height: '200px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: map.apiKey }}      
        defaultCenter={coordinates}
        defaultZoom={13}
      >
        <Marker lat={coordinates[0]} lng={coordinates[1]} text="Your Location" />
      </GoogleMapReact>
    </div>
  );
};

const Marker = () => <div style={{ color: 'red' }}>📍</div>;

export default MapsComponent;
