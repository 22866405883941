import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select, Checkbox } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { Calendar } from 'antd';
import FirebaseService from "../../services/firebaseService";
import  {odsCollections,categoryName}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';





const onPanelChange = (value, mode) => {
  //console.log(value.format('YYYY-MM-DD'), mode);
};

const onChange = (value) => {
 // console.log(`selected ${value}`);
};
  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TrainingAdd = () => {

    // const [previewOpen, setPreviewOpen] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    // const [previewTitle, setPreviewTitle] = useState('');
    // const [fileList, setFileList] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [trainers, setTrainers] = useState([]);
    const [category, setCategory] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        const data = await FirebaseService.getTrainerUnDeleted();
        const trainerOptions = data.map(author => ({
          value: author.key,
          label: author.nameSurname,
        }));
        setTrainers(trainerOptions);

        const categories = await FirebaseService.getCategoryData(categoryName.education);
        const categoryOptions = categories.map(category => ({
          value: category,
          label: category,
        }));
        setCategory(categoryOptions)
      };
      fetchData();
    }, []);



    // const handleCancel = () => setPreviewOpen(false);
    // const handlePreview = async (file) => {
    //   if (!file.url && !file.preview) {
    //     file.preview = await getBase64(file.originFileObj);
    //   }
    //   setPreviewImage(file.url || file.preview);
    //   setPreviewOpen(true);
    //   setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    // };
    // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    // const uploadButton = (
    //   <div style={{width:"1000px" }}>
    //     <img alt="plus" src="/images/uploadplus.png" />
    //     <div
    //       style={{
    //         marginTop: 8,
    //       }}
    //     >
    //       Resim Yükle
    //     </div>
    //   </div>
    //   );

    const onFinish = async (values) => {
      console.log("editor",editorContent)
     
      const currentDate =new Date()
      const educationData = {
        firstTransactionDate: currentDate,
        firstUserId: localStorage.getItem("uid"),
        isDeleted: false,
        description: editorContent,
        title: values.title,
        categoryName:values.categoryName,
        trainerId:values.author !==undefined ? values.trainer:null,
        trainerNameSurname:values.author !==undefined ? trainers.find(trainer=>trainer.value===values.trainer).label:null,
        releaseDate:values.releaseDate ? new Date(values.releaseDate) : new Date(),
        videoId:values.videoId,
        url:values.url??null
      };
      // values.image=fileList.length > 0 ? values.image : null;
      if(educationData.releaseDate.getDate()< currentDate.getDate())
      {
        NotificationService.openErrorNotification({
          title: 'Geçersiz Tarih',
          description: "Lütfen gelecekten bir tarih seçiniz.",
          placement: 'topRight'
        });
        return null;
      }
      
    try {
      // if (values.image) {
      //   educationData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(odsCollections.education, values.image.file);
      
      const result = await FirebaseService.addRecording(odsCollections.education,educationData);
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        setTimeout(() => {
          navigate("/trainings");
        }, 2000);
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });}
      
      // }else{
      //   NotificationService.openErrorNotification({
      //     title: 'Geçersiz Resim',
      //     description: "Lütfen resim seçiniz!",
      //     placement: 'topRight'
      //   });
      // }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
  }; 

  const validateURL = (rule, value, callback) => {
    if (value === undefined || value === null || value === '') {
      callback();
      return;
    }
  
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  
    if (!urlPattern.test(value)) {
      callback('Lütfen geçerli bir URL giriniz.');
      return;
    }
  
    callback();
  };
  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (content) => {
    console.log("cotent",content)
    setEditorContent(content);
  };
   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

          {/* <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
            rules={[
              {
              required: true,
              message: 'Lütfen resim seçiniz!',
              },
          ]}
            >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="generic-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
            </Modal>
          </Space> */}

            <Form.Item
            name="title"
            rules={[
                {
                required: true,
                message: 'Lütfen eğitim başlığını giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Başlık"/>
            </Form.Item>

            
            <Form.Item
              name="trainer"
              wrapperCol={{
                span: 5,
                }}
                style={{
                maxWidth: "100%",
                }}
              >
                <Select
                  suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                  className="news-author"
                  showSearch
                  placeholder="Eğitmen Seçiniz"
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={filterOption}
                  options={trainers}
                />
              </Form.Item>

            <Form.Item
            name="description"
           /* rules={[
                {
                required: true,
                message: 'Lütfen eğitim içeriğini giriniz!',
                },
            ]}*/
            >
         <SunEditor
          setContents={editorContent}
          onChange={handleEditorChange}
          placeholder="Eğitim İçeriği"
          setOptions={{
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['removeFormat'],
              ['fontColor', 'hiliteColor'],
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'lineHeight'],
              ['link'],
              ['fullScreen', 'showBlocks', 'codeView'],
            ],
          }}
        />            
        </Form.Item>

            <Form.Item
            name="videoId"
            rules={[
                {
                required: true,
                message: 'Lütfen yotube idsini giriniz!',
                },
            ]}
            wrapperCol={{
              span: 5,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Youtube Id"/>
            </Form.Item>

            <Form.Item
            name="url"
            rules={[
              { validator: validateURL }
            ]}
            wrapperCol={{
              span: 5,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
              <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Url Ekle"/>
            </Form.Item>

            <div className="news-Edit-container">

              <Form.Item 
                name="categoryName"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen haber kategorisini seçiniz !',
                    },
                ]}
                wrapperCol={{
                  span: 5,
                  }}
                  style={{
                  maxWidth: "100%",
                  }}
                > 
                  <Select
                        suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                        placeholder="Kategori Seç"
                        style={{borderRadius:0, borderColor:"#1A446C80"}}
                        options={category}
                      />
                  
              </Form.Item>
            </div>

            <Form.Item
              name="release"
              wrapperCol={{
                span: 4,
                }}
                style={{
                maxWidth: "100%",
                }}>
              <Checkbox checked={showCalendar} onChange={() => setShowCalendar(!showCalendar)} style={{fontWeight:"500",color:"#1A446C"}}>
                İleri Tarihte Yayımla
              </Checkbox>
            </Form.Item>
          {showCalendar && (
            <Form.Item 
            name="releaseDate"
            >       
              <Calendar fullscreen={false} onPanelChange={onPanelChange} />   
            </Form.Item> )} 

            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default TrainingAdd;