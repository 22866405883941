import React,{ useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Button, Progress } from 'antd';
import moment from 'moment';

const IpmProjectDetail = () => {
  const location = useLocation();
  const { record } = location.state;
 

  const calculateProgress = (startDate, endDate, projectProcess) => {
    const currentDate = moment();
    const start = moment(startDate);
    const end = moment(endDate);
    const totalDays = end.diff(start, 'days');
    const elapsedDays = currentDate.diff(start, 'days');
    const progressPercent = (elapsedDays / totalDays) * 100;
  
    const processes = projectProcess || []; 
  
    const formattedProcesses = processes.map(process => ({
      name: process.name,
      date: process.date
    }));
  
    return { progress: progressPercent.toFixed(2), start, end, processes: formattedProcesses };
  };
  
 

  const applicationAmountData = record.applicationAmount[0];
  const applicationPercentage = (applicationAmountData.amount / applicationAmountData.maxAmount) * 100;

  const supportData = record.supportAmount[0];
  const supportPercentage = (supportData.amount / supportData.maxAmount) * 100;


 
  return (
    <div style={{padding:"20px"}}>
    <Card
        title={
            <>
            <h2 style={{ fontWeight: 'bold', color: "#A47B5A" }}> {record.companyName}</h2>
            <h3 style={{ fontWeight: 'bold', color: "#A47B5A" }}>{record.programName}</h3>
            </>
        }       
        style={{textAlign: 'left' }}
        extra={
          <p  style={{ color:"#1A446C80"}}>{(record.firstTransactionDate)}</p>
        }
      >
         <div style={{justifyContent:"space-between", display:"flex", padding:"20px"}}>
                    <div  style={{display:"flex"}}>
                    <div>
                         <div style={{color:"#1A446C"}}>
                        Operasyon Danışmanı
                            {Array.isArray(record.operationName) &&
                            record.operationName.map((item) => (
                                <span key={item.nameSurname}> {item.nameSurname}</span>
                            ))}
                        </div>
                        <div style={{color:"#1A446C"}}>
                            Proje Geliştirme Danışmanı:
                            {Array.isArray(record.businessDevelopmentName) &&
                            record.businessDevelopmentName.map((item) => (
                                <span key={item.nameSurname}> {item.nameSurname}</span>
                            ))}
                        </div>
                        <div style={{color:"#1A446C",fontSize:"18px"}}>
                           <span style={{color:"#A47B5A"}}> Durum:  </span> {record.processStatus}
                        </div>
                       
                    </div>

                    </div>
        </div>
      </Card>
     
      <div  style={{width: '88%'}}>
     
      <span style={{ color: "#1A446C", fontWeight: "bold" }}>Başvuru Tutarı: {applicationAmountData.amount} / {applicationAmountData.maxAmount} </span>
      <Progress percent={applicationPercentage} style={{  flexGrow: 1 }} strokeColor="#A47B5A" />
     


      <span style={{ color: "#1A446C", fontWeight: "bold" }}>Destek Tutarı: {supportData.amount} / {supportData.maxAmount}</span>
      <Progress percent={supportPercentage} strokeColor="#A47B5A" />
    </div>

     
      <div  style={{color:"#1A446C", textAlign:"left"}}>
       <p style={{fontWeight:"bold" }}> Kurumlarla İlgili Tarihler</p>
      {record.companyDates.map((item, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <p style={{ color: "#1A446C", textAlign:"left" }}>{item.name} {(item.date)} </p>
        </div>
      ))}
    </div>
     </div>
  );
};

export default IpmProjectDetail;
