import React,{useState,useEffect,useRef} from "react";
import { Button, Form, Input, Select, DatePicker, Upload, Space, Checkbox, Divider,InputNumber } from 'antd';
import NotificationService from "../../../../services/antNotificationService";
import FirebaseService from "../../../../services/firebaseService";
import  {odsCollections}  from "../../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined,MinusOutlined } from '@ant-design/icons';
import { useLocation  } from 'react-router-dom';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

const onFinishFailed = (errorInfo) => {
  NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
};

const IpmProjectBasedEdit = () => {

    const [users, setUsers] = useState([]);
    const [advisor, setAdvisor] = useState([]);
    const [status, setStatus] = useState([]);
    const location = useLocation();

    const navigate = useNavigate();

    const record = location.state && location.state.record;
    const recordIpm = location.state && location.state.recordIpm
    useEffect(() => {
      window.scrollTo(0, 0);

     // console.log(recordIpm);
      const fetchData = async () => {
          const users = await FirebaseService.getUserData();
          const usersOption = users.map(user => ({
            value: user.userId,
           // label: user.nameSurname + "-" + user.email +"-" +user.companyName,
           label: user.nameSurname

          }));
          setUsers(usersOption)

         const advisors = await FirebaseService.getAdvisorData();
          const advisorsOption = advisors.filter(f=>!f.isDeleted).map(advisor => ({
            value: advisor.key,
            label: advisor.nameSurname,
          }));
          setAdvisor(advisorsOption)
          setStatus([{
            value: "Proje başlangıç aşamasında",
            label:  "Proje başlangıç aşamasında",
          },
          {
            value: "Analiz alındı",
            label:  "Analiz alındı",
          },
          {
            value: "Firma ile görüşme bekleniyor",
            label:  "Firma ile görüşme bekleniyor",
          },
          {
            value: "Yazım süreci",
            label:  "Yazım süreci",
          },
          {
            value: "Proje araştırma aşaması",
            label:  "Proje araştırma aşaması",
          },
          {
            value: "Yazım devam ediyor",
            label:  "Yazım devam ediyor",
          },
          {
            value: "Firmadan bilgi/belge bekleniyor",
            label:  "Firmadan bilgi/belge bekleniyor",
          },
          {
            value: "Yazım tamamlandı",
            label:  "Yazım tamamlandı",
          },
          {
            value: "Firma kontrolünde",
            label:  "Firma kontrolünde",
          },
          {
            value: "Kuruma teslim edildi ",
            label:  "Kuruma teslim edildi ",
          },
          {
            value: "Kurum değerlendirme aşamasında",
            label:  "Kurum değerlendirme aşamasında",
          },
          {
            value: "Proje onaylandı",
            label:  "Proje onaylandı",
          },
          {
            value: "Proje reddedildi",
            label:  "Proje reddedildi",
          },
          {
            value: "Revizyonlar tamamlanıyor",
            label:  "Revizyonlar tamamlanıyor",
          },
        ])
      };

      fetchData();
    }, []);

      const onFinish = async (values) => {
        const currentDate =new Date()

        const selectedAdvisorIds = values.operationName;
        const selectedbusinessDevelopmentName = values.businessDevelopmentName;


        const advisorData = selectedAdvisorIds.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });
        const businessDevelopmentName = selectedbusinessDevelopmentName.map(advisorId => {
          const selectedAdvisor = advisor.find(a => a.value === advisorId);
          return {
            advisorId: advisorId,
            nameSurname: selectedAdvisor ? selectedAdvisor.label : null
          };
        });
        values.companyDates.map(b=>{
            if (b.date1 !== undefined && b.date1 === null) {          
              NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Lütfen tarih bilgilerini boş bırakmayınız.",
              placement: 'topRight'
            });
            return null;
          }})

        const projectData = {
            firstTransactionDate: record.firstTransactionDateEdit??currentDate,
            firstUserId: record.firstUserId??localStorage.getItem("uid"),
            lastTransactionDate: currentDate,
            lastUserId: localStorage.getItem("uid"),
            isDeleted: record.isDeleted,
            companyName: values.companyName,
            companyDates: values.companyDates.map(step => ({
                name:step.name,
                date:step.date1 && step.date1 !== undefined ? new Date(step.date1) : step.dateEdit
            })),
            operationName:advisorData,
            businessDevelopmentName:businessDevelopmentName,
            programName:values.programName,
            processStatus :values.processStatus,
            applicationAmount:[{amount:values.applicationAmount1,maxAmount:values.applicationAmount2}],
            supportAmount:[{amount:values.supportAmount1,maxAmount:values.supportAmount2}]
        };   
        try {
            const result = await FirebaseService.updateIpmProjectBased(record.key, projectData);
           if (result.success) {
             NotificationService.openSuccessNotification({
               title: 'İşlem Başarılı',
               description: result.message,
               placement: 'topRight'
             });
             setTimeout(() => {
              if(recordIpm.addIpm)
              {
                navigate("/ipmprojectadd",{ state: { result }});
              }
              else{
                const record=recordIpm.record;
                navigate("/ipmprojectedit",{ state: { record }});
              }
             }, 2000);
           } else {
             NotificationService.openErrorNotification({
               title: 'İşlem Başarısız',
               description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
               placement: 'topRight'
             });}    
         }
          catch (error) {
   
           NotificationService.openErrorNotification({
             title: 'İşlem Başarısız',
             description: "Bilinmeyen bir hata ile karşılaşıldı.",
             placement: 'topRight'
           });
         }
    }; 

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
                companyName:record.companyName,
                programName:record.programName,
                processStatus:record.processStatus,
                applicationAmount1:record.applicationAmount[0].amount,
                applicationAmount2:record.applicationAmount[0].maxAmount,
                supportAmount1:record.supportAmount[0].amount,
                supportAmount2:record.supportAmount[0].maxAmount,
                companyDates:record.companyDates,
                operationName:record.operationName.map(user=>user.advisorId),
                businessDevelopmentName:record.businessDevelopmentName.map(user=>user.advisorId),
              }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

          <div className="news-Edit-container">
              <Form.Item 
              name="companyName"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen şirket ismi giriniz !',
                  },
              ]}
              wrapperCol={{
                span: 15,
                }}
                style={{
                maxWidth: "100%",
                }}
              > 

                <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Kurum Adı"/>            
              </Form.Item>
            </div>

            <div className="news-Edit-container">
              <Form.Item 
              name="programName"
              rules={[
                  {
                  required: true,
                  message: 'Lütfen destek programı adı giriniz !',
                  },
              ]}
              wrapperCol={{
                span: 15,
                }}
                style={{
                maxWidth: "100%",
                }}
              > 
                <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Destek Programı Adı"/>                      
              </Form.Item>
            </div>

            <div className="news-Edit-container">
            <Form.Item 
            name="operationName"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>Operasyon Danışmanı</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"start"
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen danışman seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="Danışman"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div>

              <div className="news-Edit-container">
            <Form.Item 
            name="businessDevelopmentName"
            label={<label style={{ color: "#1A446C",fontWeight:"500" }}>İş Geliştirme Danışmanı</label>}
            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen danışman seçiniz !',
                },
            ]}
            > 
              <Select
                mode="tags"
                placeholder="Yönetici"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={advisor}
                size="large"
              />
            </Form.Item>
              </div> 

              <div className="news-Edit-container">
            <Form.Item 
            name="processStatus"
            wrapperCol={{
              span: 14,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"start"
              }}
              rules={[
                {
                required: true,
                message: 'Lütfen durum seçiniz !',
                },
            ]}
            > 
              <Select
                placeholder="Durum Ekle"
                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left"}}
                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                options={status}
                size="large"
              />
            </Form.Item>
              </div>

              <h3 style={{textAlign:"start", marginLeft:"18px", marginTop:"30px",color:"#1A446C"}}>Tutarlar</h3>
              <div style={{marginLeft:"40px"}}>
              <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
            <label style={{ color: "#1A446C",fontWeight:"500" }}>Başvuru Tutarı</label>
              <Form.Item
                name='applicationAmount1'
                rules={[{ required: true, message: 'Lütfen tutarı giriniz!' }]}
                style={{marginLeft:"20px"}}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Tutar"/>
              </Form.Item>  
              <Form.Item
                name='applicationAmount2'
                rules={[{ required: true, message: 'Lütfen maksimum tutarı giriniz!' },({ getFieldValue }) => ({
                  validator(_, value) {
                    const maxTutar = getFieldValue('applicationAmount');
                    if (!maxTutar || value >= maxTutar) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Maksimum tutar, tutardan büyük olmalı!');
                  },
                }),]}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Maksimum Tutar"/>
              </Form.Item>  
            </Space>

            <Space style={{ display: 'flex', marginBottom: 3 }} align="baseline">
            <label style={{ color: "#1A446C",fontWeight:"500" }}>Destek Tutarı</label>
              <Form.Item
                name='supportAmount1'
                rules={[{ required: true, message: 'Lütfen tutarı giriniz!' }]}
                style={{marginLeft:"28px"}}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Tutar"/>
              </Form.Item>  
              <Form.Item
                name='supportAmount2'
                rules={[{ required: true, message: 'Lütfen maksimum tutarı giriniz!' },({ getFieldValue }) => ({
                  validator(_, value) {
                    const maxTutar = getFieldValue('supportAmount1');
                    if (!maxTutar || value >= maxTutar) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Maksimum tutar, tutardan büyük olmalı!');
                  },
                })]}
              >
                <InputNumber 
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                  }}
                size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"150px"}} placeholder="Maksimum Tutar"/>
              </Form.Item>  
            </Space>
            </div>

            <h3 style={{textAlign:"start", marginLeft:"18px", marginTop:"30px",color:"#1A446C"}}>Kurumla İlgili Tarihler</h3>
          <div style={{marginLeft:"40px"}}>
            <Form.List name="companyDates">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            rules={[{ required: true, message: 'Lütfen tarih başlığı giriniz!' }]}
                          >
                            <Input size="large" style={{borderRadius:0, borderColor:"#1A446C80",width:"210px"}} placeholder="Tarih Başlığı"/>
                          </Form.Item>
                          <Form.Item  
                            {...restField}
                            name={[name, 'date1']}
                           >
                              <DatePicker 
                                suffixIcon={<img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" />}
                                style={{borderRadius:0, borderColor:"#1A446C80",textAlign:"left", width:"210px"}} placeholder="Tarih Ekle" size="large"
                                defaultValue={
                                    record.companyDates && 
                                    record.companyDates[key] &&
                                    record.companyDates[key].date 
                                      ? dayjs(record.companyDates[key].date, dateFormat)
                                      : undefined
                                  }/>
                            </Form.Item>  
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button style={{width:"50%",float:"left",border:"0"}} size="large" onClick={() => add()} block icon={<img style={{width:"15px",float:"right"}} alt="edit" src="/images/plusbutton.png" />}>
                      Tarih Ekle
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            </div>
                 

            <Form.Item
            wrapperCol={{
                offset: 10,
                span: 10,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500",borderRadius:0}}>Kaydet</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default IpmProjectBasedEdit;