import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Input, notification } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import emailjs from '@emailjs/browser';
import { emailJs } from '../../environments/environment';


const CampaignDetail = () => {
  const location = useLocation();
  const { state } = location;
  const campaignItem = state && state.record;
  const currentUser = { id: localStorage.getItem("uid") }; 
  const [liked, setLiked] = useState(false);
  const [favoriteUserIdCount, setFavoriteUserIdCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userEmail, setUserEmail] = useState(""); 
  const [userFirstName, setUserFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleInputChange = (e) => {
   
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNumber(onlyNumbers);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchUserData = async () => {
      try {
        const userData = await FirebaseService.getFilteredUserData();
        setUserEmail(userData.email);
        setUserFirstName(userData.nameSurname);
     } catch (error) {
      //  console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, []); 
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (campaignItem && campaignItem.favoriteUserId.includes(currentUser.id)) {
          setLiked(true);
        } else {
          setLiked(false);
        }
  
        setFavoriteUserIdCount(campaignItem.favoriteUserId.length);
      } catch (error) {
       // console.error('Data fetching error:', error);
      }
    };
  
    fetchData();
  }, [campaignItem, currentUser.id, userEmail, userFirstName]);
  const handleFirstNameChange = (event) => {
    setUserFirstName(event.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleTalepEt = async () => {
    const ad = document.getElementById("ad").value;
    const eposta = document.getElementById("eposta").value;
    const telefon = document.getElementById("telefon").value;
    const aciklama = document.getElementById("aciklama").value;
  
    if (!ad || !eposta || !telefon) {
      notification.error({
        message: 'Lütfen tüm zorunlu alanları doldurunuz.',
      });
      
      return;
    }
    if (telefon.length < 10) {
      notification.warning({
        message: 'Telefon numarası en az 10 karakter olmalıdır.',
      });
      return;
    }
  
    const userData = await FirebaseService.getFilteredUserData();
  
    
    const purchaseRequest = {
      serviceId: "",
      createdDate: new Date(),
      educationId:"" ,
      campaignsId:campaignItem.key,
      email: eposta,
      note: aciklama,
      phoneNumber: telefon,
      title: campaignItem.title,
      type: "campaigns",
      userId: userData.userId,
      userNameSurname: `${ad}`,
    };
    try {
      await FirebaseService.addPurchaseRequest(purchaseRequest);
      notification.success({
        message: 'Talebiniz başarıyla oluşturuldu.',
      });
      setIsModalVisible(false);


/*
       const data = await FirebaseService.getUserData();
        const filteredData = data.filter(item =>
          ((item.userType.includes('admin') && item.authorizationPages.includes('Satın Alma Talepleri'))) || item.userType.includes('headAdmin')
        );
        console.log("filteredData", filteredData);
        
        const headAdminEmailsSet = new Set(filteredData.map(item => item.email));
        const headAdminEmails = Array.from(headAdminEmailsSet);
        
        console.log(headAdminEmails);

     const serviceId = emailJs.serviceId;
      const templateId = emailJs.templateId;
      const publicKey = emailJs.publicKey;
      
      headAdminEmails.forEach(email => {
        const templateParams = {
          user_email: email,
          from_name: 'ODS',
          message: 'Kampanya için satın alma talebi gelmiştir.',
        };
      
        emailjs.send(serviceId, templateId, templateParams, publicKey)
          .then(
            (response) => {
              console.log('Email sent successfully:', response);
            },
            (error) => {
              console.error('Failed to send email:', error);
            }
          );
      });

      */
        
      
    } catch (error) {
     //console.error("Satın alma talebi eklenirken hata oluştu:", error);
      notification.error({
        message: 'Talep oluşturulurken bir hata oluştu. Lütfen tekrar deneyin.',
      });
    }
  };


  

  const handleLikeToggle = async () => {
    setLiked((prevLiked) => !prevLiked);
  
    await updateCampaignFavoriteStatus(campaignItem.key, currentUser.id, !liked);
  
  
    setFavoriteUserIdCount(
      !liked ? favoriteUserIdCount + 1 : favoriteUserIdCount - 1
    );
  };
  const updateCampaignFavoriteStatus = async (campaignItem, userId, liked) => {
    try {
      if (!campaignItem) {
       // console.error('Invalid blogsItemId');
        return;
      }
   
    
      await FirebaseService.updateCampaignFavoriteStatus(campaignItem, userId, liked);
     // console.log('Favorite status updated successfully.başarılı');
    } catch (error) {
     // console.error('Error updating favorite status:', error);
    }
  };

  if (!campaignItem) {

    return <div>Veri bulunamadı</div>;
  }
  return (
  <div style={{margin:"3% 0 0 5%"}}>
    <Card
    style={{
      width: 1000, 
      border:"none"
    }}
      >
         {campaignItem.imageUrl && (
  <div
    style={{
      width: '1050px',
      height: '250px',
      borderRadius: '15px',
      overflow: 'hidden', 
    }}
  >
    <img
      src={campaignItem.imageUrl}
      alt="Blog Resmi"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',  
      }}
    />
  </div>
)}
        <div style={{textAlign: "start", marginTop:"30px"}}>
        {currentUser.id ? (
         <div style={{ display: "flex", alignItems: "center", float: "right", marginTop: 0 }}>
         <img
           alt="like"
           src={liked ? "../images/likedIcon.png" : "/images/likeicon.png"}
           style={{ width: "38px", cursor: 'pointer' }}
           onClick={handleLikeToggle}
         />
         <h4 style={{ marginLeft: "10px", marginTop: "10px", color:'#1A446C' }}>{favoriteUserIdCount}</h4>
       </div>
        ) : (
         <></>
        )}       
           <h3 style={{ color: '#1A446C' }}>{campaignItem.title} </h3>
           <p style={{ textDecoration: 'none', color: '#1A446C' }}>{campaignItem.companyName}</p>
           <div style={{ color: '#1A446C', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', height: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: campaignItem.description }} />
          </div>

          {campaignItem.url && (
            <div>
              <p style={{ color: '#1A446C' }}>Kampanyanın Detayına Buradan Ulaşabilirsiniz;</p>
              <p><a href={campaignItem.url} target="_blank" rel="noopener noreferrer">{campaignItem.url}</a></p>
            </div>
          )}
        </div>
        {currentUser.id ? (
         <Button htmlType="submit" style={{ background: '#1A446C', color: 'white', float: 'right' }} onClick={showModal}>
         Satın Al
       </Button>
        ) : (
         <></>
        )}       

      
        <Modal

          title={<span style={{ color: '#A47B5A' }}>Satın Alma Formu</span>}
          visible={isModalVisible}
  onCancel={() => setIsModalVisible(false)}  
          onOk={null}
        
          style={{ color: '#A47B5A', height: '1000px'}}
          footer={null} 
          closeIcon={<img src="/images/popupcloseicon.png" alt="popupcloseicon" style={{ width: '16px', height: '16px', color: '#A47B5A' }} />}
        >
          
          
          <div>
            
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', marginTop: '45px' }}>
      <Input
        id="ad"
        placeholder="Ad Soyad"
        style={{ marginRight: '10px', width: '48%', padding: '10px' }}
        value={userFirstName}
        onChange={handleFirstNameChange}
      />
    
    </div>

            <Input id="eposta" placeholder="E-posta" style={{ marginBottom: '20px', padding:'10px' }} value={userEmail} disabled />

           
            <Input
      id="telefon"
      placeholder="Telefon numarası: (05xxxxxxxx)"
      style={{ marginBottom: '20px', padding: '10px' }}
      value={phoneNumber}
      onChange={handleInputChange}
    />

            <Input.TextArea id="aciklama" placeholder="Açıklama" />
            <Button type="primary" style={{ bottom: '0px', right: '0px',marginLeft:'300px',marginTop:'30px', background:'#1A446C'}} onClick={handleTalepEt}>
           Satın Alma Talep Et 
          </Button>
          </div>
         
        </Modal>
        
      </Card>
  </div>
);
};
export default CampaignDetail;